import React from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CardHeader,
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import { Link, Redirect } from "react-router-dom";
import firebase from "firebase";
import urls from "../../../urls";
import logo from "../../../assets/img/brand/logo-purple.png";
import i18n from "variables/translations/i18n";
import { Helmet } from "react-helmet";
import { META_LOGIN_TITLE, META_LOGIN_DESCRIPTION } from "constants/MetaTagsConstants";
import { WEBSITE_NAME } from "constants/CompanyConstants";

class Login extends React.Component {
  state = {
    email: "",
    password: "",
    loginCorrect: null,
    errorMessage: "",
    successMessage: "",
    redirectForgotPassword: false,
    notVerified: false,
  };

  onPressLogin() {
    if (!this.state.email) {
      this.setState({ errorMessage: i18n.t("meldingen.email_leeg") });
    } else {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.state.email, this.state.password)
        .then((user) => {
          let verified = user.user.emailVerified;
          if (verified) {
            // Check if user is active
            let userObj = firebase.database().ref("users/" + user.user.uid);
            userObj.once("value", (snapshot) => {
              let data = snapshot.val();
              if (data.active === false) {
                this.setState({
                  errorMessage: i18n.t("meldingen.account_verwijderd"),
                });
              } else {
                firebase.analytics().logEvent("login");
                this.setState({ loginCorrect: true });
              }
            });
          } else {
            this.setState({
              errorMessage: i18n.t("meldingen.verifieer_account"),
              successMessage: "",
              notVerified: true,
            });
          }
        })
        .catch((error) => {
          let errorCode = error.code;
          let errorMessage = error.message;
          if (errorCode === "auth/wrong-password")
            this.setState({
              errorMessage: i18n.t("meldingen.wachtwoord_incorrect"),
            });
          else if (errorCode === "auth/user-not-found")
            this.setState({
              errorMessage: i18n.t("meldingen.geen_account"),
            });
          else this.setState({ errorMessage: errorMessage });
        });
    }
  }

  resendVerificationLink() {
    let loggedInUser = firebase.auth().currentUser;
    let self = this;
    let actionCodeSettings = {
      url: urls.base + urls.login,
    };
    let successMessage = i18n.t("mail_verstuurd");

    loggedInUser
      .sendEmailVerification(actionCodeSettings)
      .then(function () {
        self.setState({
          notVerified: false,
          errorMessage: "",
          successMessage: successMessage,
        });
      })
      .catch(function (error) {
        self.setState({ errorMessage: error.message });
        console.error(error);
      });
  }

  render() {
    if (this.state.loginCorrect) return <Redirect to={urls.dashboard} />;
    return (
      <>
        <Helmet>
          <title>{META_LOGIN_TITLE} · {WEBSITE_NAME}</title>
          <meta name="description" content={META_LOGIN_DESCRIPTION}/>
        </Helmet>
        <AuthHeader
          title={i18n.t("auth.login_titel")}
          lead={i18n.t("auth.login_subtitel")}
        />
        <Container className="mt--8 mb-5 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0 pt-4">
                <CardHeader className="bg-transparent pb-4">
                  <div className="text-muted text-center mt-2 mb-3 mr-5 ml-5">
                    <img className="mw-100" src={logo} />
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form">
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: this.state.focusedEmail,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={i18n.t("auth.email")}
                          type="email"
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              this.onPressLogin();
                            }
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={i18n.t("auth.wachtwoord")}
                          type="password"
                          onFocus={() =>
                            this.setState({ focusedPassword: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedPassword: false })
                          }
                          onChange={(e) =>
                            this.setState({ password: e.target.value })
                          }
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              this.onPressLogin();
                            }
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id=" customCheckLogin"
                        type="checkbox"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor=" customCheckLogin"
                      >
                        <span className="text-muted">
                          {i18n.t("auth.onthouden")}
                        </span>
                      </label>
                    </div>
                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="info"
                        type="button"
                        onClick={this.onPressLogin.bind(this)}
                      >
                        {i18n.t("auth.login_btn")}
                      </Button>
                    </div>
                    <Row className="justify-content-center">
                      <small className="text-warning">
                        {this.state.errorMessage}
                      </small>
                    </Row>
                    <Row className="justify-content-center">
                      <small className="text-success">
                        {this.state.successMessage}
                      </small>
                    </Row>
                    <Row className="justify-content-center mt-3">
                      {this.state.notVerified && (
                        <a
                          onClick={() => this.resendVerificationLink()}
                          href="#"
                        >
                          {i18n.t("auth.verificatie_opnieuw")}
                        </a>
                      )}
                    </Row>
                  </Form>
                  <Row className="mt-2">
                    <Col xs="6">
                      <Link to={urls.forgotPassword} className="text-muted">
                        <small>{i18n.t("auth.wachtwoord_vergeten")}</small>
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Login;
