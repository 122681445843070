import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import Graph from "./Graph";

const GraphCard = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const formatTitle = (type) => {
    const preString = t("per");
    let formattedType = "";
    if (type === "date" || type === "week" || type === "month")
      formattedType = t("day");
    else if (type === "quarter") formattedType = t("week");
    else if (type === "year") formattedType = t("month");
    return preString + " " + formattedType;
  };
  return (
    <Col lg={props.cardSize}>
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <div className="col">
              <h6 className="surtitle">{formatTitle(props.dataFilterType)}</h6>
              <h5 className="h3 mb-0">{props.cardSubTitle}</h5>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <Graph
            className={props.className}
            ref={!!ref ? ref : props.ref}
            data={props.data}
            options={props.options}
          />
        </CardBody>
      </Card>
    </Col>
  );
});

export default GraphCard;
