import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { addNameToHours, isAdminPage } from "views/functions/generic";
import HoursDashboard from "../../../../../components/Dashboards/Admin/HoursOverview";
import { calculateHoursAndEarningsInPeriod } from "../../../../functions/dataService.js";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

const UrenOverzicht = (props) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { userId } = useParams();
  const projects = useSelector(
    (state) => state.firebaseReducer.ordered.projects
  );
  const activeProjects =
    !!projects &&
    _.filter(projects, (project) => {
      return project.value.active;
    });
  const allHoursForCompany = useSelector(
    (state) => state.firebaseReducer.data.companyHours
  );
  const companyUsers = useSelector(
    (state) => state.firebaseReducer.data.companyUsers
  );
  const [totalHours, setTotalHours] = useState(0);
  const [earningsTotal, setEarningsTotal] = useState(0);
  const [hoursForMonthProjects, setHoursForMonthProjects] = useState(null);
  const [earningsForMonthProjects, setEarningsForMonthProjects] = useState([]);
  const [months, setMonths] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dataForSelect, setDataForSelect] = useState(null);
  const [filteredHoursForCompany, setFilteredHoursForCompany] = useState(null);
  const [dataFilterType, setDataFilterType] = useState("year");
  const [dataFilterPeriod, setDataFilterPeriod] = useState(moment());
  const [dataFilterProject, setDataFilterProject] = useState(
    !!projectId ? projectId : "all"
  );
  const [dataFilterUser, setDataFilterUser] = useState(
    !!userId ? userId : "all"
  );
  const [onlyInvoicableToggle, setOnlyInvoicableToggle] = useState(false);
  const notifyHourOverviewRef = useRef(null);
  const companyHoursChartRef = useRef(null);
  const companyEarningsChartRef = useRef(null);

  useEffect(() => {
    if (!!allHoursForCompany && !!projects && !!companyUsers) {
      addNameToHours(allHoursForCompany, companyUsers).then((data) => {
        calculateHoursAndEarningsInPeriod(
          data,
          activeProjects,
          moment(),
          "year",
          dataFilterProject,
          dataFilterUser,
          onlyInvoicableToggle
        ).then((data) => {
          setTotalHours(data.totalHours);
          setHoursForMonthProjects(data.hoursForPeriodPerProject);
          setEarningsForMonthProjects(data.earningsForPeriodPerProject);
          setEarningsTotal(data.totalEarnings);
          setMonths(data.labels);
          setFilteredHoursForCompany(data.filteredHours);
        });
        setIsLoading(false);
      });
    }
    if (allHoursForCompany === null) setIsLoading(false);

    // Generate and set data for projects and users selector
    if (!!projects && !!companyUsers && dataForSelect === null) {
      const sortedProjects = _.sortBy(projects, (item) =>
        item.value.name.toLowerCase()
      );
      setProjectsAndUsersForSelect(sortedProjects);
    }
  }, [allHoursForCompany, projects, companyUsers]);

  useEffect(() => {
    calculateHoursAndEarningsInPeriod(
      onlyInvoicableToggle ? filteredHoursForCompany : allHoursForCompany,
      activeProjects,
      dataFilterPeriod,
      dataFilterType,
      projectId,
      userId,
      onlyInvoicableToggle
    ).then((data) => {
      setTotalHours(data.totalHours);
      setHoursForMonthProjects(data.hoursForPeriodPerProject);
      setEarningsForMonthProjects(data.earningsForPeriodPerProject);
      setEarningsTotal(data.totalEarnings);
      setMonths(data.labels);
      setFilteredHoursForCompany(data.filteredHours);
    });
  }, [onlyInvoicableToggle]);

  /**
   * Create and set objects to fill the Cascader component (Selector for projects and users)
   * @param {*} projects
   */
  const setProjectsAndUsersForSelect = (projects) => {
    let projectsForSelect = [
      {
        value: "all",
        label: t("dashboard.all_projects"),
        children: [
          {
            value: "all",
            label: t("dashboard.all_users"),
          },
        ],
      },
    ];
    _.map(projects, (project) => {
      let projectHasHours = _.find(allHoursForCompany, [
        "projectId",
        project.key,
      ]);
      if (project.value.active && !!projectHasHours) {
        let projectObject = {
          value: project.key,
          label: project.value.name,
          children: [
            {
              value: "all",
              label: t("dashboard.all_users"),
            },
          ],
        };
        let projectHours = _.filter(allHoursForCompany, [
          "projectId",
          project.key,
        ]);
        _.map(companyUsers, (user) => {
          let userHasHoursInProject = _.find(projectHours, ["userId", user.id]);
          let userObject = {
            value: user.id,
            label: user.name,
            disabled: !userHasHoursInProject,
          };
          projectObject.children.push(userObject);
          if (
            projectsForSelect[0].children.length <=
            Object.keys(companyUsers).length
          ) {
            let userHasHoursInProject = _.find(allHoursForCompany, [
              "userId",
              user.id,
            ]);
            projectsForSelect[0].children.push({
              value: user.id,
              label: user.name,
              disabled: !userHasHoursInProject,
            });
          }
        });
        projectsForSelect.push(projectObject);
      } else if (project.value.active && !projectHasHours) {
        projectsForSelect.push({
          value: project.key,
          label: project.value.name,
          disabled: true,
          children: [
            {
              value: "all",
              label: t("dashboard.all_users"),
            },
          ],
        });
      }
    });
    setDataForSelect(projectsForSelect);
  };

  /**
   * Filter data to be shown in the charts based on the date and projects/users selectors
   * @param {*} filter The type of filter that user has changed, either date or projects/users
   * @param {*} value The value of the selector
   * @param {*} type Type of date, e.g. date, week, month, quarter, year
   */
  const filterData = (filter, value, type) => {
    let filterValue = dataFilterPeriod;
    let filterType = dataFilterType;
    let projectId = dataFilterProject;
    let userId = dataFilterUser;
    if (filter === "projectsUsers") {
      projectId = value[0];
      userId = value[1];
      setDataFilterProject(projectId);
      setDataFilterUser(userId);
    } else if (filter === "date") {
      filterValue = value;
      filterType = type;
      setDataFilterPeriod(value);
      setDataFilterType(type);
    }

    // Calculate hours and earnings for period and set state with data
    calculateHoursAndEarningsInPeriod(
      allHoursForCompany,
      activeProjects,
      filterValue,
      filterType,
      projectId,
      userId,
      onlyInvoicableToggle
    ).then((data) => {
      setTotalHours(data.totalHours);
      setHoursForMonthProjects(data.hoursForPeriodPerProject);
      setEarningsForMonthProjects(data.earningsForPeriodPerProject);
      setEarningsTotal(data.totalEarnings);
      setMonths(data.labels);
      setFilteredHoursForCompany(data.filteredHours);
    });
  };

  return (
    <HoursDashboard
      breadcrumbParentName={t("dashboard.bedrijf")}
      notificationRef={notifyHourOverviewRef}
      isAdminPage={isAdminPage(props.location.pathname)}
      dataFilterType={dataFilterType}
      allHoursForCompany={
        !!filteredHoursForCompany ? filteredHoursForCompany : allHoursForCompany
      }
      isLoading={isLoading}
      months={months}
      totalHours={totalHours}
      earningsTotal={earningsTotal}
      ref={{
        companyHoursChartRef: companyHoursChartRef,
        companyEarningsChartRef: companyEarningsChartRef,
      }}
      hoursForMonthProjects={hoursForMonthProjects}
      earningsForMonthProjects={earningsForMonthProjects}
      hideTourIcon={true}
      dataFilters={{
        data: dataForSelect,
        onChange: filterData,
      }}
      dataFilterProject={dataFilterProject}
      dataFilterUser={dataFilterUser}
      setOnlyInvoicableToggle={setOnlyInvoicableToggle}
      projects={projects}
    />
  );
};

export default UrenOverzicht;
