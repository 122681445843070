import React from "react";
import { UncontrolledTooltip } from "reactstrap";

const Tooltip = (props) => {
  return (
    <div className="d-inline ml-2">
      <i
        className={"fas fa-info-circle " + props.className}
        style={{ fontSize: props.size }}
        id={props.id}
      />
      <UncontrolledTooltip delay={0} target={props.id}>
        {props.text}
      </UncontrolledTooltip>
    </div>
  );
};

export default Tooltip;
