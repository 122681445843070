import moment from "moment";
import i18n from "../../variables/translations/i18n";

const currentYear = moment().format('YYYY');

/**
 * Formatter for hourly rate used in ProjectHeader.
 */
function formatHourlyRate(value) {
  if (!!value) return i18n.t("dashboard.uren.uurtarief") + value;
  else return i18n.t("dashboard.uren.geen_uurtarief");
}

/**
 * Formatter for remaining hours used in ProjectHeader.
 */
function formatRemainingHours(value) {
  if (!!value) return value + i18n.t("dashboard.uren.uurbudget");
  else return i18n.t("dashboard.uren.geen_uurbudget");
}

/**
 * Formatter for hours worked used in ProjectHeader.
 */
function formatHoursWorked(totalHours, budget) {
  if (budget) return totalHours + " / " + budget;
  else return totalHours + " / -";
}

/**
 * Formatter for earnings used in ProjectHeader.
 */
function formatEarnings(budget, totalEarnings, hourlyRate) {
  if (budget && hourlyRate)
    return (
      "€" + Math.round(totalEarnings * 100) / 100 + " / €" + budget * hourlyRate
    );
  else if (budget && !hourlyRate) return "€0 / €0";
  else if (!budget && hourlyRate) return "€" + totalEarnings + " / -";
  else return "€0 / -";
}

/**
 * Formatter for dates used in the offday deleteModal
 */
function formatOffdayDate(date, type) {
  if (type === "date") {
    if (!moment(date).isValid() || !date) return "-";
    return moment(date).format("DD-MM-YYYY");
  } else if (type === "fromUntil") {
    if (!date) return "-";
    if (moment(date).isValid()) return moment(date).format("DD-MM-YYYY");
    else return date;
  }
}

/**
 * Format single day for Email Service
 * @param day Day as 2021-08-09
 * @returns day as 9 augustus (2021)
 */
function formatOffdaySingleDay(day) {
  let year = moment(day).format('YYYY')
  if (year === currentYear) return moment(day).format("D MMMM");
  else  return moment(day).format("D MMMM YYYY");
}

/**
 * Format period for Email Service
 * @param startDay Day as 2021-08-09
 * @param endDay Day as 2021-08-10
 * @returns 9 augustus t/m 10 augustus
 */
function formatOffdayPeriod(startDay, endDay) {
  let startYear = moment(startDay).format('YYYY')
  let endYear = moment(endDay).format('YYYY')
  if (startYear === currentYear || endYear === currentYear) 
    return moment(startDay).format('D MMMM') + " t/m " + moment(endDay).format('D MMMM')
  else return moment(startDay).format('D MMMM YYYY') + "t/m" + moment(endDay).format('D MMMM YYYY')
}

/**
 * Format single day with time for Email Service
 * @param day Day as 2021-08-09
 * @param startTime StartTime as 09:00
 * @param endTime EndTime as 21:00
 * @returns 9 augustus van 09:00 tot 21:00.
 */
function formatOffdayDayTime(day, startTime, endTime) {
  let year = moment(day).format('YYYY')
  if (year === currentYear) return moment(day).format("D MMMM") + " van " + startTime + " tot " + endTime
  else return moment(day).format("D MMMM YYYY") + " van " + startTime + " tot " + endTime
}

export {
  formatHourlyRate,
  formatRemainingHours,
  formatHoursWorked,
  formatEarnings,
  formatOffdayDate,
  formatOffdaySingleDay,
  formatOffdayPeriod,
  formatOffdayDayTime
};
