import urls from "../urls";
import { WEBSITE_NAME } from "./CompanyConstants";

const homepageUrl = urls.base;

// Breadcrumb names
export const BREADCRUMB_HOME = {
  name: WEBSITE_NAME,
  url: homepageUrl,
};
export const BREADCRUMB_CONTACT = {
  name: "Contact",
  url: homepageUrl + urls.contact,
};
export const BREADCRUMB_PRICING = {
  name: "Prijzen",
  url: homepageUrl + urls.pricing,
};
export const BREADCRUMB_PRIVACY = {
  name: "Privacy Beleid",
  url: homepageUrl + urls.privacy,
};
