import React from "react";
import ReactDatetime from "react-datetime";
import Select2 from "react-select2-wrapper";
import { Bar } from "react-chartjs-2";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
import classnames from "classnames";
import {
  regularBarChart,
  stackedBarChartEarnings,
} from "../../../../variables/charts";
import NotificationAlert from "react-notification-alert";
import CardsHeader from "components/Headers/CardsHeader";
import firebase from "firebase";
import notify from "../../../functions/notify.js";
import { isAdminPage } from "../../../functions/generic";
import { getUserByUserId } from "../../../functions/firebase/users";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import i18n from "variables/translations/i18n";
import i18next from "i18next";
import Tour from "components/Support/Tour";
import { tourSteps } from "variables/tourSteps";
import {connect} from "react-redux";

moment.locale(i18next.language);
const {
  getActiveProjects,
  getCosts,
  calculateCosts,
} = require("../../../functions/costs");

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: false,
  withFirstAndLast: false,
  paginationSize: 5,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        {i18n.t("dashboard.uren.rijen1")}{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        {i18n.t("dashboard.uren.rijen2")}
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

class Kosten extends React.Component {
  constructor(props) {
    super(props);
    this.notifyCostsRef = React.createRef();
    this.costsChartRef = React.createRef();
  }
  state = {
    userId: localStorage.getItem("uid"),
    companyId: localStorage.getItem("compid"),
    formDate: moment().unix(),
    formCosts: 0,
    formProject: null,
    formCostTypes: null,
    formNotes: "",
    costsThisYear: {},
    projects: [],
    projectsForSelect: [],
    costTypes: [
      i18n.t("dashboard.kosten.administratie"),
      i18n.t("dashboard.kosten.inkoop"),
      i18n.t("dashboard.kosten.marketing"),
      i18n.t("dashboard.kosten.service"),
      i18n.t("dashboard.kosten.transport"),
      i18n.t("dashboard.kosten.overig"),
    ],
    totalCosts: 0,
    editFormModal: false,
    beforeEditCost: {},
    editCostId: null,
    editFormDate: null,
    editFormCosts: "",
    editFormProject: null,
    editCostTypes: null,
    editFormNotes: "",
    activeNavCosts: 2,
    currentYear: moment(new Date()).year(),
    isLoading: true,
    startTour: false,
    steps: tourSteps.kosten,
    userProfile: this.props.profile,
  };

  componentDidMount() {
    /** If no userId or companyId is available, retrieve them */
    if (!!this.state.userId || !!this.state.companyId) {
      this.getProjectsForCompany();
    } else {
      this.setState(
        { userId: this.state.userProfile.id, companyId: this.state.userProfile.companyId },
        this.getProjectsForCompany
      );
    }
  }

  getProjectsForCompany() {
    let projects = firebase
      .database()
      .ref("projects")
      .orderByChild("companyId")
      .equalTo(this.state.companyId);
    projects.on("value", (snapshot) => {
      if (!!snapshot.val()) {
        getActiveProjects(snapshot).then((projectsArray) => {
          let projectsForSelect = [];
          for (let i = 0; i < projectsArray.length; i++) {
            let object = {
              id: projectsArray[i].id,
              text: projectsArray[i].name,
            };
            projectsForSelect.push(object);
          }
          this.setState(
            { projects: projectsArray, projectsForSelect: projectsForSelect },
            this.getCostsForCompany
          );
        });
      } else {
        // No projects, but still check for costs!
        this.getCostsForCompany();
      }
    });
  }

  getCostsForCompany() {
    let costs = firebase
      .database()
      .ref("costs")
      .orderByChild("companyId")
      .equalTo(this.state.companyId);
    costs.on("value", (snapshot) => {
      if (snapshot.val()) {
        getCosts(snapshot).then((data) => {
          this.setState({ allCostsForCompany: data }, () => {
            let self = this;
            let counter = 0;
            // Get the name of the user from each hour entry for in the table
            Array.from(data).forEach((entry, index) => {
              getUserByUserId(entry.userId).then(function (obj) {
                _.set(data, index + ".name", obj.name);
                counter++;
                if (counter === data.length)
                  self.setState({ isLoading: false });
              });
            });
            calculateCosts(
              data,
              this.state.projects,
              this.state.currentYear
            ).then((data) => {
              this.setState({
                totalCosts: data.totalCosts,
                costsThisYear: data.costsThisYear,
                months: data.months,
                costsForMonthTotal: data.costsForMonthTotal,
                costsForMonthProjects: data.costsForMonthProjects,
              });
              this.updateChart(data);
            });
          });
        });
      } else {
        // no costs found
        this.setState({ isLoading: false, costsThisYear: [] });
      }
    });
  }

  updateChart(data) {
    if (this.state.activeNavCosts === 1) {
      let chart = this.costsChartRef.chartInstance;
      chart.data = {
        labels: data.months,
        datasets: data.costsForMonthProjects,
      };
      chart.update();
    }
  }

  handleReactDatetimeChange = (who, date) => {
    let formattedDate = moment(date).unix();
    this.setState({
      [who]: formattedDate,
    });
  };

  handleSubmitCosts() {
    let validDate = moment(this.state.formDate).isValid();
    let costs = Math.round(parseFloat(this.state.formCosts) * 100) / 100;
    // Validate inputs, then push new entry to db
    if (
      validDate &&
      this.state.formDate !== null &&
      this.state.formCosts !== null &&
      !!costs &&
      costs > 0 &&
      // Project OR notes must be entered
      (!!this.state.formProject ||
        !!this.state.formNotes ||
        !!this.state.formCostTypes)
    ) {
      firebase
        .database()
        .ref("costs")
        .push(
          {
            userId: this.state.userId,
            projectId: this.state.formProject,
            companyId: this.state.companyId,
            date: this.state.formDate,
            costs: costs,
            notes: this.state.formNotes,
            type: this.state.formCostTypes,
            created_at: firebase.database.ServerValue.TIMESTAMP,
          },
          (error) => {
            if (error) {
              console.error(error);
            } else {
              notify(
                "success",
                i18n.t("meldingen.kosten_opgeslagen"),
                this.notifyCostsRef
              );
              this.setState({
                formCosts: 0,
                formProject: null,
                formNotes: "",
              });
            }
          }
        );
    } else {
      if (!validDate)
        notify(
          "warning",
          i18n.t("meldingen.datum_ongeldig"),
          this.notifyCostsRef
        );
      if (this.state.formDate === null)
        notify("warning", i18n.t("meldingen.datum_leeg"), this.notifyCostsRef);
      if (this.state.formCosts === null)
        notify("warning", i18n.t("meldingen.kosten_leeg"), this.notifyCostsRef);
      if (!this.state.formProject && !this.state.formNotes)
        notify(
          "warning",
          i18n.t("meldingen.project_opmerking_leeg"),
          this.notifyCostsRef
        );
      if (this.state.formCostTypes === null)
        notify(
          "warning",
          i18n.t("meldingen.kosten_type_leeg"),
          this.notifyCostsRef
        );
      if (this.state.formCosts === 0)
        notify("warning", i18n.t("meldingen.kosten_leeg"), this.notifyCostsRef);
      if (this.state.formCosts < 0)
        notify(
          "warning",
          i18n.t("meldingen.kosten_negatief"),
          this.notifyCostsRef
        );
    }
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  toggleNavs = (e, index, variable) => {
    e.preventDefault();
    this.setState({
      [variable]: index,
    });
  };

  onSaveEditCosts() {
    let before = this.state.beforeEditCost;
    let validDate = moment(this.state.editFormDate).isValid();
    let roundedFormCosts = Math.round(this.state.editFormCosts * 100) / 100;
    /** If nothing changed, no need to call firebase */
    if (
      before.date !== this.state.editFormDate ||
      before.costs !== this.state.editFormCosts ||
      before.projectId !== this.state.editFormProject ||
      before.notes !== this.state.editFormNotes ||
      before.types !== this.state.editCostTypes
    ) {
      if (
        validDate &&
        !!this.state.editFormCosts &&
        this.state.editFormCosts > 0
      ) {
        try {
          firebase
            .database()
            .ref("costs/" + this.state.editCostId)
            .update(
              {
                projectId: this.state.editFormProject,
                date: this.state.editFormDate,
                costs: roundedFormCosts,
                notes: this.state.editFormNotes,
                type: this.state.editCostTypes || null,
              },
              (error) => {
                if (error) {
                  console.error(error);
                } else {
                  notify(
                    "success",
                    i18n.t("meldingen.kosten_opgeslagen"),
                    this.notifyCostsRef
                  );
                  this.setState({
                    editFormCosts: "",
                    editFormCostsTypes: null,
                    editFormProject: null,
                    editFormNotes: "",
                    editCostId: null,
                    editFormModal: false,
                  });
                }
              }
            );
        } catch (error) {
          console.error(error);
        }
      } else {
        if (!validDate)
          notify(
            "warning",
            i18n.t("meldingen.datum_ongeldig"),
            this.notifyCostsRef
          );
        if (this.state.editFormCosts === 0)
          notify(
            "warning",
            i18n.t("meldingen.kosten_leeg"),
            this.notifyCostsRef
          );
        if (this.state.formCosts < 0)
          notify(
            "warning",
            i18n.t("meldingen.kosten_negatief"),
            this.notifyCostsRef
          );
      }
    } else {
      /** Nothing changed, close modal */
      this.setState({
        editFormCosts: "",
        editFormProject: null,
        editFormNotes: "",
        editFormDate: null,
        editCostId: null,
        editFormModal: false,
      });
    }
  }

  onDeleteCost(id) {
    firebase
      .database()
      .ref("costs/" + id)
      .remove((error) => {
        if (error) {
          console.error(error);
        } else {
          notify(
            "success",
            i18n.t("meldingen.kosten_verwijderd"),
            this.notifyCostsRef
          );
        }
      });
  }

  openEditCosts(cost) {
    this.setState({
      beforeEditCost: cost,
      editFormDate: cost.date,
      editFormCosts: parseFloat(cost.costs),
      editFormProject: cost.projectId,
      editCostTypes: cost.type,
      editFormNotes: cost.notes,
      editFormModal: true,
      editCostId: cost.id,
    });
  }

  linkActionButtons = (cell, row, rowIndex, formatExtraData) => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-light"
          color=""
          role="button"
          size="sm"
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          <DropdownItem onClick={() => this.openEditCosts(row)}>
            {i18n.t("dashboard.bewerken")}
          </DropdownItem>
          <DropdownItem onClick={() => this.onDeleteCost(row.id)}>
            {i18n.t("dashboard.verwijderen")}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  changeYear(e, year) {
    e.preventDefault();

    calculateCosts(this.state.allCostsForCompany, this.state.projects, year)
      .then((data) => {
        this.setState({
          totalCosts: data.totalCosts,
          costsThisYear: data.costsThisYear,
          months: data.months,
          costsForMonthTotal: data.costsForMonthTotal,
          costsForMonthProjects: data.costsForMonthProjects,
          currentYear: year,
        });
      })
      .catch((error) => {
        console.error(error);
        if (error === "no-costs") {
          notify(
            "warning",
            i18n.t("meldingen.nohours_year1") +
              year +
              i18n.t("meldingen.nocosts"),
            this.notifyCostsRef
          );
        }
      });
  }

  onChangeCostAmount(state, value) {
    this.setState({ [state]: value });
  }

  render() {
    const { steps } = this.state;
    const costsPerProject = {
      labels: this.state.months,
      datasets: this.state.costsForMonthProjects,
    };
    const costsTotal = {
      labels: this.state.months,
      datasets: [
        {
          label: "€",
          data: this.state.costsForMonthTotal,
          maxBarThickness: 10,
        },
      ],
    };

    return (
      <>
        <Tour
          steps={steps}
          continuous={true}
          run={this.state.startTour}
          onFinished={() => this.setState({ startTour: false })}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref={this.notifyCostsRef} />
        </div>
        <CardsHeader
          parentName={i18n.t("dashboard.bedrijf")}
          name={i18n.t("dashboard.kosten.kosten")}
          isAdminPage={isAdminPage(this.props.location.pathname)}
          startTour={() => this.setState({ startTour: true })}
          data={[
            {
              name: i18n.t("dashboard.kosten.totaal") + this.state.currentYear,
              value: "€" + Math.round(this.state.totalCosts * 100) / 100,
            },
          ]}
        />
        <Container className="mt--6" fluid id="kosten-body">
          <Row>
            <Col md="4">
              <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={this.state.editFormModal}
                toggle={() => this.toggleModal("editFormModal")}
              >
                <div className="modal-body p-0">
                  <Card className="bg-secondary border-0 mb-0">
                    <CardHeader className="bg-transparent pb-3">
                      <div className="text-muted text-center mt-2 mb-3">
                        <span className="card-header-title">
                          {i18n.t("dashboard.kosten.aanpassen")}
                        </span>
                        <span className="card-header-subtitle">
                          {i18n.t("dashboard.vul_formulier_in")}
                        </span>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <Form role="form">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="kosten-datum"
                          >
                            {i18n.t("dashboard.uren.datum.label")}
                          </label>
                          <ReactDatetime
                            id="kosten-datum"
                            onChange={(e) =>
                              this.handleReactDatetimeChange("editFormDate", e)
                            }
                            locale={i18next.language}
                            value={
                              this.state.editFormDate !== null
                                ? moment
                                    .unix(this.state.editFormDate)
                                    .format("DD-MM-YYYY")
                                : ""
                            }
                            inputProps={{
                              placeholder: i18n.t(
                                "dashboard.uren.datum.placeholder"
                              ),
                            }}
                            timeFormat={false}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="kosten"
                          >
                            {i18n.t("dashboard.uren.kosten.label")}
                          </label>
                          <Input
                            value={this.state.editFormCosts}
                            step="1"
                            min="0"
                            id="kosten"
                            type="number"
                            placeholder={i18n.t(
                              "dashboard.uren.kosten.placeholder"
                            )}
                            onChange={(e) =>
                              this.onChangeCostAmount(
                                "editFormCosts",
                                e.target.value
                              )
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className=" form-control-label">
                            {i18n.t("dashboard.uren.project.label")}
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue="0"
                            options={{
                              placeholder: i18n.t(
                                "dashboard.uren.project.placeholder"
                              ),
                            }}
                            data={this.state.projectsForSelect}
                            value={this.state.editFormProject}
                            onSelect={(e) =>
                              this.setState({ editFormProject: e.target.value })
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <label className=" form-control-label">
                            {i18n.t("dashboard.uren.kosten_type.label")}
                          </label>
                          <Select2
                            className="form-control"
                            defaultValue="0"
                            options={{
                              placeholder: i18n.t(
                                "dashboard.uren.kosten_type.placeholder"
                              ),
                            }}
                            data={this.state.costTypes}
                            value={this.state.editCostTypes}
                            onSelect={(e) =>
                              this.setState({ editCostTypes: e.target.value })
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlTextarea2"
                          >
                            {i18n.t("dashboard.uren.opmerkingen.label")}
                          </label>
                          <Input
                            id="kosten-opmerkingen"
                            rows="1"
                            type="textarea"
                            placeholder={i18n.t(
                              "dashboard.uren.opmerkingen.placeholder"
                            )}
                            onChange={(e) =>
                              this.setState({ editFormNotes: e.target.value })
                            }
                            value={this.state.editFormNotes}
                          />
                        </FormGroup>
                        <div className="text-center">
                          <Button
                            className="my-4"
                            color="primary"
                            type="button"
                            onClick={() => this.toggleModal("editFormModal")}
                          >
                            {i18n.t("dashboard.sluiten")}
                          </Button>
                          <Button
                            className="my-4"
                            color="success"
                            type="button"
                            onClick={() => this.onSaveEditCosts()}
                          >
                            {i18n.t("dashboard.opslaan")}
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </Modal>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="card-wrapper">
                <Card id="kosten-formulier">
                  <CardHeader>
                    <h3 className="mb-0">
                      {i18n.t("dashboard.kosten.toevoegen")}
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="kosten-datum"
                            >
                              {i18n.t("dashboard.uren.datum.label")}
                            </label>
                            <ReactDatetime
                              id="kosten-datum"
                              onChange={(e) =>
                                this.handleReactDatetimeChange("formDate", e)
                              }
                              locale={i18next.language}
                              value={moment
                                .unix(this.state.formDate)
                                .format("DD-MM-YYYY")}
                              inputProps={{
                                placeholder: i18n.t(
                                  "dashboard.uren.datum.placeholder"
                                ),
                              }}
                              timeFormat={false}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="kosten"
                            >
                              {i18n.t("dashboard.uren.kosten.label")}
                            </label>
                            <Input
                              defaultValue="0"
                              value={this.state.formCosts}
                              step="1"
                              min="0"
                              id="kosten"
                              type="number"
                              placeholder={i18n.t(
                                "dashboard.uren.kosten.placeholder"
                              )}
                              onChange={(e) =>
                                this.onChangeCostAmount(
                                  "formCosts",
                                  e.target.value
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label className=" form-control-label">
                              {i18n.t("dashboard.uren.project.label")}
                            </label>
                            <Select2
                              className="form-control"
                              defaultValue="0"
                              options={{
                                placeholder: i18n.t(
                                  "dashboard.uren.project.placeholder"
                                ),
                              }}
                              data={this.state.projectsForSelect}
                              value={this.state.formProject}
                              onSelect={(e) =>
                                this.setState({ formProject: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <label className=" form-control-label">
                              {i18n.t("dashboard.uren.kosten_type.label")}
                            </label>
                            <Select2
                              className="form-control"
                              defaultValue="0"
                              options={{
                                placeholder: i18n.t(
                                  "dashboard.uren.kosten_type.placeholder"
                                ),
                              }}
                              data={this.state.costTypes}
                              value={this.state.formCostTypes}
                              onSelect={(e) =>
                                this.setState({ formCostTypes: e.target.value })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="exampleFormControlTextarea2"
                            >
                              {i18n.t("dashboard.uren.opmerkingen.label")}
                            </label>
                            <Input
                              id="kosten-opmerkingen"
                              rows="1"
                              type="textarea"
                              placeholder={i18n.t(
                                "dashboard.uren.opmerkingen.placeholder"
                              )}
                              onChange={(e) =>
                                this.setState({ formNotes: e.target.value })
                              }
                              value={this.state.formNotes}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Button
                        color="info"
                        type="button"
                        onClick={() => this.handleSubmitCosts()}
                      >
                        {i18n.t("dashboard.toevoegen")}
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-right mb-3">
              <Button
                className="btn-neutral"
                onClick={(e) => this.changeYear(e, this.state.currentYear - 1)}
                size="sm"
              >
                <i className="ni ni-fat-delete align-middle" />
                <span className="align-middle">
                  {this.state.currentYear - 1}
                </span>
              </Button>
              <Button
                className="btn-neutral"
                onClick={(e) => this.changeYear(e, this.state.currentYear + 1)}
                size="sm"
              >
                <i className="ni ni-fat-add align-middle" />
                <span className="align-middle">
                  {this.state.currentYear + 1}
                </span>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">
                    {i18n.t("dashboard.kosten.recent")} {this.state.currentYear}
                  </h3>
                  <p className="text-sm mb-0">
                    {i18n.t("dashboard.kosten.recent2")}
                  </p>
                </CardHeader>
                {this.state.isLoading ? (
                  <Skeleton count={10} height={52} />
                ) : (
                  <ToolkitProvider
                    data={Object.values(this.state.costsThisYear)}
                    keyField="id"
                    columns={[
                      {
                        dataField: "date",
                        text: "Datum",
                        sort: true,
                        formatter: (cell) => {
                          if (cell == null) {
                            return;
                          }
                          return `${moment.unix(cell).format("DD-MM-YYYY")}`;
                        },
                      },
                      {
                        dataField: "costs",
                        text: i18n.t("dashboard.kosten.tabel.kosten"),
                        sort: true,
                      },
                      {
                        dataField: "projectName",
                        text: i18n.t("dashboard.kosten.tabel.project"),
                        sort: true,
                      },
                      {
                        dataField: "type",
                        text: i18n.t("dashboard.kosten.tabel.type"),
                      },
                      {
                        dataField: "notes",
                        text: i18n.t("dashboard.kosten.tabel.opmerkingen"),
                      },
                      {
                        dataField: "name",
                        text: i18n.t("dashboard.kosten.tabel.wie"),
                      },
                      {
                        dataField: "actions",
                        text: i18n.t("dashboard.kosten.tabel.acties"),
                        formatter: this.linkActionButtons,
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 table-responsive">
                        <div
                          id="datatable-basic_filter"
                          className="dataTables_filter px-4 pb-1"
                        >
                          <label>
                            {i18n.t("dashboard.uren.tabel.zoeken")}
                            <SearchBar
                              className="form-control-sm"
                              placeholder={i18n.t(
                                "dashboard.uren.tabel.zoeken_placeholder"
                              )}
                              {...props.searchProps}
                            />
                          </label>
                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <CardHeader>
                  <Row>
                    <div className="col">
                      <h6 className="surtitle">
                        {this.state.activeNavCosts === 1 && " Per Project, "}{" "}
                        {i18n.t("dashboard.uren.per_maand")}
                      </h6>
                      <h5 className="h3 mb-0">
                        {i18n.t("dashboard.kosten.gemaakt")}
                        {this.state.currentYear}
                      </h5>
                    </div>
                    <div className="col">
                      <Nav className="justify-content-end" pills>
                        <NavItem className="mr-2 mr-md-0">
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNavCosts === 1,
                            })}
                            href="#"
                            onClick={(e) =>
                              this.toggleNavs(e, 1, "activeNavCosts")
                            }
                          >
                            <span className="d-none d-md-block">
                              {i18n.t("dashboard.per_project")}
                            </span>
                            <span className="d-md-none">PP</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNavCosts === 2,
                            })}
                            data-toggle="tab"
                            href="#"
                            onClick={(e) =>
                              this.toggleNavs(e, 2, "activeNavCosts")
                            }
                          >
                            <span className="d-none d-md-block">
                              {i18n.t("dashboard.totaal")}
                            </span>
                            <span className="d-md-none">T</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Bar
                      ref={(reference) => (this.costsChartRef = reference)}
                      data={
                        this.state.activeNavCosts === 1
                          ? costsPerProject
                          : costsTotal
                      }
                      options={
                        this.state.activeNavCosts === 1
                          ? stackedBarChartEarnings.options
                          : regularBarChart.options
                      }
                      className="chart-canvas"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    profile: state.firebaseReducer.profile,
  }
}

export default connect(mapStateToProps)(Kosten);