import _ from "lodash";
import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Excel = (props) => {
  return (
    <div>
      {!_.isEmpty(props.data) && (
        <ExcelFile
          element={
            <button
              className={props.button.className}
              type="button"
              id="export-button"
            >
              {props.button.label}
            </button>
          }
          filename={props.fileName}
        >
          <ExcelSheet data={props.data} name={props.sheetName}>
            {!!props.columns &&
              props.columns.map((column) => {
                return (
                  <ExcelColumn label={column.label} value={column.value} />
                );
              })}
          </ExcelSheet>
        </ExcelFile>
      )}
    </div>
  );
};

export default Excel;
