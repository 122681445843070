import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {Row} from "reactstrap";
import Locked from "assets/img/theme/lock.jpg";

const LockedAlert = (props) => {
  return (
    <ReactBSAlert
      custom
      title={props.title}
      onConfirm={() => props.confirmAlert()}
      confirmBtnBsStyle={props.type}
      confirmBtnText={props.confirmBtnText}
      customIcon={Locked}>
      <Row className="justify-content-center mb-2 ml-1 mr-1">
        {props.description}
      </Row>
    </ReactBSAlert>
  );
};

export default LockedAlert;
