import Tooltip from "components/Form/Tooltip";
import TourIcon from "components/Support/TourIcon";
import React, { useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Progress,
  Row,
} from "reactstrap";
import { Cascader, Checkbox, DatePicker, Select, Space } from "antd";
import locale from "antd/es/date-picker/locale/nl_NL";
import moment from "moment";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const CardsHeader = (props) => {
  const { t } = useTranslation();
  const [type, setType] = useState("year");
  const [datePickerValue, setDatePickerValue] = useState(moment());
  const filters = props.dataFilters;

  const getCardBackground = () => {
    if (!props.isAdminPage) return "bg-cards";
    else return "bg-info";
  };

  const getHeaderBg = () => {
    if (!props.isAdminPage) return "bg-gradient-info";
  };

  const getTitleColor = () => {
    if (!props.isAdminPage) return "text-white";
  };

  const getBreadCrumbColor = () => {
    if (!props.isAdminPage) return "breadcrumb-dark";
  };

  const getColWidth = (dataAmount) => {
    if (dataAmount <= 6) return 12 / dataAmount;
    else return 3;
  };

  const PickerWithType = ({ type, onChange }) => {
    if (type === "date")
      return (
        <DatePicker
          onChange={onChange}
          locale={locale}
          size="large"
          value={datePickerValue}
          format={"DD-MM-YYYY"}
        />
      );
    return (
      <DatePicker
        picker={type}
        onChange={onChange}
        locale={locale}
        size="large"
        value={datePickerValue}
      />
    );
  };

  const onChangeType = (value) => {
    setType(value);
    if (value !== type) setDatePickerValue(null);
  };

  return (
    <>
      <div className={"header pb-6 " + getHeaderBg()}>
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="4">
                <h6 className={"h2 d-inline-block mb-0 " + getTitleColor()}>
                  {props.name}
                </h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName={"breadcrumb-links " + getBreadCrumbColor()}
                >
                  <BreadcrumbItem>
                    <a onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a onClick={(e) => e.preventDefault()}>
                      {props.parentName}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {props.name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col lg={!props.hideTourIcon ? "5" : "8"}>
                {!!filters && filters.data && filters.data.length > 0 && (
                  <Space style={{ float: "right" }} wrap>
                    <Checkbox
                      onChange={(e) =>
                        props.setOnlyInvoicableToggle(e.target.checked)
                      }
                    >
                      {t("dashboard.uren.alleenFactureerbaar")}
                    </Checkbox>
                    <Select
                      value={type}
                      onChange={onChangeType}
                      style={{ width: 100 }}
                      size="large"
                      defaultValue={"year"}
                    >
                      <Option value="date">{t("day")}</Option>
                      <Option value="week">{t("week")}</Option>
                      <Option value="month">{t("month")}</Option>
                      <Option value="quarter">{t("quarter")}</Option>
                      <Option value="year">{t("year")}</Option>
                    </Select>
                    <PickerWithType
                      type={type}
                      onChange={(value) => {
                        props.dataFilters.onChange("date", value, type);
                        setDatePickerValue(value);
                      }}
                    />
                    <Cascader
                      size="large"
                      options={filters.data}
                      allowClear={false}
                      style={{ width: 240 }}
                      onChange={(value) =>
                        props.dataFilters.onChange("projectsUsers", value)
                      }
                      defaultValue={[
                        props.dataFilterProject,
                        props.dataFilterUser,
                      ]}
                    />
                  </Space>
                )}
              </Col>
              <Col lg={!props.hideTourIcon ? "3" : "0"} className="text-right">
                {!props.hideTourIcon && (
                  <TourIcon
                    startTour={props.startTour}
                    color={props.isAdminPage ? "muted" : "white"}
                  />
                )}
              </Col>
            </Row>
            <Row>
              {!!props.data &&
                props.data.map((prop, i) => {
                  return (
                    <Col lg={getColWidth(props.data.length)} key={i}>
                      <Card className={getCardBackground() + " border-0"}>
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                className="text-uppercase text-muted mb-0 text-white"
                                tag="h4"
                              >
                                {prop.name}
                                {!!prop.tooltip && (
                                  <Tooltip
                                    id={prop.tooltip.id}
                                    text={prop.tooltip.text}
                                    className={"text-white"}
                                    size={18}
                                  />
                                )}
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0 text-white">
                                {prop.value}
                              </span>
                              {!!prop.min && !!prop.max && (
                                <Progress
                                  className="progress-xs mt-3 mb-0"
                                  max={prop.max}
                                  value={prop.min}
                                  color="green"
                                />
                              )}
                            </div>
                            <Col className="col-auto">
                              {prop.editable ? (
                                <a
                                  className="icon icon-shape bg-pink text-white rounded-circle shadow"
                                  onClick={props.onClick}
                                >
                                  <i className="fa fa-edit text-white" />
                                </a>
                              ) : (
                                <div className="icon icon-shape bg-white rounded-circle shadow">
                                  <i className="ni ni-chart-bar-32" />
                                </div>
                              )}
                            </Col>
                          </Row>
                          {!!prop.bottomText && (
                            <p className="mt-3 mb-0 text-sm text-light font-weight-600">
                              {prop.bottomText}
                            </p>
                          )}
                          {!!prop.percentageChange && (
                            <div className="mt-3 mb-0 text-sm text-light font-weight-600 text-capitalize d-flex flex-row">
                              <i
                                className={
                                  prop.percentageChange > 0
                                    ? "fas fa-arrow-up align-self-center"
                                    : "fas fa-arrow-down align-self-center"
                                }
                              ></i>
                              <span className="ml-1 text-sm">
                                {prop.percentageChange}%
                              </span>
                            </div>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default CardsHeader;
