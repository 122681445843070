// Meta tags titles
export const META_HOME_TITLE = "Biedt startups structuur";
export const META_CONTACT_TITLE = "Contact";
export const META_PRICING_TITLE = "Prijzen";
export const META_RESET_PASSWORD_TITLE = "Wachtwoord Resetten";
export const META_LOGIN_TITLE = "Inloggen"
export const META_REGISTER_COMPANY_TITLE = "Bedrijf Registreren"
export const META_NOT_FOUND_TITLE = "Niet Gevonden";
export const META_PRIVACY_TITLE = "Privacy Beleid";
export const META_DASHBOARD_TITLE = "Dashboard";

// Meta tags descriptions
export const META_GENERIC_DESCRIPTION = "DashBee digitaliseert dagelijkse taken en brengt structuur in je bedrijf. Een dashboard voor administratie, registratie en data-analyse, gericht op startups.";
export const META_CONTACT_DESCRIPTION = "Heb je vragen of opmerkingen over DashBee? Vul het contactformulier in en wij nemen direct contact met je op!";
export const META_PRICING_DESCRIPTION = "Naast ons gratis FreeBee pakket, hebben wij drie betaalde pakketten. Bekijk de mogelijkheden en kies degene die bij jouw bedrijf past!";
export const META_RESET_PASSWORD_DESCRIPTION = "Je wachtwoord voor DashBee vergeten? Vraag direct een nieuwe aan via deze pagina.";
export const META_LOGIN_DESCRIPTION = "Log in bij DashBee om verder te gaan met administreren, registreren en analyseren!"
export const META_REGISTER_DESCRIPTION = "Ben je op zoek naar structuur en wil je jouw dagelijkse taken digitaliseren? Probeer DashBee nu 30 dagen gratis!"
export const META_NOT_FOUND_DESCRIPTION = "Oeps, je bent naar een pagina gegaan die bij ons niet (meer) bestaat. Let's go back to the hive!";
export const META_PRIVACY_DESCRIPTION = "DashBee hecht waarde aan je privacy. Lees hier hoe wij met je gegevens omgaan.";