import React from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import HomepageNavbar from "components/Navbars/HomepageNavbar.js";
import HomepageHeader from "components/Headers/HomepageHeader.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import "@fortawesome/fontawesome-free/js/brands.js";
import urls from "../../../urls";
import Cookies from "js-cookie";
import { CookieNotice } from "react-cookienotice";
import "react-cookienotice/dist/style.css";
import i18n from "../../../variables/translations/i18n";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";
import { WEBSITE_NAME } from "constants/CompanyConstants";
import {
  META_GENERIC_DESCRIPTION,
  META_HOME_TITLE,
} from "constants/MetaTagsConstants";
import Testimonials from "wrappers/testimonials/Testimonials";
import Modules from "wrappers/modules/Modules";
import { BreadcrumbStructuredData } from "../../../components/StructuredData/BreadcrumbStructuredData";
import { BREADCRUMB_HOME } from "../../../constants/BreadcrumbConstants";

class Homepage extends React.Component {
  render() {
    console.log(BreadcrumbStructuredData[{ BREADCRUMB_HOME }]);
    const cookieValue = Cookies.get("allow-cookies");
    return (
      <>
        <Helmet>
          <title>
            {WEBSITE_NAME} · {META_HOME_TITLE}
          </title>
          <meta name="description" content={META_GENERIC_DESCRIPTION} />
          {BreadcrumbStructuredData([BREADCRUMB_HOME])}
        </Helmet>
        <HomepageNavbar />
        <div className="main-content">
          <HomepageHeader />
          <section className="py-6 bg-default">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Fade delay={100}>
                    <h2 className="display-4">{i18n.t("homepage_titel")}</h2>
                  </Fade>
                  <Fade delay={300}>
                    <p className="text-justify">{i18n.t("homepage_desc_2")}</p>
                    <p className="text-justify">
                      {i18n.t("homepage_desc_3")}{" "}
                      <strong>{i18n.t("homepage_desc_4")}</strong>
                    </p>
                    <Button
                      className="btn-cta my-2"
                      to={urls.registerCompany}
                      tag={Link}
                    >
                      {i18n.t("bedrijf_aanmelden_btn")}
                    </Button>
                  </Fade>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="justify-content-center mt-6 text-center">
                <Col lg="12">
                  <Row>
                    <Col lg="4">
                      <Fade delay={300}>
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-4">
                            <div className="icon icon-shape bg-gradient-info text-white rounded-circle mb-4">
                              <i className="ni ni-watch-time" />
                            </div>
                            <h4 className="h3 text-info text-uppercase">
                              {i18n.t("urenregistratie")}
                            </h4>
                            <p className="description mt-3">
                              {i18n.t("urenregistratie_desc")}
                            </p>
                          </CardBody>
                        </Card>
                      </Fade>
                    </Col>
                    <Col lg="4">
                      <Fade delay={400}>
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-4">
                            <div className="icon icon-shape bg-gradient-success text-white rounded-circle mb-4">
                              <i className="ni ni-calendar-grid-58" />
                            </div>
                            <h4 className="h3 text-success text-uppercase">
                              {i18n.t("verlof_administratie")}
                            </h4>
                            <p className="description mt-3">
                              {i18n.t("verlof_administratie_desc")}
                            </p>
                          </CardBody>
                        </Card>
                      </Fade>
                    </Col>
                    <Col lg="4">
                      <Fade delay={500}>
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-4">
                            <div className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                              <i className="ni ni-chart-bar-32" />
                            </div>
                            <h4 className="h3 text-warning text-uppercase">
                              {i18n.t("admin_panel")}
                            </h4>
                            <p className="description mt-3">
                              {i18n.t("admin_panel_desc")}
                            </p>
                          </CardBody>
                        </Card>
                      </Fade>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <div className="container mt-5">
              <Fade delay={100}>
                <h2>{i18n.t("homepage_question_1")}</h2>
                <p className="text-justify">{i18n.t("homepage_quote_1")}</p>
              </Fade>
            </div>
          </section>
          <section className="py-7 bg-white overflow-hidden">
            <Testimonials />
          </section>
          <Modules />
        </div>
        <div className={typeof cookieValue !== "undefined" && "d-none"}>
          <CookieNotice
            titleLabel={i18n.t("cookiebar_text")}
            descriptionLabel=""
            acceptAllButtonLabel="OK"
            readMoreLabel={i18n.t("cookiebar_meer_lezen")}
            readMoreLink="https://dashbee.nl/privacy"
            readMoreInNewTab
            hideDeclineButton
            declineAllButtonLabel={""}
          />
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Homepage;
