import i18n from "variables/translations/i18n";

function notify(type, message, ref) {
  let title;
  switch (type) {
    case "success":
      title = i18n.t("meldingen.gelukt");
      break;
    case "warning":
      title = i18n.t("meldingen.waarschuwing");
      break;
    case "danger":
      title = i18n.t("meldingen.waarschuwing");
      break;
    default:
      title = i18n.t("meldingen.waarschuwing");
  }
  let options = {
    place: "tc",
    message: (
      <div className="alert-text">
        <span className="alert-title" data-notify="title">
          {title}
        </span>
        <span data-notify="message">{message}</span>
      </div>
    ),
    type: type,
    icon: "ni ni-bell-55",
    autoDismiss: 7,
  };
  ref.current.notificationAlert(options);
}

export default notify;
