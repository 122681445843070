import React from "react";
import {
  Card,
  CardBody,
  ListGroupItem,
  ListGroup,
  CardImg,
  Progress,
  Row,
  Col,
  CardHeader,
} from "reactstrap";
import i18n from "../../variables/translations/i18n";
import beeImage from "assets/img/theme/dash.png";
import bgImg from "assets/img/theme/profile-bg.jpg";
import leendertImg from "assets/img/theme/leendert.jpg";

const SupportContact = (props) => {
  return (
    <Col>
      <Card className="card-profile">
        <CardImg alt="..." src={bgImg} top />
        <Row className="justify-content-center">
          <Col className="order-lg-4" lg="4">
            <div className="card-profile-image">
              <img alt="..." src={leendertImg} className="rounded-circle" />
            </div>
          </Col>
        </Row>
        <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></CardHeader>
        <CardBody className="mt-4 pt-2">
          <div className="text-center">
            <h5 className="h3">
              {i18n.t("contact_pagina.support.supporter_name")}
            </h5>
            <div className="h5 font-weight-300">
              <i className="ni location_pin" />
              {i18n.t("contact_pagina.support.supporter_location")}
            </div>
            <div className="h5 mt-2">
              <i className="ni business_briefcase-24 mr-2" />
              {i18n.t("contact_pagina.support.supporter_quote")}
            </div>
            <div className="mb-2">
              {i18n.t("contact_pagina.support.support_message")}{" "}
              <a href={i18n.t("contact_pagina.support.supporter_phone.href")}>
                {i18n.t("contact_pagina.support.supporter_phone.number")}
              </a>
            </div>
            <a
              href={i18n.t("contact_pagina.support.supporter_phone.whatsapp")}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-whatsapp fa-2x" />
            </a>
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <h5 className="h3 mb-0">
            {i18n.t("contact_pagina.support.support_topic_titel")}
          </h5>
        </CardHeader>

        <CardBody>
          <ListGroup className="list my--3" flush>
            <ListGroupItem className="px-0">
              <Row className="align-items-center">
                <Col className="col-auto">
                  <a className="avatar rounded-circle">
                    <img alt="..." src={beeImage} />
                  </a>
                </Col>
                <div className="col">
                  <h5>{i18n.t("contact_pagina.support.support_topic_1")}</h5>
                  <Progress
                    className="progress-xs mb-0"
                    max="100"
                    value="100"
                    color="success"
                  />
                </div>
              </Row>
            </ListGroupItem>
            <ListGroupItem className="px-0">
              <Row className="align-items-center">
                <Col className="col-auto">
                  <a className="avatar rounded-circle">
                    <img alt="..." src={beeImage} />
                  </a>
                </Col>
                <div className="col">
                  <h5>{i18n.t("contact_pagina.support.support_topic_2")}</h5>
                  <Progress
                    className="progress-xs mb-0"
                    max="100"
                    value="10"
                    color="danger"
                  />
                </div>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>
    </Col>
  );
};

export default SupportContact;
