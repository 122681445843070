import { createStore, applyMiddleware } from "redux";
import rootReducer from './rootReducer'
import { save, load } from "redux-localstorage-simple";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save()))
);

export default store;