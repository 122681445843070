import i18n from "./translations/i18n";

const styleOptions = {
  options: {
    arrowColor: "#8965e0",
    backgroundColor: "#8965e0",
    primaryColor: "#00bb97",
    textColor: "#fff",
    header: "#fff",
    title: "#fff",
    color: "#fff",
  },
  tooltip: {
    borderRadius: 20,
    fontSize: 16,
  },
  tooltipTitle: {
    fontSize: 20,
    color: "#fff",
  },
  buttonNext: {
    borderRadius: 6,
  },
};

const locale = {
  next: i18n.t("dashboard.tour.stappen.volgende"),
  back: i18n.t("dashboard.tour.stappen.terug"),
  skip: i18n.t("dashboard.tour.stappen.skip"),
  last: i18n.t("dashboard.tour.stappen.afronden"),
};

const defaultOptions = {
  disableBeacon: true,
  showProgress: true,
  showSkipButton: true,
  styles: styleOptions,
  locale: locale,
};

export const tourSteps = {
  urenregistratie: [
    {
      title: i18n.t("dashboard.uren.titel"),
      target: "#urenregistratie-formulier",
      content: i18n.t("dashboard.tour.urenregistratie.formulier"),
      ...defaultOptions,
    },
    {
      title: i18n.t("dashboard.uren.titel"),
      target: "#urenregistratie-tabel",
      content: i18n.t("dashboard.tour.urenregistratie.tabel"),
      ...defaultOptions,
    },
    {
      title: i18n.t("dashboard.uren.titel"),
      target: "#grafiek-toggle",
      content: i18n.t("dashboard.tour.urenregistratie.grafiek_toggle"),
      ...defaultOptions,
    },
  ],
  verlof: [
    {
      title: i18n.t("dashboard.verlof.titel"),
      target: "#verlofaanvraag-button",
      content: i18n.t("dashboard.tour.verlof.button"),
      ...defaultOptions,
    },
    {
      title: i18n.t("dashboard.verlof.titel"),
      target: "#aanvragen-tabel",
      content: i18n.t("dashboard.tour.verlof.tabel"),
      ...defaultOptions,
    },
  ],
  kalender: [
    {
      title: i18n.t("dashboard.kalender.titel"),
      target: "#kalender-body",
      content: i18n.t("dashboard.tour.kalender.body"),
      placement: "center",
      ...defaultOptions,
    },
  ],
  verlofaanvraag: [
    {
      title: i18n.t("dashboard.navbar.admin.aanvragen"),
      target: "#aanvragen-onbehandeld",
      content: i18n.t("dashboard.tour.verlofaanvraag.onbehandeld"),
      ...defaultOptions,
    },
    {
      title: i18n.t("dashboard.navbar.admin.aanvragen"),
      target: "#aanvragen-behandeld",
      content: i18n.t("dashboard.tour.verlofaanvraag.behandeld"),
      ...defaultOptions,
    },
    {
      title: i18n.t("dashboard.navbar.admin.aanvragen"),
      target: "#aanvragen-export",
      content: i18n.t("dashboard.tour.verlofaanvraag.export"),
      placement: "right",
      ...defaultOptions,
    },
  ],
  hoursOverview: [
    {
      target: "#grafiek-toggle",
      content: i18n.t("dashboard.tour.uren.grafiek"),
      ...defaultOptions,
    },
    {
      target: "#tabel",
      content: i18n.t("dashboard.tour.uren.tabel"),
      ...defaultOptions,
    },
    {
      target: "#export-button",
      content: i18n.t("dashboard.tour.uren.export"),
      ...defaultOptions,
      placement: "right",
    },
  ],
  kosten: [
    {
      title: i18n.t("dashboard.navbar.admin.kosten"),
      target: "#kosten-body",
      content: i18n.t("dashboard.tour.kosten.body"),
      ...defaultOptions,
    },
    {
      title: i18n.t("dashboard.navbar.admin.kosten"),
      target: "#kosten-formulier",
      content: i18n.t("dashboard.tour.kosten.formulier"),
      ...defaultOptions,
    },
  ],
  facturen: [
    {
      title: i18n.t("dashboard.facturen.facturen"),
      target: "#facturen-body",
      content: i18n.t("dashboard.tour.facturen.body"),
      placement: "right",
      ...defaultOptions,
    },
    {
      title: i18n.t("dashboard.facturen.facturen"),
      target: "#facturen-klant",
      content: i18n.t("dashboard.tour.facturen.klant"),
      placement: "right",
      ...defaultOptions,
    },
    {
      title: i18n.t("dashboard.facturen.facturen"),
      target: "#facturen-datum",
      content: i18n.t("dashboard.tour.facturen.datum"),
      placement: "right",
      ...defaultOptions,
    },
    {
      title: i18n.t("dashboard.facturen.facturen"),
      target: "#facturen-gegevens",
      content: i18n.t("dashboard.tour.facturen.bedrijfsgegevens"),
      placement: "right",
      ...defaultOptions,
    },
  ],
  klanten: [
    {
      title: i18n.t("dashboard.klanten.klanten"),
      target: "#klanten-button",
      content: i18n.t("dashboard.tour.klanten.button"),
      placement: "left",
      ...defaultOptions,
    },
    {
      title: i18n.t("dashboard.klanten.klanten"),
      target: "#klanten-tabel",
      content: i18n.t("dashboard.tour.klanten.tabel"),
      ...defaultOptions,
    },
  ],
  projecten: [
    {
      title: i18n.t("dashboard.navbar.admin.projecten"),
      target: "#projecten-body",
      content: i18n.t("dashboard.tour.projecten.body"),
      ...defaultOptions,
    },
    {
      title: i18n.t("dashboard.navbar.admin.projecten"),
      target: "#new-project-btn",
      content: i18n.t("dashboard.tour.projecten.button"),
      ...defaultOptions,
    },
    {
      title: i18n.t("dashboard.navbar.admin.projecten"),
      target: "#projecten-edit",
      content: i18n.t("dashboard.tour.projecten.edit"),
      ...defaultOptions,
    },
    {
      title: i18n.t("dashboard.navbar.admin.projecten"),
      target: "#projecten-verwijderd",
      content: i18n.t("dashboard.tour.projecten.verwijderd"),
      ...defaultOptions,
    },
  ],
  team: [
    {
      title: i18n.t("dashboard.navbar.admin.team"),
      target: "#team-body",
      content: i18n.t("dashboard.tour.team.body"),
      ...defaultOptions,
    },
    {
      title: i18n.t("dashboard.navbar.admin.team"),
      target: "#new-team-btn",
      content: i18n.t("dashboard.tour.team.button"),
      ...defaultOptions,
    },
    {
      title: i18n.t("dashboard.navbar.admin.team"),
      target: "#team-edit",
      content: i18n.t("dashboard.tour.team.edit"),
      ...defaultOptions,
    },
    {
      title: i18n.t("dashboard.navbar.admin.team"),
      target: "#team-tabel",
      content: i18n.t("dashboard.tour.team.tabel"),
      ...defaultOptions,
    },
  ],
  header: [
    {
      target: "#header-settings",
      content: i18n.t("dashboard.tour.header.settings"),
      ...defaultOptions,
    },
    {
      target: "#header-taal",
      content: i18n.t("dashboard.tour.header.taal"),
      ...defaultOptions,
    },
    {
      target: "#header-notificatie",
      content: i18n.t("dashboard.tour.header.notificatie"),
      ...defaultOptions,
    },
  ],
};
