import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./variables/translations/i18n";

// bootstrap rtl for rtl support page
import "assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// plugins styles downloaded
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import 'antd/dist/antd.css';
// core styles
import "assets/scss/stylesheet.scss?v1.1.0";
// moment
import "moment/locale/nl";
import "moment/locale/en-gb";
// pages
import DashboardLayout from "layouts/Dashboard.js";
import AuthLayout from "layouts/Auth.js";
import Homepage from "views/pages/website/Homepage.js";
import PrivacyPolicy from "views/pages/website/PrivacyPolicy.js";
import ScrollToTop from "views/functions/ScrollToTop";
import Pricing from "views/pages/website/Pricing";
import Contact from "views/pages/website/Contact";
// Sentry
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
// Redux
import {Provider} from "react-redux";
import store from "./redux/store";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import firebase from "../src/redux/firebase";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,
});

const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: false
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
};

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route
            path="/dashboard"
            render={(props) => <DashboardLayout {...props} />}
          />
          <Route path="/privacy" render={(props) => <PrivacyPolicy {...props} />} />
          <Route path="/prijzen" render={(props) => <Pricing {...props} />} />
          <Route path="/contact" render={(props) => <Contact {...props} />} />
          <Route path="/" render={(props) => <Homepage {...props} />} />
          <Redirect from="*" to="/auth" />
        </Switch>
      </BrowserRouter>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);
