import React from "react";
import firebase from "firebase";
import notify from "../../../functions/notify";
import i18n from "../../../../variables/translations/i18n";
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "../../../../components/Headers/SimpleHeader";
import { isAdminPage } from "../../../functions/generic";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Media,
  Modal,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import classnames from "classnames";
import beeImage from "../../../../assets/img/theme/dash.png";
import Tour from "components/Support/Tour";
import { tourSteps } from "variables/tourSteps";
import { deleteImage, uploadImage } from "../../../functions/images";

class Klanten extends React.Component {
  constructor(props) {
    super(props);
    this.notifyClientsRef = React.createRef();
  }
  state = {
    companyId: localStorage.getItem("compid"),
    errorMessage: "",
    formModal: false,
    editForm: false,
    deleteModal: false,
    clientToDelete: null,
    clients: null,
    clientName: "",
    clientNameFocused: "",
    streetName: "",
    streetNameFocused: "",
    zipcode: "",
    zipcodeFocused: "",
    streetNumber: "",
    streetNumberFocused: "",
    city: "",
    cityFocused: "",
    email: "",
    emailFocused: "",
    contactPerson: "",
    contactPersonFocused: "",
    clientLogo: null,
    editClientLogo: null,
    clientNumber: "",
    clientNumberFocused: "",
    startTour: false,
    steps: tourSteps.klanten,
  };

  componentDidMount() {
    this.onGetClients();
  }

  onGetClients() {
    let clients = firebase
      .database()
      .ref("clients")
      .orderByChild("companyId")
      .equalTo(this.state.companyId);
    clients.on("value", (snapshot) => {
      let data = snapshot.val();
      this.setState({ clients: data });
    });
  }

  onSaveClient() {
    if (this.formValidation()) {
      uploadImage(this.state.clientLogo)
        .then((clientLogo) => {
          firebase
            .database()
            .ref("clients")
            .push(
              {
                companyId: this.state.companyId,
                name: this.state.clientName,
                street: this.state.streetName,
                streetNumber: this.state.streetNumber,
                zipcode: this.state.zipcode,
                city: this.state.city,
                email: this.state.email,
                contact: this.state.contactPerson,
                logo: clientLogo,
                clientNumber: this.state.clientNumber,
                created_at: firebase.database.ServerValue.TIMESTAMP,
              },
              (error) => {
                if (error) {
                  notify(
                    "warning",
                    i18n.t("meldingen.foutmelding"),
                    this.notifyClientsRef
                  );
                } else {
                  notify(
                    "success",
                    i18n.t("meldingen.klant_opgeslagen"),
                    this.notifyClientsRef
                  );
                  this.setState({
                    clientName: "",
                    clientNumber: "",
                    streetName: "",
                    street: "",
                    streetNumber: "",
                    zipcode: "",
                    city: "",
                    email: "",
                    contactPerson: "",
                    clientLogo: null,
                    formModal: false,
                  });
                }
              }
            );
        })
        .catch(() => {
          notify(
            "warning",
            i18n.t("meldingen.afbeelding_upload_gefaald"),
            this.notifyClientsRef
          );
        });
    }
  }

  onEditClient() {
    if (this.formValidation()) {
      if (!!this.state.editClientLogo && !!this.state.logoUrl)
        deleteImage(this.state.logoUrl);
      uploadImage(this.state.editClientLogo)
        .then((clientLogo) => {
          let updateObject = {
            name: this.state.clientName,
            street: this.state.streetName,
            streetNumber: this.state.streetNumber,
            zipcode: this.state.zipcode,
            city: this.state.city,
            email: this.state.email,
            contact: this.state.contactPerson,
            clientNumber: this.state.clientNumber,
          };
          if (clientLogo !== null && !!this.state.editClientLogo)
            updateObject.logo = clientLogo;
          firebase
            .database()
            .ref("clients/" + this.state.clientId)
            .update(updateObject, (error) => {
              if (error) {
                notify(
                  "warning",
                  i18n.t("meldingen.foutmelding"),
                  this.notifyClientsRef
                );
              } else {
                notify(
                  "success",
                  i18n.t("meldingen.klant_opgeslagen"),
                  this.notifyClientsRef
                );
                this.setState({
                  clientName: "",
                  clientNumber: "",
                  streetName: "",
                  streetNumber: "",
                  zipcode: "",
                  city: "",
                  email: "",
                  contactPerson: "",
                  clientLogo: null,
                  editClientLogo: null,
                  clientId: "",
                  formModal: false,
                  editForm: false,
                });
              }
            });
        })
        .catch(() => {
          notify(
            "warning",
            i18n.t("meldingen.afbeelding_upload_gefaald"),
            this.notifyClientsRef
          );
        });
    }
  }

  onDeleteClient() {
    let clientId = this.state.clientToDelete[0];
    let clientImage = this.state.clientToDelete[1].logo;
    let clientRef = firebase.database().ref("clients/" + clientId);
    clientRef
      .remove()
      .then(() => {
        notify(
          "success",
          i18n.t("meldingen.klant_verwijderd"),
          this.notifyClientsRef
        );
        this.setState({
          clientToDelete: null,
          deleteModal: false,
        });
      })
      .then(() => deleteImage(clientImage));
  }

  /**
   * Set the corresponding modal to true or false based on the initial state.
   */
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  formValidation() {
    if (this.state.clientName === "") {
      notify(
        "warning",
        i18n.t("meldingen.klantnaam_leeg"),
        this.notifyClientsRef
      );
      return false;
    } else return true;
  }

  /**
   * Set state of clientToDelete to the clicked project and set deleteModal to true,
   * which opens the modal.
   */
  onPressDeleteClient(client) {
    this.setState({ clientToDelete: client, deleteModal: true });
  }

  /**
   * Set state of clicked projects and open editForm modal.
   */
  onPressEditClient(clientId) {
    this.setState({
      clientName: clientId[1].name,
      clientNumber: !!clientId[1].clientNumber ? clientId[1].clientNumber : "",
      clientLogo: !!clientId[1].logo ? clientId[1].logo : "",
      logoUrl: !!clientId[1].logo ? clientId[1].logo : "",
      streetName: !!clientId[1].street ? clientId[1].street : "",
      streetNumber: !!clientId[1].streetNumber ? clientId[1].streetNumber : "",
      zipcode: !!clientId[1].zipcode ? clientId[1].zipcode : "",
      city: !!clientId[1].city ? clientId[1].city : "",
      email: !!clientId[1].email ? clientId[1].email : "",
      contactPerson: !!clientId[1].contact ? clientId[1].contact : "",
      formModal: true,
      editForm: true,
      clientId: clientId[0],
    });
  }

  render() {
    const { steps } = this.state;
    return (
      <>
        <Tour
          steps={steps}
          continuous={true}
          run={this.state.startTour}
          onFinished={() => this.setState({ startTour: false })}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref={this.notifyClientsRef} />
        </div>
        <SimpleHeader
          name={i18n.t("dashboard.klanten.klanten")}
          parentName={i18n.t("dashboard.bedrijf")}
          isAdminPage={isAdminPage(this.props.location.pathname)}
          startTour={() => this.setState({ startTour: true })}
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col md="4">
              <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={this.state.formModal}
                toggle={() => this.toggleModal("formModal")}
              >
                <div className="modal-body p-0">
                  <Card className="bg-secondary border-0 mb-0">
                    <CardHeader className="bg-transparent pb-3">
                      <div className="text-muted text-center mt-2 mb-3">
                        <span className="card-header-title">
                          {this.state.editForm
                            ? i18n.t("dashboard.klanten.bewerken")
                            : i18n.t("dashboard.klanten.aanmaken")}
                        </span>
                        <span className="card-header-subtitle">
                          {this.state.clients !== null
                            ? i18n.t("dashboard.vul_formulier_in")
                            : i18n.t("dashboard.projecten.eerste_aanmaken")}
                        </span>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <Form role="form">
                        <FormGroup>
                          <InputGroup
                            className={classnames("input-group-merge", {
                              focused: this.state.clientNameFocused,
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-user" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder={i18n.t("dashboard.klanten.naam")}
                              type="text"
                              value={this.state.clientName}
                              onChange={(e) =>
                                this.setState({ clientName: e.target.value })
                              }
                              onFocus={(e) =>
                                this.setState({ clientNameFocused: true })
                              }
                              onBlur={(e) =>
                                this.setState({ clientNameFocused: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup
                            className={classnames("input-group-merge", {
                              focused: this.state.clientNumberFocused,
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-hashtag" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder={i18n.t("dashboard.klanten.nummer")}
                              type="text"
                              value={this.state.clientNumber}
                              onChange={(e) =>
                                this.setState({ clientNumber: e.target.value })
                              }
                              onFocus={(e) =>
                                this.setState({ clientNumberFocused: true })
                              }
                              onBlur={(e) =>
                                this.setState({ clientNumberFocused: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup
                            className={classnames("input-group-merge", {
                              focused: this.state.streetNameFocused,
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-road" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder={i18n.t("dashboard.klanten.straat")}
                              type="text"
                              value={this.state.streetName}
                              onChange={(e) =>
                                this.setState({ streetName: e.target.value })
                              }
                              onFocus={(e) =>
                                this.setState({ streetNameFocused: true })
                              }
                              onBlur={(e) =>
                                this.setState({ streetNameFocused: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup
                            className={classnames("input-group-merge", {
                              focused: this.state.streetNumberFocused,
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-map-pin" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder={i18n.t(
                                "dashboard.klanten.huisnummer"
                              )}
                              type="text"
                              value={this.state.streetNumber}
                              onChange={(e) =>
                                this.setState({ streetNumber: e.target.value })
                              }
                              onFocus={(e) =>
                                this.setState({ streetNumberFocused: true })
                              }
                              onBlur={(e) =>
                                this.setState({ streetNumberFocused: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup
                            className={classnames("input-group-merge", {
                              focused: this.state.zipcodeFocused,
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-map-pin" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder={i18n.t("dashboard.klanten.postcode")}
                              type="text"
                              value={this.state.zipcode}
                              onChange={(e) =>
                                this.setState({ zipcode: e.target.value })
                              }
                              onFocus={(e) =>
                                this.setState({ zipcodeFocused: true })
                              }
                              onBlur={(e) =>
                                this.setState({ zipcodeFocused: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup
                            className={classnames("input-group-merge", {
                              focused: this.state.cityFocused,
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-map-pin" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder={i18n.t("dashboard.klanten.stad")}
                              type="text"
                              value={this.state.city}
                              onChange={(e) =>
                                this.setState({ city: e.target.value })
                              }
                              onFocus={(e) =>
                                this.setState({ cityFocused: true })
                              }
                              onBlur={(e) =>
                                this.setState({ cityFocused: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup
                            className={classnames("input-group-merge", {
                              focused: this.state.emailFocused,
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-envelope" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder={i18n.t("dashboard.klanten.email")}
                              type="text"
                              value={this.state.email}
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                              onFocus={(e) =>
                                this.setState({ emailFocused: true })
                              }
                              onBlur={(e) =>
                                this.setState({ emailFocused: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup
                            className={classnames("input-group-merge", {
                              focused: this.state.contactPersonFocused,
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-female" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder={i18n.t(
                                "dashboard.klanten.contact_persoon"
                              )}
                              type="text"
                              value={this.state.contactPerson}
                              onChange={(e) =>
                                this.setState({ contactPerson: e.target.value })
                              }
                              onFocus={(e) =>
                                this.setState({ contactPersonFocused: true })
                              }
                              onBlur={(e) =>
                                this.setState({ contactPersonFocused: false })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <div className="custom-file">
                            <input
                              className="custom-file-input opacity-0"
                              id="customFileLang"
                              lang="nl"
                              type="file"
                              accept="image/png, image/jpeg"
                              onChange={(e) =>
                                this.state.editForm
                                  ? this.setState({
                                      editClientLogo: e.target.files[0],
                                    })
                                  : this.setState({
                                      clientLogo: e.target.files[0],
                                    })
                              }
                            />
                            <label
                              className={
                                !this.state.clientLogo &&
                                !this.state.editClientLogo
                                  ? "custom-file-label"
                                  : "custom-file-label text-success"
                              }
                              htmlFor="customFileLang"
                            >
                              {!this.state.clientLogo &&
                              !this.state.editClientLogo
                                ? i18n.t(
                                    "dashboard.klanten.tabel.logo.placeholder"
                                  )
                                : i18n.t(
                                    "dashboard.klanten.tabel.logo.gekozen"
                                  )}
                            </label>
                          </div>
                        </FormGroup>
                        <div className="text-center">
                          <Button
                            className="my-4"
                            color="primary"
                            type="button"
                            onClick={() =>
                              this.setState({
                                clientName: "",
                                streetName: "",
                                streetNumber: "",
                                zipcode: "",
                                city: "",
                                email: "",
                                contactPerson: "",
                                clientLogo: null,
                                formModal: false,
                                editForm: false,
                              })
                            }
                          >
                            {i18n.t("dashboard.sluiten")}
                          </Button>
                          <Button
                            className="my-4"
                            color="success"
                            type="button"
                            onClick={() =>
                              this.state.editForm
                                ? this.onEditClient()
                                : this.onSaveClient()
                            }
                          >
                            {i18n.t("dashboard.opslaan")}
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </Modal>
            </Col>
          </Row>
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="border-0">
                  <Row>
                    <Col xs="4">
                      <h3 className="mb-0">
                        {i18n.t("dashboard.klanten.klanten")}
                      </h3>
                    </Col>
                    <Col className="text-right" xs="8">
                      <Button
                        className="btn-round btn-icon"
                        color="info"
                        id="klanten-button"
                        onClick={(e) => this.setState({ formModal: true })}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                        </span>
                        <span className="btn-inner--text">
                          {i18n.t("dashboard.klanten.nieuw")}
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>

                <Table
                  id="klanten-tabel"
                  className="align-items-center table-flush"
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        {i18n.t("dashboard.klanten.tabel.klant")}
                      </th>
                      <th scope="col">{i18n.t("dashboard.klanten.nummer")}</th>
                      <th scope="col">{i18n.t("dashboard.klanten.straat")}</th>
                      <th scope="col">
                        {i18n.t("dashboard.klanten.huisnummer")}
                      </th>
                      <th scope="col">
                        {i18n.t("dashboard.klanten.postcode")}
                      </th>
                      <th scope="col">{i18n.t("dashboard.klanten.stad")}</th>
                      <th scope="col">{i18n.t("dashboard.klanten.email")}</th>
                      <th scope="col">
                        {i18n.t("dashboard.klanten.tabel.contact")}
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {this.state.clients !== null &&
                      Object.entries(this.state.clients).map((client, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">
                              <Media className="align-items-center">
                                <img
                                  src={
                                    !!client[1].logo ? client[1].logo : beeImage
                                  }
                                  className="avatar mr-3 bg-white"
                                  alt="logo"
                                />
                                <Media>
                                  <span className="name mb-0 text-sm">
                                    {client[1].name}
                                  </span>
                                </Media>
                              </Media>
                            </th>
                            <td>{client[1].clientNumber}</td>
                            <td>{client[1].street}</td>
                            <td>{client[1].streetNumber}</td>
                            <td>{client[1].zipcode}</td>
                            <td>{client[1].city}</td>
                            <td>{client[1].email}</td>
                            <td>{client[1].contact}</td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-info"
                                  color=""
                                  role="button"
                                  size="sm"
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <DropdownItem
                                    onClick={() =>
                                      this.onPressEditClient(client)
                                    }
                                  >
                                    {i18n.t("dashboard.bewerken")}
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() =>
                                      this.onPressDeleteClient(client)
                                    }
                                  >
                                    {i18n.t("dashboard.verwijderen")}
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
          <Row>
            <Col md="4">
              <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={this.state.deleteModal}
                toggle={() =>
                  this.setState({
                    deleteModal: !this.state.deleteModal,
                    clientToDelete: null,
                  })
                }
              >
                {this.state.clientToDelete !== null && (
                  <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                      <CardHeader className="bg-transparent pb-3">
                        <div className="text-muted text-center mt-2 mb-3">
                          <span className="card-header-title">
                            {i18n.t("dashboard.klanten.verwijderen")}
                          </span>
                        </div>
                      </CardHeader>
                      <CardBody className="px-lg-5 py-lg-5">
                        <a href="#" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="logo project"
                            className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                            src={
                              !!this.state.clientToDelete[1].logo
                                ? this.state.clientToDelete[1].logo
                                : beeImage
                            }
                            style={{ width: "140px" }}
                          />
                        </a>
                        <div className="pt-4 text-center">
                          <h5 className="h3 title">
                            <span className="d-block mb-1">
                              {this.state.clientToDelete[1].name}
                            </span>
                          </h5>
                        </div>
                        <Form role="form">
                          <div className="text-center">
                            <Button
                              className="my-4"
                              color="primary"
                              type="button"
                              onClick={() =>
                                this.setState({
                                  deleteModal: false,
                                  memberToDelete: null,
                                })
                              }
                            >
                              {i18n.t("dashboard.sluiten")}
                            </Button>
                            <Button
                              className="my-4"
                              color="success"
                              type="button"
                              onClick={() => this.onDeleteClient()}
                            >
                              {i18n.t("dashboard.verwijderen")}
                            </Button>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </div>
                )}
              </Modal>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={this.state.deletedProjectsOverviewModal}
                toggle={() =>
                  this.setState({
                    deletedProjectsOverviewModal:
                      !this.state.deletedProjectsOverviewModal,
                  })
                }
              >
                {!!this.state.deletedProjects && (
                  <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                      <CardHeader className="bg-transparent pb-3">
                        <div className="text-muted text-center mt-2 mb-3">
                          <span className="card-header-title">
                            {i18n.t("dashboard.klanten.verwijderd")}
                          </span>
                          <span className="text-muted d-block">
                            {i18n.t("dashboard.klanten.verwijderd_desc")}
                          </span>
                        </div>
                      </CardHeader>
                      <CardBody className="px-lg-3 py-lg-3">
                        {this.state.deletedProjects.length > 0 ? (
                          this.state.deletedProjects.map((project, i) => {
                            return (
                              <Row
                                key={i}
                                className={
                                  i % 2 === 0
                                    ? "pb-4 pt-4"
                                    : "bg-lighter pb-4 pt-4"
                                }
                              >
                                <Col xs="6" className="align-self-center">
                                  <h3 className="mb-0 text-center">
                                    {project[1].name}
                                  </h3>
                                </Col>
                                <Col xs="6" className="text-center">
                                  <Button
                                    color="info"
                                    type="button"
                                    onClick={() =>
                                      this.onRestoreProject(project[0])
                                    }
                                  >
                                    {i18n.t("dashboard.klanten.terugzetten")}
                                  </Button>
                                </Col>
                              </Row>
                            );
                          })
                        ) : (
                          <h3 className="mt-3 mb-3 text-center">
                            {i18n.t("dashboard.klanten.geen_verw_klanten")}
                          </h3>
                        )}
                        <Row className="justify-content-center mt-3">
                          <Button
                            color="primary"
                            type="button"
                            onClick={() =>
                              this.setState({
                                deletedProjectsOverviewModal: false,
                              })
                            }
                          >
                            {i18n.t("dashboard.sluiten")}
                          </Button>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                )}
              </Modal>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Klanten;
