import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import DashboardNavbar from "components/Navbars/DashboardNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import { isAdminPage } from "views/functions/generic";
import { getCompanySettings } from "views/functions/firebase/company";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Loading from "components/Loading/loading";
import i18n from "../variables/translations/i18n";
import LockedAlert from "../components/Notifications/LockedAlert";
import urls from "../urls";
import {
  calculateRestDaysForModal,
  fetchSubscription,
  getSubscriptionInfo,
} from "../views/functions/stripe/subscription";
import LockedAlertUser from "../components/Notifications/LockedAlertUser";
import _ from "lodash";
import { chartOptions, parseOptions } from "variables/charts";
import Chart from "chart.js";
import Helmet from "react-helmet";
import {
  META_DASHBOARD_TITLE,
  META_GENERIC_DESCRIPTION,
} from "constants/MetaTagsConstants";
import { WEBSITE_NAME } from "constants/CompanyConstants";
import { useSelector } from "react-redux";
import { useFirebaseConnect } from "react-redux-firebase";
import {
  FIREBASE_COMPANIES_TABLE,
  FIREBASE_PROJECTS_TABLE,
  FIREBASE_HOURS_TABLE,
  FIREBASE_INACTIVE_USER_PROJECTS_TABLE,
} from "constants/FirebaseConstants";
import { ADMIN_ROLE } from "../constants/UserConstants";
import { FIREBASE_USERS_TABLE } from "constants/FirebaseConstants";

const Dashboard = (props) => {
  const profile = useSelector((state) => state.firebaseReducer.profile);
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const companyId = profile.companyId;
  const role = profile.role;
  // Create array of calls to make to firebase
  let FIREBASE_CALLS = [
    { path: FIREBASE_COMPANIES_TABLE + "/" + companyId },
    {
      path: FIREBASE_PROJECTS_TABLE,
      queryParams: ["orderByChild=companyId", "equalTo=" + companyId],
    },
    {
      path: FIREBASE_HOURS_TABLE,
      queryParams: ["orderByChild=userId", "equalTo=" + auth.uid],
      storeAs: "userHours",
    },
    {
      path: FIREBASE_INACTIVE_USER_PROJECTS_TABLE + "/" + auth.uid,
      storeAs: "deactivatedProjectsForUser",
    },
  ];
  // Add calls to fill data for admin to the calls array
  if (role === ADMIN_ROLE) {
    FIREBASE_CALLS.push(
      { path: FIREBASE_COMPANIES_TABLE + "/" + companyId },
      {
        path: FIREBASE_HOURS_TABLE,
        queryParams: ["orderByChild=companyId", "equalTo=" + companyId],
        storeAs: "companyHours",
      },
      {
        path: FIREBASE_USERS_TABLE,
        queryParams: ["orderByChild=companyId", "equalTo=" + companyId],
        storeAs: "companyUsers",
      },
      {
        path: FIREBASE_INACTIVE_USER_PROJECTS_TABLE,
        queryParams: ["orderByChild=companyId", "equalTo=" + companyId],
        storeAs: "deactivatedProjectsForUsers",
      }
    );
  }

  useFirebaseConnect(FIREBASE_CALLS);
  const company = useSelector(
    ({
      firebaseReducer: {
        data: { companies },
      },
    }) => companies && companies[companyId]
  );
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [redirectToPayment, setRedirectToPayment] = useState(false);
  const [showPaymentAlert, setShowPaymentAlert] = useState(false);
  const [showUserPaymentAlert, setShowUserPaymentAlert] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [visibleRoutes, setVisibleRoutes] = useState(null);

  useEffect(() => {
    if (!!companyId && !!company) {
      getCompanySettings(companyId)
        .then((companySettings) => {
          getUserSubscriptionInformation(companyId, role);
          getVisibleRoutes(companySettings)
            .then((routes) => {
              setVisibleRoutes(routes);
              setIsLoading(false);
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }
  }, [companyId, company]);

  useEffect(() => {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }, [window.Chart]);

  const getVisibleRoutes = () => {
    return new Promise(function (resolve) {
      let visibleRoutes = [];
      routes.map((prop) => {
        if (prop.layout === "/dashboard") {
          if (!!prop.module && !prop.adminOnly) {
            visibleRoutes.push(prop.layout + prop.path);
          }
        }
      });
      resolve(visibleRoutes);
    });
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (redirectToPayment) {
        if (prop.module === "subscription")
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
      } else {
        if (prop.collapse) {
          return getRoutes(prop.views);
        }
        if (prop.adminOnly && profile.role !== ADMIN_ROLE) return null;
        if (prop.layout === "/dashboard") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        } else {
          return null;
        }
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (path.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = () => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    let url = props.location.pathname;
    if (isAdminPage(url)) return "light";
    else return "dark";
  };
  const getFirstActiveRoute = () => {
    return visibleRoutes[0];
  };

  /**
   * Get user/company subscription status
   * @param companyId
   * @param userRole
   */
  const getUserSubscriptionInformation = (companyId, userRole) => {
    fetchSubscription(companyId)
      .then((subscription) => {
        if (subscription.subscriptionId !== undefined) {
          getSubscriptionInfo(subscription.subscriptionId)
            .then((subscriptionInfo) => {
              if (subscriptionInfo.status === "active")
                setSubscriptionType(subscription.subscriptionType);
              else if (
                subscriptionInfo.status !== "active" &&
                userRole === ADMIN_ROLE
              )
                showPaymentAlertAdmin();
            })
            .catch(() => {
              if (userRole === ADMIN_ROLE) showPaymentAlertAdmin();
            });
        } else {
          if (!subscription.couponCreatedAt && userRole === ADMIN_ROLE)
            showPaymentAlertAdmin();
        }
      })
      .catch(() => {
        let restDays = calculateRestDaysForModal(company.created_at);
        if (restDays <= 0 && userRole === ADMIN_ROLE) showPaymentAlertAdmin();
      });
  };

  /**
   * Set state of payment alert of user and admin
   */
  const showPaymentAlertAdmin = () => {
    let urlParts = props.location.pathname.split("/");
    let firstPartOfString = "/" + urlParts[1] + "/" + urlParts[2];
    let reservedUrls = ["/dashboard/betaling"];
    let filter = !!firstPartOfString
      ? firstPartOfString
      : props.location.pathname;
    let reserved = _.includes(reservedUrls, filter);
    if (!reserved) {
      setShowPaymentAlert(true);
      setShowUserPaymentAlert(false);
    }
  };

  /**
   * Redirect to payment if company has no subscription
   */
  const onRedirectToPayment = () => {
    setShowPaymentAlert(false);
    setRedirectToPayment(true);
  };

  if (auth.isLoaded && auth.isEmpty) return <Redirect to={urls.login} />;
  return (
    <>
      <Helmet>
        <title>
          {META_DASHBOARD_TITLE} · {WEBSITE_NAME}
        </title>
        <meta name="description" content={META_GENERIC_DESCRIPTION} />
      </Helmet>
      {showPaymentAlert && (
        <LockedAlert
          type="success"
          title={i18n.t("dashboard.abonnement.lock_scherm_titel")}
          description={i18n.t("dashboard.abonnement.lock_scherm_desc")}
          confirmBtnText={i18n.t("dashboard.abonnement.lock_scherm_button")}
          confirmAlert={() => onRedirectToPayment()}
        />
      )}
      {showUserPaymentAlert && (
        <LockedAlertUser
          type="success"
          title={i18n.t("dashboard.abonnement.lock_scherm_titel")}
          description={i18n.t("dashboard.abonnement.lock_scherm_desc_user")}
        />
      )}
      <TransitionGroup>
        {isLoading ? (
          <div>
            {/* Don't show sidebar on mobile devices while loading */}
            {window.innerWidth > 1200 && (
              <Sidebar
                {...props}
                isLoading={true}
                logo={{
                  innerLink: "/",
                  imgSrc: require("assets/img/brand/logo-purple.png"),
                  imgAlt: "logo",
                }}
              />
            )}
            <Loading />
          </div>
        ) : (
          <CSSTransition timeout={800} classNames="animation">
            <div>
              <Sidebar
                {...props}
                routes={routes}
                toggleSidenav={toggleSidenav}
                sidenavOpen={sidenavOpen}
                isLoading={false}
                logo={{
                  innerLink: "/",
                  imgSrc: require("assets/img/brand/logo-purple.png"),
                  imgAlt: "logo",
                }}
                subscriptionType={subscriptionType}
              />
              <div className="main-content">
                <DashboardNavbar
                  {...props}
                  theme={getNavbarTheme()}
                  toggleSidenav={toggleSidenav}
                  sidenavOpen={sidenavOpen}
                  brandText={getBrandText(props.location.pathname)}
                />
                <Switch>
                  {getRoutes(routes)}
                  <Redirect
                    from="*"
                    to={
                      !redirectToPayment
                        ? getFirstActiveRoute()
                        : urls.subscription
                    }
                  />
                </Switch>
                <AdminFooter />
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
};

export default Dashboard;
