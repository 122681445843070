import React from "react";
import { Col, Row } from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import HomepageNavbar from "components/Navbars/HomepageNavbar";
import AuthFooter from "components/Footers/AuthFooter";
import i18n from "../../../variables/translations/i18n";
import Fade from "react-reveal/Fade";
import ContactForm from "components/Contact/ContactForm";
import SupportContact from "components/Contact/SupportContact";
import { GeneralFaq } from "../../../components/Faq/GeneralFaq";
import {
  META_CONTACT_DESCRIPTION,
  META_CONTACT_TITLE,
} from "constants/MetaTagsConstants";
import { WEBSITE_NAME } from "constants/CompanyConstants";
import { BreadcrumbStructuredData } from "../../../components/StructuredData/BreadcrumbStructuredData";
import {
  BREADCRUMB_CONTACT,
  BREADCRUMB_HOME,
} from "../../../constants/BreadcrumbConstants";
import { Helmet } from "react-helmet";

class Contact extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            {META_CONTACT_TITLE} · {WEBSITE_NAME}
          </title>
          <meta name="description" content={META_CONTACT_DESCRIPTION} />
          {BreadcrumbStructuredData([BREADCRUMB_HOME, BREADCRUMB_CONTACT])}
        </Helmet>
        <HomepageNavbar />
        <AuthHeader title={i18n.t("contact_pagina.pagina_titel")} />
        <div className="container mt--8 mb-5 pb-5">
          <Row className="justify-content-center">
            <Col lg="8" md="10">
              <Fade delay={100}>
                <ContactForm />
              </Fade>
            </Col>
            <Col className=" pl-3 pr-3" lg="4" md="12">
              <Fade delay={300}>
                <SupportContact />
              </Fade>
            </Col>
          </Row>
          <div className="text-center mt-5 mb-6">
            <Fade delay={100}>
              <p className="display-4 center-text">
                {i18n.t("contact_pagina.contact_desc_2")}
              </p>
            </Fade>
          </div>
          <GeneralFaq />
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Contact;
