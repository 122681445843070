import { chartColors } from "../../variables/charts";
import _ from "lodash";
import moment from "moment";

const EMPTY_PROJECT_ID = "001";

function getRandomColor(project) {
  // Get color and remove from colors array
  let index = chartColors.findIndex((x) => x.assignedTo === project);
  if (index !== -1) return chartColors[index];
  else {
    let randomIndex = Math.floor(Math.random() * chartColors.length);
    if (!chartColors[randomIndex].assignedTo) {
      chartColors[randomIndex].assignedTo = project;
      return chartColors[randomIndex];
    } else {
      let anotherRandomIndex = Math.floor(Math.random() * chartColors.length);
      chartColors[anotherRandomIndex].assignedTo = project;
      return chartColors[anotherRandomIndex];
    }
  }
}

function getActiveUsers(snapshot) {
  return new Promise(function (resolve, reject) {
    let data = snapshot.val();
    if (data !== null) {
      let usersArray = [];
      for (let key in data) {
        if (!data.hasOwnProperty(key)) continue;
        let id = key;
        let name;
        let active = true;
        let obj = data[key];
        for (let prop in obj) {
          if (!obj.hasOwnProperty(prop)) continue;
          if (obj.active === false) active = false;
          if (prop === "name") name = obj[prop];
        }
        if (active) {
          let object = {
            id: id,
            name: name,
          };
          usersArray.push(object);
        }
      }
      resolve(usersArray);
    }
  });
}

const getDateLabel = (type) => {
  if (type === "date" || type === "week" || type === "month") return "D MMM";
  else if (type === "quarter") return "W";
  else if (type === "year") return "MMM";
};

const getDateComparator = (date, type) => {
  if (type === "date" || type === "week" || type === "month")
    return moment(date).format("DD-MM-YYYY");
  else if (type === "quarter") return parseInt(moment(date).format("W"));
  else if (type === "year") return parseInt(moment(date).format("M") - 1);
};

/**
 * Get all dates between two dates, create objects for dates, labels and
 * comparators which will be used for calculations and charts
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} type
 * @returns
 */
const enumerateDaysBetweenDates = (startDate, endDate, type) => {
  let now = startDate.clone();
  let dates = [];
  let labels = [];
  let comparators = [];

  while (now.isSameOrBefore(endDate)) {
    let date = now.format("DD-MM-YYYY");
    let label = now.format(getDateLabel(type));
    let comparator = getDateComparator(now, type);
    if (!dates.includes(date)) dates.push(date);
    if (!comparators.includes(comparator)) comparators.push(comparator);
    if (!labels.includes(label)) labels.push(label);
    now.add(1, "days");
  }
  return { dates: dates, labels: labels, comparators: comparators };
};

/**
 * For each project, generate a new object for chartJS which can be filled later
 * @param {*} projects
 * @returns
 */
const getProjectObjectsForCharts = (projects) => {
  let array = [];
  const grey = "#a8a8a8";
  _.map(projects, (project) => {
    let color = { color: null, border: null };
    if (project.key === EMPTY_PROJECT_ID) {
      color.color = grey;
      color.border = grey;
    } else color = getRandomColor(project.value.name);
    array.push({
      label: project.value.name,
      data: [],
      backgroundColor: color.color,
      borderColor: color.border,
      maxBarThickness: 10,
      projectId: project.key,
    });
  });

  return array;
};

export const calculateHoursAndEarningsInPeriod = (
  hours,
  projects,
  date,
  dateType,
  projectId,
  userId,
  onlyInvoicable
) => {
  return new Promise(function (resolve, reject) {
    let totalHours = 0;
    let totalEarnings = 0;
    let startDate = moment(date).clone().startOf(dateType);
    let endDate = moment(date, "DD-MM-YYYY").clone().endOf(dateType);
    let datesAndLabels = enumerateDaysBetweenDates(
      startDate,
      endDate,
      dateType
    );
    let allDates = datesAndLabels.dates;
    let labels = datesAndLabels.labels;
    let comparators = datesAndLabels.comparators;
    let hoursInPeriod = _.filter(hours, (item) => {
      return onlyInvoicable
        ? item.invoiceable &&
            moment
              .unix(item.date)
              .isBetween(startDate, endDate, undefined, "[]")
        : moment.unix(item.date).isBetween(startDate, endDate, undefined, "[]");
    });
    if (!projectId) projectId = "all";
    if (!userId) userId = "all";

    let filter;
    if (projectId !== "all" && userId !== "all") {
      filter = { projectId: projectId, userId: userId };
    } else if (projectId === "all" && userId !== "all") {
      filter = { userId: userId };
    } else if (projectId !== "all" && userId === "all") {
      filter = { projectId: projectId };
    }

    let filteredHoursByProject =
      projectId === "all" && userId === "all"
        ? hoursInPeriod
        : _.filter(hoursInPeriod, filter);

    let emptyProject = {
      key: EMPTY_PROJECT_ID,
      value: {
        active: true,
        hourlyRate: "0",
        name: "-",
      },
    };

    projects.push(emptyProject);
    let projectHours = getProjectObjectsForCharts(projects);
    let projectEarnings = getProjectObjectsForCharts(projects);

    let dateYear = moment(startDate).year();

    // Calculate the totalHours for each date
    _.map(filteredHoursByProject, (obj) => {
      let objectIndex;
      // Calculate total hours, to be shown in card header
      totalHours += parseFloat(obj.hours);

      // For quarterly filter, week and year have to match
      // For yearly filter, month and year have to match
      // For all other date filters, dates have to match
      if (dateType === "quarter")
        objectIndex = _.findIndex(comparators, (item) => {
          return (
            moment.unix(obj.date).week() === parseInt(item) &&
            moment.unix(obj.date).year() === dateYear
          );
        });
      else if (dateType === "year") {
        objectIndex = _.findIndex(comparators, (item) => {
          return (
            moment.unix(obj.date).month() === parseInt(item) &&
            moment.unix(obj.date).year() === dateYear
          );
        });
      } else
        objectIndex = _.findIndex(
          allDates,
          (x) => x === moment.unix(obj.date).format("DD-MM-YYYY")
        );

      let projectIndex = _.findIndex(projectHours, [
        "projectId",
        obj.projectId,
      ]);

      let emptyProjectIndex = _.findIndex(projectHours, [
        "projectId",
        EMPTY_PROJECT_ID,
      ]);

      let projectObj;
      if (projectIndex >= 0)
        projectObj = _.find(projects, ["key", obj.projectId]).value;
      else projectObj = emptyProject.value;

      if (projectIndex < 0) projectIndex = emptyProjectIndex;

      let projectHourlyRate = projectObj.hourlyRate;

      obj.projectName = projectObj.name;

      // Either set (if not yet present) or increment the amount of hours
      // in the right spot of the projectHours array
      projectHours[projectIndex].data[objectIndex] = projectHours[projectIndex]
        .data[objectIndex]
        ? projectHours[projectIndex].data[objectIndex] + parseFloat(obj.hours)
        : parseFloat(obj.hours);

      // Calculate the earnings for each date, if the hours are invoiceable
      // and the project has an hourly rate
      if (obj.invoiceable && !!projectHourlyRate) {
        // Calculate total earnings, to be shown in card header
        totalEarnings += obj.hours * projectHourlyRate;

        // Either set (if not yet present) or increment the amount of hours
        // in the right spot of the projectEarnings array
        projectEarnings[projectIndex].data[objectIndex] = projectEarnings[
          projectIndex
        ].data[objectIndex]
          ? projectEarnings[projectIndex].data[objectIndex] +
            obj.hours * projectHourlyRate
          : obj.hours * projectHourlyRate;
      }
    });

    const object = {
      totalHours: totalHours,
      totalEarnings: totalEarnings,
      hoursForPeriodPerProject: projectHours,
      earningsForPeriodPerProject: projectEarnings,
      filteredHours: filteredHoursByProject,
      labels: labels,
    };

    resolve(object);
  });
};

function calculateHoursAndEarnings(allHours, allProjects, year) {
  return new Promise(function (resolve, reject) {
    let hours = [];
    let hoursForMonthProjects = [];
    let earnings = [];
    let earningsForMonthProjects = [];
    let earningsTotal = 0;
    let formattedMonths = [];
    let formattedWeeks = [];
    let totalHoursWeek = 0;
    let totalHoursMonth = 0;
    let hoursLastMonth = 0;
    let hoursLastWeek = 0;

    // Object keys are the ids, put those in the object itself
    for (let key in allHours) {
      if (!allHours[key].id) _.set(allHours[key], ["id"], key);
    }

    let dataThisYear = _.sortBy(
      _.filter(allHours, function (value) {
        return moment.unix(value.date).year() === year;
      }),
      "date"
    );

    if (dataThisYear.length === 0) reject("no-hours");

    // Get data of this week
    let dataThisWeek = _.sortBy(
      _.filter(allHours, function (value) {
        return (
          moment.unix(value.date).year() === year &&
          moment.unix(value.date).isoWeek() === moment().isoWeek()
        );
      }),
      "date"
    );

    for (let item in dataThisWeek) {
      if (dataThisWeek.hasOwnProperty(item)) {
        let obj = dataThisWeek[item];
        totalHoursWeek += obj.hours;
      }
    }

    // Get data of this month
    let dataThisMonth = _.sortBy(
      _.filter(allHours, function (value) {
        return (
          moment.unix(value.date).year() === year &&
          moment.unix(value.date).month() === moment().month()
        );
      }),
      "date"
    );

    for (let item in dataThisMonth) {
      if (dataThisMonth.hasOwnProperty(item)) {
        let obj = dataThisMonth[item];
        totalHoursMonth += obj.hours;
      }
    }

    let uniMonths = dataThisYear
      .map((item) => moment.unix(item.date).month())
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort(function (a, b) {
        return a - b;
      });

    let uniWeeks = dataThisYear
      .map((item) => moment.unix(item.date).week())
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort(function (a, b) {
        return a - b;
      });

    /** Clone projects, each time we recalculate we want to start with empty projects */
    let projects = _.cloneDeep(allProjects);
    for (let i = 0; i < uniMonths.length; i++) {
      formattedMonths.push(moment(uniMonths[i] + 1, "M").format("MMMM"));
      hours.push(0);
      earnings.push(0);
    }

    for (let i = 0; i < uniWeeks.length; i++) {
      formattedWeeks.push("Week " + uniWeeks[i].toString());
    }

    _.map(dataThisYear, function (obj) {
      let dateYear = moment.unix(obj.date).year();
      let lastMonth = moment().month() - 1;
      let lastWeek = moment().subtract(1, "week").format("w");
      if (dateYear === year) {
        let monthsIndex = uniMonths.findIndex(
          (x) => x === moment.unix(obj.date).month()
        );
        hours[monthsIndex] += parseFloat(obj.hours);

        if (moment.unix(obj.date).month() == lastMonth) {
          hoursLastMonth += parseFloat(obj.hours);
        }
        if (moment.unix(obj.date).week() == lastWeek) {
          hoursLastWeek += parseFloat(obj.hours);
        }

        let index = _.findIndex(projects, ["key", obj.projectId]);

        if (!!projects[index]) {
          obj.projectName = projects[index].value.name;
          obj.projectHourlyRate =
            index !== -1 ? projects[index].value.hourlyRate : "-";
          if (obj.invoiceable || typeof obj.invoiceable == "undefined") {
            earnings[monthsIndex] +=
              !!obj.projectHourlyRate && obj.hours * obj.projectHourlyRate;

            if (!!obj.projectHourlyRate) {
              if (
                !projects[index].value.monthlyEarnings ||
                !projects[index].value.monthlyEarnings[monthsIndex]
              ) {
                _.set(
                  projects[index].value,
                  ["monthlyEarnings", monthsIndex],
                  obj.hours * obj.projectHourlyRate
                );
                earningsTotal += obj.hours * obj.projectHourlyRate;
              } else {
                projects[index].value.monthlyEarnings[monthsIndex] +=
                  obj.hours * obj.projectHourlyRate;
                earningsTotal += obj.hours * obj.projectHourlyRate;
              }
            }
          }

          if (
            !projects[index].value.monthlyHours ||
            !projects[index].value.monthlyHours[monthsIndex]
          ) {
            _.set(
              projects[index].value,
              ["monthlyHours", monthsIndex],
              parseFloat(obj.hours)
            );
          } else {
            projects[index].value.monthlyHours[monthsIndex] += parseFloat(
              obj.hours
            );
          }
        }
      }
    });

    _.map(projects, function (item) {
      let project = item.value;
      let color = getRandomColor(project.name);
      let hours = {
        label: project.name,
        data: project.monthlyHours,
        backgroundColor: color.color,
        borderColor: color.border,
        maxBarThickness: 10,
      };
      hoursForMonthProjects.push(hours);
      if (!!project.hourlyRate) {
        let earnings = {
          label: project.name,
          data: project.monthlyEarnings,
          backgroundColor: color.color,
          borderColor: color.border,
          maxBarThickness: 10,
        };
        earningsForMonthProjects.push(earnings);
      }
    });

    let totalHours = hours.reduce((a, b) => a + b);
    let returnObject = {
      totalHours: totalHours,
      hoursThisYear: dataThisYear,
      months: formattedMonths,
      weeks: formattedWeeks,
      hoursForMonthTotal: hours,
      hoursForMonthProjects: hoursForMonthProjects,
      earningsForMonthTotal: earnings,
      earningsForMonthProjects: earningsForMonthProjects,
      earningsTotal: earningsTotal,
      hoursThisWeek: dataThisWeek,
      hoursThisMonth: dataThisMonth,
      totalHoursWeek: totalHoursWeek,
      totalHoursMonth: totalHoursMonth,
      totalHoursLastWeek: hoursLastWeek,
      totalHoursLastMonth: hoursLastMonth,
    };
    resolve(returnObject);
  });
}

/**
 * @param allHours Array containing the firebase 'hours' objects for selected project
 * @param project Firebase 'project' object
 * @param year Current year
 * @returns Object containing the following values:
 * totalHours: Total hours of project over all years,
 * totalEarnings: Total earnings of project over all years,,
 * months: Array of months using their full qualified name as String e.g. januari,
 * hoursForMonthTotal: Array of the sum of hours this year
 * hoursForMonthProjects: Array of a object which contains the the label and an array of hours for each month
 * earningsForMonthTotal:  Array of the sum of earnings this year,
 * earningsForMonthProjects: Array of a object which contains the the label and an array of earnings for each month,
 */
function calculateHoursAndEarningsForProject(allHours, project, year) {
  return new Promise(function (resolve, reject) {
    let hoursArray = [];
    let totalEarnings = 0;
    //  Fetch hours from the array of Firebase hours objects
    Array.from(allHours).forEach((object) => {
      hoursArray.push(parseFloat(object.hours));
      if (
        (object.invoiceable || typeof object.invoiceable == "undefined") &&
        !!project.hourlyRate
      )
        totalEarnings += object.hours * project.hourlyRate;
    });

    let totalHours = hoursArray.reduce((a, b) => a + b);

    // filter array of Firebase hours to hours of only the year parameter and sort chronologically
    let dataThisYear = allHours
      .filter(function (value) {
        return moment.unix(value.date).year() === year;
      })
      .sort((a, b) => {
        return b.created_at - a.created_at;
      });

    // if there are no  hours of the year requested
    if (dataThisYear.length === 0) reject("no-hours");

    let objectArray = [];
    let color = getRandomColor(project.name);

    // create generic object containing hours, fully qualified month name, month index and earnings
    Array.from(dataThisYear).forEach((object) => {
      objectArray.push({
        hours: parseFloat(object.hours),
        monthFQN: moment(moment.unix(object.date).month() + 1, "M").format(
          "MMMM"
        ),
        monthIndex: moment.unix(object.date).month() + 1,
        earning:
          object.invoiceable || typeof object.invoiceable == "undefined"
            ? object.hours * project.hourlyRate
            : 0,
      });
    });

    // Sort chronologically
    objectArray.sort(function (a, b) {
      return parseFloat(a.monthIndex) - parseFloat(b.monthIndex);
    });

    let onlyMonthFQNArray = [];
    let onlyHoursArray = [];
    let onlyEarningsArray = [];

    let hours = {
      label: project.name,
      data: onlyHoursArray,
      backgroundColor: color.color,
      borderColor: color.border,
      maxBarThickness: 10,
    };

    let earnings = {
      label: project.name,
      data: onlyEarningsArray,
      backgroundColor: color.color,
      borderColor: color.border,
      maxBarThickness: 10,
    };

    // merge duplicate months
    objectArray = mergeDuplicateMonths(objectArray);

    // create separate arrays for graph
    Array.from(objectArray).forEach((object) => {
      onlyHoursArray.push(object.hours);
      onlyMonthFQNArray.push(object.monthFQN);
      onlyEarningsArray.push(object.earning);
    });

    let hoursForMonthProjects = [];
    let earningsForMonthProjects = [];

    hoursForMonthProjects.push(hours);
    earningsForMonthProjects.push(earnings);

    let hoursForMonthTotal = [];
    let earningsForMonthTotal = [];
    hoursForMonthTotal.push(onlyHoursArray.reduce((a, b) => a + b));
    earningsForMonthTotal.push(onlyEarningsArray.reduce((a, b) => a + b));

    let returnObj = {
      totalHours: totalHours,
      totalEarnings: totalEarnings,
      months: onlyMonthFQNArray,
      hoursForMonthTotal: hoursForMonthTotal,
      hoursForMonthProjects: hoursForMonthProjects,
      earningsForMonthTotal: earningsForMonthTotal,
      earningsForMonthProjects: earningsForMonthProjects,
    };
    resolve(returnObj);
  });
}

/**
 * Method for merging objects that have the same months
 * @param array containing duplicates
 * @returns array that has merged the duplicate months and added the sum of both hours and earnings
 */
function mergeDuplicateMonths(array) {
  let result = [];

  array.forEach(function (object) {
    if (!this[object.monthIndex]) {
      this[object.monthIndex] = {
        hours: 0,
        monthFQN: object.monthFQN,
        monthIndex: object.monthIndex,
        earning: 0,
      };
      result.push(this[object.monthIndex]);
    }
    this[object.monthIndex].hours += object.hours;
    this[object.monthIndex].earning += object.earning;
  }, Object.create(null));

  return result;
}

export {
  getActiveUsers,
  calculateHoursAndEarnings,
  calculateHoursAndEarningsForProject,
  getRandomColor,
};
