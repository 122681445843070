import React, { useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Modal,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import firebase from "firebase";
import urls from "../../../../urls.js";
import notify from "../../../functions/notify.js";
import NotificationAlert from "react-notification-alert";
import { roles } from "../../../../variables/Roles";
import encode from "nodejs-base64-encode";
import { CopyToClipboard } from "react-copy-to-clipboard";
import beeImage from "assets/img/theme/dash.png";
import {
  calculateOffDayBalance,
  getSumOffDays,
} from "../../../functions/offDays";
import _ from "lodash";
import { filterObjectRemoveValue } from "views/functions/generic.js";
import CardsHeader from "components/Headers/CardsHeader.js";
import { setUserSettings } from "views/functions/firebase/users.js";
import Tour from "components/Support/Tour";
import { tourSteps } from "variables/tourSteps";
import { Link } from "react-router-dom";
import { sendInvitationMail } from "../../../functions/mail";
import { useSelector } from "react-redux";
import { Form, Input, InputNumber, Select, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import { ADMIN_ROLE, USER_ROLE } from "constants/UserConstants.js";
import { FIREBASE_INACTIVE_USER_PROJECTS_TABLE } from "constants/FirebaseConstants.js";

const { Option } = Select;

const Team = () => {
  const profile = useSelector((state) => state.firebaseReducer.profile);
  const company = useSelector(
    (state) => state.firebaseReducer.data.companies[profile.companyId]
  );
  const projects = useSelector(
    (state) => state.firebaseReducer.ordered.projects
  );
  const activeProjects =
    !!projects &&
    _.filter(projects, (project) => {
      return project.value.active;
    });
  const companyId = profile.companyId;
  const notifyTeamRef = useRef(null);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [offDaysForm] = Form.useForm();
  const [members, setMembers] = useState(null);
  const [formModal, setFormModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [offDaysModal, setOffDaysModal] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [memberToEdit, setMemberToEdit] = useState(null);
  const [usedOffDays, setUsedOffDays] = useState(0);
  const [sumOffDaysTeams, setSumOffDaysTeams] = useState(0);
  const [startTour, setStartTour] = useState(false);
  const steps = tourSteps.team;
  const offDaysTeam =
    !!company && !!company.offDaysTeam ? company.offDaysTeam : 25;
  const [isLoading, setIsLoading] = useState(true);
  const [projectsForSelect, setProjectsForSelect] = useState(null);
  const [projectSelectDisabled, setProjectSelectDisabled] = useState(false);

  useEffect(() => {
    if (!profile.isEmpty && profile.isLoaded) getTeamMembers();
  }, [profile]);

  useEffect(() => {
    if (!!activeProjects && !projectsForSelect) {
      setActiveProjectsInSelect();
    }
  }, [activeProjects]);

  /**
   * Set the projects for in the Select form item. Used both on mount and when user is being edited
   */
  const setActiveProjectsInSelect = () => {
    let objectArray = [];
    _.forEach(activeProjects, (project) => {
      objectArray.push({
        value: project.key,
        label: project.value.name,
        key: project.key,
      });
    });
    setProjectsForSelect(objectArray);
    form.setFieldsValue({ accessProjects: objectArray });
    editForm.setFieldsValue({ accessProjects: objectArray });
  };

  /**
   * Fetch users from Firebase based on companyId.
   * Set the fetched users on the state, then fetch invited users.
   */
  const getTeamMembers = () => {
    let members = firebase
      .database()
      .ref("users")
      .orderByChild("companyId")
      .equalTo(companyId);
    members.on("value", (snapshot) => {
      let data = snapshot.val();
      const sortedData = _.sortBy(data, (item) => item.name);
      if (data !== null) {
        let activeUsers = filterObjectRemoveValue(sortedData, "active", false);
        setMembers(activeUsers);
        getInvitedUsers(activeUsers);
      }
    });
  };

  /**
   * Fetch users from Firebase based on companyId where status is invited.
   * Update the members state with invited users, then fetch the company info.
   * The method getCompanyInfo() depends on the members state, that's why we call it here.
   */
  const getInvitedUsers = (activeUsers) => {
    let users = firebase
      .database()
      .ref("invitedUsers")
      .orderByChild("companyId")
      .equalTo(companyId);
    users.on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        let data = Object.entries(snapshot.val());
        let newData = Object.assign({}, activeUsers);
        for (let i = 0; i < data.length; i++) {
          data[i][1].status = "invited";
          newData[data[i][0]] = data[i][1];
        }
        setMembers(newData);
        setSumOffDaysTeams(getSumOffDays(offDaysTeam, newData));
        getOffDays(offDaysTeam, newData);
      } else {
        // if there are no invited users, still proceed...
        setSumOffDaysTeams(getSumOffDays(offDaysTeam, activeUsers));
        getOffDays(offDaysTeam, activeUsers);
      }
    });
  };

  /**
   * Fetch OffDays from Firebase.
   * @param teamOffDays Initial off days of a team, e.g. 25
   * @param teamMembers Nested Objects containing the users of the company.
   */
  const getOffDays = (teamOffDays, teamMembers) => {
    let offDaysRef = firebase
      .database()
      .ref("offdays")
      .orderByChild("companyId")
      .equalTo(companyId);
    offDaysRef.on("value", (snapshot) => {
      let data = snapshot.val();
      if (!!data) {
        getTeamOffDaysUsage(Object.values(data), teamOffDays);
        getOffDayBalanceOfUser(Object.values(data), teamOffDays, teamMembers);
      } else {
        // No off days found but we need to show the initial default value
        getOffDayBalanceOfUser([], teamOffDays, teamMembers);
      }
    });
  };

  /**
   * Calculate the usage of the off days of the team.
   * @param teamOffDaysArray Array of off days objects from Firebase.
   * @param offDaysTeam Initial off days for team, e.g. 25
   */
  const getTeamOffDaysUsage = (teamOffDaysArray, offDaysTeam) => {
    let returnObject = calculateOffDayBalance(teamOffDaysArray, offDaysTeam);
    setUsedOffDays(returnObject.usedDays);
  };

  /**
   * Calculate the off day balance and usage of the user and then set it in the nested members object.
   * @param teamOffDaysArray Array containing all the offdays of the team.
   * @param offDaysTeam Initial off days for team, e.g. 25
   * @param teamMembers Nested Objects containing the users of the company.
   */
  const getOffDayBalanceOfUser = (
    teamOffDaysArray,
    offDaysTeam,
    teamMembers
  ) => {
    let filteredArray = [];
    let teamUserIds = [];
    Object.values(teamMembers).forEach((member) => teamUserIds.push(member.id));
    let teamMembersValues = Object.values(teamMembers);
    for (let i = 0; i < teamUserIds.length; i++) {
      filteredArray = teamOffDaysArray.filter(
        (offDayEntry) => offDayEntry.userId === teamUserIds[i]
      );
      let currentUser = teamMembersValues[i];
      // If users' offdays differ from team offdays, use that in calculation
      let offDayAmount = !!currentUser.offdays
        ? currentUser.offdays
        : offDaysTeam;
      let returnObject = calculateOffDayBalance(filteredArray, offDayAmount);
      const userIndex = _.findIndex(
        Object.values(teamMembers),
        (item) => item.id === teamUserIds[i]
      );
      if (userIndex >= 0) {
        _.set(
          teamMembers,
          userIndex + ".remainingDays",
          returnObject.remainingDays
        );
        _.set(teamMembers, userIndex + ".usedDays", returnObject.usedDays);
        _.set(teamMembers, userIndex + ".initialDays", offDayAmount);
      }
    }
    setMembers(teamMembers);
    setIsLoading(false);
  };

  /**
   * Inviting the user. If user is already invited it show a notification,
   * otherwise it will save the entry in the 'invitedUsers' Firebase Object.
   */
  const onSaveMember = () => {
    form.validateFields().then(() => {
      const values = form.getFieldsValue();
      let deactivatedIds = [];
      if (values.role === USER_ROLE) {
        let deactivated = _.differenceWith(
          projectsForSelect,
          values.accessProjects,
          _.isEqual
        );
        _.forEach(deactivated, (item) => {
          deactivatedIds.push(item.value);
        });
      } else deactivatedIds = null;
      form.resetFields();
      /** Check if user already exists */
      let invitedUser = firebase
        .database()
        .ref("users")
        .orderByChild("email")
        .equalTo(values.email);
      invitedUser.once("value", (snapshot) => {
        let data = snapshot.val();
        if (!!data) {
          /** User is already invited */
          notify(
            "warning",
            values.email + t("meldingen.gebruiker_bestaat_al"),
            notifyTeamRef
          );
        } else {
          /** Push invited user to firebase */
          firebase
            .database()
            .ref("invitedUsers")
            .push(
              {
                email: values.email,
                role: values.role,
                companyId: companyId,
                created_at: firebase.database.ServerValue.TIMESTAMP,
                offdays: values.offdays,
                deactivatedProjects: deactivatedIds,
              },
              (error) => {
                if (error) {
                  console.log(error);
                }
              }
            )
            .then((snap) => {
              /** Use new users' id and show the invite link */
              let key = snap.key;
              let fullUrl = getInviteLink(key, values.email, companyId);
              closeAddForm();
              showInviteLink(values.email, fullUrl);
            });
        }
      });
    });
  };

  /**
   * Formatting the user role to Bee related roles.
   * @param role
   * @returns {string}
   */
  const formatRole = (role) => {
    switch (role) {
      case "admin":
        return "Queen Bee";
      case "user":
        return "Worker Bee";
      default:
        return "Bee";
    }
  };

  /**
   * Toggle for showing a notification if the 'to delete' user is the current user, otherwise it will open the delete modal.
   * @param member The user to delete
   */
  const onPressDelete = (member) => {
    if (member[1].id === profile.id)
      notify("warning", t("meldingen.jezelf_verwijderen"), notifyTeamRef);
    else {
      setMemberToDelete(member);
      setDeleteModal(true);
    }
  };

  /**
   * Toggle for showing a notification if the 'to edit' user is the current user, otherwise it will open the edit modal.
   * @param member
   */
  const onPressEdit = (member) => {
    if (member[1].id === profile.id)
      notify("warning", t("meldingen.jezelf_bewerken"), notifyTeamRef);
    else {
      setMemberToEdit(member);
      let userId = member[1].id;
      // Retrieve entry from deactivatedProjectsForUser to set the Select form item
      firebase
        .database()
        .ref(FIREBASE_INACTIVE_USER_PROJECTS_TABLE + "/" + userId)
        .on("value", (snapshot) => {
          let data = snapshot.val();
          let objectArray = [];
          if (!!data) {
            _.forEach(activeProjects, (project) => {
              if (!data.projects.includes(project.key)) {
                objectArray.push({
                  value: project.key,
                  label: project.value.name,
                  key: project.key,
                });
              }
            });
            editForm.setFieldsValue({
              accessProjects: objectArray,
            });
          } else setActiveProjectsInSelect();
          if (member[1].role === ADMIN_ROLE) setProjectSelectDisabled(true);
          else setProjectSelectDisabled(false);
          editForm.setFieldsValue({
            role: member[1].role,
            offdays: !!member[1].offdays ? member[1].offdays : offDaysTeam,
          });
          setEditModal(true);
        });
    }
  };

  /**
   * Editing the user. It will edit the entry in the 'users' Firebase Object.
   */
  const onEditMember = () => {
    let userObjectId = memberToEdit[0];

    editForm.validateFields().then(() => {
      const values = editForm.getFieldsValue();
      // If not all projects are selected, add entry to deactivatedProjectsForUser table
      if (
        !!values.accessProjects &&
        values.accessProjects.length < activeProjects.length &&
        values.role === USER_ROLE
      ) {
        let deactivatedIds = [];
        let deactivated = _.differenceWith(
          projectsForSelect,
          values.accessProjects,
          _.isEqual
        );
        _.forEach(deactivated, (item) => {
          deactivatedIds.push(item.value);
        });
        firebase
          .database()
          .ref(FIREBASE_INACTIVE_USER_PROJECTS_TABLE + "/" + userObjectId)
          .set({ projects: deactivatedIds, companyId: companyId });
      }
      // If all projects are selected, remove entry from deactivatedProjectsForUser
      else
        firebase
          .database()
          .ref(FIREBASE_INACTIVE_USER_PROJECTS_TABLE + "/" + userObjectId)
          .remove();
      // If entered offdays is the same as team offdays, remove the offdays property from user
      if (values.offdays === offDaysTeam || values.offdays === "") {
        setUserSettings(userObjectId, { role: values.role, offdays: null });
        onUserEdited();
      } else if (
        values.role !== memberToEdit[1].role ||
        values.offdays !== memberToEdit[1].offdays
      ) {
        setUserSettings(userObjectId, {
          role: values.role,
          offdays: values.offdays,
        });
        onUserEdited();
      } else {
        onUserEdited();
      }
    });
  };

  /**
   * When user is succesfully edited, show notification and revert to initial state
   */
  const onUserEdited = () => {
    notify("success", t("meldingen.gebruiker_aangepast"), notifyTeamRef);
    setMemberToEdit(null);
    setEditModal(false);
    editForm.resetFields();
  };

  /**
   * Deleting the user. If user is invited it will remove the user entry in the 'invitedUsers' Firebase Object,
   * otherwise it will remove the entry in the 'users' Firebase Object.
   */
  const onDeleteMember = () => {
    let userObjectId = memberToDelete[0];
    if (memberToDelete[1].status === "invited") {
      firebase
        .database()
        .ref("invitedUsers/" + userObjectId)
        .remove((error) => {
          if (error) {
            console.error(error);
          } else {
            notify(
              "success",
              t("meldingen.gebruiker_verwijderd"),
              notifyTeamRef
            );
            setMemberToDelete(null);
            setDeleteModal(false);
          }
        });
    } else {
      firebase
        .database()
        .ref("users/" + userObjectId)
        .update({ active: false }, (error) => {
          if (error) {
            console.error(error);
          } else {
            notify(
              "success",
              t("meldingen.gebruiker_verwijderd"),
              notifyTeamRef
            );
            setMemberToDelete(null);
            setDeleteModal(false);
          }
        });
    }
  };

  /**
   * Update the initial off days in the Firebase 'company' object.
   */
  const onSaveTeamOffDays = () => {
    let company = firebase.database().ref("companies/" + companyId);
    const formOffDays = offDaysForm.getFieldValue("offdays");
    company.update({ offDaysTeam: formOffDays }).then((data) => {
      notify("success", t("meldingen.verlofdagen_aangepast"), notifyTeamRef);
      setOffDaysModal(false);
      setSumOffDaysTeams(getSumOffDays(formOffDays, members));
      getOffDays(formOffDays, members);
    });
  };

  /**
   * Toggle for opening the off day modal, the toggle is passed to the child component TeamHeader, where it is triggered.
   */
  const toggleOffDaysModal = () => {
    setOffDaysModal(!offDaysModal);
    offDaysForm.setFieldsValue({ offdays: offDaysTeam });
  };

  /**
   * Notification showing the confirmation of sending the invite link.
   */
  const showInviteLink = (email, inviteUrl) => {
    sendInvitationMail(
      email,
      profile.name,
      inviteUrl,
      !!company && company.name
    )
      .then(() => {
        notify(
          "success",
          email + " " + t("dashboard.team.uitnodiging_verstuurd"),
          notifyTeamRef
        );
      })
      .catch(() => {
        notify("warning", t("meldingen.foutmelding"), notifyTeamRef);
      });
  };

  /**
   * Create the invite link for the user by encoding it.
   * @param id Id of the user.
   * @param email
   * @param companyId
   * @returns {string} The encoded invite link.
   */
  const getInviteLink = (id, email, companyId) => {
    let uri = id + "/" + email + "/" + companyId;
    let encodedUrl = encode.encode(uri, "base64");
    return urls.base + urls.acceptInvite + encodedUrl;
  };

  const onChangeRole = (value) => {
    if (value === ADMIN_ROLE) setProjectSelectDisabled(true);
    else setProjectSelectDisabled(false);
  };

  const closeAddForm = () => {
    setFormModal(false);
    form.resetFields();
    form.setFieldsValue({ accessProjects: projectsForSelect });
  };

  return (
    <>
      <Tour
        steps={steps}
        continuous={true}
        run={startTour}
        onFinished={() => setStartTour(false)}
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notifyTeamRef} />
      </div>
      <CardsHeader
        name={t("dashboard.team.team")}
        parentName={!!company && company.name}
        onClick={toggleOffDaysModal}
        isAdminPage={true}
        startTour={() => setStartTour(true)}
        data={[
          {
            name: t("dashboard.team.team_dagen"),
            value: offDaysTeam,
            editable: true,
            tooltip: {
              id: "teamOffdays",
              text: t("dashboard.team.team_dagen_uitleg"),
            },
          },
          {
            name: t("dashboard.team.team_saldo"),
            value: isNaN(sumOffDaysTeams - usedOffDays)
              ? 0
              : sumOffDaysTeams - usedOffDays,
            editable: false,
            tooltip: {
              id: "teamBalance",
              text: t("dashboard.team.team_saldo_uitleg"),
            },
          },
          {
            name: t("dashboard.team.team_gebruikt"),
            value: usedOffDays,
            editable: false,
            tooltip: {
              id: "teamOffdaysUsed",
              text: t("dashboard.team.team_gebruikt_uitleg"),
            },
          },
        ]}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="4">
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModal}
              toggle={() => closeAddForm()}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary border-0 mb-0">
                  <CardHeader className="bg-transparent pb-3">
                    <div className="text-muted text-center mt-2 mb-3">
                      <span className="card-header-title">
                        {t("dashboard.team.nieuw")}
                      </span>
                      <span className="card-header-subtitle">
                        {t("dashboard.team.nieuw_info")}
                      </span>
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-5 py-lg-5">
                    <Form
                      form={form}
                      layout="vertical"
                      initialValues={{
                        role: "user",
                        offdays: offDaysTeam,
                      }}
                    >
                      <Form.Item
                        name="email"
                        label={t("dashboard.team.email.label")}
                        rules={[
                          {
                            required: true,
                            type: "email",
                            message: t("form.enter_email"),
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder={t("dashboard.team.email.label")}
                        />
                      </Form.Item>
                      <Form.Item
                        name="role"
                        label={t("dashboard.team.rol.label")}
                        rules={[
                          { required: true, message: t("form.enter_role") },
                        ]}
                      >
                        <Select size="large" onChange={onChangeRole}>
                          {!!roles &&
                            roles.map((role) => (
                              <Option value={role.value}>{role.text}</Option>
                            ))}
                        </Select>
                      </Form.Item>
                      {!projectSelectDisabled && (
                        <Form.Item
                          name="accessProjects"
                          label={t("dashboard.team.project_toegang.label")}
                          rules={[
                            {
                              required: true,
                              message: t("form.enter_project_access"),
                            },
                          ]}
                        >
                          <Select labelInValue mode="multiple" size="large">
                            {!!projectsForSelect &&
                              projectsForSelect.map(
                                (project) =>
                                  !!project && (
                                    <Option value={project.value}>
                                      {project.label}
                                    </Option>
                                  )
                              )}
                          </Select>
                        </Form.Item>
                      )}
                      <Form.Item
                        name="offdays"
                        label={t("dashboard.team.verlof_dagen.label")}
                      >
                        <InputNumber size="large" />
                      </Form.Item>
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={() => closeAddForm()}
                        >
                          {t("dashboard.sluiten")}
                        </Button>
                        <Button
                          className="my-4"
                          color="success"
                          type="button"
                          onClick={() => onSaveMember()}
                        >
                          {t("dashboard.opslaan")}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>
          </Col>
        </Row>
        <Row>
          <div className="col">
            <Card id="team-body">
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">{t("dashboard.team.gebruikers")}</h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Button
                      className="btn-round btn-icon"
                      color="info"
                      id="new-team-btn"
                      onClick={() => setFormModal(true)}
                      size="sm"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-user-edit" />
                      </span>
                      <span className="btn-inner--text">
                        {t("dashboard.team.nieuw")}
                      </span>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <Table
                id="team-tabel"
                className="align-items-center table-flush"
                responsive
              >
                <thead className="thead-light">
                  <tr>
                    <th className="sort" data-sort="name" scope="col">
                      {t("dashboard.team.tabel.gebruiker")}
                    </th>
                    <th scope="col">{t("dashboard.team.tabel.email")}</th>
                    <th className="sort" data-sort="role" scope="col">
                      {t("dashboard.team.tabel.rol")}
                    </th>
                    <th scope="col">{t("dashboard.team.tabel.functie")}</th>
                    <th scope="col">{t("dashboard.team.tabel.status")}</th>
                    <th scope="col">{t("dashboard.team.tabel.verlofdagen")}</th>
                    <th scope="col">{t("dashboard.team.tabel.verlofsaldo")}</th>
                    <th scope="col">
                      {t("dashboard.team.tabel.verlof_gebruikt")}
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody className="list">
                  {isLoading ? (
                    <tr>
                      <td>
                        <Skeleton.Button
                          active={true}
                          size="large"
                          shape="round"
                        />
                      </td>
                      <td>
                        <Skeleton.Button
                          active={true}
                          size="large"
                          shape="round"
                        />
                      </td>
                      <td>
                        <Skeleton.Button
                          active={true}
                          size="large"
                          shape="round"
                        />
                      </td>
                      <td>
                        <Skeleton.Button
                          active={true}
                          size="large"
                          shape="round"
                        />
                      </td>
                      <td>
                        <Skeleton.Button
                          active={true}
                          size="large"
                          shape="round"
                        />
                      </td>
                      <td>
                        <Skeleton.Button
                          active={true}
                          size="large"
                          shape="round"
                        />
                      </td>
                      <td>
                        <Skeleton.Button
                          active={true}
                          size="large"
                          shape="round"
                        />
                      </td>
                      <td>
                        <Skeleton.Button
                          active={true}
                          size="large"
                          shape="round"
                        />
                      </td>
                    </tr>
                  ) : (
                    !!members &&
                    Object.entries(members).map((member, i) => {
                      if (member[1].active !== false)
                        return (
                          <tr key={i}>
                            <th scope="row">
                              <Media className="align-items-center">
                                <img
                                  src={
                                    !!member[1].picture
                                      ? member[1].picture
                                      : beeImage
                                  }
                                  className="avatar mr-3 bg-white rounded-circle"
                                  alt="profielfoto"
                                />
                                <Media>
                                  {/*<Link to={urls.overzichtPerUser + member[0]}>*/}
                                  <span className="name mb-0 text-sm">
                                    {member[1].name}
                                  </span>
                                  {/*</Link>*/}
                                </Media>
                              </Media>
                            </th>
                            <td className="budget">{member[1].email}</td>
                            <td className="budget">
                              {formatRole(member[1].role)}
                            </td>
                            <td className="budget">
                              {!!member[1].function ? member[1].function : ""}
                            </td>
                            <td>
                              <Badge color="" className="badge-dot mr-4">
                                <i
                                  className={
                                    member[1].status === "invited"
                                      ? "bg-teal"
                                      : "bg-success"
                                  }
                                />
                                <span className="status">
                                  {member[1].status === "invited"
                                    ? t("dashboard.team.uitgenodigd")
                                    : t("dashboard.team.actief")}
                                </span>
                              </Badge>
                            </td>
                            <td className="text-align-center">
                              {member[1].initialDays}
                            </td>
                            <td className="text-align-center">
                              {member[1].remainingDays}
                            </td>
                            <td className="text-align-center">
                              {member[1].usedDays}
                            </td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  id="team-edit"
                                  className="btn-icon-only text-info"
                                  color=""
                                  role="button"
                                  size="sm"
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  {member[1].status === "invited" ? (
                                    <CopyToClipboard
                                      text={getInviteLink(
                                        member[0],
                                        member[1].email,
                                        member[1].companyId
                                      )}
                                    >
                                      <DropdownItem
                                        onClick={() =>
                                          notify(
                                            "success",
                                            t("dashboard.team.link_copied1") +
                                              member[1].email +
                                              t("dashboard.team.link_copied2"),
                                            notifyTeamRef
                                          )
                                        }
                                      >
                                        {t("dashboard.team.link_kopieren")}
                                      </DropdownItem>
                                    </CopyToClipboard>
                                  ) : (
                                    <DropdownItem
                                      onClick={() => onPressEdit(member)}
                                    >
                                      {t("dashboard.bewerken")}
                                    </DropdownItem>
                                  )}

                                  <DropdownItem
                                    onClick={() => onPressDelete(member)}
                                  >
                                    {t("dashboard.verwijderen")}
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      else return null;
                    })
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        {/* Edit Modal */}
        <Row>
          <Col md="4">
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={editModal}
              toggle={() => {
                setEditModal(!editModal);
                setMemberToEdit(null);
              }}
            >
              {!!memberToEdit && (
                <div className="modal-body p-0">
                  <Card className="bg-secondary border-0 mb-0">
                    <CardHeader className="bg-transparent pb-3">
                      <div className="text-muted text-center mt-2 mb-3">
                        <span className="card-header-title">
                          {t("dashboard.team.rol_aanpassen")}
                        </span>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                          src={
                            !!memberToEdit[1].picture
                              ? memberToEdit[1].picture
                              : beeImage
                          }
                          style={{ width: "140px" }}
                        />
                      </a>
                      <Form
                        layout="vertical"
                        form={editForm}
                        initialValues={{ role: memberToEdit[1].role }}
                      >
                        <Form.Item
                          name="role"
                          label={t("dashboard.team.rol.label")}
                          rules={[
                            { required: true, message: t("form.enter_role") },
                          ]}
                        >
                          <Select size="large" onChange={onChangeRole}>
                            {!!roles &&
                              roles.map((role) => (
                                <Option value={role.value}>{role.text}</Option>
                              ))}
                          </Select>
                        </Form.Item>
                        {!projectSelectDisabled && (
                          <Form.Item
                            name="accessProjects"
                            label={t("dashboard.team.project_toegang.label")}
                            rules={[
                              {
                                required: true,
                                message: t("form.enter_project_access"),
                              },
                            ]}
                          >
                            <Select labelInValue mode="multiple" size="large">
                              {!!projectsForSelect &&
                                projectsForSelect.map(
                                  (project) =>
                                    !!project && (
                                      <Option value={project.value}>
                                        {project.label}
                                      </Option>
                                    )
                                )}
                            </Select>
                          </Form.Item>
                        )}
                        <Form.Item
                          name="offdays"
                          label={t("dashboard.team.verlof_dagen.label")}
                        >
                          <InputNumber size="large" />
                        </Form.Item>
                        <div className="text-center">
                          <Button
                            className="my-4"
                            color="primary"
                            type="button"
                            onClick={() => {
                              setEditModal(false);
                              setMemberToEdit(null);
                            }}
                          >
                            {t("dashboard.sluiten")}
                          </Button>
                          <Button
                            className="my-4"
                            color="success"
                            type="button"
                            onClick={() => onEditMember()}
                          >
                            {t("dashboard.opslaan")}
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              )}
            </Modal>
          </Col>
        </Row>
        {/* Delete Modal */}
        <Row>
          <Col md="4">
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={deleteModal}
              toggle={() => {
                setDeleteModal(!deleteModal);
                setMemberToDelete(null);
              }}
            >
              {!!memberToDelete && (
                <div className="modal-body p-0">
                  <Card className="bg-secondary border-0 mb-0">
                    <CardHeader className="bg-transparent pb-3">
                      <div className="text-muted text-center mt-2 mb-3">
                        <span className="card-header-title">
                          {t("dashboard.team.verwijderen")}
                        </span>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                          src={
                            memberToDelete[1].picture
                              ? memberToDelete[1].picture
                              : beeImage
                          }
                          style={{ width: "140px" }}
                        />
                      </a>
                      <div className="pt-4 text-center">
                        <h5 className="h3 title">
                          <span className="d-block mb-1">
                            {memberToDelete[1].name}
                          </span>
                          <span className="d-block mb-1 font-weight-light">
                            {memberToDelete[1].email}
                          </span>
                          <small className="h4 font-weight-light text-muted">
                            {memberToDelete[1].function}
                          </small>
                        </h5>
                      </div>
                      <Form role="form">
                        <div className="text-center">
                          <Button
                            className="my-4"
                            color="primary"
                            type="button"
                            onClick={() => {
                              setDeleteModal(false);
                              setMemberToDelete(null);
                            }}
                          >
                            {t("dashboard.sluiten")}
                          </Button>
                          <Button
                            className="my-4"
                            color="success"
                            type="button"
                            onClick={() => onDeleteMember()}
                          >
                            {t("dashboard.verwijderen")}
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              )}
            </Modal>
          </Col>
        </Row>
        {/* Verlofdagen instellen Modal */}
        <Row>
          <Col md="4">
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={offDaysModal}
              toggle={() => setOffDaysModal(!offDaysModal)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary border-0 mb-0">
                  <CardHeader className="bg-transparent pb-3">
                    <div className="text-muted text-center mt-2 mb-3">
                      <span className="card-header-title">
                        {t("dashboard.team.verlof_aanpassen")}
                      </span>
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-5 py-lg-5">
                    <Form layout="vertical" form={offDaysForm}>
                      <Form.Item
                        name="offdays"
                        label={t("dashboard.team.verlof_dagen.label")}
                      >
                        <InputNumber size="large" />
                      </Form.Item>
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={() => setOffDaysModal(false)}
                        >
                          {t("dashboard.sluiten")}
                        </Button>
                        <Button
                          className="my-4"
                          color="success"
                          type="button"
                          onClick={() => onSaveTeamOffDays()}
                        >
                          {t("dashboard.opslaan")}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Team;
