import firebase from "firebase";
import moment from "moment";
import urls from "../../../urls";
import i18n from "../../../variables/translations/i18n";

/**
 * Fetch subscription from Firebase
 * @param companyId
 * @returns {Promise<unknown>}
 */
function fetchSubscription(companyId) {
  return new Promise(function (resolve, reject) {
    let subscriptionsRef = firebase
      .database()
      .ref("subscriptions/" + companyId);
    subscriptionsRef.on("value", (snapshot) => {
      let subscription = snapshot.val();
      if (subscription) {
        resolve(subscription);
      } else {
        reject("Subscription not found");
      }
    });
  });
}

/**
 * Update subscription Object in Firebase
 * @param companyId
 * @param object
 */
function updateSubscription(companyId, object) {
  firebase
    .database()
    .ref("subscriptions/" + companyId)
    .update(object);
}

/**
 * Cancel subscription in Stripe
 * @param subscriptionId
 * @returns {Promise<unknown>}
 */
function cancelSubscription(subscriptionId) {
  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      process.env.REACT_APP_STRIPE_PAYMENT_API +
        urls.stripeSubscriptionCancel +
        subscriptionId,
      requestOptions
    )
      .then(() => resolve("Subscription successfully cancelled"))
      .catch(() => reject("Subscription failed to cancel"));
  });
}

/**
 * Renew subscription in Stripe
 * @param subscriptionId
 * @returns {Promise<unknown>}
 */
function renewSubscription(subscriptionId) {
  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      process.env.REACT_APP_STRIPE_PAYMENT_API +
        urls.stripeSubscriptionRenew +
        subscriptionId,
      requestOptions
    )
      .then(() => resolve("Subscription successfully renewd"))
      .catch(() => reject("Subscription failed to renew"));
  });
}

/**
 * Upgrade subscription in Stripe
 * @param subscriptionId
 * @param priceId
 * @returns {Promise<unknown>}
 */
function upgradeSubscription(subscriptionId, priceId) {
  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ priceId: priceId }),
    };
    fetch(
      process.env.REACT_APP_STRIPE_PAYMENT_API +
        urls.stripeSubscriptionUpgrade +
        subscriptionId,
      requestOptions
    )
      .then(() => resolve("Subscription successfully upgraded"))
      .catch(() => reject("Subscription failed to upgraded"));
  });
}

/**
 * Fetch subscription information from Stripe
 * @param subscriptionId
 * @returns {Promise<unknown>}
 */
function getSubscriptionInfo(subscriptionId) {
  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      process.env.REACT_APP_STRIPE_PAYMENT_API +
        urls.stripeSubscriptionInfo +
        subscriptionId,
      requestOptions
    )
      .then((response) => resolve(response.json()))
      .catch((err) => {
        if (err.message === "Failed to fetch") {
          resolve({ status: "active" });
        } else reject("Subscription not found");
      });
  });
}

/**
 * Check validity promotion code from Stripe
 * @param promotionCode
 * @returns {Promise<unknown>}
 */
function promotionCodeChecker(promotionCode) {
  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      process.env.REACT_APP_STRIPE_PAYMENT_API +
        urls.stripePromotionCodeChecker +
        promotionCode,
      requestOptions
    )
      .then((response) => resolve(response.json()))
      .catch(() => reject("Promotion code not found"));
  });
}

/**
 * calculate amount of days left of subscription/coupon/free trial period
 * @param hasSubscription
 * @param hasCouponCode
 * @param subscriptionRenewalDate
 * @param couponCreatedAt
 * @param couponDuration
 * @param companyCreatedAt
 * @param subscriptionCreatedAt
 * @returns {{restDays: number, totalDays: number, usedDays: number|{restDays: string, totalDays: number, usedDays: number}|{restDays: (number|number), totalDays: number, usedDays: number}}
 */
function calculateSubscriptionDays(
  hasSubscription,
  hasCouponCode,
  subscriptionRenewalDate,
  couponCreatedAt,
  couponDuration,
  companyCreatedAt,
  subscriptionCreatedAt
) {
  if (hasSubscription && !hasCouponCode) {
    let today = moment(new Date()).format("MM-DD-YYYY");
    let renewalDate = moment.unix(subscriptionRenewalDate).format("MM-DD-YYYY");
    let creationDate = moment.unix(subscriptionCreatedAt).format("MM-DD-YYYY");
    let momentToday = moment(today);
    let momentRenewalDate = moment(renewalDate);
    let momentCreationDate = moment(creationDate);
    let differenceInDaysTotal = momentRenewalDate.diff(
      momentCreationDate,
      "days"
    );
    let differenceInDaysUsed = momentToday.diff(momentCreationDate, "days");
    let differenceInDaysRest = differenceInDaysTotal - differenceInDaysUsed;
    return {
      restDays: differenceInDaysRest < 0 ? 0 : differenceInDaysRest,
      totalDays: differenceInDaysTotal,
      usedDays: differenceInDaysUsed,
    };
  } else if (hasCouponCode && !hasSubscription) {
    if (couponDuration === "unlimited") {
      return {
        restDays: "∞",
        totalDays: 100,
        usedDays: 100,
      };
    } else {
      let couponDays = couponDuration * 30;
      let couponRegisterDate = moment.unix(couponCreatedAt);
      let momentCouponRegisterDate = moment(couponRegisterDate);
      let today = moment(new Date());
      let differenceInDays = today.diff(momentCouponRegisterDate, "days");
      let restDays = couponDays - differenceInDays;
      return {
        restDays: restDays < 0 ? 0 : restDays,
        totalDays: couponDays,
        usedDays: differenceInDays,
      };
    }
  } else if (hasSubscription && hasCouponCode) {
    if (couponDuration === "unlimited") {
      return {
        restDays: "∞",
        totalDays: 100,
        usedDays: 100,
      };
    } else {
      let today = moment(new Date()).format("MM-DD-YYYY");
      let renewalDate = moment
        .unix(subscriptionRenewalDate)
        .format("MM-DD-YYYY");
      let momentToday = moment(today);
      let momentRenewalDate = moment(renewalDate);
      let freeBeeStartDate = moment.unix(couponCreatedAt).format("MM-DD-YYYY");
      let momentFreeBeeStartDate = moment(freeBeeStartDate);
      let differenceInDays = momentRenewalDate.diff(momentToday, "days");
      let differenceInDaysFromBeginning = momentToday.diff(
        momentFreeBeeStartDate,
        "days"
      );
      let couponDays = couponDuration * 30;
      let differenceInDaysTotal =
        momentRenewalDate.diff(momentFreeBeeStartDate, "days") + couponDays;
      let freeBeeDays = differenceInDays + couponDays;
      return {
        restDays: freeBeeDays < 0 ? 0 : freeBeeDays,
        totalDays: differenceInDaysTotal,
        usedDays: differenceInDaysFromBeginning,
      };
    }
  } else if (!hasSubscription && !hasCouponCode) {
    let restDays = calculateRestDaysForModal(companyCreatedAt);
    return {
      restDays: restDays < 0 ? 0 : restDays,
      totalDays: 30,
      usedDays: 30 - restDays,
    };
  }
}

/**
 * Calculate days of FreeBee for the Locked Modal
 * @param companyCreatedAt in Unix
 */
function calculateRestDaysForModal(companyCreatedAt) {
  let initialToday = moment().format("MM-DD-YYYY");
  let initialCompanyCreatedAt = moment(companyCreatedAt).format("MM-DD-YYYY");
  let initializedToday = moment(initialToday);
  let initializedCompanyCreatedAt = moment(initialCompanyCreatedAt);
  let differenceInDays = initializedToday.diff(
    initializedCompanyCreatedAt,
    "days"
  );
  return 30 - differenceInDays;
}

export {
  fetchSubscription,
  updateSubscription,
  getSubscriptionInfo,
  cancelSubscription,
  renewSubscription,
  upgradeSubscription,
  promotionCodeChecker,
  calculateSubscriptionDays,
  calculateRestDaysForModal,
};
