import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {Row} from "reactstrap";
import Locked from "assets/img/theme/lock.jpg";

const LockedAlertUser = (props) => {
  return (
    <ReactBSAlert
      custom
      showConfirm={false}
      title={props.title}
      customIcon={Locked} 
      onConfirm={() => {}}>
      <Row className="justify-content-center mb-2 ml-1 mr-1">
        {props.description}
      </Row>
    </ReactBSAlert>
  );
};

export default LockedAlertUser;
