import React from "react";
import { Link } from "react-router-dom";
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import navbarLogo from "assets/img/brand/logo-white.png";
import navbarLogoPurple from "assets/img/brand/logo-purple.png";
import urls from "../../urls";
import i18n from "../../variables/translations/i18n";
import nlFlag from "assets/img/icons/common/nl.png";
import enFlag from "assets/img/icons/common/en.png";
import i18next from "i18next";

class HomepageNavbar extends React.Component {
  changeLanguage = (lang) => {
    i18next.changeLanguage(lang);
  };
  getActiveFlag() {
    if (i18next.language === "nl") return nlFlag;
    else return enFlag;
  }
  render() {
    const langs = [
      { label: "English", value: "en", icon: enFlag },
      { label: "Nederlands", value: "nl", icon: nlFlag },
    ];
    return (
      <>
        <Navbar
          className="navbar-horizontal navbar-main navbar-dark bg-gradient-info"
          expand="lg"
          id="navbar-main"
        >
          <Container>
            <NavbarBrand to="/" tag={Link}>
              <img alt="logo" src={navbarLogo} />
            </NavbarBrand>
            <button
              aria-controls="navbar-collapse"
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#navbar-collapse"
              data-toggle="collapse"
              id="navbar-collapse"
              type="button"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse
              className="navbar-custom-collapse"
              navbar
              toggler="#navbar-collapse"
            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/">
                      <img alt="logo" src={navbarLogoPurple} />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button
                      aria-controls="navbar-collapse"
                      aria-expanded={false}
                      aria-label="Toggle navigation"
                      className="navbar-toggler"
                      data-target="#navbar-collapse"
                      data-toggle="collapse"
                      id="navbar-collapse"
                      type="button"
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="mr-auto" navbar>
                <NavItem>
                  <NavLink to={urls.dashboard} tag={Link}>
                    <span className="nav-link-inner--text">
                      {i18n.t("nav_dashboard")}
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={urls.pricing} tag={Link}>
                    <span className="nav-link-inner--text">
                      {i18n.t("nav_prijzen")}
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={urls.contact} tag={Link}>
                    <span className="nav-link-inner--text">
                      {i18n.t("nav_contact")}
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
              <hr className="d-lg-none" />
              <Nav className="align-items-lg-center ml-lg-auto" navbar>
                {langs.map((item, i) => {
                  return (
                    <a
                      onClick={() => this.changeLanguage(item.value)}
                      href="#"
                      key={i}
                    >
                      <img
                        src={item.icon}
                        style={{ width: "22px", marginRight: "1rem" }}
                      />
                    </a>
                  );
                })}
                <NavItem className="d-none d-lg-block ml-lg-4">
                  <Link to={urls.login}>
                    <Button outline>
                      <span className="btn-inner--icon">
                        <i className="ni ni-satisfied mr-2" />
                      </span>
                      <span className="nav-link-inner--text">
                        {i18n.t("nav_login")}
                      </span>
                    </Button>
                  </Link>
                </NavItem>
                <NavItem className="d-none d-lg-block ml-lg-4">
                  <Link to={urls.registerCompany}>
                    <Button className="btn-cta btn-icon" color="default">
                      <span className="nav-link-inner--text">
                        {i18n.t("bedrijf_aanmelden_btn")}
                      </span>
                    </Button>
                  </Link>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default HomepageNavbar;
