import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import * as loading from "../../assets/img/theme/love.json";

const Love = (props) => {
  const [isVisible, setIsVisible] = useState(true);

  const eventListener = [
    {
      eventName: "complete",
      callback: () => {
        setIsVisible(false);
        props.stopAnimation();
      },
    },
  ];

  return (
    <>
      {isVisible && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-62%, -50%)",
          }}
        >
          <Lottie
            options={loadingOptions}
            width={500}
            isClickToPauseDisabled={true}
            eventListeners={eventListener}
          />
        </div>
      )}
    </>
  );
};

const loadingOptions = {
  loop: false,
  autoplay: false,
  animationData: loading.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default Love;
