import firebase from "firebase";
import _ from "lodash";
import checkAuth from "./checkAuth";

export function isPlural(input) {
  return input === 0 || input > 1 || input < -1;
}

/**
 * Sort dates descending
 * @param data
 * @returns sorted data
 */
export function sortDatesDescending(data) {
  return data.sort((a, b) => b.date - a.date);
}

/**
 * Sort dates ascending
 * @param data
 * @returns sorted data
 */
export function sortDatesAscending(data) {
  return data.sort((a, b) => a.date - b.date);
}

/**
 * Get all admin users from company.
 */
export function getAllAdminUsersFromCompany() {
  return new Promise((resolve, reject) => {
    let users = firebase
      .database()
      .ref("users")
      .orderByChild("companyId")
      .equalTo(localStorage.getItem("compid"));
    users.on("value", (snapshot) => {
      let data = snapshot.val();
      if (data !== null) {
        let adminUsers = filterObjectKeepValue(data, "role", "admin");
        resolve(adminUsers);
      } else {
        reject("No team members");
      }
    });
  });
}

export function isAuthenticatedUserAdmin() {
  return new Promise(function (resolve) {
    checkAuth().then((user) => {
      let uid = user.id;
      firebase
        .database()
        .ref("users/" + uid)
        .on("value", (snapshot) => {
          let data = snapshot.val();
          if (!!data) {
            let isAdmin = data.role === "admin";
            resolve(isAdmin);
          } else resolve(false);
        });
    });
  });
}

export function isAdminPage(url) {
  let urlParts = url.split("/");
  let firstPartOfString = "/" + urlParts[1] + "/" + urlParts[2];
  let adminUrls = [
    "/dashboard/team",
    "/dashboard/project",
    "/dashboard/aanvragen",
    "/dashboard/urenoverzicht",
    "/dashboard/projecten",
    "/dashboard/kosten",
    "/dashboard/facturen",
    "/dashboard/klanten",
    "/dashboard/abonnement",
    "/dashboard/gebruiker",
    "/dashboard/betaling",
    "/dashboard/opzeggen",
    "/dashboard/hernieuwen",
    "/dashboard/upgraden",
    "/dashboard/downgraden",
    "/dashboard/uren",
  ];
  let filter = !!firstPartOfString ? firstPartOfString : url;
  return _.includes(adminUrls, filter);
}

/**
 * Filter object of objects based on the value you want to REMOVE
 * @param {*} obj The object of objects that has to be filtered
 * @param {*} filter The attribute that has to be filtered in
 * @param {*} filterValue The value that has to match the filter
 * @returns Object of objects, without the items based on the filter
 */
export const filterObjectRemoveValue = (obj, filter, filterValue) =>
  Object.keys(obj).reduce(
    (acc, val) =>
      obj[val][filter] === filterValue
        ? acc
        : {
            ...acc,
            [val]: obj[val],
          },
    {}
  );

/**
 * Filter object of objects based on the value you want to KEEP
 * @param {*} obj The object of objects that has to be filtered
 * @param {*} filter The attribute that has to be filtered in
 * @param {*} filterValue The value that has to match the filter
 * @returns Object of objects, with only the items that match the filter
 */
export const filterObjectKeepValue = (obj, filter, filterValue) =>
  Object.keys(obj).reduce(
    (acc, val) =>
      obj[val][filter] !== filterValue
        ? acc
        : {
            ...acc,
            [val]: obj[val],
          },
    {}
  );

/**
 * Add username to data
 * @param hours
 * @returns modified data
 */
export function addNameToHours(hours, users) {
  return new Promise(function (resolve) {
    // Get the name of the user from each hour entry for in the table
    _.forEach(hours, (item) => {
      if (!!users[item.userId] && users[item.userId].name)
        _.set(item, "name", users[item.userId].name);
    });
    resolve(hours);
  });
}

/**
 * Add project name and hourly rate to data
 * @param hours
 * @param project
 * @returns modified data
 */
export function addProjectNameToHours(hours, project) {
  return new Promise(function (resolve) {
    Array.from(hours).forEach((entry, index) => {
      _.set(hours, index + ".projectName", project.name);
      _.set(hours, index + ".projectHourlyRate", project.hourlyRate);
    });
    resolve(hours);
  });
}
