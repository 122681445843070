import React from "react";
import { Badge, Col, Container, Row } from "reactstrap";
import HomepageNavbar from "components/Navbars/HomepageNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import i18n from "variables/translations/i18n";
import Fade from "react-reveal/Fade";
import {
  META_PRIVACY_DESCRIPTION,
  META_PRIVACY_TITLE,
} from "constants/MetaTagsConstants";
import { WEBSITE_NAME } from "constants/CompanyConstants";
import { BreadcrumbStructuredData } from "../../../components/StructuredData/BreadcrumbStructuredData";
import {
  BREADCRUMB_HOME,
  BREADCRUMB_PRIVACY,
} from "../../../constants/BreadcrumbConstants";
import { Helmet } from "react-helmet";

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            {META_PRIVACY_TITLE} · {WEBSITE_NAME}
          </title>
          <meta name="description" content={META_PRIVACY_DESCRIPTION} />
          {BreadcrumbStructuredData([BREADCRUMB_HOME, BREADCRUMB_PRIVACY])}
        </Helmet>
        <HomepageNavbar />
        <div className="main-content">
          <section className="py-5">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <Fade delay={100}>
                      <h2>{i18n.t("privacy_beleid.tldr.titel")}</h2>
                    </Fade>
                    <Fade delay={200}>
                      <p>{i18n.t("privacy_beleid.tldr.text")}</p>
                    </Fade>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="py-3">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="12">
                  <div className="pr-md-5">
                    <Fade delay={100}>
                      <h1>
                        {i18n.t("privacy_beleid.privacy_verklaring.titel")}
                      </h1>
                    </Fade>
                    <Fade delay={200}>
                      <p>{i18n.t("privacy_beleid.privacy_verklaring.text")}</p>
                      <ul className="list-unstyled">
                        {i18n
                          .t("privacy_beleid.privacy_verklaring.bullets", {
                            returnObjects: true,
                          })
                          .map((bullet, i) => (
                            <li className="py-2" key={i}>
                              <div className="d-flex align-items-end">
                                <div>
                                  <Badge
                                    className="badge-circle mr-3"
                                    color="success"
                                  >
                                    <i />
                                  </Badge>
                                </div>
                                <div>
                                  <h4 className="mb-0">{bullet}</h4>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </Fade>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="py-3">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="12">
                  <div className="pr-md-5">
                    <Fade delay={100}>
                      <h2>
                        {i18n.t("privacy_beleid.verzamelde_gegevens.titel")}
                      </h2>
                    </Fade>
                    <Fade delay={200}>
                      <p>{i18n.t("privacy_beleid.verzamelde_gegevens.text")}</p>
                    </Fade>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="py-3">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="12">
                  <div className="pr-md-5">
                    <Fade delay={100}>
                      <h2>{i18n.t("privacy_beleid.doel.titel")}</h2>
                    </Fade>
                    <Fade delay={200}>
                      <p>{i18n.t("privacy_beleid.doel.text")}</p>
                    </Fade>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="py-3">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="12">
                  <div className="pr-md-5">
                    <Fade delay={100}>
                      <h2>{i18n.t("privacy_beleid.besluitvorming.titel")}</h2>
                    </Fade>
                    <Fade delay={200}>
                      <p>{i18n.t("privacy_beleid.besluitvorming.text")}</p>
                    </Fade>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="py-3">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="12">
                  <div className="pr-md-5">
                    <Fade delay={100}>
                      <h2>{i18n.t("privacy_beleid.tijd.titel")}</h2>
                    </Fade>
                    <Fade delay={200}>
                      <p>{i18n.t("privacy_beleid.tijd.text")}</p>
                      <ul className="list-unstyled">
                        {i18n
                          .t("privacy_beleid.tijd.bullets", {
                            returnObjects: true,
                          })
                          .map((bullet, i) => (
                            <li className="py-2" key={i}>
                              <div className="d-flex align-items-end">
                                <div>
                                  <Badge
                                    className="badge-circle mr-3"
                                    color="success"
                                  >
                                    <i />
                                  </Badge>
                                </div>
                                <div>
                                  <h4 className="mb-0">{bullet}</h4>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </Fade>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="py-3">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="12">
                  <div className="pr-md-5">
                    <Fade delay={100}>
                      <h2>{i18n.t("privacy_beleid.delen.titel")}</h2>
                    </Fade>
                    <Fade delay={200}>
                      <p>{i18n.t("privacy_beleid.delen.text")}</p>
                    </Fade>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="py-3">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="12">
                  <div className="pr-md-5">
                    <Fade delay={100}>
                      <h2>{i18n.t("privacy_beleid.cookies.titel")}</h2>
                    </Fade>
                    <Fade delay={200}>
                      <p>{i18n.t("privacy_beleid.cookies.text")}</p>
                    </Fade>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="py-3">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="12">
                  <div className="pr-md-5">
                    <Fade delay={100}>
                      <h2>{i18n.t("privacy_beleid.inzien.titel")}</h2>
                    </Fade>
                    <Fade delay={200}>
                      <p>{i18n.t("privacy_beleid.inzien.text")}</p>
                    </Fade>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="py-3">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md="12">
                  <div className="pr-md-5">
                    <Fade delay={100}>
                      <h2>{i18n.t("privacy_beleid.beveiligen.titel")}</h2>
                    </Fade>
                    <Fade delay={200}>
                      <p>{i18n.t("privacy_beleid.beveiligen.text")}</p>
                    </Fade>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default PrivacyPolicy;
