import firebase from "firebase";

/**
 * Set user settings in Firebase
 * @param userid Id of user
 * @param object The settings object
 */
function setUserSettings(userid, object) {
  firebase
    .database()
    .ref("users/" + userid)
    .update(object, (error) => {
      if (error) {
        console.log(error);
      }
    });
}

/**
 * Retrieve user settings from Firebase
 * @param userid Id of user
 * @returns {Promise<unknown>}
 */
function getUserSettings(userid) {
  return new Promise(function (resolve, reject) {
    let userRef = firebase.database().ref("users/" + userid);
    userRef.on("value", (snapshot) => {
      let user = snapshot.val();
      if (!!user) {
        resolve({
          offDayRequestMails:
            user.offDayRequestMails === undefined
              ? true
              : user.offDayRequestMails,
          promotionMails:
            user.promotionMails === undefined ? true : user.promotionMails,
        });
      } else {
        reject("No user found");
      }
    });
  });
}

/**
 * Get details of a user
 * @param uid ID of user
 * @returns Object with user details
 */
function getUserByUserId(uid) {
  return new Promise(function (resolve, reject) {
    firebase
      .database()
      .ref("users/" + uid)
      .on("value", (snapshot) => {
        let data = snapshot.val();
        if (!!data) {
          let object = {
            id: data.id,
            name: data.name,
            picture: data.picture,
            email: data.email,
            offdays: data.offdays,
            offDayRequestMails: data.offDayRequestMails,
            promotionMails: data.promotionMails,
          };
          resolve(object);
        } else resolve(null);
      });
  });
}

export { setUserSettings, getUserSettings, getUserByUserId };
