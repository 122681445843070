import React from "react";
import Lottie from "react-lottie";
import * as loading from "../../assets/img/theme/payment.json";

const PaymentLoading = () => {
  return (
    <>
      <div className="loading-container">
        <Lottie options={loadingOptions} width={600} />
      </div>
    </>
  );
};

const loadingOptions = {
  loop: true,
  autoplay: true,
  animationData: loading.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default PaymentLoading;
