import React from "react";
import { Card, CardBody, Badge, Col } from "reactstrap";
import Fade from "react-reveal/Fade";

const PricingCardsExplanation = (props) => {
  return (
    <Col lg="4">
      <Fade delay={300}>
        <Card className="card-lift--hover shadow border-0">
          <CardBody className="py-5">
            <div
              className={`icon icon-shape bg-gradient-${props.themeColor} text-white rounded-circle mb-4`}
            >
              <i className={props.icon} />
            </div>
            <h4 className={`h3 text-${props.themeColor} text-uppercase`}>
              {props.titel}
            </h4>
            <p className="description mt-3">{props.description}</p>
            <div>
              <Badge color={`${props.themeColor}`} pill>
                {props.badge1}
              </Badge>
              <Badge color={`${props.themeColor}`} pill>
                {props.badge2}
              </Badge>
              <Badge color={`${props.themeColor}`} pill>
                {props.badge3}
              </Badge>
            </div>
          </CardBody>
        </Card>
      </Fade>
    </Col>
  );
};

export { PricingCardsExplanation };
