import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  FormGroup,
  Form,
  Input,
  Modal,
  Container,
  Row,
  Col,
} from "reactstrap";
import ProfileHeader from "components/Headers/ProfileHeader.js";
import firebase from "firebase";
import notify from "../../../functions/notify.js";
import NotificationAlert from "react-notification-alert";
import bgImg from "assets/img/theme/profile-bg.jpg";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import beeImage from "assets/img/theme/dash.png";
import i18n from "variables/translations/i18n.js";
import {deleteImage, uploadImage} from "../../../functions/images";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.notifyProfileRef = React.createRef();
  }

  state = {
    userObjectId: null,
    name: {
      initial: "",
      changed: null,
    },
    function: {
      initial: "",
      changed: null,
    },
    companyId: localStorage.getItem("compid"),
    companyName: "",
    email: "",
    role: "",
    profilePicture: "",
    crop: {
      unit: "%",
      aspect: 1,
      width: 60,
    },
    imgSrc: null,
    profileCropModal: false,
  };

  componentDidMount() {
    this.getCompanyName();
    this.getUserInfo();
  }

  getCompanyName() {
    let company = firebase.database().ref("companies/" + this.state.companyId);
    company.on("value", (snapshot) => {
      this.setState({ companyName: snapshot.val().name });
    });
  }
  getUserInfo() {
    let user = firebase.database().ref("users/" + localStorage.getItem("uid"));
    user.on("value", (snapshot) => {
      let data = snapshot.val();
      this.setState({
        userObjectId: data.id,
        email: data.email,
        role: data.role,
        name: { ...this.state.name, initial: data.name },
        function: { ...this.state.function, initial: data.function },
        profilePicture: data.picture,
      });
    });
  }

  handleNameChange() {
    /** First check if name changed. If so, push to DB */
    if (
      this.state.name.changed !== null &&
      this.state.name.changed !== this.state.name.initial
    ) {
      firebase
        .database()
        .ref("users/" + this.state.userObjectId)
        .update(
          {
            name: this.state.name.changed,
          },
          (error) => {
            if (error) {
              notify("warning", error, this.notifyProfileRef);
              console.error(error);
            } else {
              notify(
                "success",
                i18n.t("meldingen.naam_opgeslagen"),
                this.notifyProfileRef
              );
              /** Set initial to changed state so user can edit again */
              this.setState({
                name: { ...this.state.name, initial: this.state.name.changed },
              });
            }
          }
        );
    }
  }

  handleFunctionChange() {
    /** First check if name changed. If so, push to DB */
    if (
      this.state.function.changed !== null &&
      this.state.function.changed !== this.state.function.initial
    ) {
      firebase
        .database()
        .ref("users/" + this.state.userObjectId)
        .update(
          {
            function: this.state.function.changed,
          },
          (error) => {
            if (error) {
              notify("danger", error, this.notifyProfileRef);
              console.error(error);
            } else {
              notify(
                "success",
                i18n.t("meldingen.functie_opgeslagen"),
                this.notifyProfileRef
              );
              /** Set initial to changed state so user can edit again */
              this.setState({
                function: {
                  ...this.state.function,
                  initial: this.state.function.changed,
                },
              });
            }
          }
        );
    }
  }

  onSaveProfilePicture() {
    if (!!this.state.profilePicture) deleteImage(this.state.profilePicture)
    uploadImage(this.state.croppedImageUrl).then((picture) => {       
      firebase
        .database()
        .ref("users/" + this.state.userObjectId)
        .update(
          {
            picture: picture,
          },
          (error) => {
            if (error) {
              notify(
                "success",
                i18n.t("meldingen.klant_opgeslagen"),
                this.notifyProfileRef
              );
            } else {
              notify(
                "success",
                i18n.t("meldingen.foto_opgeslagen"),
                this.notifyProfileRef
              );
            }
          }
        );
      this.setState({ profilePicture: picture, profileCropModal: false });
    }).catch(() => {
      notify(
        "warning",
        i18n.t("meldingen.afbeelding_upload_gefaald"),
        this.notifyProfileRef
      );
    })
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ imgSrc: reader.result, profileCropModal: true })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        resolve(blob);
      }, "image/jpeg");
    });
  }

  render() {
    const { crop, imgSrc } = this.state;
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref={this.notifyProfileRef} />
        </div>
        <ProfileHeader
          title={
            i18n.t("dashboard.profiel.groet") +
            this.state.name.initial.replace(/ .*/, "")
          }
          subtitle={i18n.t("dashboard.profiel.desc")}
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col md="4">
              <Modal
                className="modal-dialog-centered"
                size="lg"
                isOpen={this.state.profileCropModal}
                toggle={() =>
                  this.setState({
                    profileCropModal: !this.state.profileCropModal,
                  })
                }
              >
                <div className="modal-body p-0">
                  {imgSrc && (
                    <ReactCrop
                      src={imgSrc}
                      crop={crop}
                      ruleOfThirds
                      circularCrop
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                    />
                  )}
                </div>
                <Button
                  color="info"
                  type="button"
                  onClick={() => this.onSaveProfilePicture()}
                >
                  {i18n.t("dashboard.opslaan")}
                </Button>
              </Modal>
            </Col>
          </Row>
          <Row>
            <Col className="order-xl-2" xl="4">
              <Card className="card-profile">
                <CardImg alt="..." src={bgImg} top />
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a onClick={(e) => e.preventDefault()}>
                        <img
                          alt="profielfoto"
                          className="rounded-circle bg-white"
                          src={
                            !!this.state.profilePicture
                              ? this.state.profilePicture
                              : beeImage
                          }
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between opacity-0">
                    <Button
                      className="mr-4"
                      color="info"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Connect
                    </Button>
                    <Button
                      className="float-right"
                      color="default"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Message
                    </Button>
                  </div>
                </CardHeader>
                <CardBody className="pt-0">
                  <Row>
                    {/* <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center">
                        <div>
                          <span className="heading">22</span>
                          <span className="description">Friends</span>
                        </div>
                        <div>
                          <span className="heading">10</span>
                          <span className="description">Photos</span>
                        </div>
                        <div>
                          <span className="heading">89</span>
                          <span className="description">Comments</span>
                        </div>
                      </div>
                    </div> */}
                  </Row>
                  <div className="text-center">
                    <h5 className="h3">
                      {this.state.name.changed === null
                        ? this.state.name.initial
                        : this.state.name.changed}
                    </h5>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      {this.state.email}
                    </div>
                    <div className="h5 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      {this.state.function.changed === null
                        ? this.state.function.initial
                        : this.state.function.changed}
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                      {this.state.companyName}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card>
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {i18n.t("dashboard.profiel.bewerken")}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      {i18n.t("dashboard.profiel.gebruikers_info")}
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              {i18n.t("dashboard.profiel.naam")}
                            </label>
                            <Input
                              defaultValue={this.state.name.initial}
                              id="input-username"
                              placeholder={i18n.t("dashboard.profiel.naam")}
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  name: {
                                    ...this.state.name,
                                    changed: e.target.value,
                                  },
                                })
                              }
                              onBlur={() => this.handleNameChange()}
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  this.handleNameChange();
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              {i18n.t("dashboard.profiel.email")}
                            </label>
                            <Input
                              id="input-email"
                              placeholder={this.state.email}
                              type="email"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <div className="custom-file">
                              <label
                                className="form-control-label"
                                htmlFor="input-profile-picture"
                              >
                                {i18n.t("dashboard.profiel.profielfoto.label")}
                              </label>
                              <input
                                className="custom-file-input opacity-0"
                                id="input-profile-picture"
                                lang="nl"
                                type="file"
                                accept="image/png, image/jpeg"
                                onChange={(e) => this.onSelectFile(e)}
                              />

                              <label
                                className={
                                  this.state.profilePicture === "" ||
                                  typeof this.state.profilePicture ===
                                    "undefined"
                                    ? "custom-file-label"
                                    : "custom-file-label text-success"
                                }
                                htmlFor="input-profile-picture"
                              >
                                {this.state.profilePicture === "" ||
                                typeof this.state.profilePicture === "undefined"
                                  ? i18n.t(
                                      "dashboard.profiel.profielfoto.label"
                                    )
                                  : i18n.t(
                                      "dashboard.profiel.profielfoto.gekozen"
                                    )}
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />

                    <h6 className="heading-small text-muted mb-4">
                      {i18n.t("dashboard.profiel.rol_info")}
                      {this.state.companyName}
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-function"
                            >
                              {i18n.t("dashboard.profiel.functie")}
                            </label>
                            <Input
                              defaultValue={this.state.function.initial}
                              id="input-function"
                              placeholder="Innovation Master"
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  function: {
                                    ...this.state.function,
                                    changed: e.target.value,
                                  },
                                })
                              }
                              onBlur={() => this.handleFunctionChange()}
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  this.handleFunctionChange();
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-role"
                            >
                              {i18n.t("dashboard.profiel.dashbee_rol")}
                            </label>
                            <Input
                              placeholder={
                                this.state.role === "admin"
                                  ? "Queen Bee"
                                  : "Worker Bee"
                              }
                              id="input-role"
                              type="text"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Row>
          <Col xs="6>">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Profiel bewerken</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Settings
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                
                  {imgSrc && (
                    <ReactCrop
                      src={imgSrc}
                      crop={crop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                    />
                  )}
              </CardBody>
            </Card>
            </Col>
            {/* <Col xs="6>">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Profiel bewerken</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Settings
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                  {croppedImageUrl && (
                    <img
                      alt="Crop"
                      style={{ maxWidth: "100%" }}
                      src={croppedImageUrl}
                    />
                  )}
              </CardBody>
            </Card>
            </Col> */}
        </Container>
      </>
    );
  }
}

export default Profile;
