import React from "react";
import { Link } from "react-router-dom";
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
import urls from "urls";
import algemeneVoorwaarden from "../../assets/documents/Algemene-Voorwaarden-DashBee.pdf";
import bgImage from "../../assets/img/theme/footer-bg.png";
import logo from "../../assets/img/brand/logo-purple.png";
import i18n from "../../variables/translations/i18n";

class Login extends React.Component {
  render() {
    return (
      <>
        <footer
          className="py-6"
          id="footer-main"
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
          }}
        >
          <Container>
            <Row className="justify-content-xl-between">
              <Col lg="3">
                <div className="justify-content-center text-xl-left">
                  <img alt="logo" src={logo} style={{ width: "180px" }} />
                </div>
                <strong>{i18n.t("footer_sub_titel")}</strong>
              </Col>
              <Col lg="3" className="md-mt-4">
                <Nav className="nav-footer justify-content-lg-start">
                  <NavItem>
                    <NavLink to={urls.pricing} tag={Link} className="pl-0">
                      {i18n.t("nav_prijzen")}
                    </NavLink>
                  </NavItem>
                </Nav>
                <Nav className="nav-footer justify-content-lg-start">
                  <NavItem>
                    <NavLink to={urls.contact} tag={Link} className="pl-0">
                      {i18n.t("nav_contact")}
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg="3" className="md-mt-4">
                <Nav className="nav-footer justify-content-lg-start">
                  <NavItem>
                    <NavLink to={urls.privacy} tag={Link} className="pl-0">
                      {i18n.t("nav_privacy")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href={algemeneVoorwaarden}
                      download
                      className="pl-0"
                    >
                      {i18n.t("nav_voorwaarden")}
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg="3" className="md-mt-4">
                <div className="copyright justify-content-center text-muted">
                  <p className="m-0 font-weight-bold">
                    {i18n.t("contact_bedrijf")}
                  </p>
                  <p className="m-0">{i18n.t("contact_kvk")}</p>
                  <p className="m-0">{i18n.t("contact_straat")}</p>
                  <p className="m-0">{i18n.t("contact_postcode_land")}</p>
                  <p className="m-0">{i18n.t("contact_telefoon")}</p>
                  <p className="m-0">{i18n.t("contact_email")}</p>
                </div>
              </Col>
            </Row>
            <Row className="md-mt-4">
              <Col lg="3">
                <div className="copyright justify-content-center text-xl-left text-muted">
                  © {new Date().getFullYear()}{" "}
                  <a
                    className="font-weight-bold ml-1"
                    href="https://digitalkiss.nl"
                    target="_blank"
                  >
                    Digital KISS
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Login;
