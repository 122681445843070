import React from "react";
import i18n from "../../../../variables/translations/i18n";
import { Container } from "reactstrap";
import { Redirect } from "react-router";
import urls from "urls";
import SimpleHeader from "../../../../components/Headers/SimpleHeader";
import {isAdminPage} from "../../../functions/generic";
import AlertWithCancel from "../../../../components/Notifications/AlertWithCancel";
import PaymentLoading from "../../../../components/Loading/payment";
import {fetchSubscription, renewSubscription} from "../../../functions/stripe/subscription";

class RenewSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: localStorage.getItem("compid"),
      showRenewAlert: true,
      proceedWithRenew: null,
      renewSucceeded: null,
      authorized: this.props.location.authorized,
      subscriptionType: this.props.location.subscriptionType,
      isLoading: false,
    };
  }

  /**
   * Renew subscription in Payment API
   */
  confirmRenewSubscription() {
    this.setState({proceedWithRenew: true, showRenewAlert: null, isLoading:true})
    fetchSubscription(this.state.companyId).then((subscription) => {
      renewSubscription(subscription.subscriptionId)
        .then(() => this.setState({isLoading: false, renewSucceeded: true}))
        .catch(() => this.setState({isLoading: false, renewSucceeded: false}))
    })
  }

  render() {
    const { renewSucceeded, showRenewAlert, authorized, proceedWithRenew, isLoading } = this.state;
    if (renewSucceeded === true) return <Redirect to={{pathname: urls.subscription, renewDone: true}} />;
    if (renewSucceeded === false) return <Redirect to={{pathname: urls.subscription, renewDone: false}} />;
    if (!authorized || proceedWithRenew === false) return <Redirect to={{pathname: urls.subscription}} />;
    return (
      <>
        <SimpleHeader
          name={i18n.t("dashboard.abonnement.abonnement_titel")}
          parentName={i18n.t("dashboard.bedrijf")}
          isAdminPage={isAdminPage(this.props.location.pathname)}
        />
        <Container className="mt--8 pb-5">
          {isLoading &&
          <PaymentLoading/>
          }
          {showRenewAlert &&
          <AlertWithCancel
            type="info"
            title={i18n.t("dashboard.abonnement.hernieuwen_bevestigings_vraag_titel")}
            description={i18n.t("dashboard.abonnement.hernieuwen_bevestigings_vraag_descr")}
            confirmBtnText={i18n.t("dashboard.abonnement.abonnement_hernieuwen_btn")}
            confirmAlert={() => this.confirmRenewSubscription()}
            closeAlert={() => this.setState({proceedWithRenew: false, showRenewAlert: null})}
            cancelBtnText={i18n.t("dashboard.sluiten")}
          />
          }
        </Container>
      </>
    );
  }
}

export default RenewSubscription;
