import firebase from "firebase";

/**
 * Set company settings in Firebase
 * @param companyId Id of company
 * @param object The settings object
 */
function setCompanySettings(companyId, object) {
  firebase
    .database()
    .ref("companies/" + companyId)
    .update(object, (error) => {
      if (error) {
        console.log(error);
      }
    });
}

/**
 * Retrieve the company settings from Firebase
 * @param companyId Id of company
 * @returns {Promise<unknown>}
 */
function getCompanySettings(companyId) {
  return new Promise(function (resolve, reject) {
    let companyRef = firebase.database().ref("companies/" + companyId);
    companyRef.on("value", (snapshot) => {
      let company = snapshot.val();
      if (!!company) {
        resolve({
          offDayModule:
            company.offDayModule === undefined ? true : company.offDayModule,
          invoiceModule:
            company.invoiceModule === undefined ? true : company.invoiceModule,
          clientModule:
            company.clientModule === undefined ? true : company.clientModule,
          hoursModule:
            company.hoursModule === undefined ? true : company.hoursModule,
          costsOverviewModule:
            company.costsOverviewModule === undefined
              ? true
              : company.costsOverviewModule,
          projectOverviewModule:
            company.projectOverviewModule === undefined
              ? true
              : company.projectOverviewModule,
          teamOverviewModule:
            company.teamOverviewModule === undefined
              ? true
              : company.teamOverviewModule,
        });
      } else {
        reject("No company found");
      }
    });
  });
}

/**
 * Retrieve the count of users of a company
 * @param companyId Id of company
 */
function getCompanyUserCount(companyId) {
  return new Promise(function (resolve, reject) {
    let usersRef = firebase
      .database()
      .ref("users")
      .orderByChild("companyId")
      .equalTo(companyId);
    usersRef.on("value", (snapshot) => {
      let users = snapshot.val();
      if (!!users) {
        let count = Object.keys(users).length;
        resolve(count);
      } else {
        reject("No company found");
      }
    });
  });
}

/**
 * Get company info of one company
 * @param companyId Id of company
 * @returns Company object
 */
function getCompanyInfo(companyId) {
  return new Promise(function (resolve, reject) {
    let companyRef = firebase.database().ref("companies/" + companyId);
    companyRef.on("value", (snapshot) => {
      let company = snapshot.val();
      if (!!company) {
        resolve(company);
      } else {
        reject("No company found");
      }
    });
  });
}

export { setCompanySettings, getCompanySettings, getCompanyUserCount, getCompanyInfo };
