import React from "react";
import { Badge, Button, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/js/brands.js";
import ipadPreview from "assets/img/icons/preview/ipad.png";
import iphonePreview from "assets/img/icons/preview/iphone.png";
import imacPreview from "assets/img/icons/preview/uren-imac.png";
import imacPreview2 from "assets/img/icons/preview/imac2.png";
import ipadPreview2 from "assets/img/icons/preview/factuur-ipad.png";
import imacPreview3 from "assets/img/icons/preview/calendar-imac.png";
import urls from "../../urls";
import i18n from "../../variables/translations/i18n";
import Fade from "react-reveal/Fade";

class Modules extends React.Component {
  render() {
    return (
      <>
        <section className="py-7">
          <Container>
            <Row className="row-grid align-items-center">
              <Col className="order-md-2" md="6">
                <Fade delay={750}>
                  <img
                    alt="Dashboard op iMac"
                    className="img-fluid"
                    src={imacPreview}
                  />
                </Fade>
              </Col>
              <Col className="order-md-1" md="6">
                <div className="pr-md-5">
                  <Fade delay={100}>
                    <h2 className="display-4">
                      {i18n.t("homepage_feature1_titel")}
                    </h2>
                  </Fade>
                  <Fade delay={200}>
                    <p>{i18n.t("homepage_feature1_desc")}</p>
                  </Fade>

                  <ul className="list-unstyled mt-2">
                    {i18n
                      .t("homepage_feature1_bullets", {
                        returnObjects: true,
                      })
                      .map((value, index) => (
                        <li className="py-2" key={index}>
                          <Fade delay={300 + index * 150} cascade>
                            <div className="d-flex align-items-center">
                              <div>
                                <Badge
                                  className="badge-circle mr-3"
                                  color="success"
                                >
                                  <i className="ni ni-air-baloon lg" />
                                </Badge>
                              </div>
                              <div>
                                <h3 className="mb-0">{value}</h3>
                              </div>
                            </div>
                          </Fade>
                        </li>
                      ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="container text-center mt-6 mb-2">
          <Fade delay={200}>
            <h2>{i18n.t("homepage_desc_1")}</h2>
          </Fade>
        </div>
        <section className="py-7">
          <Container>
            <Row className="row-grid align-items-center">
              <Col md="6">
                <Fade delay={750}>
                  <img
                    width="300"
                    alt="Urenregistratie op iPhone"
                    className="img-fluid"
                    src={iphonePreview}
                  />
                </Fade>
              </Col>
              <Col md="6">
                <div className="pr-md-5 text-end">
                  <Fade delay={100}>
                    <h2 className="display-4">
                      {i18n.t("homepage_feature2_titel")}
                    </h2>
                  </Fade>
                  <Fade delay={200}>
                    <p>{i18n.t("homepage_feature2_desc")}</p>
                  </Fade>
                  <ul className="list-unstyled mt-2">
                    {i18n
                      .t("homepage_feature2_bullets", {
                        returnObjects: true,
                      })
                      .map((value, index) => (
                        <li className="py-2" key={index}>
                          <Fade delay={300 + index * 150}>
                            <div className="d-flex list-items-end">
                              <div>
                                <h3 className="mb-0 mr-3">{value}</h3>
                              </div>
                              <div>
                                <Badge className="badge-circle" color="success">
                                  <i className="ni ni-check-bold" />
                                </Badge>
                              </div>
                            </div>
                          </Fade>
                        </li>
                      ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-7 bg-white">
          <Container>
            <Row className="row-grid align-items-center">
              <Col className="order-md-2" md="6">
                <Fade delay={750}>
                  <img
                    alt="DashBee op iPad"
                    className="img-fluid"
                    src={ipadPreview}
                  />
                </Fade>
              </Col>
              <Col className="order-md-1" md="6">
                <div className="pr-md-5">
                  <Fade delay={100}>
                    <h2 className="display-4">
                      {i18n.t("homepage_feature3_titel")}
                    </h2>
                  </Fade>
                  <Fade delay={200}>
                    <p>{i18n.t("homepage_feature3_desc")}</p>
                  </Fade>
                  <ul className="list-unstyled mt-2">
                    {i18n
                      .t("homepage_feature3_bullets", {
                        returnObjects: true,
                      })
                      .map((value, index) => (
                        <li className="py-2" key={index}>
                          <Fade delay={300 + index * 150}>
                            <div className="d-flex align-items-center">
                              <div>
                                <Badge
                                  className="badge-circle mr-3"
                                  color="success"
                                >
                                  <i className="ni ni-check-bold" />
                                </Badge>
                              </div>
                              <div>
                                <h3 className="mb-0">{value}</h3>
                              </div>
                            </div>
                          </Fade>
                        </li>
                      ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-7 bg-white">
          <Container>
            <Row className="row-grid align-items-center">
              <Col md="6">
                <Fade delay={200}>
                  <img
                    alt="Dashboard op iMac"
                    className="img-fluid"
                    src={imacPreview3}
                  />
                </Fade>
              </Col>
              <Col md="6">
                <div className="pr-md-5 text-end">
                  <Fade delay={100}>
                    <h2 className="display-4">
                      {i18n.t("homepage_feature4_titel")}
                    </h2>
                  </Fade>
                  <Fade delay={200}>
                    <p>{i18n.t("homepage_feature4_desc")}</p>
                  </Fade>
                  <ul className="list-unstyled mt-2">
                    {i18n
                      .t("homepage_feature4_bullets", {
                        returnObjects: true,
                      })
                      .map((value, index) => (
                        <li className="py-2" key={index}>
                          <Fade delay={300 + index * 150}>
                            <div className="d-flex list-items-end">
                              <div>
                                <h3 className="mb-0 mr-3">{value}</h3>
                              </div>
                              <div>
                                <Badge className="badge-circle" color="success">
                                  <i className="ni ni-check-bold" />
                                </Badge>
                              </div>
                            </div>
                          </Fade>
                        </li>
                      ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-7">
          <Container>
            <Row className="row-grid align-items-center">
              <Col className="order-md-2" md="6">
                <Fade delay={750}>
                  <img
                    alt="DashBee op iPad"
                    className="img-fluid"
                    src={ipadPreview2}
                  />
                </Fade>
              </Col>
              <Col className="order-md-1" md="6">
                <div className="pr-md-5">
                  <Fade delay={100}>
                    <h2 className="display-4">
                      {i18n.t("homepage_feature5_titel")}
                    </h2>
                  </Fade>
                  <Fade delay={200}>
                    <p>{i18n.t("homepage_feature5_desc")}</p>
                  </Fade>
                  <ul className="list-unstyled mt-2">
                    {i18n
                      .t("homepage_feature5_bullets", {
                        returnObjects: true,
                      })
                      .map((value, index) => (
                        <li className="py-2" key={index}>
                          <Fade delay={300 + index * 150}>
                            <div className="d-flex align-items-center">
                              <div>
                                <Badge
                                  className="badge-circle mr-3"
                                  color="success"
                                >
                                  <i className="ni ni-check-bold" />
                                </Badge>
                              </div>
                              <div>
                                <h3 className="mb-0">{value}</h3>
                              </div>
                            </div>
                          </Fade>
                        </li>
                      ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="text-center">
          <Fade delay={100}>
            <p className="display-4">{i18n.t("homepage_quote_2")}</p>
          </Fade>
        </div>
        <section className="py-7">
          <Container>
            <Row className="row-grid align-items-center">
              <Col md="6">
                <Fade delay={200}>
                  <img
                    alt="Dashboard op iMac"
                    className="img-fluid"
                    src={imacPreview2}
                  />
                </Fade>
              </Col>
              <Col md="6">
                <div className="pr-md-5 text-end">
                  <Fade delay={100}>
                    <h2 className="display-4">
                      {i18n.t("homepage_feature6_titel")}
                    </h2>
                  </Fade>
                  <Fade delay={200}>
                    <p>{i18n.t("homepage_feature6_desc")}</p>
                  </Fade>
                  <Button
                    className="mt-3 mt-md-2"
                    to={urls.contact}
                    tag={Link}
                    color="info"
                  >
                    {i18n.t("contact_btn")}
                  </Button>
                  <Button
                    className="mt-3 mt-md-2"
                    color="default"
                    to={urls.pricing}
                    tag={Link}
                  >
                    {i18n.t("show_pricing")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default Modules;
