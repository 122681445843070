import { Card, CardHeader, Col, Row } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import Table from "./Table";
import React from "react";

const TableCard = (props) => {
  return (
    <Col lg={props.cardSize}>
      <Card id="tabel">
        <CardHeader>
          <h3 className="mb-0">{props.cardTitle}</h3>
          <p className="text-sm mb-0">{props.cardSubTitle}</p>
        </CardHeader>
        {props.isLoading ? (
          <Skeleton count={10} height={52} />
        ) : (
          <Table data={props.tableData} columns={props.tableColumns} />
        )}
      </Card>
    </Col>
  );
};

export default TableCard;
