import React, { useRef, useState } from 'react';
import {useStripe, useElements, IdealBankElement} from '@stripe/react-stripe-js';
import {
  createStripeCustomer,
  saveStripeSubscriptionDetails
} from "../../views/functions/stripe/payment";
import moment from "moment";
import i18n from "../../variables/translations/i18n";
import urls from "../../urls";
import {updateSubscription} from "../../views/functions/stripe/subscription";
import NotificationAlert from "react-notification-alert";
import notify from 'views/functions/notify';
import {Col, Button} from "reactstrap";
let hasSelectedBank = null;

const IDEAL_ELEMENT_OPTIONS = {
  style: {
    base: {
      padding: "6px",
      color: '#32325d',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      },
    },
  },
};

const Checkout = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const paymentNotifyRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const idealBank = elements.getElement(IdealBankElement);

    if (!!hasSelectedBank) {
      setIsLoading(true);
      let client_secret = '';
      let customerId = '';
      createStripeCustomer(props)
        .then((data) => {
          client_secret = data.client_secret
          customerId = data.customerId
        })
        .then(() => {
          let subscription = {
            customerId: customerId,
            priceId: props.priceId,
            companyId: props.companyId,
            subscriptionType: props.subscriptionType,
            duration: props.duration,
            createdAt: moment().unix(),
          }
          saveStripeSubscriptionDetails(subscription)
          if (props.hasValidPromotionCode) updateSubscription(props.companyId, props.coupon)
        })
        .then(async () => {
          await stripe.confirmIdealSetup(client_secret, {
            payment_method: {
              ideal: idealBank,
              billing_details: {
                name: props.userName,
                email: props.userEmail
              },
            },
            return_url: urls.stripePaymentConfirmUrl + props.priceId,
          })
        })
    } else {
      notify(
        "warning",
        i18n.t("dashboard.abonnement.bank_empty"),
        paymentNotifyRef
      );
    }
  };

  return (
    <>
    <div className="rna-wrapper">
      <NotificationAlert ref={paymentNotifyRef} />
    </div>
    
    <form onSubmit={handleSubmit}>
      <Col>
        <div className="mb-2 h-100">
          <label>
            {i18n.t("dashboard.abonnement.ideal_betaling_subtitel")}
          </label>      
          <IdealBankElement 
            options={IDEAL_ELEMENT_OPTIONS} 
            onChange={(event) => {hasSelectedBank = event.value;}}
          />
        </div>
        <Button
          className={"mb-3"}
          color="cards"
          type="submit" disabled={!stripe}>
          {isLoading ? <i className="fa fa-spinner fa-spin" /> : i18n.t("dashboard.abonnement.ideal_betaling_button")}
        </Button>
        <div className="mandate-acceptance">
          <p className="text-sm font-weight-bold">
            {i18n.t("dashboard.abonnement.ideal_betaling_informatie")}
          </p>
          <p className="text-xs">
          {i18n.t("dashboard.abonnement.ideal_betaling_term_and_conditions")}
          </p>
        </div>
      </Col>
    </form>
    </>
  );
}

export default Checkout