import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { EN } from "./en/translations";
import { NL } from "./nl/translations";

const fallbackLng = ["nl", "en"];
const availableLanguages = ["en", "nl"];

const resources = {
  en: {
    translation: EN,
  },
  nl: {
    translation: NL,
  },
};

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng, // fallback language is english.
    keySeparator: ".",

    debug: false,

    whitelist: availableLanguages,
    detection: {
      order: ["path", "localStorage"],
      lookupFromPathIndex: 0,
      lookUpLocalStorage: "i18nextLng",
      checkWhitelist: true,
    },

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

export default i18n;
