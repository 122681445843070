import React from "react";
import Lottie from "react-lottie";
import * as loading from "../../assets/img/theme/loading.json";

const Loading = () => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-62%, -50%)",
        }}
      >
        <Lottie options={loadingOptions} width={250} />
      </div>
    </>
  );
};

const loadingOptions = {
  loop: true,
  autoplay: true,
  animationData: loading.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default Loading;
