import React from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col, CardImg, CardHeader, Button, Modal,
} from "reactstrap";
import firebase from "firebase";
import NotificationAlert from "react-notification-alert";
import ProfileHeader from "../../../../components/Headers/ProfileHeader";
import notify from "../../../functions/notify";
import i18n from "../../../../variables/translations/i18n";
import bgImg from "../../../../assets/img/theme/profile-bg.jpg";
import ReactCrop from "react-image-crop";
import {deleteImage, uploadImage} from "../../../functions/images";

class Company extends React.Component {
  constructor(props) {
    super(props);
    this.notifyProfileRef = React.createRef();
  }

  state = {
    companyId: localStorage.getItem("compid"),
    companyName: "",
    streetName: {
      initial: "",
      changed: null,
    },
    houseNumber: {
      initial: "",
      changed: null,
    },
    zipcode: {
      initial: "",
      changed: null,
    },
    city: {
      initial: "",
      changed: null,
    },
    phone: {
      initial: "",
      changed: null,
    },
    email: {
      initial: "",
      changed: null,
    },
    kvkNumber: {
      initial: "",
      changed: null,
    },
    btwNumber: {
      initial: "",
      changed: null,
    },
    iban: {
      initial: "",
      changed: null,
    },
    bic: {
      initial: "",
      changed: null,
    },
    companyPicture: "",
    croppedImageUrl: "",
    imgSrc: null,
    companyCropModal: false,
    crop: {
      height: 230,
      width: 460,
    },
  };

  componentDidMount() {
    this.getCompanyInfo();
  }

  onSaveCompanyPicture() {
    if (!!this.state.companyPicture) deleteImage(this.state.companyPicture)
    uploadImage(this.state.croppedImageUrl).then((picture) => {
      firebase
        .database()
        .ref("companies/" + this.state.companyId)
        .update(
          {picture: picture},
          (error) => {
            if (error) {
              notify(
                "success",
                i18n.t("meldingen.klant_opgeslagen"),
                this.notifyProfileRef
              );            } else {
              notify(
                "success",
                i18n.t("meldingen.bedrijfslogo_opgeslagen"),
                this.notifyProfileRef
              );
            }
          }
        );
      this.setState({ companyPicture: picture, companyCropModal: false });
    }).catch(() => {
      notify(
        "warning", 
        i18n.t("meldingen.afbeelding_upload_gefaald"), 
        this.notifyProfileRef
      );
    })
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ imgSrc: reader.result, companyCropModal: true })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, "newFile.jpeg");
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        resolve(blob);
      }, "image/jpeg");
    });
  }

  getCompanyInfo() {
    let company = firebase.database().ref("companies/" + this.state.companyId);
    company.on("value", (snapshot) => {
      let company =  snapshot.val();
      this.setState({
        companyName: company.name,
        streetName: { ...this.state.streetName, initial: company.streetName },
        houseNumber: { ...this.state.houseNumber, initial: company.houseNumber },
        zipcode: { ...this.state.zipcode, initial: company.zipcode },
        city: { ...this.state.city, initial: company.city },
        phone: { ...this.state.phone, initial: company.phone },
        email: { ...this.state.email, initial: company.email },
        kvkNumber: { ...this.state.kvkNumber, initial: company.kvkNumber },
        btwNumber: { ...this.state.btwNumber, initial: company.btwNumber },
        iban: { ...this.state.iban, initial: company.iban },
        bic: { ...this.state.bic, initial: company.bic },
        companyPicture: company.picture,
      });
    });
  }

  onSaveCompanyInfo(stateField, field, label) {
    /** First check if field has changed. If so, push to DB */
    if (stateField.changed !== null && (stateField.changed !== stateField.initial)) {
      let company = firebase.database().ref("companies/" + this.state.companyId);
      company.update({[field]: stateField.changed}).then(data => {
        notify("success",i18n.t("meldingen.je")  + label + i18n.t("meldingen.is_opgeslagen"), this.notifyProfileRef);
        /** Set initial to changed state so user can edit again */
        this.setState({[field]: {...stateField, initial: stateField.changed}})
      })
    }
  }

  render() {
    const { crop, imgSrc } = this.state;

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref={this.notifyProfileRef} />
        </div>
        <ProfileHeader
          icon="fa fa-building"
          title={
            this.state.companyName
          }
          subtitle={i18n.t("dashboard.mijn_bedrijf.pagina_titel")}
        />
        <Container className="mt--6" fluid>
          <Row>
            <Row>
              <Col md="4">
                <Modal
                  className="modal-dialog-centered"
                  size="lg"
                  isOpen={this.state.companyCropModal}
                  toggle={() =>
                    this.setState({
                      companyCropModal: !this.state.companyCropModal,
                    })
                  }
                >
                  <div className="modal-body p-0">
                    {imgSrc && (
                      <ReactCrop
                        src={imgSrc}
                        crop={crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                      />
                    )}
                  </div>
                  <Button
                    color="info"
                    type="button"
                    onClick={() => this.onSaveCompanyPicture()}
                  >
                    {i18n.t("dashboard.opslaan")}
                  </Button>
                </Modal>
              </Col>
            </Row>
            <Col className="order-xl-1" xl="8">
              <Card>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      <i className="fa fa-home mr-2" aria-hidden="true"/>
                      <span>{i18n.t("dashboard.mijn_bedrijf.adres")}</span>
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-streetName"
                            >
                              {i18n.t("dashboard.mijn_bedrijf.straat")}
                            </label>
                            <Input
                              defaultValue={this.state.streetName.initial}
                              id="input-streetName"
                              placeholder={i18n.t("dashboard.mijn_bedrijf.straat")}
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  streetName: {
                                    ...this.state.streetName,
                                    changed: e.target.value,
                                  },
                                })
                              }
                              onBlur={() =>
                                this.onSaveCompanyInfo
                                (this.state.streetName, 'streetName', i18n.t("dashboard.mijn_bedrijf.straat"))
                              }
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  this.onSaveCompanyInfo
                                  (this.state.streetName, 'streetName', i18n.t("dashboard.mijn_bedrijf.straat"))
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-houseNumber"
                            >
                              {i18n.t("dashboard.mijn_bedrijf.huisnummer")}
                            </label>
                            <Input
                              defaultValue={this.state.houseNumber.initial}
                              id="input-houseNumber"
                              placeholder={i18n.t("dashboard.mijn_bedrijf.huisnummer")}
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  houseNumber: {
                                    ...this.state.houseNumber,
                                    changed: e.target.value,
                                  },
                                })
                              }
                              onBlur={() =>
                                this.onSaveCompanyInfo
                                (this.state.houseNumber, 'houseNumber', i18n.t("dashboard.mijn_bedrijf.huisnummer"))
                            }
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  this.onSaveCompanyInfo
                                  (this.state.houseNumber, 'houseNumber', i18n.t("dashboard.mijn_bedrijf.huisnummer"))
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-zipcode"
                            >
                              {i18n.t("dashboard.mijn_bedrijf.postcode")}
                            </label>
                            <Input
                              defaultValue={this.state.zipcode.initial}
                              id="input-zipcode"
                              placeholder={i18n.t("dashboard.mijn_bedrijf.postcode")}
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  zipcode: {
                                    ...this.state.zipcode,
                                    changed: e.target.value,
                                  },
                                })
                              }
                              onBlur={() =>
                                this.onSaveCompanyInfo(this.state.zipcode, 'zipcode', i18n.t("dashboard.mijn_bedrijf.postcode"))}
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  this.onSaveCompanyInfo(this.state.zipcode, 'zipcode', i18n.t("dashboard.mijn_bedrijf.postcode"))
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              {i18n.t("dashboard.mijn_bedrijf.stad")}
                            </label>
                            <Input
                              defaultValue={this.state.city.initial}
                              id="input-city"
                              placeholder={i18n.t("dashboard.mijn_bedrijf.stad")}
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  city: {
                                    ...this.state.city,
                                    changed: e.target.value,
                                  },
                                })
                              }
                              onBlur={() =>
                                this.onSaveCompanyInfo(this.state.city, 'city', i18n.t("dashboard.mijn_bedrijf.stad"))
                              }
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  this.onSaveCompanyInfo(this.state.city, 'city', i18n.t("dashboard.mijn_bedrijf.stad"))
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">
                      <i className="fa fa-phone fa-flip-horizontal mr-2" aria-hidden="true"/>
                      <span>{i18n.t("dashboard.mijn_bedrijf.contact")}</span>
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                            >
                              {i18n.t("dashboard.mijn_bedrijf.telefoonnr")}
                            </label>
                            <Input
                              defaultValue={this.state.phone.initial}
                              id="input-phone"
                              placeholder={i18n.t("dashboard.mijn_bedrijf.telefoonnr")}
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  phone: {
                                    ...this.state.phone,
                                    changed: e.target.value,
                                  },
                                })
                              }
                              onBlur={() =>
                                this.onSaveCompanyInfo(this.state.phone, 'phone', i18n.t("dashboard.mijn_bedrijf.telefoonnr"))
                              }
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  this.onSaveCompanyInfo(this.state.phone, 'phone', i18n.t("dashboard.mijn_bedrijf.telefoonnr"))
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              {i18n.t("dashboard.mijn_bedrijf.email")}
                            </label>
                            <Input
                              defaultValue={this.state.email.initial}
                              id="input-email"
                              placeholder={i18n.t("dashboard.mijn_bedrijf.email")}
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  email: {
                                    ...this.state.email,
                                    changed: e.target.value,
                                  },
                                })
                              }
                              onBlur={() =>
                                this.onSaveCompanyInfo(this.state.email, 'email', i18n.t("dashboard.mijn_bedrijf.email"))
                              }
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  this.onSaveCompanyInfo(this.state.email, 'email', i18n.t("dashboard.mijn_bedrijf.email"))
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-2" xl="4">
              <Card className="card-profile">
                <CardImg alt="..." src={!!this.state.companyPicture ? this.state.companyPicture : bgImg} top />
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                  <div className="d-flex justify-content-between">
                    <Col lg="12">
                      <FormGroup>
                        <div className="custom-file">
                          <label
                            className="form-control-label"
                            htmlFor="input-profile-picture"
                          >
                            {i18n.t("dashboard.mijn_bedrijf.logo")}
                          </label>
                          <input
                            className="custom-file-input opacity-0"
                            id="input-profile-picture"
                            lang="nl"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={
                              (e) => this.onSelectFile(e)
                            }
                          />
                          <label
                            className={
                              this.state.companyPicture === "" ||
                              typeof this.state.companyPicture ===
                              "undefined"
                                ? "custom-file-label"
                                : "custom-file-label text-success"
                            }
                            htmlFor="input-profile-picture"
                          >
                            {this.state.companyPicture === "" ||
                            typeof this.state.companyPicture === "undefined"
                              ? i18n.t("dashboard.mijn_bedrijf.logo_kies")
                              : i18n.t("dashboard.mijn_bedrijf.logo_gekozen")}
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                  </div>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Card>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      <i className="fa fa-building mr-2" aria-hidden="true"/>
                      <span>{i18n.t("dashboard.mijn_bedrijf.kvk")}</span>
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-kvk"
                            >
                              {i18n.t("dashboard.mijn_bedrijf.kvk_nr")}
                            </label>
                            <Input
                              defaultValue={this.state.kvkNumber.initial}
                              id="input-kvk"
                              placeholder={i18n.t("dashboard.mijn_bedrijf.kvk_nr")}
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  kvkNumber: {
                                    ...this.state.kvkNumber,
                                    changed: e.target.value,
                                  },
                                })
                              }
                              onBlur={() =>
                                this.onSaveCompanyInfo
                                (this.state.kvkNumber, 'kvkNumber', i18n.t("dashboard.mijn_bedrijf.kvk_nr"))
                              }
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  this.onSaveCompanyInfo
                                  (this.state.kvkNumber, 'kvkNumber', i18n.t("dashboard.mijn_bedrijf.kvk_nr"))
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-btw"
                            >
                              {i18n.t("dashboard.mijn_bedrijf.btw_nr")}
                            </label>
                            <Input
                              defaultValue={this.state.btwNumber.initial}
                              id="input-btw"
                              placeholder={i18n.t("dashboard.mijn_bedrijf.btw_nr")}
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  btwNumber: {
                                    ...this.state.btwNumber,
                                    changed: e.target.value,
                                  },
                                })
                              }
                              onBlur={() =>
                                this.onSaveCompanyInfo
                                (this.state.btwNumber, 'btwNumber', i18n.t("dashboard.mijn_bedrijf.btw_nr"))
                              }
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  this.handleNameChange();
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">
                      <i className="fa fa-credit-card mr-2" aria-hidden="true"/>
                      <span>{i18n.t("dashboard.mijn_bedrijf.bank")}</span>
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-iban"
                            >
                              {i18n.t("dashboard.mijn_bedrijf.iban")}
                            </label>
                            <Input
                              defaultValue={this.state.iban.initial}
                              id="input-iban"
                              placeholder={i18n.t("dashboard.mijn_bedrijf.iban")}
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  iban: {
                                    ...this.state.iban,
                                    changed: e.target.value,
                                  },
                                })
                              }
                              onBlur={() => this.onSaveCompanyInfo(this.state.iban, 'iban', i18n.t("dashboard.mijn_bedrijf.iban"))}
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  this.onSaveCompanyInfo(this.state.iban, 'iban', i18n.t("dashboard.mijn_bedrijf.iban"))
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-bic"
                            >
                              {i18n.t("dashboard.mijn_bedrijf.bic")}
                            </label>
                            <Input
                              defaultValue={this.state.bic.initial}
                              id="input-bic"
                              placeholder={i18n.t("dashboard.mijn_bedrijf.bic")}
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  bic: {
                                    ...this.state.bic,
                                    changed: e.target.value,
                                  },
                                })
                              }
                              onBlur={() => this.onSaveCompanyInfo(this.state.bic, 'bic', i18n.t("dashboard.mijn_bedrijf.bic"))}
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault();
                                  this.onSaveCompanyInfo(this.state.bic, 'bic', i18n.t("dashboard.mijn_bedrijf.bic"))
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Company;
