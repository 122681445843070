import React from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import firebase from "firebase";
import { Link, Redirect } from "react-router-dom";
import urls from "../../../urls";
import logo from "../../../assets/img/brand/logo-purple.png";
import i18n from "variables/translations/i18n";
import { Helmet } from "react-helmet";
import { WEBSITE_NAME } from "constants/CompanyConstants";
import {
  META_REGISTER_COMPANY_TITLE,
  META_REGISTER_DESCRIPTION,
} from "constants/MetaTagsConstants";
import { EMAIL_VALIDATION_REGEX } from "../../../constants/InputConstants";

class Register extends React.Component {
  state = {
    name: "",
    email: "",
    password: "",
    acceptPrivacy: false,
    errorMessage: "",
    userRegistrationComplete: false,
    userId: null,
    companyName: "",
    companyType: "",
    redirectDashboard: false,
  };

  componentDidMount() {
    /** If user has already made account but did not complete registering company, show company screen */
    if (localStorage.getItem("registrationComplete") === "false") {
      this.setState({
        userRegistrationComplete: true,
        name: localStorage.getItem("registrationName"),
        email: localStorage.getItem("registrationEmail"),
        userId: localStorage.getItem("registrationUid"),
      });
    }
  }

  formValidation(type) {
    if (type === "user") {
      if (this.state.name.length < 3 || this.state.name === "") {
        this.setState({
          errorMessage: i18n.t("meldingen.naam_3_letters"),
        });
        return false;
      } else if (!this.state.email.match(EMAIL_VALIDATION_REGEX)) {
        this.setState({ errorMessage: i18n.t("meldingen.email_leeg") });
        return false;
      } else if (this.state.password.length < 6) {
        this.setState({
          errorMessage: i18n.t("meldingen.wachtwoord_6_karakters"),
        });
        return false;
      } else if (this.state.acceptPrivacy === false) {
        this.setState({
          errorMessage: i18n.t("meldingen.privacy_beleid"),
        });
        return false;
      } else {
        return true;
      }
    } else if (type === "company") {
      if (this.state.companyName === "") {
        this.setState({
          errorMessage: i18n.t("meldingen.bedrijfsnaam_leeg"),
        });
        return false;
      } else if (this.state.companyType === "") {
        this.setState({
          errorMessage: i18n.t("meldingen.rechtsvorm_leeg"),
        });
        return false;
      } else {
        return true;
      }
    }
  }

  handleSubmitUser() {
    if (this.formValidation("user")) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.state.email, this.state.password)
        .then((user) => {
          localStorage.setItem("registrationComplete", "false");
          localStorage.setItem("registrationEmail", this.state.email);
          localStorage.setItem("registrationName", this.state.name);
          localStorage.setItem("registrationUid", user.user.uid);
          this.setState({
            errorMessage: "",
            password: "",
            userId: user.user.uid,
          });
          let loggedInUser = firebase.auth().currentUser;
          let self = this;
          let actionCodeSettings = {
            url: urls.base + urls.login,
          };

          loggedInUser
            .sendEmailVerification(actionCodeSettings)
            .then(function () {
              self.setState({ userRegistrationComplete: true });
            })
            .catch(function (error) {
              console.error(error);
            });
          firebase.analytics().logEvent("sign_up");
        })
        .catch((error) => {
          let errorCode = error.code;
          let errorMessage = error.message;
          if (errorCode === "auth/email-already-in-use")
            this.setState({
              errorMessage: i18n.t("meldingen.email_reeds_gekozen"),
            });
          else this.setState({ errorMessage: errorMessage });
        });
    }
  }

  handleSubmitCompany() {
    if (this.formValidation("company")) {
      firebase
        .database()
        .ref("companies")
        .push(
          {
            name: this.state.companyName,
            type: this.state.companyType,
            created_at: firebase.database.ServerValue.TIMESTAMP,
          },
          (error) => {
            if (error) {
              console.error(error);
            }
          }
        )
        .then((snapshot) => {
          /** Push user to DB with corresponding companyId */
          const companyId = snapshot.key;
          firebase
            .database()
            .ref("users/" + this.state.userId)
            .set(
              {
                id: this.state.userId,
                companyId: companyId,
                name: this.state.name,
                email: this.state.email,
                role: "admin",
                created_at: firebase.database.ServerValue.TIMESTAMP,
              },
              (error) => {
                if (error) {
                  console.error(error);
                  this.setState({ errorMessage: error });
                } else {
                  /** Registration complete */
                  localStorage.removeItem("registrationName");
                  localStorage.removeItem("registrationEmail");
                  localStorage.removeItem("registrationComplete");
                  localStorage.removeItem("registrationUid");
                  this.setState({ redirectDashboard: true });
                }
              }
            );
        });
    }
  }

  render() {
    if (this.state.redirectDashboard) return <Redirect to={urls.dashboard} />;
    if (this.state.userRegistrationComplete === false) {
      return (
        <>
          <Helmet>
            <title>
              {META_REGISTER_COMPANY_TITLE} · {WEBSITE_NAME}
            </title>
            <meta name="description" content={META_REGISTER_DESCRIPTION} />
          </Helmet>
          <AuthHeader
            title={i18n.t("auth.registratie.titel1")}
            lead={i18n.t("auth.registratie.subtitel1")}
          />
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Col lg="6" md="8">
                <Card className="bg-secondary border-0">
                  <CardHeader className="bg-transparent pb-4">
                    <div className="text-muted text-center mt-3 mb-3 mr-5 ml-5">
                      <img className="mw-100" src={logo} />
                      <span className="card-header-title">
                        {i18n.t("auth.registratie.instructie1")}
                      </span>
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-5 py-lg-5">
                    <Form role="form">
                      <FormGroup
                        className={classnames({
                          focused: this.state.focusedName,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-hat-3" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder={i18n.t("auth.registratie.naam")}
                            type="text"
                            onFocus={() => this.setState({ focusedName: true })}
                            onBlur={() => this.setState({ focusedName: false })}
                            onChange={(e) =>
                              this.setState({ name: e.target.value })
                            }
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                this.handleSubmitUser();
                              }
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.focusedEmail,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder={i18n.t("auth.email")}
                            type="email"
                            onFocus={() =>
                              this.setState({ focusedEmail: true })
                            }
                            onBlur={() =>
                              this.setState({ focusedEmail: false })
                            }
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                this.handleSubmitUser();
                              }
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.focusedPassword,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder={i18n.t("auth.wachtwoord")}
                            type="password"
                            onFocus={() =>
                              this.setState({ focusedPassword: true })
                            }
                            onBlur={() =>
                              this.setState({ focusedPassword: false })
                            }
                            onChange={(e) =>
                              this.setState({ password: e.target.value })
                            }
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                this.handleSubmitUser();
                              }
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                      <Row className="my-4">
                        <Col xs="12">
                          <div className="custom-control custom-control-alternative custom-checkbox">
                            <input
                              className="custom-control-input"
                              id="customCheckRegister"
                              type="checkbox"
                              onChange={() =>
                                this.setState({
                                  acceptPrivacy: !this.state.acceptPrivacy,
                                })
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheckRegister"
                            >
                              <span className="text-muted">
                                {i18n.t("auth.registratie.akkoord")}{" "}
                                <a href="/privacy" target="_blank">
                                  {i18n.t("auth.registratie.privacy")}
                                </a>
                              </span>
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <small className="text-warning">
                          {this.state.errorMessage}
                        </small>
                      </Row>
                      <Row className="justify-content-center">
                        <Button
                          className="mt-4"
                          color="info"
                          type="button"
                          onClick={() => this.handleSubmitUser()}
                        >
                          {i18n.t("auth.registratie.submit_btn1")}
                        </Button>
                      </Row>
                    </Form>
                    <Row>
                      <Col xs="6">
                        <Link to={urls.login} className="text-muted">
                          <small>{i18n.t("auth.registratie.inloggen")}</small>
                        </Link>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    } else {
      return (
        <>
          <AuthHeader
            title={i18n.t("auth.registratie.titel2")}
            lead={i18n.t("auth.registratie.subtitel2")}
          />
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Col lg="6" md="8">
                <Card className="bg-secondary border-0">
                  <CardHeader className="bg-transparent pb-3">
                    <div className="text-muted text-center mt-3 mb-3 mr-5 ml-5">
                      <img className="mw-100" src={logo} />
                      <span className="card-header-title">
                        {i18n.t("auth.registratie.instructie1")}
                      </span>
                      <div className="card-header-details mt-3">
                        <span>{this.state.name}</span>
                        <span>{this.state.email}</span>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-5 py-lg-5">
                    <Form role="form">
                      <FormGroup
                        className={classnames({
                          focused: this.state.focusedCompanyName,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-hat-3" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder={i18n.t(
                              "auth.registratie.bedrijfsnaam"
                            )}
                            value={this.state.companyName}
                            type="text"
                            onFocus={() =>
                              this.setState({ focusedCompanyName: true })
                            }
                            onBlur={() =>
                              this.setState({ focusedCompanyName: false })
                            }
                            onChange={(e) =>
                              this.setState({ companyName: e.target.value })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <label className="form-control-label">
                        {i18n.t("auth.registratie.rechtsvorm")}
                      </label>
                      <div className="custom-control custom-radio mb-3">
                        <input
                          className="custom-control-input"
                          id="radio-personal"
                          name="radio-type"
                          type="radio"
                          onChange={() =>
                            this.setState({ companyType: "eenmanszaak" })
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="radio-personal"
                        >
                          {i18n.t("auth.registratie.eenmanszaak")}
                        </label>
                      </div>
                      <div className="custom-control custom-radio mb-3">
                        <input
                          className="custom-control-input"
                          id="radio-company"
                          name="radio-type"
                          type="radio"
                          onChange={() =>
                            this.setState({ companyType: "bedrijf" })
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="radio-company"
                        >
                          {i18n.t("auth.registratie.bv")}
                        </label>
                      </div>
                      <div className="text-muted font-italic text-center">
                        <small className="text-danger">
                          {this.state.errorMessage}
                        </small>
                      </div>
                      <div className="text-center">
                        <Button
                          className="mt-4"
                          color="info"
                          type="button"
                          onClick={() => this.handleSubmitCompany()}
                        >
                          {i18n.t("auth.registratie.submit_btn2")}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
                <Row className="mt-3">
                  <Col xs="6">
                    <Link to={urls.login} className="text-muted">
                      <small>{i18n.t("auth.registratie.inloggen")}</small>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
}

export default Register;
