import {FormGroup} from "reactstrap";
import i18n from "../../variables/translations/i18n";
import React from "react";

const Toggle = (props) => {
  return(
    <FormGroup className={props.containerClass}>
      <label className="form-control-label align-top mr-3">
        <i className={props.icon} aria-hidden="true"/>
        {props.label}
      </label>
      {props.break}
      <label className={props.className}>
        <input checked={props.checked} type="checkbox" onChange={props.onToggleChange}
        />
        <span
          className="custom-toggle-slider rounded-circle"
          data-label-off={i18n.t("dashboard.facturen.tabel.kosten.nee")}
          data-label-on={i18n.t("dashboard.facturen.tabel.kosten.ja")}
        />
      </label>
    </FormGroup>
  );
}

export default Toggle