import React from "react";
import { Table } from "reactstrap";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";

export const PricingTable = () => {
  const { t } = useTranslation();
  return (
    <Table className="mt-5 w-100" responsive>
      <Fade delay={200}>
        <thead className="w-100">
          <tr>
            <th className="px-0 bg-transparent" scope="col">
              <span className="text-dark font-weight-700">
                {t("prijzen_pagina.tabel.functies")}
              </span>
            </th>
            <th className="text-center bg-transparent" scope="col">
              {t("prijzen_pagina.tabel.larva")}
            </th>
            <th className="text-center bg-transparent" scope="col">
              {t("prijzen_pagina.tabel.hive")}
            </th>
            <th className="text-center bg-transparent" scope="col">
              {t("prijzen_pagina.tabel.factory")}
            </th>
          </tr>
        </thead>
      </Fade>
      <tbody>
        {t("prijzen_pagina.tabel.features", {
          returnObjects: true,
        }).map((data, i) => (
          <Fade delay={200 + i * 100}  key={i}>
            <tr key={i}>
              <td className="px-0">{data.titel}</td>
              <td className="text-center">
                {data.larva ? <i className="fas fa-check text-success" /> : "-"}
              </td>
              <td className="text-center">
                {data.hive ? <i className="fas fa-check text-success" /> : "-"}
              </td>
              <td className="text-center">
                {data.factory ? (
                  <i className="fas fa-check text-success" />
                ) : (
                  "-"
                )}
              </td>
            </tr>
          </Fade>
        ))}
      </tbody>
    </Table>
  );
};
