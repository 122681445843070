const base = process.env.REACT_APP_BASEURL;
const dashboardUrl = "/dashboard";
const authUrl = "/auth";
const demoUrl = "/demo";

module.exports = {
  base: base,
  dashboard: dashboardUrl,
  auth: authUrl,
  login: authUrl + "/login",
  registerCompany: authUrl + "/register",
  forgotPassword: authUrl + "/reset",
  projects: dashboardUrl + "/projecten",
  team: dashboardUrl + "/team",
  acceptInvite: authUrl + "/uitnodiging/",
  profile: dashboardUrl + "/profiel",
  support: dashboardUrl + "/support",
  company: dashboardUrl + "/bedrijf",
  subscription: dashboardUrl + "/abonnement",
  payment: dashboardUrl + "/betaling",
  cancelSubscription: dashboardUrl + "/opzeggen",
  renewSubscription: dashboardUrl + "/hernieuwen",
  upgradeSubscription: dashboardUrl + "/upgraden",
  downgradeSubscription: dashboardUrl + "/downgraden",
  settings: dashboardUrl + "/instellingen",
  urenregistratie: dashboardUrl + "/urenregistratie",
  offdays: dashboardUrl + "/verlof",
  offdayRequests: dashboardUrl + "/aanvragen",
  demoDashboard: demoUrl + "/urenregistratie",
  pricing: "/prijzen",
  contact: "/contact",
  about: "/over",
  privacy: "/privacy",
  overzichtPerProject: dashboardUrl + "/project/",
  overzichtPerUser: dashboardUrl + "/gebruiker/",
  stripePayment: "/price/",
  stripeSubscription: "/ideal/recurring/subscribe",
  stripeSubscriptionSetup: "/ideal/recurring/setup",
  stripeSubscriptionInfo: "/subscription/",
  stripeSubscriptionCancel: "/subscription/cancel/",
  stripeSubscriptionRenew: "/subscription/renew/",
  stripeSubscriptionUpgrade: "/subscription/upgrade/",
  stripePromotionCodeChecker: "/promotion/check/",
  stripePaymentConfirmUrl: base + dashboardUrl + "/betaling/",
};
