import React from "react";
import i18n from "../../../../variables/translations/i18n";
import { Container } from "reactstrap";
import { Redirect } from "react-router";
import urls from "urls";
import SimpleHeader from "../../../../components/Headers/SimpleHeader";
import {isAdminPage} from "../../../functions/generic";
import AlertWithCancel from "../../../../components/Notifications/AlertWithCancel";
import PaymentLoading from "../../../../components/Loading/payment";
import {
  fetchSubscription,
  updateSubscription,
  upgradeSubscription
} from "../../../functions/stripe/subscription";
import moment from "moment";

class UpgradeSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: localStorage.getItem("compid"),
      showDowngradeAlert: true,
      proceedWithDowngrade: null,
      downgradeSucceeded: null,
      authorized: this.props.location.authorized,
      subscriptionType: this.props.location.subscriptionType,
      duration: this.props.location.duration,
      priceId: this.props.location.priceId,
      isLoading: false,
    };
  }

  /**
   * Downgrade subscription in Payment API
   */
  confirmDowngradeSubscription() {
    this.setState({proceedWithDowngrade: true, showDowngradeAlert: null, isLoading:true})
    fetchSubscription(this.state.companyId).then((subscription) => {
      upgradeSubscription(subscription.subscriptionId, this.state.priceId)
        .then(() => {
          this.updateSubscription()
          this.setState({isLoading: false, downgradeSucceeded: true})
        })
        .catch(() => this.setState({isLoading: false, downgradeSucceeded: false}))
    })
  }

  /**
   * Update subscription in Firebase
   */
  updateSubscription() {
    let subscription = {
      priceId: this.state.priceId, 
      subscriptionType: this.state.subscriptionType,
      duration: this.state.duration,
      createdAt: moment().unix(),
    }
    updateSubscription(this.state.companyId, subscription)
  }

  render() {
    const { downgradeSucceeded, showDowngradeAlert, authorized, proceedWithDowngrade, isLoading } = this.state;
    if (downgradeSucceeded === true) return <Redirect to={{pathname: urls.subscription, paymentDone: true}} />;
    if (downgradeSucceeded === false) return <Redirect to={{pathname: urls.subscription, paymentDone: false}} />;
    if (!authorized || proceedWithDowngrade === false) return <Redirect to={{pathname: urls.subscription}} />;
    return (
      <>
        <SimpleHeader
          name={i18n.t("dashboard.abonnement.abonnement_titel")}
          parentName={i18n.t("dashboard.bedrijf")}
          isAdminPage={isAdminPage(this.props.location.pathname)}
        />
        <Container className="mt--8 pb-5">
          {isLoading &&
          <PaymentLoading/>
          }
          {showDowngradeAlert &&
          <AlertWithCancel
            type="info"
            title={i18n.t("dashboard.abonnement.downgraden_bevestigings_vraag_titel")}
            description={i18n.t("dashboard.abonnement.downgraden_bevestigings_vraag_descr")}
            confirmBtnText={i18n.t("dashboard.abonnement.abonnement_downgraden_btn")}
            confirmAlert={() => this.confirmDowngradeSubscription()}
            confirmBtnBsStyle="info"
            closeAlert={() => this.setState({proceedWithDowngrade: false, showDowngradeAlert: null})}
            cancelBtnText={i18n.t("dashboard.sluiten")}
          />
          }
        </Container>
      </>
    );
  }
}

export default UpgradeSubscription;
