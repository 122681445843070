import React from "react";
// react library for routing
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";
import firebase from "firebase";
import { getCompanySettings } from "../../views/functions/firebase/company";
import i18n from "i18next";
import Skeleton from "react-loading-skeleton";
import urls from "../../urls";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      ...this.getCollapseStates(props.routes),
    };
  }

  componentDidMount() {
    this.closeSidenav();
    let self = this;
    /** Check if user is authenticated, otherwise redirect to login screen */
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        let userData = firebase.database().ref("users/" + user.uid);
        userData.on("value", (snapshot) => {
          /** Set Name and UID to localstorage so we won't have to call firebase for that all the time */
          let obj = snapshot.val();
          if (!!obj) {
            self.setState({ role: obj.role });
          }
        });
        let companyId = localStorage.getItem("compid");
        getCompanySettings(companyId).then((companySettings) => {
          self.setState(companySettings);
        });
      }
    });
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // makes the sidenav normal on hover (actually when mouse enters on it)
  onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  closeSidenav = () => {
    if (window.innerWidth < 1200) {
      this.props.toggleSidenav();
    }
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes, isAdmin) => {
    return routes.map((prop, key) => {
      if (prop.redirect || prop.invisible) {
        return null;
      }
      if (prop.adminOnly && this.state.role !== "admin") {
        return null;
      } else if (prop.adminOnly && !isAdmin) {
        return null;
      } else if (!prop.adminOnly && isAdmin) {
        return null;
      }
      // Admin Modules
      if (
        prop.name === i18n.t("dashboard.navbar.admin.aanvragen") &&
        !this.state.offDayModule
      ) {
        return null;
      } else if (
        prop.name === i18n.t("dashboard.navbar.admin.uren") &&
        !this.state.hoursModule
      ) {
        return null;
      } else if (
        prop.name === i18n.t("dashboard.navbar.admin.kosten") &&
        !this.state.costsOverviewModule
      ) {
        return null;
      } else if (
        prop.name === i18n.t("dashboard.navbar.admin.facturen") &&
        !this.state.invoiceModule
      ) {
        return null;
      } else if (
        prop.name === i18n.t("dashboard.navbar.admin.klanten") &&
        !this.state.clientModule
      ) {
        return null;
      } else if (
        prop.name === i18n.t("dashboard.navbar.admin.projecten") &&
        !this.state.projectOverviewModule
      ) {
        return null;
      } else if (
        prop.name === i18n.t("dashboard.navbar.admin.team") &&
        !this.state.teamOverviewModule
      ) {
        return null;
      }

      // User Modules
      if (
        prop.name === i18n.t("dashboard.navbar.urenregistratie") &&
        !this.state.hoursModule
      ) {
        return null;
      } else if (
        prop.name === i18n.t("dashboard.navbar.verlof") &&
        !this.state.offDayModule
      ) {
        return null;
      }

      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <NavItem key={key}>
            <NavLink
              href="#"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              className={classnames({
                active: this.getCollapseInitialState(prop.views),
              })}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">{prop.name}</span>
                </>
              ) : prop.miniName ? (
                <>
                  <span className="sidenav-mini-icon"> {prop.miniName} </span>
                  <span className="sidenav-normal"> {prop.name} </span>
                </>
              ) : null}
            </NavLink>
            <Collapse isOpen={this.state[prop.state]}>
              <Nav className="nav-sm flex-column">
                {this.createLinks(prop.views)}
              </Nav>
            </Collapse>
          </NavItem>
        );
      }
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            activeClassName=""
            onClick={this.closeSidenav}
            className={this.activeRoute(prop.layout + prop.path)}
            tag={NavLinkRRD}
          >
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <span className="nav-link-text">{prop.name}</span>
              </>
            ) : prop.miniName !== undefined ? (
              <>
                <span className="sidenav-mini-icon"> {prop.miniName} </span>
                <span className="sidenav-normal"> {prop.name} </span>
              </>
            ) : (
              prop.name
            )}
          </NavLink>
        </NavItem>
      );
    });
  };
  render() {
    const { routes, logo, subscriptionType} = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: "#",
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    const scrollBarInner = (
      <div className="scrollbar-inner">
        <div className="sidenav-header d-flex align-items-center">
          {logo ? (
            <NavbarBrand {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc.default}
              />
            </NavbarBrand>
          ) : null}
          <div className="ml-auto">
            <div
              className={classnames("sidenav-toggler d-none d-xl-block", {
                active: this.props.sidenavOpen,
              })}
              onClick={this.props.toggleSidenav}
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner">
          {this.props.isLoading && (
            <div style={{ lineHeight: 2.5 }}>
              <Skeleton count={10} height={40} />
            </div>
          )}
          <Collapse navbar isOpen={true}>
            <Nav navbar>{this.createLinks(routes, false)}</Nav>
            {this.state.role === "admin" && !this.props.isLoading && (
              <div>
                <hr className="my-3" />
                <h6 className="navbar-heading p-0 text-muted">
                  <span className="docs-normal">Admin</span>
                  <span className="docs-mini">A</span>
                </h6>
              </div>
            )}
            <Nav navbar>{this.createLinks(routes, true)}</Nav>
            {this.state.role === "admin" && !this.props.isLoading && (
              <Subscription createLinks={this.createLinks} subscriptionType={subscriptionType}/>
            )}
          </Collapse>
        </div>
      </div>
    );
    return (
      <Navbar
        className={
          "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white " +
          (this.props.rtlActive ? "" : "fixed-left")
        }
        onMouseEnter={this.onMouseEnterSidenav}
        onMouseLeave={this.onMouseLeaveSidenav}
      >
        {navigator.platform.indexOf("Win") > -1 ? (
          <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
        ) : (
          scrollBarInner
        )}
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false,
  rtlActive: false,
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.object.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
};

const Subscription = (props) => {
  const larva_pack = [
    {
      path: "/abonnement",
      name: i18n.t("prijzen_pagina.larva_pack.titel"),
      miniName: i18n.t("prijzen_pagina.larva_pack.naam_small"),
      icon: "far fa-star text-info",
      layout: urls.dashboard,
      adminOnly: true,
    }
  ]
  const hive_pack = [
    {
      path: "/abonnement",
      name: i18n.t("prijzen_pagina.hive_pack.titel"),
      miniName: i18n.t("prijzen_pagina.hive_pack.naam_small"),
      icon: "far fa-star text-cards",
      layout: urls.dashboard,
      adminOnly: true,
    }
  ]
  const factory_pack = [
    {
      path: "/abonnement",
      name: i18n.t("prijzen_pagina.honey_factory_pack.titel"),
      miniName: i18n.t("prijzen_pagina.honey_factory_pack.naam_small"),
      icon: "far fa-star text-orange",
      layout: urls.dashboard,
      adminOnly: true,
    }
  ]
  const freebee = [
    {
      path: "/abonnement",
      name: i18n.t("dashboard.abonnement.freebee_naam"),
      miniName: i18n.t("dashboard.abonnement.freebee_naam_small"),
      icon: "far fa-star text-info",
      layout: urls.dashboard,
      adminOnly: true,
    }
  ]
  return (
    <>
      <div>
        <hr className="my-3" />
        <h6 className="navbar-heading p-0 text-muted">
          <span className="docs-normal">{i18n.t("dashboard.abonnement.abonnement_titel")}</span>
          <span className="docs-mini">{i18n.t("dashboard.abonnement.abonnement_afkorting")}</span>
        </h6>
      </div>
      {props.subscriptionType === "larva" && <Nav navbar>{props.createLinks(larva_pack, true)}</Nav>}
      {props.subscriptionType === "hive" && <Nav navbar>{props.createLinks(hive_pack, true)}</Nav>}
      {props.subscriptionType === "factory" && <Nav navbar>{props.createLinks(factory_pack, true)}</Nav>}
      {!props.subscriptionType && <Nav navbar>{props.createLinks(freebee, true)}</Nav>}
    </>
  );
}
 
export default Sidebar;
