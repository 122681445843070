import moment from "moment";
import "moment-business-days";
import _ from "lodash";

const currentYear = moment().year();

/**
 * Calculate off days balance of an user
 * @param offDayArray  Array containing the Firebase 'offdays' object
 * @param initialOffDays The initial off days of the user
 * @returns {{
 * usedHours: number of used off days in hours,
 * usedDays: number of used off days,
 * remainingDays: number of remaining  off days
 * }}
 */
function calculateOffDayBalance(offDayArray, initialOffDays) {
  let acceptedOffDaysOfThisYear = filterOffDays(offDayArray);
  // If there no accepted off day requests, return initial state
  if (acceptedOffDaysOfThisYear.length < 1) {
    return {
      usedHours: 0,
      usedDays: 0,
      remainingDays: initialOffDays,
    };
  }

  let usedHours = calculateOffDaysInHours(acceptedOffDaysOfThisYear);
  let usedDays = Math.round((usedHours / 8) * 100) / 100;
  let remainingDays = Math.round((initialOffDays - usedDays) * 100) / 100;
  return {
    usedHours: !!usedHours ? usedHours : 0,
    usedDays: !!usedDays ? usedDays : 0,
    remainingDays: !!remainingDays ? remainingDays : 0,
  };
}

const calculateOffDaysInHours = (offDays, year) => {
  let offDaysInHours = [];
  let requestYear = year || currentYear;
  Array.from(offDays).forEach((entry) => {
    switch (entry.type) {
      case "day":
        let businessDays = calculateBusinessDaysFromPeriod(
          entry.day,
          entry.day
        );
        let workingHours = businessDays * 8;
        offDaysInHours.push(workingHours);
        break;
      case "period":
        if (
          isPeriodFullyInThisYear(entry.fromDay, entry.untilDay, requestYear)
        ) {
          // Period is in current year, calculate all days
          let businessDays = calculateBusinessDaysFromPeriod(
            entry.fromDay,
            entry.untilDay
          );
          let workingHours = businessDays * 8;
          offDaysInHours.push(workingHours);
        } else if (doesPeriodIntersectThisYear(entry.fromDay, entry.untilDay)) {
          // Period is partially in current year and partially in other year
          let nextYearFirstOfJan = moment(entry.untilDay).startOf("year");
          let businessDays = calculateBusinessDaysFromPeriod(
            entry.fromDay,
            nextYearFirstOfJan
          );
          let workingHours = businessDays * 8;
          offDaysInHours.push(workingHours);
        }
        break;
      case "dayPeriod":
        let periodBusinessDays = calculateBusinessDaysFromPeriod(
          entry.day,
          entry.day
        );
        let periodWorkingHours =
          periodBusinessDays *
          (parseFloat(entry.untilTime) - parseFloat(entry.fromTime));
        offDaysInHours.push(periodWorkingHours);
        break;
      default:
        break;
    }
  });
  return offDaysInHours.reduce((a, b) => a + b, 0);
};

/**
 * Calculate the amount of business days between 2 dates.
 * Note: add 1 day to until date to include it in the calculation of the business day difference
 * @param fromDate
 * @param untilDate
 * @returns {number} the amount of business days
 */
function calculateBusinessDaysFromPeriod(fromDate, untilDate) {
  let fromDay = moment(fromDate).format("DD-MM-YYYY");
  let untilDay = moment(untilDate).add(1, "day").format("DD-MM-YYYY");
  return moment(untilDay, "DD-MM-YYYY").businessDiff(
    moment(fromDay, "DD-MM-YYYY")
  );
}

/**
 * Check if a period is fully in the current year.
 * @param fromDate
 * @param untilDate
 * @returns {boolean} if the period is fully in this year
 */
function isPeriodFullyInThisYear(fromDate, untilDate, year) {
  let requestYear = year || currentYear;
  let momentFromDay = moment(fromDate).year();
  let momentUntilDay = moment(untilDate).year();
  return momentFromDay === requestYear && momentUntilDay === requestYear;
}

/**
 * Check if a period starts in current year and ends in another year.
 * @param fromDate
 * @param untilDate
 * @returns {boolean} if the period starts this year but ends another year
 */
function doesPeriodIntersectThisYear(fromDate, untilDate) {
  let momentFromDay = moment(fromDate).year();
  let momentUntilDay = moment(untilDate).year();
  return momentFromDay === currentYear && momentFromDay !== momentUntilDay;
}

/**
 * Check if user has remaining off days in balance
 * @param timeObject The off day type and the time values
 * @param offDays The off days of the user
 * @param yearlyBalance Amount of total off days for user per year
 * @returns {boolean} if the user has off days available in balance for the request
 */
function hasRemainingOffDaysInBalance(timeObject, offDays, yearlyBalance) {
  let days;
  let requestYear;
  if (timeObject.type === "day" || timeObject.type === "dayPeriod")
    requestYear = moment(timeObject.day).year();
  else {
    if (
      moment(timeObject.fromDay).year() === moment(timeObject.untilDay).year()
    )
      requestYear = moment(timeObject.fromDay).year();
    else
      requestYear = [
        moment(timeObject.fromDay).year(),
        moment(timeObject.untilDay).year(),
      ];
  }
  let offDaysInRequestYear = filterOffDays(offDays, requestYear);
  let usedHours = calculateOffDaysInHours(offDaysInRequestYear, requestYear);
  let usedDays = usedHours / 8;

  switch (timeObject.type) {
    case "dayPeriod":
      let duration =
        parseFloat(timeObject.untilTime) - parseFloat(timeObject.fromTime);
      days = duration / 8;
      break;
    case "day":
      days = 1;
      break;
    case "period":
      let requestIsMultipleYears = typeof requestYear === "object";
      days = calculateBusinessDaysFromPeriod(
        timeObject.fromDay,
        timeObject.untilDay
      );
      if (requestIsMultipleYears) {
        let bothYearsEnoughBalance = true;
        // Check for both years if user has enough offdays in that year
        requestYear.forEach((year, index) => {
          let daysInYear;
          if (index === 0) {
            let endDateOfYear = moment(timeObject.fromDay).endOf("year");
            daysInYear = calculateBusinessDaysFromPeriod(
              timeObject.fromDay,
              endDateOfYear
            );
          } else {
            let startDateOfYear = moment(timeObject.untilDay).startOf("year");
            daysInYear = calculateBusinessDaysFromPeriod(
              startDateOfYear,
              timeObject.untilDay
            );
          }

          let offDaysInYear = filterOffDays(offDays, year);
          let usedDays = calculateOffDaysInHours(offDaysInYear, year) / 8;
          if (usedDays + daysInYear > yearlyBalance)
            bothYearsEnoughBalance = false;
        });
        return bothYearsEnoughBalance;
      }
      break;
    default:
      console.error("No days found");
  }
  return usedDays + days <= yearlyBalance;
}

/**
 * Filter array to only have 'accepted', 'private' and 'current year' off days
 * @param offDayArray Array containing the Firebase 'offdays' object
 * @param year Year to filter the offdays by - OPTIONAL
 * @returns {*} filtered array
 */
function filterOffDays(offDayArray, year) {
  let requestYear = year || currentYear;
  return _.filter(offDayArray, (item) => {
    return (
      item.status === "accepted" &&
      item.isBusinessOffday === false &&
      ((!!item.day && moment(item.day).year() === requestYear) ||
        (!!item.fromDay && moment(item.fromDay).year() === requestYear) ||
        (!!item.untilDay && moment(item.untilDay).year() === requestYear))
    );
  });
}

/**
 * Calculate both the start and end time of an event/offday
 * @param data The event object.
 */
function calculateStartAndEnd(data) {
  let start;
  let end;
  if (data.type === "day") {
    start = data.day;
    end = null;
  } else if (data.type === "dayPeriod") {
    start = moment(data.day + " " + data.fromTime, "YYYY-MM-DD HH:mm").format(
      "YYYY-MM-DDTHH:mm:ss"
    );
    end = moment(data.day + " " + data.untilTime, "YYYY-MM-DD HH:mm").format(
      "YYYY-MM-DDTHH:mm:ss"
    );
  } else {
    start = data.fromDay;
    end = moment(data.untilDay).add(1, "day").format("YYYY-MM-DD");
  }
  return { start: start, end: end };
}

/**
 * Get the sum of total offdays of the entire team
 * @param teamOffDays The default amount of offdays a member gets
 * @param members All individual team members
 * @returns Sum of team offdays
 */
function getSumOffDays(teamOffDays, members) {
  let sum = 0;
  Array.from(Object.values(members)).forEach((entry) => {
    if (!!entry.offdays) sum += parseInt(entry.offdays);
    else sum += parseInt(teamOffDays);
  });
  return sum;
}

export {
  calculateOffDayBalance,
  hasRemainingOffDaysInBalance,
  calculateStartAndEnd,
  getSumOffDays,
};
