import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Redirect, Link } from "react-router-dom";
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  ListGroupItem,
  ListGroup,
  Media,
  Navbar,
  Nav,
  NavItem,
  Container,
  Row,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import firebase from "firebase";
import urls from "../../urls";
import moment from "moment";
import beeImage from "assets/img/theme/dash.png";
import nlFlag from "assets/img/icons/common/nl.png";
import enFlag from "assets/img/icons/common/en.png";
import i18n from "variables/translations/i18n";
import i18next from "i18next";

class DashboardNavbar extends React.Component {
  state = {
    userName: localStorage.getItem("userName"),
    userId: localStorage.getItem("uid"),
    companyId: localStorage.getItem("compid"),
    activeLanguage: i18next.language,
    role: null,
    profilePic: null,
    redirectLogin: false,
    notifications: null,
    notificationAmount: 0,
    linkToNotification: false,
    notificationId: null,
    notificationsTabOpen: false,
    languageTabOpen: false,
  };

  componentDidMount() {
    this.getUserInfo();
  }

  getUserInfo() {
    let self = this;
    /** After logging in, uid is not yet available so get auth user first */
    if (!localStorage.getItem("uid")) {
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          self.setUserInfo(user.uid);
        }
      });
    } else {
      self.setUserInfo(self.state.userId);
    }
  }

  getNotifications() {
    if (!!this.state.userId) {
      let notifications = firebase
        .database()
        .ref("notifications")
        .orderByChild("userId")
        .equalTo(this.state.userId);
      notifications.on("value", (snapshot) => {
        let data = snapshot.val();
        let counter = 0;
        if (!!data) {
          let sortedData = {};
          Object.entries(data)
            // Sort by created_at; new items first
            .sort((a, b) => {
              return b[1].created_at - a[1].created_at;
            })
            .map(function (item, i) {
              sortedData[item[0]] = item[1];
              counter++;
            });
          this.setState({
            notifications: sortedData,
            notificationAmount: counter,
          });
        } else this.setState({ notifications: null, notificationAmount: 0 });
      });
    }
  }

  // TODO VERLOF REQUEST to /Notifications
  getAdminNotifications() {
    if (!!this.state.companyId) {
      let requests = firebase
        .database()
        .ref("notifications")
        .orderByChild("companyId")
        .equalTo(this.state.companyId);
      requests.on("value", (snapshot) => {
        let data = snapshot.val();
        let counter = 0;
        if (!!data) {
          let sortedData = {};
          Object.entries(data)
            // Sort by created_at; new items first
            .sort((a, b) => {
              return b[1].created_at - a[1].created_at;
            })
            .map(function (item, i) {
              sortedData[item[0]] = item[1];
              counter++;
            });
          this.setState({
            notifications: sortedData,
            notificationAmount: counter,
          });
        } else this.setState({ notifications: null, notificationAmount: 0 });
      });
    }
  }

  setUserInfo(uid) {
    let user = firebase.database().ref("users/" + uid);
    user.on("value", (snapshot) => {
      let user = snapshot.val();
      if (!!user) {
        this.setState({
          profilePic: user.picture,
          userName: user.name,
          role: user.role,
        });
        if (user.role === "admin") this.getAdminNotifications();
        else this.getNotifications();
      }
    });
  }

  notify = (type, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Error
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  logout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem("userName");
        localStorage.removeItem("uid");
        localStorage.removeItem("compid");
        this.setState({ redirectLogin: true });
      })
      .catch((error) => {
        console.error(error);
        this.notify("danger", error);
      });
  }

  changeLanguage(lang) {
    i18next.changeLanguage(lang);
    moment.locale(i18next.language);
  }

  deleteNotification(id) {
    firebase
      .database()
      .ref("notifications/" + id)
      .remove();
  }

  getNotificationTitle(type) {
    if (type === "result-offday")
      return i18n.t("dashboard.notificaties.aanvraag");
    if (type === "request-offday")
      return i18n.t("dashboard.notificaties.nieuw_aanvraag_titel");
  }

  getNotificationText(notification) {
    if (notification.result === "accepted")
      return i18n.t("dashboard.notificaties.aanvraag_geaccepteerd");
    if (notification.result === "denied")
      return i18n.t("dashboard.notificaties.aanvraag_geweigerd");
    if (notification.type === "request-offday")
      return i18n.t("dashboard.notificaties.nieuw_aanvraag_desc");
  }

  getNotificationTime(timestamp) {
    let formatted = moment(timestamp).fromNow();
    return formatted;
  }

  getActiveFlag() {
    if (i18next.language === "nl") return nlFlag;
    else return enFlag;
  }

  render() {
    if (this.state.redirectLogin) return <Redirect to={urls.login} />;
    const langs = [
      { label: "English", value: "en", icon: enFlag },
      { label: "Nederlands", value: "nl", icon: nlFlag },
    ];
    return (
      <>
        <Navbar
          className={classnames(
            "navbar-top navbar-expand border-bottom",
            { "navbar-dark bg-gradient-info": this.props.theme === "dark" },
            { "navbar-light": this.props.theme === "light" }
          )}
        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler",
                      { active: this.props.sidenavOpen },
                      { "sidenav-toggler-dark": this.props.theme === "dark" }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div>
                  </div>
                </NavItem>
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    className="nav-link"
                    color=""
                    tag="a"
                    onClick={() =>
                      this.setState({ notificationsTabOpen: true })
                    }
                  >
                    <i className="ni ni-bell-55">
                      {this.state.notificationAmount > 0 && (
                        <span className="nav-notification">.</span>
                      )}
                    </i>
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu-xl py-0 overflow-hidden"
                    right
                  >
                    <div className="px-3 py-3">
                      <h6 className="text-sm text-muted m-0">
                        {i18n.t("dashboard.navbar.meldingen1")}
                        <strong className="text-info">
                          {this.state.notificationAmount}
                        </strong>{" "}
                        {i18n.t("dashboard.navbar.meldingen2")}
                      </h6>
                    </div>

                    <ListGroup flush>
                      {!!this.state.notifications &&
                        Object.entries(this.state.notifications).map(
                          (notification, i) => {
                            return (
                              <Link
                                to={
                                  this.state.role === "admin"
                                    ? {
                                        pathname: urls.offdayRequests,
                                      }
                                    : {
                                        pathname: urls.offdays,
                                        notification: notification,
                                      }
                                }
                                onClick={() =>
                                  this.deleteNotification(notification[0])
                                }
                                key={i}
                              >
                                <ListGroupItem
                                  className="list-group-item-action"
                                  href="#"
                                  tag="a"
                                >
                                  <Row className="align-items-center">
                                    <div className="col ml-2">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                          <h4 className="mb-0 text-sm">
                                            {this.getNotificationTitle(
                                              notification[1].type
                                            )}
                                          </h4>
                                        </div>
                                        <div className="text-right text-muted">
                                          <small>
                                            {this.getNotificationTime(
                                              notification[1].created_at
                                            )}
                                          </small>
                                        </div>
                                      </div>
                                      <p className="text-sm mb-0">
                                        {this.getNotificationText(
                                          notification[1]
                                        )}
                                      </p>
                                    </div>
                                  </Row>
                                </ListGroupItem>
                              </Link>
                            );
                          }
                        )}
                    </ListGroup>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    className="nav-link"
                    color=""
                    tag="a"
                    onClick={() => this.setState({ languageTabOpen: true })}
                  >
                    <img
                      alt="flag"
                      style={{ width: "20px" }}
                      src={this.getActiveFlag()}
                    />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">
                        {i18n.t("dashboard.taal_kiezen")}
                      </h6>
                    </DropdownItem>
                    {langs.map((item, i) => {
                      return (
                        item.value !== i18next.language && (
                          <DropdownItem key={i}>
                            <a
                              onClick={() => this.changeLanguage(item.value)}
                              href="#"
                            >
                              <img src={item.icon} style={{ width: "30px" }} />
                              <span>{item.label}</span>
                            </a>
                          </DropdownItem>
                        )
                      );
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        <img
                          alt="profielfoto"
                          src={
                            !!this.state.profilePic
                              ? this.state.profilePic
                              : beeImage
                          }
                        />
                      </span>
                      <Media className="ml-2 d-none d-lg-block">
                        <span
                          id="user-name"
                          className="mb-0 text-sm font-weight-bold"
                        >
                          {this.state.userName}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">
                        {i18n.t("dashboard.navbar.welkom")}
                      </h6>
                    </DropdownItem>
                    <DropdownItem>
                      <Link to={urls.profile}>
                        <i className="ni ni-single-02" />
                        <span>{i18n.t("dashboard.navbar.profiel")}</span>
                      </Link>
                    </DropdownItem>
                    {this.state.role === "admin" && (
                      <>
                        <DropdownItem>
                          <Link to={urls.company}>
                            <i className="fa fa-building" />
                            <span>{i18n.t("dashboard.navbar.bedrijf")}</span>
                          </Link>
                        </DropdownItem>
                        <DropdownItem>
                          <Link to={urls.subscription}>
                            <i className="ni ni-lock-circle-open" />
                            <span>{i18n.t("dashboard.navbar.abonnement")}</span>
                          </Link>
                        </DropdownItem>
                      </>
                    )}
                    <DropdownItem>
                      <Link to={urls.settings}>
                        <i className="ni ni-settings-gear-65" />
                        <span>{i18n.t("dashboard.navbar.instellingen")}</span>
                      </Link>
                    </DropdownItem>
                    <DropdownItem>
                      <Link to={urls.support}>
                        <i className="ni ni-chat-round" />
                        <span>Contact & Support</span>
                      </Link>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={(e) => this.logout()}>
                      <i className="ni ni-user-run" />
                      <span>{i18n.t("dashboard.navbar.uitloggen")}</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
      </>
    );
  }
}
DashboardNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
DashboardNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default DashboardNavbar;
