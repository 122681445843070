import React from "react";
import i18n from "../../../../variables/translations/i18n";
import { Col, Container, Row } from "reactstrap";
import urls from "../../../../urls";
import PricingCard from "../../../../components/Subscription/PricingCard";
import {
  getCompanyInfo,
  getCompanyUserCount,
} from "views/functions/firebase/company";
import Loading from "components/Loading/loading";
import { isAdminPage } from "views/functions/generic";
import Button from "reactstrap/lib/Button";
import Alert from "components/Notifications/Alert";
import {
  calculateSubscriptionDays,
  fetchSubscription,
  getSubscriptionInfo
} from "../../../functions/stripe/subscription";
import moment from "moment";
import CardsHeader from "../../../../components/Headers/CardsHeader";
import {PricingTable} from "../../../../components/Tables/PricingTable";
import { PricingFaq } from "components/Faq/PricingFaq";

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      userCount: null,
      companyId: localStorage.getItem("compid"),
      monthlyPricing: true,
      activeSubscription: null,
      paymentSuccessAlert: null,
      paymentFailedAlert: null,
      cancelSuccessAlert: null,
      cancelFailedAlert: null,
      renewSuccessAlert: null,
      renewFailedAlert: null,
      renewalDate: "",
      subscriptionPeriod: "",
      subscriptionStatus: "",
      subscriptionCancelled: null,
      freeBeeDaysInitial: 0,
      freeBeeDaysUsed: 0,
      freeBeeDaysRest: 0,
    };
  }

  /**
   * Check if subscription/payment had been processed.
   * Show modal based on paymentDone property
   */
  checkPaymentStatus() {
    if (typeof this.props.location.paymentDone !== "undefined") {
      this.props.location.paymentDone ? 
        this.setState({ paymentSuccessAlert: true }) :
        this.setState({ paymentFailedAlert: true })
    } else if (typeof this.props.location.cancelDone !== "undefined") {
      this.props.location.cancelDone ? 
        this.setState({ cancelSuccessAlert: true }) : 
        this.setState({ cancelFailedAlert: true })
    } else if (typeof this.props.location.renewDone !== "undefined") { 
      this.props.location.renewDone ? 
        this.setState({ renewSuccessAlert: true }) :
        this.setState({ renewFailedAlert: true })
    }
  }

  componentDidMount() {
    this.checkPaymentStatus();
    getCompanyUserCount(this.state.companyId).then((count) => {
      this.setState({ userCount: count, isLoading: false });
    });
    fetchSubscription(this.state.companyId).then((subscription) => {
      let hasCoupCode = !!subscription.couponCreatedAt
      if (subscription.subscriptionId !== undefined) {
        getSubscriptionInfo(subscription.subscriptionId).then((subscriptionInfo) => {
          let renewalDate;
          if (hasCoupCode) { 
            renewalDate = moment.unix(subscriptionInfo.renewalDate).add(subscription.couponDuration, 'months').format("DD-MM-YYYY")
          } else {
            renewalDate = moment.unix(subscriptionInfo.renewalDate).format("DD-MM-YYYY")
          }
          let freeBee;
          if (subscriptionInfo.cancelledAt) {
            this.setState({subscriptionCancelled: true})
          }
          if (subscriptionInfo.status === "canceled") {
            this.setState({
              subscriptionStatus: subscriptionInfo.status,
              activeSubscription: null,
            })
          } else if (subscriptionInfo.status === "active") {
              freeBee = calculateSubscriptionDays(true, hasCoupCode, subscriptionInfo.renewalDate, subscription.couponCreatedAt, subscription.couponDuration, null, subscription.createdAt)
            this.setState({
              subscriptionStatus: subscriptionInfo.status,
              activeSubscription: subscription.subscriptionType,
            })
          }
          this.setState({
            renewalDate: renewalDate,
            subscriptionPeriod: subscription.duration,
            freeBeeDaysInitial: freeBee.totalDays,
            freeBeeDaysUsed: freeBee.usedDays,
            freeBeeDaysRest: freeBee.restDays,
            monthlyPricing: subscription.duration !== "year"
          })
        }).catch(() => {
          getCompanyInfo(this.state.companyId).then((company) => {
            // No Stripe Subscription
            let freeBee;
            hasCoupCode ?
              freeBee = calculateSubscriptionDays(false, true, null, subscription.couponCreatedAt, subscription.couponDuration, null)
              :
              freeBee = calculateSubscriptionDays(false, false, null,null, null, company.created_at)
            this.setState({
              freeBeeDaysInitial: freeBee.totalDays,
              freeBeeDaysUsed: freeBee.usedDays,
              freeBeeDaysRest: freeBee.restDays,
            })
          })
        })
      } else {
        // No Stripe Subscription
        let freeBee;
        if (hasCoupCode) {
          freeBee = calculateSubscriptionDays(false, true, null, subscription.couponCreatedAt, subscription.couponDuration, null)
        } else {
          getCompanyInfo(this.state.companyId).then((company) => {
            // No Firebase Subscription Object
            freeBee = calculateSubscriptionDays(false, false, null,null, null, company.created_at)
          })
        }
        this.setState({
          freeBeeDaysInitial: freeBee.totalDays,
          freeBeeDaysUsed: freeBee.usedDays,
          freeBeeDaysRest: freeBee.restDays,
        })
      }
    }).catch(() => {
      getCompanyInfo(this.state.companyId).then((company) => {
        // No Firebase Subscription Object
        let freeBee = calculateSubscriptionDays(false, false, null,null, null, company.created_at)
        this.setState({
          freeBeeDaysInitial: freeBee.totalDays,
          freeBeeDaysUsed: freeBee.usedDays,
          freeBeeDaysRest: freeBee.restDays,
        })
      })
    })
  }

  /**
   * calculate Yearly (pre discount) price 
   * @param price
   * @returns {string}
   */
  getOldPrice(price) {
    let oldPrice = parseFloat(price) * 12;
    return "€" + oldPrice;
  }

  /**
   * Fetch the button text under the pricing cards, depending on the subscription
   * @param pack
   * @returns {{text: string, url: string}|{active: boolean, text: string, url: string}}
   */
  getButtonTextAndUrl(pack) {
    let activeSubscription = this.state.activeSubscription;
    if (activeSubscription === null) {
      return {
        text: i18n.t("dashboard.abonnement.abonnement_kiezen_btn"),
        url: urls.payment + "/" + pack + "/" + (this.state.monthlyPricing ? "month" : "year"),
      };
    } else {
      switch (pack) {
        case "larva":
          if (activeSubscription === "larva")
            return {
              text: this.state.subscriptionCancelled ?
                i18n.t("dashboard.abonnement.abonnement_hernieuwen_btn") :
                i18n.t("dashboard.abonnement.abonnement_opzeggen_btn"),
              url: this.state.subscriptionCancelled ?
                urls.renewSubscription :
                urls.cancelSubscription,
              active: true,
            };
            else if (activeSubscription === "hive")
            return {
              text: i18n.t("dashboard.abonnement.abonnement_downgraden_btn"),
              url: urls.downgradeSubscription,
              active: false
            };
            else if (activeSubscription === "factory")
            return {
              text: i18n.t("dashboard.abonnement.abonnement_downgraden_btn"),
              url: urls.downgradeSubscription,
              active: false
            };
          else
            return {
              text: i18n.t("dashboard.abonnement.abonnement_kiezen_btn"),
              url: urls.payment,
              active: false
            };
        case "hive":
          if (activeSubscription === "hive")
            return {
              text: this.state.subscriptionCancelled ?
                i18n.t("dashboard.abonnement.abonnement_hernieuwen_btn") : 
                i18n.t("dashboard.abonnement.abonnement_opzeggen_btn"),
              url: this.state.subscriptionCancelled ?
                urls.renewSubscription :
                urls.cancelSubscription,
              active: true
            };
          else if (activeSubscription === "larva")
            return {
              text: i18n.t("dashboard.abonnement.abonnement_upgraden_btn"),
              url: urls.upgradeSubscription,
              active: false
            };
          else
            return {
              text: i18n.t("dashboard.abonnement.abonnement_downgraden_btn"),
              url: urls.downgradeSubscription,
              active: false
            };
        case "factory":
          if (activeSubscription === "factory")
            return {
              text: this.state.subscriptionCancelled ?
                i18n.t("dashboard.abonnement.abonnement_hernieuwen_btn") :
                i18n.t("dashboard.abonnement.abonnement_opzeggen_btn"),
              url: this.state.subscriptionCancelled ?
                urls.renewSubscription :
                urls.cancelSubscription,
              active: true
            };
          else if (activeSubscription === "hive")
            return {
              text: i18n.t("dashboard.abonnement.abonnement_upgraden_btn"),
              url: urls.upgradeSubscription,
              active: false
            };
          else if (activeSubscription === "larva")
            return {
              text: i18n.t("dashboard.abonnement.abonnement_upgraden_btn"),
              url: urls.upgradeSubscription,
              active: false
            };
          else
            return {
              text: i18n.t("dashboard.abonnement.abonnement_kiezen_btn"),
              url: urls.payment,
              active: false
            };
      }
    }
  }

  /**
   * Get disabled status of subscription pack
   * @param pack
   * @returns {boolean}
   */
  isDisabled(pack) {
    const { userCount } = this.state;
    switch (pack) {
      case "larva":
        return userCount > 1;
      case "hive":
        return userCount > 20;
      case "factory":
        return userCount > 60;
    }
  }

  render() {
    return (
      <>
        {this.state.paymentSuccessAlert && (
          <Alert
            type="success"
            title={i18n.t("dashboard.abonnement.betaling_geslaagd_titel")}
            description={i18n.t("dashboard.abonnement.betaling_geslaagd_desc")}
            confirmBtnText={i18n.t("dashboard.sluiten")}
            closeAlert={() => this.setState({ paymentSuccessAlert: null })}
          />
        )}
        {this.state.paymentFailedAlert && (
          <Alert
            type={"danger"}
            title={i18n.t("dashboard.abonnement.betaling_mislukt_titel")}
            description={i18n.t("dashboard.abonnement.betaling_mislukt_desc")}
            confirmBtnText={i18n.t("dashboard.sluiten")}
            closeAlert={() => this.setState({ paymentFailedAlert: null })}
          />
        )}
        {this.state.cancelSuccessAlert && (
          <Alert
            type="success"
            title={i18n.t("dashboard.abonnement.opzeggen_geslaagd_titel")}
            description={i18n.t("dashboard.abonnement.opzeggen_geslaagd_desc")}
            confirmBtnText={i18n.t("dashboard.sluiten")}
            closeAlert={() => this.setState({ cancelSuccessAlert: null })}
          />
        )}
        {this.state.cancelFailedAlert && (
          <Alert
            type="danger"
            title={i18n.t("dashboard.abonnement.opzeggen_mislukt_titel")}
            description={i18n.t("dashboard.abonnement.opzeggen_mislukt_desc")}
            confirmBtnText={i18n.t("dashboard.sluiten")}
            closeAlert={() => this.setState({ cancelFailedAlert: null })}
          />
        )}
        {this.state.renewSuccessAlert && (
          <Alert
            type="success"
            title={i18n.t("dashboard.abonnement.hernieuwen_geslaagd_titel")}
            description={i18n.t("dashboard.abonnement.hernieuwen_geslaagd_desc")}
            confirmBtnText={i18n.t("dashboard.sluiten")}
            closeAlert={() => this.setState({ renewSuccessAlert: null })}
          />
        )}
        {this.state.renewFailedAlert && (
          <Alert
            type={"danger"}
            title={i18n.t("dashboard.abonnement.hernieuwen_mislukt_titel")}
            description={i18n.t("dashboard.abonnement.hernieuwen_mislukt_desc")}
            confirmBtnText={i18n.t("dashboard.sluiten")}
            closeAlert={() => this.setState({ renewFailedAlert: null })}
          />
        )}
        <CardsHeader
          parentName={i18n.t("dashboard.bedrijf")}
          name={i18n.t("dashboard.abonnement.abonnement_titel")}
          isAdminPage={isAdminPage(this.props.location.pathname)}
          data={[
            {
              name: i18n.t("dashboard.abonnement.freebee_dagen_resterend"),
              value: this.state.freeBeeDaysRest,
              max: this.state.freeBeeDaysInitial,
              min: this.state.freeBeeDaysUsed,
              tooltip: {
                id: "remaining_days_subscription",
                text: i18n.t("dashboard.abonnement.dagen_resterend_uitleg"),
              },
            },
          ]}
          hideTourIcon={true}
        />
        {!this.state.isLoading ? (
          <Container className="pb-5">
            <Row className="justify-content-center flex-row mb-3 mt-3">
              <Button
                className={!this.state.monthlyPricing && "opacity-5"}
                color="info"
                type="button"
                onClick={() => {
                 this.state.subscriptionPeriod !== "month" && this.setState({ monthlyPricing: !this.state.monthlyPricing })
                }
                }
                disabled={this.state.subscriptionPeriod === "year"}
              >
                {i18n.t("dashboard.abonnement.maandelijks")}
              </Button>
              <Button
                className={this.state.monthlyPricing && "opacity-5"}
                color="cards"
                type="button"
                onClick={() => {
                  this.state.subscriptionPeriod !== "year" && this.setState({ monthlyPricing: !this.state.monthlyPricing })
                } 
                }
                disabled={this.state.subscriptionPeriod === "month"}
              >
                {i18n.t("dashboard.abonnement.jaarlijks")}
              </Button>
            </Row>
            <Row className="justify-content-center">
              <Col lg="12" className="col-lg-14">
                <div className="pricing card-group flex-column flex-md-row mb-3 justify-content-center">
                  <PricingCard
                    id="1"
                    cardTextColor={"text-info"}
                    cardTextSecondColor={""}
                    cardTitle={i18n.t("prijzen_pagina.larva_pack.titel")}
                    subscriptionPrice={
                      this.state.monthlyPricing
                        ? "€" + i18n.t("prijzen_pagina.larva_pack.prijs")
                        : "€" + i18n.t("prijzen_pagina.larva_pack.prijs_yearly")
                    }
                    monthColor={"text-muted"}
                    monthText={i18n.t(
                      "prijzen_pagina.larva_pack.per_gebruiker"
                    )}
                    iconClass={
                      "icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white"
                    }
                    icon={"fas fa-user"}
                    firstBullet={i18n.t("prijzen_pagina.larva_pack.feature1")}
                    firstBulletClass={"pl-2"}
                    secondBulletIconClass={
                      "icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white"
                    }
                    secondBulletIcon={"fas fa-phone"}
                    secondBulleClass={"pl-2"}
                    secondBulletText={i18n.t(
                      "prijzen_pagina.larva_pack.feature2"
                    )}
                    thirdBulletIconClass={
                      "icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white"
                    }
                    thirdBulletIcon={"fa fa-users"}
                    thirdBulletClass={"pl-2"}
                    thirdBulletText={i18n.t(
                      "prijzen_pagina.larva_pack.feature3"
                    )}
                    buttonClass={"info"}
                    buttonUrl={this.getButtonTextAndUrl("larva").url}
                    buttonText={this.getButtonTextAndUrl("larva").text}
                    isDisabled={this.isDisabled("larva")}
                    oldPrice={
                      !this.state.monthlyPricing
                        ? this.getOldPrice(
                            i18n.t("prijzen_pagina.larva_pack.prijs")
                          )
                        : null
                    }
                    authorized={true}
                    priceId={this.state.monthlyPricing ? 
                      process.env.REACT_APP_LARVA_PACK_MONTHLY : 
                      process.env.REACT_APP_LARVA_PACK_YEARLY
                    }
                    subscriptionType={"larva"}
                    duration={this.state.monthlyPricing ? "month" : "year"}
                    active={this.getButtonTextAndUrl("larva").active}
                    activeColor={"info"}
                    activeColorText={"text-info"}
                    renewalDate={this.state.renewalDate}
                    renewalText={this.state.subscriptionCancelled ?
                      i18n.t("prijzen_pagina.automatisch_aflopen")
                      : i18n.t("prijzen_pagina.automatisch_verlengd")}
                    subscriptionPeriod={this.state.subscriptionPeriod === "month" ? 
                      i18n.t("prijzen_pagina.maand_abonnement") :
                      i18n.t("prijzen_pagina.jaar_abonnement")}
                    cancelled={this.state.subscriptionCancelled}
                  />
                  <PricingCard
                    id="2"
                    cardTextColor={"text-cards"}
                    cardTextSecondColor={""}
                    cardTitle={i18n.t("prijzen_pagina.hive_pack.titel")}
                    subscriptionPrice={
                      this.state.monthlyPricing
                        ? "€" + i18n.t("prijzen_pagina.hive_pack.prijs")
                        : "€" + i18n.t("prijzen_pagina.hive_pack.prijs_yearly")
                    }
                    monthColor={"text-muted"}
                    monthText={i18n.t("prijzen_pagina.hive_pack.per_gebruiker")}
                    iconClass={
                      "icon icon-xs icon-shape bg-cards shadow rounded-circle text-white"
                    }
                    icon={"fas fa-home"}
                    firstBullet={i18n.t("prijzen_pagina.hive_pack.feature1")}
                    firstBulletClass={"pl-2"}
                    secondBulletIconClass={
                      "icon icon-xs icon-shape bg-cards shadow rounded-circle text-white"
                    }
                    secondBulletIcon={"fas fa-phone"}
                    secondBulleClass={"pl-2"}
                    secondBulletText={i18n.t(
                      "prijzen_pagina.hive_pack.feature2"
                    )}
                    thirdBulletIconClass={
                      "icon icon-xs icon-shape bg-cards shadow rounded-circle text-white"
                    }
                    thirdBulletIcon={"fas fa-users"}
                    thirdBulletClass={"pl-2"}
                    thirdBulletText={i18n.t(
                      "prijzen_pagina.hive_pack.feature3"
                    )}
                    buttonClass={"cards"}
                    buttonUrl={this.getButtonTextAndUrl("hive").url}
                    buttonText={this.getButtonTextAndUrl("hive").text}
                    isDisabled={this.isDisabled("hive")}
                    oldPrice={
                      !this.state.monthlyPricing
                        ? this.getOldPrice(
                            i18n.t("prijzen_pagina.hive_pack.prijs")
                          )
                        : null
                    }
                    authorized={true}
                    priceId={this.state.monthlyPricing ?
                      process.env.REACT_APP_HIVE_PACK_MONTHLY :
                      process.env.REACT_APP_HIVE_PACK_YEARLY
                    }
                    subscriptionType={"hive"}
                    duration={this.state.monthlyPricing ? "month" : "year"}
                    active={this.getButtonTextAndUrl("hive").active}
                    activeColor={"success"}
                    activeColorText={"text-cards"}
                    renewalDate={this.state.renewalDate}
                    renewalText={this.state.subscriptionCancelled ?
                      i18n.t("prijzen_pagina.automatisch_aflopen")
                      : i18n.t("prijzen_pagina.automatisch_verlengd")}
                    subscriptionPeriod={this.state.subscriptionPeriod === "month" ?
                      i18n.t("prijzen_pagina.maand_abonnement") :
                      i18n.t("prijzen_pagina.jaar_abonnement")}
                    cancelled={this.state.subscriptionCancelled}
                  />
                  <PricingCard
                    id="3"
                    cardTextColor={"text-orange"}
                    cardTextSecondColor={""}
                    cardTitle={i18n.t(
                      "prijzen_pagina.honey_factory_pack.titel"
                    )}
                    subscriptionPrice={
                      this.state.monthlyPricing
                        ? "€" +
                          i18n.t("prijzen_pagina.honey_factory_pack.prijs")
                        : "€" +
                          i18n.t(
                            "prijzen_pagina.honey_factory_pack.prijs_yearly"
                          )
                    }
                    monthColor={"text-muted"}
                    monthText={i18n.t(
                      "prijzen_pagina.honey_factory_pack.per_gebruiker"
                    )}
                    iconClass={
                      "icon icon-xs icon-shape bg-orange shadow rounded-circle text-white"
                    }
                    icon={"fas fa-building"}
                    firstBullet={i18n.t(
                      "prijzen_pagina.honey_factory_pack.feature1"
                    )}
                    firstBulletClass={"pl-2"}
                    secondBulletIconClass={
                      "icon icon-xs icon-shape bg-orange shadow rounded-circle text-white"
                    }
                    secondBulletIcon={"fas fa-phone"}
                    secondBulleClass={"pl-2"}
                    secondBulletText={i18n.t(
                      "prijzen_pagina.honey_factory_pack.feature2"
                    )}
                    thirdBulletIconClass={
                      "icon icon-xs icon-shape bg-orange shadow rounded-circle text-white"
                    }
                    thirdBulletIcon={"fas fa-users"}
                    thirdBulletClass={"pl-2"}
                    thirdBulletText={i18n.t(
                      "prijzen_pagina.honey_factory_pack.feature3"
                    )}
                    buttonClass="orange"
                    buttonUrl={this.getButtonTextAndUrl("factory").url}
                    buttonText={this.getButtonTextAndUrl("factory").text}
                    isDisabled={this.isDisabled("factory")}
                    oldPrice={
                      !this.state.monthlyPricing
                        ? this.getOldPrice(
                            i18n.t("prijzen_pagina.honey_factory_pack.prijs")
                          )
                        : null
                    }
                    authorized={true}
                    priceId={this.state.monthlyPricing ?
                      process.env.REACT_APP_FACTORY_PACK_MONTHLY :
                      process.env.REACT_APP_FACTORY_PACK_YEARLY
                    }
                    subscriptionType={"factory"}
                    duration={this.state.monthlyPricing ? "month" : "year"}
                    active={this.getButtonTextAndUrl("factory").active}
                    activeColor={"warning"}
                    activeColorText={"text-orange"}
                    renewalDate={this.state.renewalDate}
                    renewalText={this.state.subscriptionCancelled ?
                      i18n.t("prijzen_pagina.automatisch_aflopen")
                      : i18n.t("prijzen_pagina.automatisch_verlengd")}
                    subscriptionPeriod={this.state.subscriptionPeriod === "month" ?
                      i18n.t("prijzen_pagina.maand_abonnement") :
                      i18n.t("prijzen_pagina.jaar_abonnement")}
                    cancelled={this.state.subscriptionCancelled}
                  />
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-lg-center px-3">
              <Col lg="8">
                <p className="text-muted text-xs">
                  {i18n.t("prijzen_pagina.excluding_tax")}
                </p>
              </Col>
            </div>
            <Row className="row-grid justify-content-center">
              <PricingTable/>
            </Row>
            <PricingFaq />
          </Container>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

export default Subscription;
