import {FIREBASE_STORAGE_IMAGE_PATH, IMAGE_COMPRESSION_QUALITY} from "../../constants/ImagesConstants";
import firebase from "firebase";
import Compressor from "compressorjs";
import { v4 as uuidv4 } from "uuid";

/**
 * Compress and return image
 * @param {*} image The file to be compressed
 * @returns image Compressed Image
 */
export const compressImage = (image) => {
  return new Promise((resolve, reject) => {
    new Compressor(image, {
      quality: IMAGE_COMPRESSION_QUALITY,
      error: (error) => reject(error),
      success: (compressedResult) => resolve(compressedResult)
    });
  });
};

/**
 * Get Firebase Storage Image Reference from file path/url
 * @param url Path to file
 * @returns imageReference Reference to Firebase Storage Image
 */
export const getReferenceFromUrl = (url) => {
  return new Promise(function (resolve) {
    const imageRef = firebase.storage().refFromURL(url)
    resolve(imageRef)
  })
}

/**
 * Compress image and upload to Firebase Storage 
 * @param file
 * @returns filePath Path to file in FirebaseStorage 
 */
export const uploadImage = (file) => {
  return new Promise(function (resolve, reject) {
    if (!!file && file instanceof File) {
        compressImage(file).then((compressedImage) => {
          const storageRef = firebase.storage().ref();
          const imageRef = storageRef.child(FIREBASE_STORAGE_IMAGE_PATH + uuidv4());
          imageRef.put(compressedImage).then((snapshot) => {
            snapshot.ref.getDownloadURL().then((downloadURL) => resolve(downloadURL))
          }).catch((err) => reject(err))
        }).catch((err) => reject(err))
    } else resolve(null);
  })
}

/**
 * Remove image from Firebase Storage
 * @param url Path to file in FirebaseStorage
 */
export const deleteImage = (url) => {
  getReferenceFromUrl(url).then((imageRef) => {
    imageRef.delete().catch((error) => console.log(error));
  })
}