import ReactJoyride from "react-joyride";
import { tourSteps } from "variables/tourSteps";

const Tour = (props) => {
  /** When tour is finished, reset the state in parent component so that tour can be started again */
  const handleCallback = (data) => {
    if (data.status === "finished" || data.status === "skipped") {
      if (!!props.onFinished) props.onFinished();
    }
  };

  return (
    <ReactJoyride
      steps={props.steps}
      continuous={props.continuous}
      run={props.run}
      options={tourSteps.tourOptions}
      callback={handleCallback}
    />
  );
};

export default Tour;
