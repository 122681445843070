import React from "react";
import i18n from "../../../../variables/translations/i18n";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
} from "reactstrap";
import { Redirect } from "react-router";
import urls from "urls";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from "../../../../components/Subscription/Checkout";
import { isAdminPage } from "../../../functions/generic";
import { getCompanyInfo } from "../../../functions/firebase/company";
import {
  createPaymentSubscription,
  fetchPriceInfo,
} from "../../../functions/stripe/payment";
import SimpleHeader from "../../../../components/Headers/SimpleHeader";
import { getUserByUserId } from "../../../functions/firebase/users";
import PaymentLoading from "../../../../components/Loading/payment";
import {
  fetchSubscription,
  promotionCodeChecker,
  updateSubscription,
} from "../../../functions/stripe/subscription";
import i18next from "i18next";
import Love from "components/Loading/love";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY, {
  locale: i18next.language,
});

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: localStorage.getItem("uid"),
      companyId: localStorage.getItem("compid"),
      authorized: this.props.location.authorized,
      subscriptionType: this.props.location.subscriptionType,
      price: this.props.location.price,
      duration: this.props.location.duration,
      priceId: this.props.location.priceId,
      priceIdOnRedirect: this.props.match.params.priceId,
      subscriptionId: "",
      paymentSucceeded: null,
      isLoading: false,
      redirectUnauthorized: false,
      couponId: null,
      validPromotionCode: null,
      promotionCodeText: "",
      promotionCodeInput: "",
      errorMessage: null,
      coupon: null,
      startLoveAnimation: false,
    };
  }

  componentDidMount() {
    getUserByUserId(this.state.userId).then((user) => {
      this.setState({ userName: user.name, userEmail: user.email });
    });
    getCompanyInfo(this.state.companyId).then((company) => {
      this.setState({ companyName: company.name, companyEmail: company.email });
    });
    this.fetchUrlParameters();
  }

  /**
   * Fetch url parameters when redirected from Stripe
   */
  fetchUrlParameters() {
    const params = new URLSearchParams(this.props.location.search);
    const redirect_status = params.get("redirect_status");
    if (redirect_status === "succeeded") {
      this.setState({ isLoading: true }, this.setSubscription);
    } else if (redirect_status === "failed") {
      this.setState({ paymentSucceeded: false });
    } else if (!redirect_status) this.setState({ redirectUnauthorized: true });
  }

  /**
   * Set the subscription of the user after Ideal verification
   */
  setSubscription() {
    fetchPriceInfo(this.state.priceIdOnRedirect)
      .then((price) => {
        fetchSubscription(this.state.companyId)
          .then((firebaseSubscription) => {
            createPaymentSubscription(
              firebaseSubscription.customerId,
              price,
              firebaseSubscription.couponId
            )
              .then((subscription) => {
                this.setState(
                  {
                    paymentSucceeded: true,
                    isLoading: false,
                    subscriptionId: subscription.subscriptionId,
                  },
                  this.updateSubscriptionId
                );
              })
              .catch(() =>
                this.setState({ paymentSucceeded: false, isLoading: false })
              );
          })
          .catch(() =>
            this.setState({ paymentSucceeded: false, isLoading: false })
          );
      })
      .catch(() =>
        this.setState({ paymentSucceeded: false, isLoading: false })
      );
  }

  /**
   * Update the Firebase Subscription object with the subscriptionId
   */
  updateSubscriptionId() {
    let subscription = { subscriptionId: this.state.subscriptionId };
    updateSubscription(this.state.companyId, subscription);
  }

  /**
   * Update Coupon object with the couponId
   */
  updateSubscriptionCouponId() {
    let couponDuration = !!this.state.couponDuration
      ? this.state.couponDuration
      : "unlimited";
    let coupon = {
      couponId: this.state.couponId,
      couponDuration: couponDuration,
      couponCreatedAt: this.state.couponCreatedAt,
      companyId: this.state.companyId,
    };
    this.setState({ coupon: coupon });
  }

  /**
   * Check if promotion code is valid
   * @param promotionCode
   */
  checkPromotionCode(promotionCode) {
    promotionCodeChecker(promotionCode)
      .then((promotionCodeObject) => {
        if (
          promotionCodeObject.promotionId === null ||
          promotionCodeObject.active === false
        ) {
          this.setState({
            validPromotionCode: false,
            errorMessage: i18n.t("dashboard.abonnement.kortingscode_invalide"),
          });
        } else if (this.state.duration === "year") {
          this.setState({
            validPromotionCode: false,
            errorMessage: i18n.t(
              "dashboard.abonnement.kortingscode_niet_toegestaan"
            ),
          });
        } else {
          this.addCustomMessageToDiscountCode(promotionCode);
          this.setState(
            {
              validPromotionCode: true,
              couponId: promotionCodeObject.couponId,
              percentageOff: promotionCodeObject.discount,
              couponDuration: promotionCodeObject.duration,
              promotionCode: promotionCodeObject.code,
              couponCreatedAt: promotionCodeObject.createdAt,
              errorMessage: null,
              promotionCodeText: this.getPromotionValidText(
                promotionCodeObject.discount,
                promotionCodeObject.duration
              ),
            },
            this.updateSubscriptionCouponId
          );
        }
      })
      .catch(() =>
        this.setState({
          errorMessage: i18n.t("dashboard.abonnement.kortingscode_invalide"),
        })
      );
  }

  /**
   * If discount code contains 'ilove', start love animation
   * @param {*} promoCode Discount code
   */
  addCustomMessageToDiscountCode(promoCode) {
    if (promoCode.toUpperCase().includes("ILOVE"))
      this.setState({ startLoveAnimation: true });
  }

  /**
   * Get the correct text with the promotion code duration
   * @returns {string}
   */
  getPromotionValidText(discountPercentage, duration) {
    let discount = parseInt(discountPercentage);
    let discountText;
    if (!!duration) {
      if (duration === 1) {
        discountText = i18n.t("dashboard.abonnement.korting_voor_maand", {
          korting: discount,
          maand: duration,
        });
      } else if (duration > 1)
        discountText = i18n.t("dashboard.abonnement.korting_voor_maanden", {
          korting: discount,
          maand: duration,
        });
    } else {
      discountText = i18n.t("dashboard.abonnement.korting_voor_altijd", {
        korting: discount,
      });
    }
    return discountText;
  }

  render() {
    const { paymentSucceeded, authorized, redirectUnauthorized } = this.state;
    if (paymentSucceeded === true)
      return (
        <Redirect to={{ pathname: urls.subscription, paymentDone: true }} />
      );
    if (paymentSucceeded === false)
      return (
        <Redirect to={{ pathname: urls.subscription, paymentDone: false }} />
      );
    if (authorized === undefined && redirectUnauthorized === true)
      return <Redirect to={{ pathname: urls.subscription }} />;
    return (
      <>
        <SimpleHeader
          name={i18n.t("dashboard.abonnement.abonnement_titel")}
          parentName={i18n.t("dashboard.bedrijf")}
          isAdminPage={isAdminPage(this.props.location.pathname)}
        />
        {this.state.isLoading ? (
          <PaymentLoading />
        ) : (
          <div className="container-fluid">
            <Row>
              <Col lg="6">
                <Card>
                  <CardHeader>
                    <Row className="align-items-center">
                      <div className="col">
                        <h6 className="surtitle">
                          {i18n.t(
                            "dashboard.abonnement.ideal_betaling_header_titel"
                          )}
                        </h6>
                        <h5 className="h3 mb-0">
                          {i18n.t("dashboard.abonnement.ideal_betaling_titel")}
                          {""} - {""}
                          {i18n.t(
                            "dashboard.abonnement." +
                              this.state.subscriptionType +
                              "_subscription_" +
                              this.state.duration
                          )}
                        </h5>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Elements stripe={stripePromise}>
                      <Checkout
                        priceId={this.state.priceId}
                        userName={this.state.userName}
                        userEmail={this.state.userEmail}
                        companyName={this.state.companyName}
                        companyEmail={this.state.companyEmail}
                        companyId={this.state.companyId}
                        subscriptionType={this.state.subscriptionType}
                        duration={this.state.duration}
                        hasValidPromotionCode={this.state.validPromotionCode}
                        coupon={this.state.coupon}
                      />
                    </Elements>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0">
                      {i18n.t(
                        "dashboard.abonnement.kortingscode_invullen_titel"
                      )}
                    </h3>
                  </CardHeader>
                  <CardBody>
                    {this.state.validPromotionCode ? (
                      <Form className="needs-validation">
                        <div className="form-row">
                          <Col className="mb-3" md="12">
                            {this.state.promotionCode}
                            <p className="text-xs">
                              <span className="text-success mr-2">
                                {this.state.promotionCodeText}
                              </span>
                            </p>
                          </Col>
                        </div>
                      </Form>
                    ) : (
                      <Form className="needs-validation">
                        <div className="form-row">
                          <Col className="mb-3" md="12">
                            <Input
                              defaultValue={this.state.promotionCodeInput}
                              placeholder={i18n.t(
                                "dashboard.abonnement.kortingscode_invullen_inputField"
                              )}
                              type="text"
                              onChange={(e) =>
                                this.setState({
                                  promotionCodeInput: e.target.value,
                                })
                              }
                            />
                            {!!this.state.errorMessage && (
                              <small className="text-warning">
                                {this.state.errorMessage}
                              </small>
                            )}
                          </Col>
                        </div>
                        <Button
                          color="primary"
                          type="button"
                          onClick={() =>
                            this.checkPromotionCode(
                              this.state.promotionCodeInput
                            )
                          }
                          disabled={this.state.promotionCodeInput.length < 4}
                        >
                          {i18n.t(
                            "dashboard.abonnement.kortingscode_invullen_button"
                          )}
                        </Button>
                      </Form>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {this.state.startLoveAnimation && (
              <Love
                stopAnimation={() =>
                  this.setState({ startLoveAnimation: false })
                }
              />
            )}
          </div>
        )}
      </>
    );
  }
}

export default Payment;
