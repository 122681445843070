import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  CardHeader,
} from "reactstrap";
import i18n from "../../variables/translations/i18n";
import { useForm } from "react-hook-form";
import ReactTooltip from "react-tooltip";
import { sendContactMail } from "../../views/functions/mail";

const ContactForm = (props) => {
  const user_email = props.email;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      email: user_email,
      subject: i18n.t("contact_pagina.contact_form_onderwerpen_1"),
    },
  });
  const [submitted, setSubmitted] = useState(false);
  const email = "support@dashbee.nl";
  const tel = "+31 35 20 300 44";

  // If email changes (eg from null to email), reset the form field
  useEffect(() => {
    reset({ email: user_email });
  }, [user_email]);

  const onSubmit = (data) => {
    sendContactMail(data)
      .then(() => setSubmitted(true))
      .catch((error) => console.error(error));
  };

  return (
    <Card className="bg-secondary border-0">
      <CardHeader className="bg-transparent text-center pb-4">
        <h2 className="text-uppercase ls-1 text-info py-3 mb-0">
          {i18n.t("contact_pagina.contact_form_titel")}
        </h2>
        <p>{i18n.t("contact_pagina.contact_form_desc")}</p>
        <Row className="justify-content-center">
          <Col md="5">
            <Button
              data-tip
              data-for="copyToClipboardTip"
              className="btn-icon mb-2"
              type="button"
              onClick={() => {
                navigator.clipboard.writeText(email);
              }}
            >
              <span className="btn-inner--icon mr-1">
                <i className="ni ni-email-83" />
              </span>
              <span className="btn-inner--text">{email}</span>
            </Button>
            <ReactTooltip
              delayShow={350}
              id="copyToClipboardTip"
              place="top"
              effect="solid"
              arrow
            >
              {i18n.t("contact_pagina.copy")}
            </ReactTooltip>
          </Col>
          <Col md="5">
            <Button
              data-tip
              data-for="copyToClipboardTip"
              className="btn-icon"
              type="button"
              onClick={() => {
                navigator.clipboard.writeText(tel);
              }}
            >
              <span className="btn-inner--icon mr-1 info">
                <i className="ni ni-chat-round" />
              </span>
              <span className="btn-inner--text">{tel}</span>
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="px-lg-5 py-lg-5">
        {!submitted ? (
          <Form role="form" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <label className="form-control-label">
                {i18n.t("contact_pagina.contact_form_label_1")}
              </label>
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  defaultValue={user_email}
                  id="form_email"
                  placeholder={i18n.t("auth.email")}
                  type="email"
                  name="email"
                  htmlFor="email"
                  {...register("email", { required: true })}
                />
              </InputGroup>
              {errors.email && (
                <p className="text-danger text-sm">
                  {i18n.t("contact_pagina.contact_form_foutmelding_1")}
                </p>
              )}

              <InputGroup className="input-group-merge input-group-alternative mt-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-chat-round" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  id="form_phone"
                  placeholder={i18n.t("auth.telephone")}
                  type="phone"
                  name="phone"
                  htmlFor="phone"
                  {...register("phone")}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <label className="form-control-label">
                {i18n.t("contact_pagina.contact_form_label_2")}
              </label>
              <Input
                id="form_subject"
                type="select"
                name="subject"
                {...register("subject")}
              >
                <option>
                  {i18n.t("contact_pagina.contact_form_onderwerpen_1")}
                </option>
                <option>
                  {i18n.t("contact_pagina.contact_form_onderwerpen_2")}
                </option>
                <option>
                  {i18n.t("contact_pagina.contact_form_onderwerpen_3")}
                </option>
                <option>
                  {i18n.t("contact_pagina.contact_form_onderwerpen_4")}
                </option>
                <option>
                  {i18n.t("contact_pagina.contact_form_onderwerpen_5")}
                </option>
              </Input>
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="message">
                {i18n.t("contact_pagina.contact_form_label_3")}
              </label>
              <Input
                id="form_message"
                rows="3"
                type="textarea"
                name="message"
                placeholder={i18n.t(
                  "contact_pagina.contact_form_placeholder_3"
                )}
                {...register("message", { required: true })}
              />
              {errors.message && (
                <p className="text-danger text-sm">
                  {i18n.t("contact_pagina.contact_form_foutmelding_2")}
                </p>
              )}
            </FormGroup>

            <div className="text-center">
              <Button id="contact-submit-btn" color="info" type="submit">
                {i18n.t("contact_pagina.verstuur_btn")}
              </Button>
            </div>
          </Form>
        ) : (
          <Row className="text-center">
            <p className="text-xl">
              {i18n.t("contact_pagina.bericht_ontvangen")}
            </p>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default ContactForm;
