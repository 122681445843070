import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {Row} from "reactstrap";

const AlertWithCancel = (props) => {
  return (
    <ReactBSAlert
      showCancel={true}
      type={props.type}
      title={props.title}
      onConfirm={() => props.confirmAlert()}
      onCancel={() => props.closeAlert()}
      confirmBtnText={props.confirmBtnText}
      cancelBtnText={props.cancelBtnText}
    >
      <Row className="justify-content-center mb-2 ml-1 mr-1">
        {props.description}
      </Row>
    </ReactBSAlert>
  );
};

export default AlertWithCancel;