import React from "react";
import {
  Row,
  Col,
} from "reactstrap";
import ProfileHeader from "components/Headers/ProfileHeader.js";
import NotificationAlert from "react-notification-alert";
import i18n from "variables/translations/i18n.js";
import ContactForm from "components/Contact/ContactForm";
import SupportContact from "components/Contact/SupportContact";
import Fade from "react-reveal/Fade";
import { getUserByUserId } from "views/functions/firebase/users.js";

class Support extends React.Component {
  constructor(props) {
    super(props);
    this.notifyProfileRef = React.createRef();
  }

  state = {
    userId: localStorage.getItem("uid"),
    email: "",
  };

  componentDidMount() {
    this.getUserInfo();
  }

  getUserInfo() {
    getUserByUserId(this.state.userId).then((user) => {
      this.setState({ email: user.email });
    });
  }

  render() {
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref={this.notifyProfileRef} />
        </div>
        <ProfileHeader
          icon="fas fa-headset"
          title={i18n.t("contact_pagina.support.support_titel")}
          subtitle={i18n.t("contact_pagina.support.support_desc")}
        />
        <div className="mt--4 pl-4 pr-4">
          <Row>
            <Col lg="7" md="12" sm="12">
              <Fade delay={100}>
                <ContactForm email={this.state.email} />
                <div className="ml-2">
                  <p className="">
                    {i18n.t("contact_pagina.support.support_faq_link")}
                    <a href="/contact">
                      {i18n.t("contact_pagina.contact_pagina")}.
                    </a>
                  </p>
                </div>
              </Fade>
            </Col>
            <Col className=" pl-3 pr-3" lg="4" md="12">
              <Fade delay={300}>
                <SupportContact />
              </Fade>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Support;
