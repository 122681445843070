import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from "reactstrap";
import TourIcon from "components/Support/TourIcon";

class SimpleHeader extends React.Component {
  getHeaderBg() {
    if (!this.props.isAdminPage) return "bg-gradient-info";
  }

  getTitleColor() {
    if (!this.props.isAdminPage) return "text-white";
  }

  getBreadCrumbColor() {
    if (!this.props.isAdminPage) return "breadcrumb-dark";
  }
  render() {
    return (
      <>
        <div
          className={
            "header header-light pb-6 content__title content__title--calendar " +
            this.getHeaderBg()
          }
        >
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6
                    className={
                      "fullcalendar-title h2 d-inline-block mb-0 " +
                      this.getTitleColor()
                    }
                  >
                    {this.props.name}
                  </h6>{" "}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-lg-4"
                    listClassName={
                      "breadcrumb-links " + this.getBreadCrumbColor()
                    }
                  >
                    <BreadcrumbItem>
                      <a onClick={(e) => e.preventDefault()}>
                        <i className="fas fa-home" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a onClick={(e) => e.preventDefault()}>
                        {this.props.parentName}
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      {this.props.name}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col lg="6" className="text-right">
                  <TourIcon startTour={this.props.startTour} color={this.props.isAdminPage ? "muted": "white"}/>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

SimpleHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  isAdminPage: PropTypes.bool,
};

export default SimpleHeader;
