import React from "react";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

export const PricingFaq = () => {
  const { t } = useTranslation();
  return (
    <Accordion
      allowMultipleExpanded={true}
      allowZeroExpanded={true}
      className="card mt-5"
    >
      {t("faq.pricing.questions", { returnObjects: true }).map(
        (question, index) => (
          <Fade delay={200 + index * 100} key={index}>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>{question["title"]}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>{question["description"]}</p>
              </AccordionItemPanel>
            </AccordionItem>
          </Fade>
        )
      )}
    </Accordion>
  );
};
