import PropTypes from "prop-types";
import React from "react";

const TestimonialSingle = ({ data }) => {
  return (
    <div className="text-center">
      <div className="single-testimonial">
        <img className="img-fluid mb-2" src={data.image} alt="" />
      </div>
      <h3 className="mt-3 mb-3">"{data.quote}"</h3>
      <p className="linebreak">{data.content}</p>
      <div className="client-info">
        <i className="fa fa-map-signs" />
        <h5>{data.customerName}</h5>
        <span>{data.title}</span>
      </div>
    </div>
  );
};

TestimonialSingle.propTypes = {
  data: PropTypes.object,
};

export default TestimonialSingle;
