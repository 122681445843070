import React from "react";
import * as moment from "moment";
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import i18n from "variables/translations/i18n";

const Invoice = (props) => {
  return (
    <Document>
      <Page size="A4">
        <View style={styles.topRow}>
          <View style={styles.leftSection}>
            <View style={styles.logoContainer}>
              {/* First img tag is needed for preview, because second one doesnt show up there */}
              {!!props.invoice.company.logo && (
                <View>
                  <img
                    src={props.invoice.company.logo}
                    style={styles.logo}
                    alt="logo"
                  />
                  <Image
                    src={props.invoice.company.logo}
                    style={styles.logo}
                    alt="logo"
                  />
                </View>
              )}
            </View>
            <Text style={styles.clientInfoText}>
              {props.invoice.client.name}
            </Text>
            <Text style={styles.clientInfoText}>
              {i18n.t("dashboard.facturen.factuur_tav")}
              {props.invoice.client.contact}
            </Text>
            <Text
              style={{
                display: "block",
                marginTop: TITLE_MARGIN / 2,
                fontSize: TEXT_SIZE,
                color: TEXT_COLOR,
                fontFamily: "Open Sans",
              }}
            >
              {props.invoice.client.street} {props.invoice.client.streetNumber}
            </Text>
            <Text style={styles.clientInfoText}>
              {props.invoice.client.zipcode}, {props.invoice.client.city}
            </Text>
            <Text
              style={{
                display: "block",
                fontWeight: "bold",
                marginTop: 24,
                color: TITLE_COLOR,
                fontSize: TEXT_SIZE,
                fontFamily: "Open Sans",
              }}
            >
              {i18n.t("dashboard.facturen.factuur_nummer")}
              {props.invoice.invoiceNumber}
            </Text>
            <Text
              style={{
                display: "block",
                marginTop: 4,
                color: TITLE_COLOR,
                fontSize: TEXT_SIZE,
                fontFamily: "Open Sans",
              }}
            >
              {i18n.t("dashboard.facturen.factuur_datum")}
              {moment().format("DD-MM-YYYY")}
            </Text>
          </View>
          <View style={styles.rightSection}>
            <Text style={styles.senderCompanyName}>
              {props.invoice.company.name}
            </Text>
            <View style={{ marginBottom: 15, display: "block" }}>
              <Text style={styles.senderInfoText}>
                {i18n.t("dashboard.facturen.factuur_adres")}
                {props.invoice.company.address}
              </Text>
              <Text style={styles.senderInfoText}>
                {props.invoice.company.location}
              </Text>
            </View>
            <View style={{ marginBottom: 15, display: "block" }}>
              <Text style={styles.senderInfoText}>
                {i18n.t("dashboard.facturen.factuur_kvk")}
                {props.invoice.company.kvk}
              </Text>
              <Text style={styles.senderInfoText}>
                {i18n.t("dashboard.facturen.factuur_btw")}
                {props.invoice.company.btw}
              </Text>
            </View>
            <View style={{ marginBottom: 15, display: "block" }}>
              <Text style={styles.senderInfoText}>
                {i18n.t("dashboard.facturen.factuur_iban")}
                {props.invoice.company.iban}
              </Text>
              <Text style={styles.senderInfoText}>
                {i18n.t("dashboard.facturen.factuur_bic")}
                {props.invoice.company.bic}
              </Text>
            </View>
            <View style={{ marginBottom: 15, display: "block" }}>
              <Text style={styles.senderInfoText}>
                {i18n.t("dashboard.facturen.factuur_tel")}
                {props.invoice.company.tel}
              </Text>
              <Text style={styles.senderInfoText}>
                {i18n.t("dashboard.facturen.factuur_email")}
                {props.invoice.company.email}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.divider} />
        <View style={styles.clientInfoRow}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHours}>
                <Text style={styles.tableCellHeader}>
                  {i18n.t("dashboard.facturen.factuur_uren")}
                </Text>
              </View>
              <View style={styles.tableColCosts}>
                <Text style={styles.tableCellHeader}>
                  {i18n.t("dashboard.facturen.factuur_kosten")}
                </Text>
              </View>
              <View style={styles.tableColPeriod}>
                <Text style={styles.tableCellHeader}>
                  {i18n.t("dashboard.facturen.factuur_periode")}
                </Text>
              </View>
              <View style={styles.tableColPrice}>
                <Text style={styles.tableCellHeader}>
                  {i18n.t("dashboard.facturen.factuur_prijs")}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColHours}>
                <Text style={styles.tableCell}>{props.invoice.hours}</Text>
              </View>
              <View style={styles.tableColCosts}>
                <Text style={styles.tableCell}>
                  {props.invoice.costsEnabled && !!props.invoice.costs ? (
                    <Text style={styles.tableCell}>
                      € {props.invoice.costs}
                    </Text>
                  ) : (
                    <Text style={styles.tableCell}>-</Text>
                  )}
                </Text>
              </View>
              <View style={styles.tableColPeriod}>
                <Text style={styles.tableCell}>
                  {moment(props.invoice.startDate, "DD-MM-YYYY").format(
                    "DD-MM-YYYY"
                  )}{" "}
                  -{" "}
                  {moment(props.invoice.endDate, "DD-MM-YYYY").format(
                    "DD-MM-YYYY"
                  )}
                </Text>
              </View>
              <View style={styles.tableColPrice}>
                <Text style={styles.tableCell}>
                  € {props.invoice.invoicePayment}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColHours}>
                <Text style={styles.tableCell} />
              </View>
              <View style={styles.tableColCosts}>
                <Text style={styles.tableCell} />
              </View>
              <View style={styles.tableColPeriod}>
                <Text style={styles.tableCell}>
                  {i18n.t("dashboard.facturen.factuur_totaal_btw")}
                </Text>
              </View>
              <View style={styles.tableColPrice}>
                <Text style={styles.tableCell}>
                  € {Math.round(props.invoice.invoiceTax * 100) / 100}
                </Text>
              </View>
            </View>
            <View style={styles.tableDivider} />
            <View style={styles.tableRow}>
              <View style={styles.tableColHours}>
                <Text style={styles.tableCell} />
              </View>
              <View style={styles.tableColCosts}>
                <Text style={styles.tableCell} />
              </View>
              <View style={styles.tableColPeriod}>
                <Text style={styles.tableCellBold}>
                  {i18n.t("dashboard.facturen.factuur_betalen")}
                </Text>
              </View>
              <View style={styles.tableColPrice}>
                <Text style={styles.tableCellBold}>
                  €{" "}
                  {Math.round(props.invoice.invoicePaymentWithTax * 100) / 100}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

const PAGE_PADDING = 50;
const TITLE_SIZE = 18;
const TITLE_MARGIN = 16;
const TEXT_SIZE = 13;
const TITLE_COLOR = "#283147";
const TEXT_COLOR = "#6b6b6b";

const styles = StyleSheet.create({
  logoContainer: {
    display: "block",
    marginBottom: 30,
  },
  logo: {
    display: "block",
    maxWidth: "100%",
    maxHeight: 250,
    aspectRatio: "contain",
  },
  topRow: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    flex: 1,
    marginRight: -15,
    marginLeft: -15,
    padding: PAGE_PADDING,
  },
  leftSection: {
    position: "relative",
    width: "100%",
    flex: 1,
    maxWidth: "50%",
    paddingRight: 15,
    paddingLeft: 15,
  },
  rightSection: {
    position: "relative",
    width: "100%",
    flex: 1,
    maxWidth: "50%",
    paddingRight: 15,
    paddingLeft: 15,
    textAlign: "right",
  },
  senderCompanyName: {
    display: "block",
    fontWeight: 800,
    color: TITLE_COLOR,
    marginBottom: TITLE_MARGIN,
    fontSize: TITLE_SIZE,
    fontFamily: "Open Sans",
  },
  senderInfoText: {
    display: "block",
    marginBottom: 4,
    color: TEXT_COLOR,
    fontSize: TEXT_SIZE,
    width: "100%",
    fontFamily: "Open Sans",
  },
  senderInfoTextBottom: {
    display: "block",
    marginBottom: 12,
    fontSize: TEXT_SIZE,
    width: "100%",
    fontFamily: "Open Sans",
  },
  clientInfoText: {
    display: "block",
    marginBottom: 4,
    fontSize: TEXT_SIZE,
    color: TEXT_COLOR,
    fontFamily: "Open Sans",
  },
  right: {
    justifyContent: "flex-end",
  },
  clientInfoRow: {
    padding: PAGE_PADDING,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    flex: 1,
  },
  divider: {
    display: "flex",
    marginLeft: PAGE_PADDING,
    marginRight: PAGE_PADDING,
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: "#c5c5c5",
  },
  tableDivider: {
    display: "flex",
    marginBottom: -5,
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: "#e3e3e3",
  },
  table: {
    display: "table",
    flex: 1,
    width: "100%",
  },
  tableRow: {
    display: "flex",
    margin: "auto",
    flexDirection: "row",
    flex: 1,
    marginTop: 8,
    width: "100%",
  },
  tableColHeader: {
    width: "25%",
  },
  tableColHours: {
    flex: 1,
    textAlign: "left",
  },
  tableColCosts: {
    flex: 1,
    textAlign: "right",
  },
  tableColPeriod: {
    flex: 2,
    textAlign: "right",
  },
  tableColPrice: {
    flex: 1,
    textAlign: "right",
  },
  tableCol: {
    flex: 1,
    width: "25%",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: TEXT_SIZE + 1,
    fontWeight: 800,
    color: TITLE_COLOR,
    fontFamily: "Open Sans",
  },
  tableCell: {
    margin: 5,
    fontSize: TEXT_SIZE,
    color: TEXT_COLOR,
    fontFamily: "Open Sans",
  },
  tableCellBold: {
    margin: 5,
    fontWeight: 800,
    fontSize: TEXT_SIZE,
    color: TITLE_COLOR,
    fontFamily: "Open Sans",
  },
});

export default Invoice;
