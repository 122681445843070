import firebase from "firebase";
import { getHours } from "./firebase/hours";
import * as moment from "moment";
import { getCosts } from "./costs";
import _ from "lodash";

function getProjectInfo(projectId) {
  return new Promise((resolve, reject) => {
    let projects = firebase.database().ref("projects/" + projectId);
    projects.on("value", (snapshot) => {
      let project = snapshot.val();
      if (!!project) {
        resolve(project);
      } else {
        reject("No project data found");
      }
    });
  });
}

function getCompanyInfo(companyId) {
  return new Promise((resolve, reject) => {
    let company = firebase.database().ref("companies/" + companyId);
    company.on("value", (snapshot) => {
      let company = snapshot.val();
      if (!!company) {
        resolve(company);
      } else {
        reject("No company data found");
      }
    });
  });
}

function getInvoicesForCompany(companyId) {
  return new Promise((resolve, reject) => {
    let invoices = firebase
      .database()
      .ref("invoices")
      .orderByChild("companyId")
      .equalTo(companyId);
    invoices.on("value", (snapshot) => {
      let invoices = snapshot.val();
      if (!!invoices) {
        resolve(invoices);
      } else {
        reject("No invoices found");
      }
    });
  });
}

function getHoursForProjectInPeriod(projectId, startDate, endDate) {
  return new Promise((resolve, reject) => {
    let hours = firebase
      .database()
      .ref("hours")
      .orderByChild("projectId")
      .equalTo(projectId);
    hours.on("value", (snapshot) => {
      getHours(snapshot.val())
        .then((allHoursArray) => {
          // Remove all entries that are not invoiceable
          _.remove(allHoursArray, function (item) {
            return (item.invoiceable === false);
          });
          let filteredArray = filterObjectsInPeriod(
            startDate,
            endDate,
            allHoursArray,
            "hours"
          );
          if (filteredArray.length > 0) {
            let totalHours = filteredArray.reduce((a, b) => a + b);
            resolve(totalHours);
          } else {
            reject("No hours found");
          }
        })
        .catch(() => {
          reject("No hours found");
        });
    });
  });
}

function getCostsForProjectInPeriod(projectId, startDate, endDate) {
  return new Promise((resolve, reject) => {
    let costs = firebase
      .database()
      .ref("costs")
      .orderByChild("projectId")
      .equalTo(projectId);
    costs.on("value", (snapshot) => {
      getCosts(snapshot)
        .then((allCostsArray) => {
          let filteredArray = filterObjectsInPeriod(
            startDate,
            endDate,
            allCostsArray,
            "costs"
          );
          if (filteredArray.length > 0) {
            let totalCosts = filteredArray.reduce((a, b) => a + b);
            resolve(totalCosts);
          } else {
            reject("No costs found");
          }
        })
        .catch(() => {
          reject("No costs found");
        });
    });
  });
}

function filterObjectsInPeriod(startDate, endDate, hoursArray, type) {
  let momentStartDate = moment(startDate).format("YYYY-MM-DD");
  let momentEndDate = moment(endDate).format("YYYY-MM-DD");

  let filteredArray = [];

  Array.from(hoursArray).forEach((entry) => {
    let momentDate = moment.unix(entry.date).format("YYYY-MM-DD");
    let isBetween = moment(momentDate).isBetween(
      momentStartDate,
      momentEndDate,
      undefined,
      "[]"
    );
    if (isBetween) {
      if (type === "hours") {
        filteredArray.push(entry.hours);
      } else {
        filteredArray.push(entry.costs);
      }
    }
  });
  return filteredArray;
}

async function calculateInvoiceNumber(companyId) {
  let invoiceSize = 0;
  await getInvoicesForCompany(companyId)
    .then((invoices) => {
      invoiceSize = Object.values(invoices).length;
    })
    .catch(() => {
      invoiceSize = 0;
    });

  let currentYear = moment(new Date()).format("YYYY");
  let currentMonth = moment(new Date()).format("MM");
  let invoiceNumber = "";

  if (invoiceSize === 0 || invoiceSize < 10) {
    invoiceNumber = currentYear + currentMonth + "-00" + (invoiceSize + 1);
  } else if (invoiceSize > 10 && invoiceSize < 100) {
    invoiceNumber = currentYear + currentMonth + "-0" + (invoiceSize + 1);
  } else if (invoiceSize > 100 && invoiceSize < 1000) {
    invoiceNumber = currentYear + currentMonth + "-" + (invoiceSize + 1);
  }
  return invoiceNumber;
}

export {
  calculateInvoiceNumber,
  getProjectInfo,
  getCompanyInfo,
  getInvoicesForCompany,
  getHoursForProjectInPeriod,
  getCostsForProjectInPeriod,
};
