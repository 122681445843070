import NotificationAlert from "react-notification-alert";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";
import React from "react";
import ProfileHeader from "../../../../components/Headers/ProfileHeader";
import i18n from "../../../../variables/translations/i18n";
import notify from "../../../functions/notify";
import { isAuthenticatedUserAdmin } from "../../../functions/generic";
import Toggle from "../../../../components/Form/Toggle";
import {
  getUserSettings,
  setUserSettings,
} from "../../../functions/firebase/users";
import {
  getCompanySettings,
  setCompanySettings,
} from "../../../functions/firebase/company";

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.notifySettingsRef = React.createRef();
  }
  state = {
    offDayRequestMails: true,
    promotionMails: true,
    offDayModule: true,
    invoiceModule: true,
    clientModule: true,
    hoursModule: true,
    costsOverviewModule: true,
    projectOverviewModule: true,
    teamOverviewModule: true,
    isAdmin: false,
    userId: localStorage.getItem("uid"),
    companyId: localStorage.getItem("compid"),
  };

  componentDidMount() {
    isAuthenticatedUserAdmin().then((isAdmin) =>
      this.setState({ isAdmin: isAdmin })
    );
    getUserSettings(this.state.userId).then((userSettings) => {
      this.setState(userSettings);
    });
    getCompanySettings(this.state.companyId).then((companySettings) => {
      this.setState(companySettings);
    });
  }

  /**
   * Set the state of the toggle
   * @param state Variable to set the state for
   * @param label The label to display in the notification
   * @param isCompanyField Boolean to check if it's a company field or not
   */
  setToggle = (state, label, isCompanyField) => {
    let stateObject = {
      [state]: !this.state[state],
    };
    this.setState(stateObject);
    isCompanyField
      ? this.setCompanySettings(stateObject)
      : this.setUserSettings(stateObject);
    this.showNotification(!this.state[state], label);
  };

  /**
   * Show notification when toggle is toggled
   * @param value The state of the variable
   * @param label The label to display in the notification
   */
  showNotification(value, label) {
    if (value) {
      notify(
        "success",
        label + " " + i18n.t("dashboard.instellingen.meldingen.aan"),
        this.notifySettingsRef
      );
    } else {
      notify(
        "warning",
        label + " " + i18n.t("dashboard.instellingen.meldingen.uit"),
        this.notifySettingsRef
      );
    }
  }

  /**
   * Set user settings
   * @param stateObject The value of toggle
   */
  setUserSettings(stateObject) {
    setUserSettings(this.state.userId, stateObject);
  }

  /**
   * Set company wide settings
   * @param stateObject he value of toggle
   */
  setCompanySettings(stateObject) {
    setCompanySettings(this.state.companyId, stateObject);
  }

  render() {
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref={this.notifySettingsRef} />
        </div>
        <ProfileHeader
          icon="fas fa-cogs"
          title={i18n.t("dashboard.instellingen.instellingen_titel")}
          subtitle={
            this.state.isAdmin
              ? i18n.t("dashboard.instellingen.instellingen_subtitel_admin")
              : i18n.t("dashboard.instellingen.instellingen_subtitel")
          }
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col className="order-xl-1" xl="6">
              <Card>
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        {i18n.t("dashboard.instellingen.instellingen_beheren")}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      <i
                        className="ni ni-notification-70 mr-2"
                        aria-hidden="true"
                      />
                      {i18n.t("dashboard.instellingen.notificaties.algemeen")}
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="8">
                          <Toggle
                            label={i18n.t(
                              "dashboard.instellingen.notificaties.verlofaanvragen"
                            )}
                            checked={this.state.offDayRequestMails}
                            icon={"ni ni-email-83 text-primary mr-2"}
                            className={"custom-toggle float-right mr-2"}
                            onToggleChange={() =>
                              this.setToggle(
                                "offDayRequestMails",
                                i18n.t(
                                  "dashboard.instellingen.meldingen.verlofaanvragen"
                                ),
                                false
                              )
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="8">
                          <Toggle
                            label={i18n.t(
                              "dashboard.instellingen.notificaties.promotie_mails"
                            )}
                            icon={"ni ni-email-83 text-primary mr-2"}
                            className={"custom-toggle float-right mr-2"}
                            checked={this.state.promotionMails}
                            onToggleChange={() =>
                              this.setToggle(
                                "promotionMails",
                                i18n.t(
                                  "dashboard.instellingen.meldingen.promotie_mails"
                                ),
                                false
                              )
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            {this.state.isAdmin && (
              <Col className="order-xl-1" xl="6">
                <Card>
                  <CardHeader>
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">
                          {i18n.t("dashboard.instellingen.modules_beheren")}
                        </h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        <i
                          className="fas fa-door-open mr-2"
                          aria-hidden="true"
                        />
                        {i18n.t("dashboard.instellingen.toegang.algemeen")}
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="8">
                            <Toggle
                              label={i18n.t(
                                "dashboard.instellingen.toegang.overzicht_uren"
                              )}
                              icon={"far fa-clock text-primary mr-2"}
                              className={"custom-toggle float-right mr-2"}
                              checked={this.state.hoursModule}
                              onToggleChange={() =>
                                this.setToggle(
                                  "hoursModule",
                                  i18n.t(
                                    "dashboard.instellingen.toegang.overzicht_uren"
                                  ) +
                                    " " +
                                    i18n.t(
                                      "dashboard.instellingen.meldingen.module"
                                    ),
                                  true
                                )
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="8">
                            <Toggle
                              label={i18n.t(
                                "dashboard.instellingen.toegang.beheren_verlofaanvragen"
                              )}
                              icon={"ni ni-send text-primary mr-2"}
                              className={"custom-toggle float-right mr-2"}
                              checked={this.state.offDayModule}
                              onToggleChange={() =>
                                this.setToggle(
                                  "offDayModule",
                                  i18n.t(
                                    "dashboard.instellingen.toegang.beheren_verlofaanvragen"
                                  ) +
                                    " " +
                                    i18n.t(
                                      "dashboard.instellingen.meldingen.module"
                                    ),
                                  true
                                )
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="8">
                            <Toggle
                              label={i18n.t(
                                "dashboard.instellingen.toegang.genereren_factuur"
                              )}
                              icon={
                                "fa fa-file-invoice-dollar text-primary mr-2"
                              }
                              className={"custom-toggle float-right mr-2"}
                              checked={this.state.invoiceModule}
                              onToggleChange={() =>
                                this.setToggle(
                                  "invoiceModule",
                                  i18n.t(
                                    "dashboard.instellingen.toegang.genereren_factuur"
                                  ) +
                                    " " +
                                    i18n.t(
                                      "dashboard.instellingen.meldingen.module"
                                    ),
                                  true
                                )
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="8">
                            <Toggle
                              label={i18n.t(
                                "dashboard.instellingen.toegang.aanmaken_klant"
                              )}
                              icon={"fas fa-user-tie text-primary mr-2"}
                              className={"custom-toggle float-right mr-2"}
                              checked={this.state.clientModule}
                              onToggleChange={() =>
                                this.setToggle(
                                  "clientModule",
                                  i18n.t(
                                    "dashboard.instellingen.toegang.aanmaken_klant"
                                  ) +
                                    " " +
                                    i18n.t(
                                      "dashboard.instellingen.meldingen.module"
                                    ),
                                  true
                                )
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="8">
                            <Toggle
                              label={i18n.t(
                                "dashboard.instellingen.toegang.overzicht_kosten"
                              )}
                              icon={"ni ni-money-coins text-primary mr-2"}
                              className={"custom-toggle float-right mr-2"}
                              checked={this.state.costsOverviewModule}
                              onToggleChange={() =>
                                this.setToggle(
                                  "costsOverviewModule",
                                  i18n.t(
                                    "dashboard.instellingen.toegang.overzicht_kosten"
                                  ) +
                                    " " +
                                    i18n.t(
                                      "dashboard.instellingen.meldingen.module"
                                    ),
                                  true
                                )
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="8">
                            <Toggle
                              label={i18n.t(
                                "dashboard.instellingen.toegang.overzicht_projecten"
                              )}
                              icon={"ni ni-chart-pie-35 text-primary mr-2"}
                              className={"custom-toggle float-right mr-2"}
                              checked={this.state.projectOverviewModule}
                              onToggleChange={() =>
                                this.setToggle(
                                  "projectOverviewModule",
                                  i18n.t(
                                    "dashboard.instellingen.toegang.overzicht_projecten"
                                  ) +
                                    " " +
                                    i18n.t(
                                      "dashboard.instellingen.meldingen.module"
                                    ),
                                  true
                                )
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="8">
                            <Toggle
                              label={i18n.t(
                                "dashboard.instellingen.toegang.overzicht_team"
                              )}
                              icon={"fa fa-users text-primary mr-2"}
                              className={"custom-toggle float-right mr-2"}
                              checked={this.state.teamOverviewModule}
                              onToggleChange={() =>
                                this.setToggle(
                                  "teamOverviewModule",
                                  i18n.t(
                                    "dashboard.instellingen.toegang.overzicht_team"
                                  ) +
                                    " " +
                                    i18n.t(
                                      "dashboard.instellingen.meldingen.module"
                                    ),
                                  true
                                )
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </>
    );
  }
}

export default Settings;
