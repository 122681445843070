import Kalender from "views/pages/dashboard/Kalender.js";
import Grafieken from "views/pages/dashboard/Grafieken.js";
import Projects from "views/pages/dashboard/company/Projects.js";
import AanvragenBehandelen from "views/pages/dashboard/company/HandleRequests.js";
import Team from "views/pages/dashboard/company/Team.js";
import Kosten from "views/pages/dashboard/company/Kosten.js";
import Facturen from "views/pages/dashboard/company/Facturen.js";
import Klanten from "views/pages/dashboard/company/Klanten.js";
import Urenregistratie from "views/pages/dashboard/urenregistratie/Overall.js";
import Verlofregistratie from "views/pages/dashboard/verlof/Overall.js";
import AdminUrenOverzicht from "views/pages/dashboard/company/Uren/Overzicht.js";
import AdminUrenRegistratie from "views/pages/dashboard/company/Uren/Registratie";
import Login from "views/pages/authentication/Login.js";
import AcceptInvite from "views/pages/authentication/AcceptInvite.js";
import ForgotPassword from "views/pages/authentication/ForgotPassword.js";
import Register from "views/pages/authentication/Register.js";
import Profile from "views/pages/dashboard/user/Profile.js";
import Support from "views/pages/dashboard/user/Support.js";
import Company from "views/pages/dashboard/user/Company.js";
import Settings from "views/pages/dashboard/user/Settings.js";
import PrivacyPolicy from "views/pages/website/PrivacyPolicy.js";
import Pricing from "views/pages/website/Pricing.js";
import Contact from "views/pages/website/Contact.js";
import urls from "./urls";
import i18n from "variables/translations/i18n";
import Subscription from "./views/pages/dashboard/user/Subscription";
import Payment from "./views/pages/dashboard/user/Payment";
import CancelSubscription from "./views/pages/dashboard/user/CancelSubscription";
import RenewSubscription from "./views/pages/dashboard/user/RenewSubscription";
import UpgradeSubscription from "./views/pages/dashboard/user/UpgradeSubscription";
import DowngradeSubscription from "./views/pages/dashboard/user/DowngradeSubscription";

const routes = [
  {
    invisible: true,
    path: "/privacy",
    name: i18n.t("nav_privacy"),
    component: PrivacyPolicy,
    layout: "/",
  },
  {
    invisible: true,
    path: "/prijzen",
    name: i18n.t("nav_prijzen"),
    component: Pricing,
    layout: "/",
  },
  {
    invisible: true,
    path: "/contact",
    name: i18n.t("nav_contact"),
    component: Contact,
    layout: "/",
  },
  {
    path: "/urenregistratie",
    name: i18n.t("dashboard.navbar.urenregistratie"),
    miniName: "U",
    icon: "far fa-clock text-green",
    component: Urenregistratie,
    layout: urls.dashboard,
    module: "hoursModule",
  },
  {
    path: "/verlof",
    name: i18n.t("dashboard.navbar.verlof"),
    miniName: "V",
    icon: "fas fa-umbrella-beach text-warning",
    component: Verlofregistratie,
    layout: urls.dashboard,
    module: "offDayModule",
  },
  {
    path: "/kalender",
    name: i18n.t("dashboard.navbar.kalender"),
    miniName: "K",
    icon: "ni ni-calendar-grid-58 text-red",
    component: Kalender,
    layout: urls.dashboard,
    module: "calendarModule",
  },
  {
    invisible: true,
    path: "/grafieken",
    name: "Grafieken",
    miniName: "G",
    icon: "ni ni-chart-pie-35 text-info",
    component: Grafieken,
    layout: urls.dashboard,
  },
  {
    invisible: true,
    path: "/login",
    name: "Login",
    miniName: "L",
    component: Login,
    layout: urls.auth,
  },
  {
    invisible: true,
    path: "/reset",
    name: "Forgot Password",
    miniName: "FP",
    component: ForgotPassword,
    layout: urls.auth,
  },
  {
    invisible: true,
    path: "/register",
    name: "Register",
    component: Register,
    layout: urls.auth,
  },
  {
    invisible: true,
    path: "/uitnodiging/:encodedLink",
    name: "Uitnodiging",
    miniName: "U",
    component: AcceptInvite,
    layout: urls.auth,
  },
  {
    invisible: true,
    path: "/profiel",
    name: "Profiel",
    component: Profile,
    layout: urls.dashboard,
  },
  {
    adminOnly: true,
    path: "/aanvragen",
    name: i18n.t("dashboard.navbar.admin.aanvragen"),
    icon: "ni ni-send text-primary",
    miniName: "VA",
    component: AanvragenBehandelen,
    layout: urls.dashboard,
    module: "offDayModule",
  },
  {
    collapse: true,
    name: i18n.t("dashboard.navbar.admin.uren"),
    icon: "far fa-clock text-primary",
    state: "urenCollapse",
    adminOnly: true,
    views: [
      {
        path: "/uren/overzicht",
        name: i18n.t("dashboard.navbar.admin.uren_overzicht"),
        miniName: "O",
        component: AdminUrenOverzicht,
        layout: urls.dashboard,
        module: "hoursModule",
      },
      {
        path: "/uren/registratie",
        name: i18n.t("dashboard.navbar.admin.uren_registratie"),
        miniName: "R",
        component: AdminUrenRegistratie,
        layout: urls.dashboard,
        module: "hoursModule",
      },
    ]
  },
  {
    adminOnly: true,
    path: "/kosten",
    name: i18n.t("dashboard.navbar.admin.kosten"),
    icon: "ni ni-money-coins text-primary",
    miniName: "T",
    component: Kosten,
    layout: urls.dashboard,
    module: "costsOverviewModule",
  },
  {
    adminOnly: true,
    path: "/facturen",
    name: i18n.t("dashboard.navbar.admin.facturen"),
    icon: "fa fa-file-invoice-dollar text-primary",
    miniName: "F",
    component: Facturen,
    layout: urls.dashboard,
    module: "invoiceModule",
  },
  {
    adminOnly: true,
    path: "/klanten",
    name: i18n.t("dashboard.navbar.admin.klanten"),
    icon: "fas fa-user-tie text-primary",
    miniName: "K",
    component: Klanten,
    layout: urls.dashboard,
    module: "clientModule",
  },
  {
    adminOnly: true,
    path: "/projecten",
    name: i18n.t("dashboard.navbar.admin.projecten"),
    icon: "ni ni-chart-pie-35 text-primary",
    miniName: "P",
    component: Projects,
    layout: urls.dashboard,
    module: "projectOverviewModule",
  },
  {
    adminOnly: true,
    path: "/team",
    name: i18n.t("dashboard.navbar.admin.team"),
    icon: "fa fa-users text-primary",
    miniName: "T",
    component: Team,
    layout: urls.dashboard,
    module: "teamOverviewModule",
  },
  {
    invisible: true,
    adminOnly: true,
    path: "/project/:projectId",
    component: AdminUrenOverzicht,
    layout: urls.dashboard,
    module: "projectOverviewModule",
  },
  {
    invisible: true,
    adminOnly: true,
    path: "/gebruiker/:userId",
    component: AdminUrenOverzicht,
    layout: urls.dashboard,
    module: "hoursModule",
  },
  {
    invisible: true,
    adminOnly: true,
    path: "/bedrijf",
    component: Company,
    layout: urls.dashboard,
  },
  {
    invisible: true,
    adminOnly: false,
    path: "/instellingen",
    component: Settings,
    layout: urls.dashboard,
  },
  {
    invisible: true,
    adminOnly: false,
    path: "/support",
    component: Support,
    layout: urls.dashboard,
  },
  {
    invisible: true,
    adminOnly: true,
    path: "/abonnement",
    component: Subscription,
    layout: urls.dashboard,
    module: "subscription",
  },
  {
    invisible: true,
    adminOnly: true,
    path: "/betaling/:priceId",
    component: Payment,
    layout: urls.dashboard,
    module: "subscription",
  },
  {
    invisible: true,
    adminOnly: true,
    path: "/opzeggen",
    component: CancelSubscription,
    layout: urls.dashboard,
    module: "subscription",
  },
  {
    invisible: true,
    adminOnly: true,
    path: "/upgraden",
    component: UpgradeSubscription,
    layout: urls.dashboard,
    module: "subscription",
  },
  {
    invisible: true,
    adminOnly: true,
    path: "/downgraden",
    component: DowngradeSubscription,
    layout: urls.dashboard,
    module: "subscription",
  },
  {
    invisible: true,
    adminOnly: true,
    path: "/hernieuwen",
    component: RenewSubscription,
    layout: urls.dashboard,
    module: "subscription",
  },
];

export default routes;
