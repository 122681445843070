import firebase from "firebase";

/**
 * Delete an offday by id
 * @param id ID of the offday that has to be deleted
 */
function deleteOffday(id) {
  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref("offdays/" + id)
      .remove((error) => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      });
  });
}

export { deleteOffday };
