import axios from "axios";
import { getAllAdminUsersFromCompany } from "./generic";
import { getUserByUserId } from "./firebase/users";

/**
 * Send a notification email about a new offday request to all admins
 * @param {*} authenticatedUserId The currently authenticated user's ID
 * @param username Username of the user that requests the offday
 * @param offdayType Offday type [prive || zakelijk]
 * @param datePeriod Period of the requested offdays
 */
export function sendOffdayRequestEmail(authenticatedUserId, username, offdayType, datePeriod) {
  // Get all admins and send them a notification email
  getAllAdminUsersFromCompany().then((adminUsers) => {
    let users = Object.values(adminUsers);
    Array.from(users).forEach((object) => {
      let uid = object.id;
      let email = object.email;
      // Check if user has email settings enabled/disabled
      let wantsEmailNotifications = object.offDayRequestMails === undefined ? true : object.offDayRequestMails;
      // Send mail to every admin except yourself
      if (uid !== authenticatedUserId && wantsEmailNotifications) {
        axios
          .post(process.env.REACT_APP_OFFDAY_REQUEST_MAIL, {
            adminEmail: email,
            requestee: username,
            offdayType: offdayType,
            datePeriod: datePeriod,
          })
          .catch((error) => console.log(error));
      }
    });
  });
}

/**
 *
 * @param {*} userId The user ID of the user that did the request
 * @param {*} authenticatedUserId The user ID of the authenticated user
 * @param status Status of the requested offdays 
 * @param datePeriod Period of the requested offdays
 */
export function sendOffdayHandledEmail(userId, authenticatedUserId, status, datePeriod) {
  getUserByUserId(userId).then((object) => {
    let name = object.name.replace(/ .*/, "");
    let email = object.email;
    // Check if user has email settings enabled/disabled
    let wantsEmailNotifications =
      object.offDayRequestMails === undefined ? true : object.offDayRequestMails;
    if (userId !== authenticatedUserId && wantsEmailNotifications) {
      axios
        .post(process.env.REACT_APP_OFFDAY_HANDLED_MAIL, {
          userName: name,
          userEmail: email,
          status: status,
          datePeriod: datePeriod,
        })
        .catch((error) => console.log(error));
    }
  });
}

/**
 * Send invitation email to invited users
 * @param inviteeEmail The email of the user to invite
 * @param adminUser The admin user who has send the invite
 * @param inviteLink The link for the invitee to register
 * @param company The company of the user
 */
export function sendInvitationMail(inviteeEmail, adminUser, inviteLink, company) {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_INVITE_MAIL, {
        email: inviteeEmail,
        adminUser: adminUser,
        inviteLink: inviteLink,
        company: company
      })
      .then(() => resolve())
      .catch((error) => reject(error));
  })
}

/**
 * Send contact mail
 * @param data.email email of user
 * @param data.phone phone of user
 * @param data.subject contact subject
 * @param data.message contact message
 */
export function sendContactMail(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_CONTACT_MAIL, {
        email: data.email,
        phone: data.phone,
        subject: data.subject,
        message: data.message,
      })
      .then(() => resolve())
      .catch((error) => reject(error));
  })
}