import React from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Media,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  CardBody,
  Form,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import firebase from "firebase";
import NotificationAlert from "react-notification-alert";
import moment from "moment";
import beeImage from "assets/img/theme/dash.png";
import { isAdminPage } from "views/functions/generic";
import i18n from "variables/translations/i18n";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ReactExport from "react-export-excel";
import { sendOffdayHandledEmail } from "views/functions/mail";
import {
  formatOffdayDate,
  formatOffdayDayTime,
  formatOffdayPeriod,
  formatOffdaySingleDay
} from "views/functions/formatting";
import { deleteOffday } from "views/functions/firebase/offDays";
import notify from "views/functions/notify";
import Tour from "components/Support/Tour";
import { tourSteps } from "variables/tourSteps";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: false,
  withFirstAndLast: false,
  paginationSize: 5,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        {i18n.t("dashboard.uren.rijen1")}{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        {i18n.t("dashboard.uren.rijen2")}
      </label>
    </div>
  ),
});

class HandleRequests extends React.Component {
  constructor(props) {
    super(props);
    this.notifyHandleRequestsRef = React.createRef();
  }
  state = {
    companyId: localStorage.getItem("compid"),
    userId: localStorage.getItem("uid"),
    projectId: "",
    unhandledRequests: [],
    handledRequests: [],
    offDayToDelete: null,
    deleteModal: false,
    startTour: false,
    steps: tourSteps.verlofaanvraag,
  };

  componentDidMount() {
    this.getRequests();
  }

  getRequests() {
    let requests = firebase
      .database()
      .ref("offdays")
      .orderByChild("companyId")
      .equalTo(this.state.companyId);
    requests.on("value", (snapshot) => {
      let data = snapshot.val();
      if (data !== null) {
        // Initialize empty arrays
        let unHandledArray = [];
        let handledArray = [];

        // Get all unhandled requests and add the id to the object
        Object.entries(data)
          .filter(function (obj) {
            return obj[1].status === "requested";
          })
          .map(function (item, i) {
            item[1].id = item[0];
            unHandledArray.push(item[1]);
          });

        // Get all handled requests and add the id to the object
        Object.entries(data)
          .filter(function (obj) {
            return obj[1].status !== "requested";
          })
          .map(function (item, i) {
            item[1].id = item[0];
            handledArray.push(item[1]);
          });

        // Reverse the handledArray so we can show new -> old
        handledArray.reverse();

        if (unHandledArray.length !== 0) {
          // Get name and picture of the requester
          for (let i = 0; i < unHandledArray.length; i++) {
            let current = unHandledArray[i];
            let userId = current.userId;
            firebase
              .database()
              .ref("users/" + userId)
              .on("value", (snapshot) => {
                let user = snapshot.val();
                unHandledArray[i].requester = {
                  id: user.id,
                  picture: user.picture,
                  name: user.name,
                };
                if (i === unHandledArray.length - 1)
                  this.setState({ unhandledRequests: unHandledArray });
              });
            if (i === unHandledArray.length - 1)
              this.setState({ unhandledRequests: unHandledArray });
          }
        } else {
          this.setState({ unhandledRequests: null });
        }
        if (handledArray.length !== 0) {
          // Get name and picture of the requester
          for (let i = 0; i < handledArray.length; i++) {
            let current = handledArray[i];
            let userId = current.userId;
            firebase
              .database()
              .ref("users/" + userId)
              .on("value", (snapshot) => {
                let user = snapshot.val();
                handledArray[i].requester = {
                  id: user.id,
                  name: user.name,
                };
                if (i === handledArray.length - 1)
                  this.setState({ handledRequests: handledArray });
              });
            if (i === handledArray.length - 1)
              this.setState({ handledRequests: handledArray });
          }
        } else {
          this.setState({ handledRequests: null });
        }
      } else {
        this.setState({ unhandledRequests: null, handledRequests: null });
      }
    });
  }

  onHandleRequest(request, result) {
    let requestId = request.id;
    let userId = request.userId;
    let notificationObj = {
      created_at: firebase.database.ServerValue.TIMESTAMP,
      userId: userId,
      type: "result-offday",
      result: result,
      offdayId: requestId,
    };
    firebase
      .database()
      .ref("offdays/" + requestId)
      .update(
        {
          status: result,
          handledBy: this.state.userId,
        },
        (error) => {
          if (error) {
            console.error(error);
          } else {
            this.createNotification(notificationObj, request);
            this.deleteAdminNotification(requestId);
          }
        }
      );
  }

  createNotification(object, request) {
    firebase
      .database()
      .ref("notifications")
      .push(object, (error) => {
        if (error) {
          console.error(error);
        } else {
          this.sendMail(object, request)
        }
      });
  }
  
  sendMail(object, request) {
    let datePeriod = ""
    if (request.type === "dayPeriod") {
      datePeriod = formatOffdayDayTime(request.day, request.fromTime, request.untilTime)
    } else if (request.type === "day") {
      datePeriod = formatOffdaySingleDay(request.day)
    } else if (request.type === "period") {
      datePeriod = formatOffdayPeriod(request.fromDay, request.untilDay)
    }
    let status = object.result === "accepted" ? "geaccepteerd" : "geweigerd"
    sendOffdayHandledEmail(object.userId, this.state.userId, status, datePeriod);
  }

  deleteAdminNotification(offdayId) {
    firebase
      .database()
      .ref("notifications")
      .orderByChild("offdayId")
      .equalTo(offdayId)
      .on("child_added", (snapshot) => {
        if (snapshot.val().type === "request-offday") snapshot.ref.remove();
      });
  }

  getTableTime(request, type) {
    if (request.type === "dayPeriod") {
      if (type === "from") return request.fromTime;
      if (type === "until") return request.untilTime;
    } else if (request.type === "period") {
      if (type === "from") {
        if (moment().year() !== moment(request.fromDay).year())
          return moment(request.fromDay).format("dddd DD MMM YYYY");
        else return moment(request.fromDay).format("dddd DD MMM");
      }
      if (type === "until") {
        if (moment().year() !== moment(request.untilDay).year())
          return moment(request.untilDay).format("dddd DD MMM YYYY");
        else return moment(request.untilDay).format("dddd DD MMM");
      }
    } else return "-";
  }

  getTableDate(request) {
    if (request.type === "day" || request.type === "dayPeriod") {
      if (moment().year() !== moment(request.day).year())
        return moment(request.day).format("dddd DD MMM YYYY");
      else return moment(request.day).format("dddd DD MMM");
    } else return "-";
  }

  /**
   * Fetch the status of the off day depending on the input.
   * @param result The value from Firebase
   * @returns {string} The value as a readable String in Dutch
   */
  getStatus(result) {
    if (result === "accepted") return i18n.t("dashboard.verlof.geaccepteerd");
    if (result === "denied") return i18n.t("dashboard.verlof.afgekeurd");
    if (result === "requested") return i18n.t("dashboard.verlof.aangevraagd");
  }

  /**
   * Fetch the color of the badge depending on the status of the off day.
   * @param status The status of the off day
   * @returns {string} as css color
   */
  getBadgeColor(status) {
    if (status === "requested") return "bg-teal";
    if (status === "accepted") return "bg-success";
    if (status === "denied") return "bg-danger";
  }

  /**
   * Remove deleteModal from window and call firebase function to delete the offday.
   * Afterwards, notify user that the offday has been deleted.
   * @param {*} id The ID of the offday that has to be deleted
   */
  onDeleteOffday(id) {
    this.setState({ deleteModal: false });
    deleteOffday(id)
      .then(() => {
        notify(
          "success",
          i18n.t("meldingen.aanvraag_verwijderd"),
          this.notifyHandleRequestsRef
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**
   * Add a remove button to every row in the bootstrap table.
   */
  linkActionButtons = (cell, row, rowIndex, formatExtraData) => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-light"
          color=""
          role="button"
          size="sm"
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          <DropdownItem
            onClick={() =>
              this.setState({
                offDayToDelete: row,
                deleteModal: true,
              })
            }
          >
            {i18n.t("dashboard.verwijderen")}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  render() {
    const { steps } = this.state;
    return (
      <>
        <Tour
          steps={steps}
          continuous={true}
          run={this.state.startTour}
          onFinished={() => this.setState({ startTour: false })}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref={this.notifyHandleRequestsRef} />
        </div>
        <SimpleHeader
          name={i18n.t("dashboard.navbar.admin.aanvragen")}
          parentName={i18n.t("dashboard.bedrijf")}
          isAdminPage={isAdminPage(this.props.location.pathname)}
          startTour={() => this.setState({ startTour: true })}
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card id="aanvragen-onbehandeld">
                <CardHeader className="border-0">
                  <Row>
                    <Col>
                      <h3 className="mb-0">
                        {i18n.t("dashboard.verlof.onbehandeld")}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                  <thead>
                    <tr>
                      <th scope="col">
                        {i18n.t("dashboard.verlof.tabel.wie")}
                      </th>
                      <th scope="col">
                        {i18n.t("dashboard.verlof.tabel.datum")}
                      </th>
                      <th scope="col">
                        {i18n.t("dashboard.verlof.tabel.van")}
                      </th>
                      <th scope="col">
                        {i18n.t("dashboard.verlof.tabel.tot")}
                      </th>
                      <th scope="col">
                        {i18n.t("dashboard.verlof.tabel.type")}
                      </th>
                      <th scope="col">
                        {i18n.t("dashboard.verlof.tabel.opmerkingen")}
                      </th>
                      <th scope="col">
                        {i18n.t("dashboard.uren.tabel.acties")}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {!!this.state.unhandledRequests &&
                      this.state.unhandledRequests.map((request, i) => {
                        if (request.active !== false)
                          return (
                            <tr key={i}>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <img
                                    src={
                                      !!request.requester &&
                                      !!request.requester.picture
                                        ? request.requester.picture
                                        : beeImage
                                    }
                                    className="avatar rounded-circle mr-3 bg-white"
                                    alt="logo"
                                  ></img>

                                  <Media>
                                    <span className="name mb-0 text-sm">
                                      {!!request.requester &&
                                        request.requester.name}
                                    </span>
                                  </Media>
                                </Media>
                              </th>
                              <td className="budget">
                                {this.getTableDate(request)}
                              </td>
                              <td className="budget">
                                {this.getTableTime(request, "from")}
                              </td>
                              <td className="budget">
                                {this.getTableTime(request, "until")}
                              </td>
                              <td className="budget">
                                {request.isBusinessOffday
                                  ? i18n.t("dashboard.verlof.zakelijk")
                                  : i18n.t("dashboard.verlof.prive")}
                              </td>
                              <td className="budget">{request.notes}</td>
                              <td className="table-actions">
                                <Button
                                  color="danger"
                                  type="button"
                                  onClick={() =>
                                    this.onHandleRequest(request, "denied")
                                  }
                                >
                                  {i18n.t("dashboard.verlof.weigeren")}
                                </Button>
                                <Button
                                  color="success"
                                  type="button"
                                  onClick={() =>
                                    this.onHandleRequest(request, "accepted")
                                  }
                                >
                                  {i18n.t("dashboard.verlof.accepteren")}
                                </Button>
                              </td>
                            </tr>
                          );
                        else return null;
                      })}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
          {!!this.state.handledRequests && (
            <Row>
              <Col>
                <ExcelFile
                  element={
                    <button
                      className="mb-3 btn btn-info"
                      type="button"
                      id="aanvragen-export"
                    >
                      {i18n.t("dashboard.excel_export")}
                    </button>
                  }
                  filename={
                    i18n.t("dashboard.verlof.titel") +
                    "-export-" +
                    moment().format("DD-MM-YYYY")
                  }
                >
                  <ExcelSheet
                    data={this.state.handledRequests}
                    name={i18n.t("dashboard.verlof.titel")}
                  >
                    <ExcelColumn
                      label={i18n.t("dashboard.verlof.tabel.datum")}
                      value="day"
                    />
                    <ExcelColumn
                      label={i18n.t("dashboard.verlof.tabel.van")}
                      value={(col) => {
                        if (!!col.fromDay) return col.fromDay;
                        else if (!!col.fromTime) return col.fromTime;
                        else return "-";
                      }}
                    />
                    <ExcelColumn
                      label={i18n.t("dashboard.verlof.tabel.tot")}
                      value={(col) => {
                        if (!!col.untilDay) return col.untilDay;
                        else if (!!col.untilTime) return col.untilTime;
                        else return "-";
                      }}
                    />
                    <ExcelColumn
                      label={i18n.t("dashboard.verlof.tabel.type")}
                      value={(col) =>
                        col.isBusinessOffday
                          ? i18n.t("dashboard.verlof.zakelijk")
                          : i18n.t("dashboard.verlof.prive")
                      }
                    />
                    <ExcelColumn
                      label={i18n.t("dashboard.verlof.tabel.opmerkingen")}
                      value="notes"
                    />
                    <ExcelColumn
                      label={i18n.t("dashboard.verlof.tabel.status")}
                      value={(col) => this.getStatus(col.status)}
                    />
                    <ExcelColumn
                      label={i18n.t("dashboard.verlof.tabel.wie")}
                      value={(col) => col.requester.name}
                    />
                  </ExcelSheet>
                </ExcelFile>
              </Col>
              <Col lg="12">
                <Card id="aanvragen-behandeld">
                  <CardHeader>
                    <h3 className="mb-0">
                      {i18n.t("dashboard.verlof.behandeld")}
                    </h3>
                  </CardHeader>
                  <ToolkitProvider
                    data={this.state.handledRequests}
                    keyField="id"
                    columns={[
                      {
                        dataField: "day",
                        text: i18n.t("dashboard.verlof.tabel.datum"),
                        sort: true,
                        formatter: (cell, row) => {
                          if (cell == null) {
                            return "-";
                          } else return this.getTableDate(row);
                        },
                      },
                      {
                        dataField: "fromDay",
                        text: i18n.t("dashboard.verlof.tabel.van"),
                        formatter: (cell, row) => {
                          if (cell == null) {
                            // If no fromDay, show fromTime instead
                            if (row.fromTime == null) return "-";
                            else return row.fromTime;
                          } else return moment(cell).format("dddd DD MMM");
                        },
                      },
                      {
                        dataField: "untilDay",
                        text: i18n.t("dashboard.verlof.tabel.tot"),
                        formatter: (cell, row) => {
                          if (cell == null) {
                            // If no untilDay, show untilTime instead
                            if (row.untilTime == null) return "-";
                            else return row.untilTime;
                          } else return moment(cell).format("dddd DD MMM");
                        },
                      },
                      {
                        dataField: "isBusinessOffDay",
                        text: i18n.t("dashboard.verlof.tabel.type"),
                        formatter: (cell, row) => {
                          if (row.isBusinessOffday)
                            return i18n.t("dashboard.verlof.zakelijk");
                          else return i18n.t("dashboard.verlof.prive");
                        },
                      },
                      {
                        dataField: "notes",
                        text: i18n.t("dashboard.verlof.tabel.opmerkingen"),
                      },
                      {
                        dataField: "status",
                        text: i18n.t("dashboard.verlof.tabel.status"),
                        formatter: (cell) => {
                          return this.getStatus(cell);
                        },
                      },
                      {
                        dataField: "requester.name",
                        text: i18n.t("dashboard.verlof.tabel.wie"),
                      },
                      {
                        dataField: "actions",
                        text: i18n.t("dashboard.uren.tabel.acties"),
                        formatter: this.linkActionButtons,
                      },
                    ]}
                  >
                    {(props) => (
                      <div className="table-responsive">
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </Card>
              </Col>
            </Row>
          )}
          {/* Delete modal */}
          {!!this.state.offDayToDelete && (
            <Row>
              <Col md="4">
                <Modal
                  className="modal-dialog-centered"
                  size="sm"
                  isOpen={this.state.deleteModal}
                  toggle={() =>
                    this.setState({
                      deleteModal: !this.state.deleteModal,
                      offDayToDelete: null,
                    })
                  }
                >
                  {this.state.memberToDelete !== null && (
                    <div className="modal-body p-0">
                      <Card className="bg-secondary border-0 mb-0">
                        <CardHeader className="bg-transparent pb-3">
                          <div className="text-muted text-center mt-2 mb-3">
                            <span className="card-header-title">
                              {i18n.t("dashboard.verlof.verwijderen")}
                            </span>
                            <span className="card-header-subtitle">
                              {i18n.t("dashboard.verlof.verwijderen_uitleg")}
                            </span>
                          </div>
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                          <div className="text-center">
                            <h5 className="h3 title">
                              <span className="d-block mb-1">
                                {i18n.t("dashboard.verlof.aanvraag_van")}{" "}
                                {this.state.offDayToDelete.requester.name}
                              </span>
                              <span className="d-block mb-1 font-weight-light">
                                {i18n.t("dashboard.verlof.tabel.datum")}:{" "}
                                {formatOffdayDate(
                                  this.state.offDayToDelete.day,
                                  "date"
                                )}
                              </span>
                              <span className="d-block font-weight-light text-muted">
                                {i18n.t("dashboard.verlof.tabel.van")}:{" "}
                                {formatOffdayDate(
                                  this.state.offDayToDelete.fromTime ||
                                    this.state.offDayToDelete.fromDay,
                                  "fromUntil"
                                )}
                              </span>
                              <span className="d-block font-weight-light text-muted">
                                {i18n.t("dashboard.verlof.tabel.tot")}:{" "}
                                {formatOffdayDate(
                                  this.state.offDayToDelete.untilTime ||
                                    this.state.offDayToDelete.untilDay,
                                  "fromUntil"
                                )}
                              </span>
                            </h5>
                          </div>
                          <Form role="form">
                            <div className="text-center">
                              <Button
                                className="my-4"
                                color="primary"
                                type="button"
                                onClick={() =>
                                  this.setState({
                                    deleteModal: false,
                                    offDayToDelete: null,
                                  })
                                }
                              >
                                {i18n.t("dashboard.sluiten")}
                              </Button>
                              <Button
                                className="my-4"
                                color="success"
                                type="button"
                                onClick={() =>
                                  this.onDeleteOffday(
                                    this.state.offDayToDelete.id,
                                    this.notifyHandleRequestsRef
                                  )
                                }
                              >
                                {i18n.t("dashboard.verwijderen")}
                              </Button>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </div>
                  )}
                </Modal>
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  }
}

export default HandleRequests;
