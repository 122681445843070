import React from "react";
import i18n from "../../variables/translations/i18n";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
const { SearchBar } = Search;

const Table = (props) => {
  return (
    <ToolkitProvider
      data={props.data}
      keyField="id"
      columns={props.columns}
      search
    >
      {(props) => (
        <div className="py-4 table-responsive">
          <div
            id="datatable-basic_filter"
            className="dataTables_filter px-4 pb-1"
          >
            <label>
              {i18n.t("dashboard.uren.tabel.zoeken")}
              <SearchBar
                className="form-control-sm"
                placeholder={i18n.t("dashboard.uren.tabel.zoeken_placeholder")}
                {...props.searchProps}
              />
            </label>
          </div>
          <BootstrapTable
            {...props.baseProps}
            bootstrap4={true}
            pagination={pagination}
            bordered={false}
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: false,
  withFirstAndLast: false,
  paginationSize: 5,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        {i18n.t("dashboard.uren.rijen1")}{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        {i18n.t("dashboard.uren.rijen2")}
      </label>
    </div>
  ),
});

export default Table;
