import firebase from "firebase";

/**
 * Get all hours for a project
 * @param projectId
 */
function getAllHoursForProject(projectId) {
  return new Promise(function (resolve, reject) {
    let hoursRef = firebase
      .database()
      .ref("hours")
      .orderByChild("projectId")
      .equalTo(projectId);
    hoursRef.on("value", (snapshot) => {
      let hours = snapshot.val();
      if (!!hours) {
        resolve(hours);
      } else {
        reject("hours not found");
      }
    });
  });
}

/**
 * Get All Hours for Company by Company ID
 * @param companyId
 */
function getAllHoursForCompany(companyId) {
  return new Promise(function (resolve, reject) {
    let hoursRef = firebase
      .database()
      .ref("hours")
      .orderByChild("companyId")
      .equalTo(companyId);
    hoursRef.on("value", (snapshot) => {
      let hours = snapshot.val();
      if (!!hours) {
        resolve(hours);
      } else {
        reject("hours not found");
      }
    });
  });
}

/**
 * Get All Hours for User by User ID
 * @param userId
 */
function getAllHoursForUser(userId) {
  return new Promise(function (resolve, reject) {
    let hoursRef = firebase
      .database()
      .ref("hours")
      .orderByChild("userId")
      .equalTo(userId);
    hoursRef.on("value", (snapshot) => {
      let hours = snapshot.val();
      if (!!hours) {
        resolve(hours);
      } else {
        reject("hours not found");
      }
    });
  });
}

/**
 * Add id to hours
 * @param snapshot
 * @returns Array containing the hours
 */
function getHours(snapshot) {
  return new Promise(function (resolve, reject) {
    if (snapshot !== null) {
      let data = Object.values(snapshot);
      let newData = Object.entries(snapshot);
      // Add id of hour to object
      newData.forEach(([key], index) => {
        data[index].id = key;
      });
      resolve(data);
    } else {
      reject("No hours found");
    }
  });
}

export { getAllHoursForProject, getAllHoursForCompany, getAllHoursForUser, getHours };
