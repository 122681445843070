export const roles = [
  {
    key: 1,
    value: "admin",
    text: "Queen Bee",
  },
  {
    key: 2,
    value: "user",
    text: "Worker Bee",
  },
];
