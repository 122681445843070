import firebase from "firebase";

function checkAuth() {
  return new Promise(function (resolve, reject) {
    /** Check if user is authenticated */
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        let userData = firebase.database().ref("users/" + user.uid);
        userData.on("value", (snapshot) => {
          let obj = snapshot.val();
          if (!!obj) resolve(obj);
          else reject();
        });
      } else reject();
    });
  });
}

export default checkAuth;
