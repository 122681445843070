import React from "react";
import { Container, Row, Col } from "reactstrap";

const ProfileHeader = (props) => {
  return (
    <>
      <div className="header pb-6 d-flex align-items-center pt-5 pb-5">
        <span className="mask bg-gradient-info" />
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="10">
              <h1 className="display-2 text-white">
                {!!props.icon && <i className={props.icon} />} {props.title}
              </h1>
              <p className="text-white mt-0 mb-5">{props.subtitle}</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ProfileHeader;
