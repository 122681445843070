import React from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col } from "reactstrap";
import urls from "../../urls";
import i18n from "../../variables/translations/i18n";
import Fade from "react-reveal/Fade";
import ipadPreview from "assets/img/icons/preview/ipad-big.png";

class IndexHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-gradient-info pt-5 pb-7">
          <Container>
            <div className="header-body mb-6 mt-6">
              <Row className="align-items-center">
                <Col lg="6">
                  <div className="pr-0">
                    <Fade delay={100}>
                      <h1 className="display-2 text-white font-weight-bold mb-0">
                        {i18n.t("header_titel")}
                      </h1>
                      <h2 className="display-4 text-white font-weight-light">
                        {i18n.t("header_subtitel")}
                      </h2>
                      <div className="mt-5">
                        <Button
                          className="btn-cta mt-1"
                          color="default"
                          to={urls.registerCompany}
                          tag={Link}
                        >
                          {i18n.t("bedrijf_aanmelden_btn")}
                        </Button>
                      </div>
                    </Fade>
                  </div>
                </Col>
                <Col lg="6">
                  <Fade delay={350}>
                    <img
                      alt="DashBee op Ipad voor urenregistratie"
                      className="img-fluid mt-2"
                      src={ipadPreview}
                    />
                  </Fade>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
      </>
    );
  }
}

export default IndexHeader;
