import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";

const Graph = React.forwardRef((props, ref) => {
  const data = props.data;
  const chartInstance = !!ref && ref.current && ref.current.chartInstance;

  // When new data comes in, update the chart
  useEffect(() => {
    if (!!chartInstance && !!data.datasets && data.datasets.length > 0) {
      chartInstance.config.data.datasets = data.datasets;
      chartInstance.update();
    }
  }, [data]);

  return (
    <div className="chart">
      <Bar
        ref={ref}
        className={props.className}
        data={data}
        options={props.options}
      />
    </div>
  );
});

export default Graph;
