import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Modal,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import "moment/locale/nl";
import NotificationAlert from "react-notification-alert";
import { isAdminPage } from "../../../functions/generic";
import i18n from "../../../../variables/translations/i18n";
import Select2 from "react-select2-wrapper";
import ReactDatetime from "react-datetime";
import { getProjectsForCompany } from "../../../functions/projects";
import {
  calculateInvoiceNumber,
  getCompanyInfo,
  getCostsForProjectInPeriod,
  getHoursForProjectInPeriod,
  getInvoicesForCompany,
  getProjectInfo,
} from "../../../functions/invoices";
import Skeleton from "react-loading-skeleton";
import Invoice from "../../../../components/Invoices/Invoice";
import moment from "moment";
import notify from "../../../functions/notify";
import firebase from "firebase";
import SimpleHeader from "../../../../components/Headers/SimpleHeader";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  getClientInfo,
  getClientsForCompany,
} from "../../../functions/clients";
import Toggle from "../../../../components/Form/Toggle";
import Tour from "components/Support/Tour";
import { tourSteps } from "variables/tourSteps";
import {connect} from "react-redux";

class Facturen extends React.Component {
  constructor(props) {
    super(props);
    this.notifyInvoiceRef = React.createRef();
  }
  state = {
    userId: localStorage.getItem("uid"),
    companyId: localStorage.getItem("compid"),
    projectId: null,
    clientId: null,
    company: {},
    client: {},
    invoices: [],
    project: {},
    projectsForSelect: [],
    clientsForSelect: [],
    invoiceNumber: "",
    invoiceDate: new Date(),
    invoiceModal: false,
    hours: "",
    costs: "",
    costsEnabled: false,
    invoiceHoursLoaded: false,
    invoiceCostsLoaded: false,
    invoice: {},
    invoiceToDelete: null,
    startTour: false,
    steps: tourSteps.facturen,
    userProfile: this.props.profile,
  };

  componentDidMount() {
    /** If no userId or companyId is available, retrieve them */
    if (!!this.state.companyId) {
      this.getInitialInvoiceData(this.state.companyId);
      calculateInvoiceNumber(this.state.companyId).then((invoiceNumber) =>
        this.setState({ invoiceNumber: invoiceNumber })
      );
      getProjectsForCompany(this.state.companyId).then((response) =>
        this.setState({ projectsForSelect: response })
      );
      getClientsForCompany(this.state.companyId).then((response) =>
        this.setState({ clientsForSelect: response })
      );
    } else {
      this.getInitialInvoiceData(this.state.userProfile.companyId);
      calculateInvoiceNumber(this.state.companyId).then((invoiceNumber) =>
        this.setState({ invoiceNumber: invoiceNumber })
      );
      getProjectsForCompany(this.state.userProfile.companyId).then((response) =>
        this.setState({ projectsForSelect: response })
      );
      getClientsForCompany(this.state.userProfile.companyId).then((response) =>
        this.setState({ clientsForSelect: response })
      );
      
    }
  }

  getInitialInvoiceData(companyId) {
    getCompanyInfo(companyId).then((company) =>
      this.setState({ company: company })
    );
    getInvoicesForCompany(companyId)
      .then((invoices) => this.setState({ invoices: invoices }))
      .catch(() => this.setState({ invoices: [] }));
  }

  generateInvoicePreviewData = () => {
    if (this.state.clientId === null) {
      notify(
        "warning",
        i18n.t("meldingen.factuur_klant_leeg"),
        this.notifyInvoiceRef
      );
    } else if (this.state.projectId === null) {
      notify(
        "warning",
        i18n.t("meldingen.factuur_project_leeg"),
        this.notifyInvoiceRef
      );
    } else if (
      this.state.startDate === undefined ||
      this.state.endDate === undefined
    ) {
      notify(
        "warning",
        i18n.t("meldingen.factuur_periode_leeg"),
        this.notifyInvoiceRef
      );
    } else if (
      this.companyHasEmptyFields(this.state.company) ||
      this.clientHasEmptyFields(this.state.clientId)
    ) {
    } else {
      this.setState({
        invoiceHoursLoaded: false,
        invoiceCostsLoaded: false,
        invoiceModal: true,
      });
      this.generateInvoice(
        this.state.projectId,
        this.state.clientId,
        this.state.startDate,
        this.state.endDate
      );
    }
  };

  generateInvoice(projectId, clientId, startDate, endDate) {
    getProjectInfo(projectId).then((project) => {
      this.setState({ project: project });
      if (
        project.hourlyRate === null ||
        project.hourlyRate === "" ||
        !project.hasOwnProperty("hourlyRate")
      ) {
        notify(
          "warning",
          i18n.t("meldingen.factuur_geen_project_tarief_gevonden"),
          this.notifyInvoiceRef
        );
        this.setState({ invoiceModal: false });
      }
    });

    getClientInfo(clientId).then((client) => {
      this.setState({ client: client }, this.setInvoice);
    });

    getHoursForProjectInPeriod(projectId, startDate, endDate)
      .then((hours) => {
        this.setState({ hours: hours }, this.setInvoice);
      })
      .then(() => {
        this.setState({ invoiceHoursLoaded: true });
      })
      .catch(() => {
        notify(
          "warning",
          i18n.t("meldingen.factuur_geen_uren_gevonden"),
          this.notifyInvoiceRef
        );
        this.setState({ invoiceModal: false });
      });

    if (this.state.costsEnabled) {
      getCostsForProjectInPeriod(projectId, startDate, endDate)
        .then((costs) => {
          this.setState({ costs: costs }, this.setInvoice);
        })
        .then(() => {
          this.setState({ invoiceCostsLoaded: true });
        })
        .catch(() => {
          notify(
            "warning",
            i18n.t("meldingen.factuur_geen_kosten_gevonden"),
            this.notifyInvoiceRef
          );
          this.setState({ invoiceModal: false });
        });
    }
  }

  companyHasEmptyFields(company) {
    let companyField;
    let message = i18n.t("meldingen.bedrijfsveld_leeg");

    if (
      company.name === "" ||
      company.name === null ||
      !company.hasOwnProperty("name")
    ) {
      companyField = i18n.t("dashboard.facturen.bedrijfsnaam");
      notify("warning", companyField + message, this.notifyInvoiceRef);
      return true;
    } else if (
      company.bic === "" ||
      company.bic === null ||
      !company.hasOwnProperty("bic")
    ) {
      companyField = i18n.t("dashboard.facturen.bic");
      notify("warning", companyField + message, this.notifyInvoiceRef);
      return true;
    } else if (
      company.btwNumber === "" ||
      company.btwNumber === null ||
      !company.hasOwnProperty("btwNumber")
    ) {
      companyField = i18n.t("dashboard.facturen.btw_nr");
      notify("warning", companyField + message, this.notifyInvoiceRef);
      return true;
    } else if (
      company.city === "" ||
      company.city === null ||
      !company.hasOwnProperty("city")
    ) {
      companyField = i18n.t("dashboard.facturen.stad");
      notify("warning", companyField + message, this.notifyInvoiceRef);
      return true;
    } else if (
      company.email === "" ||
      company.email === null ||
      !company.hasOwnProperty("email")
    ) {
      companyField = i18n.t("dashboard.facturen.email");
      notify("warning", companyField + message, this.notifyInvoiceRef);
      return true;
    } else if (
      company.houseNumber === "" ||
      company.houseNumber === null ||
      !company.hasOwnProperty("houseNumber")
    ) {
      companyField = i18n.t("dashboard.facturen.huisnr");
      notify("warning", companyField + message, this.notifyInvoiceRef);
      return true;
    } else if (
      company.iban === "" ||
      company.iban === null ||
      !company.hasOwnProperty("iban")
    ) {
      companyField = i18n.t("dashboard.facturen.iban");
      notify("warning", companyField + message, this.notifyInvoiceRef);
      return true;
    } else if (
      company.kvkNumber === "" ||
      company.kvkNumber === null ||
      !company.hasOwnProperty("kvkNumber")
    ) {
      companyField = i18n.t("dashboard.facturen.kvk_nr");
      notify("warning", companyField + message, this.notifyInvoiceRef);
      return true;
    } else if (
      company.phone === "" ||
      company.phone === null ||
      !company.hasOwnProperty("phone")
    ) {
      companyField = i18n.t("dashboard.facturen.telefoonnr");
      notify("warning", companyField + message, this.notifyInvoiceRef);
      return true;
    } else if (
      company.streetName === "" ||
      company.streetName === null ||
      !company.hasOwnProperty("streetName")
    ) {
      companyField = i18n.t("dashboard.facturen.straat");
      notify("warning", companyField + message, this.notifyInvoiceRef);
      return true;
    } else if (
      company.type === "" ||
      company.type === null ||
      !company.hasOwnProperty("type")
    ) {
      companyField = i18n.t("dashboard.facturen.bedrijfstype");
      notify("warning", companyField + message, this.notifyInvoiceRef);
      return true;
    } else if (
      company.zipcode === "" ||
      company.zipcode === null ||
      !company.hasOwnProperty("zipcode")
    ) {
      companyField = i18n.t("dashboard.facturen.postcode");
      notify("warning", companyField + message, this.notifyInvoiceRef);
      return true;
    }
  }

  clientHasEmptyFields(clientId) {
    getClientInfo(clientId).then((client) => {
      let clientField;
      let message = i18n.t("meldingen.klantgegevens_leeg");

      if (
        client.name === "" ||
        client.name === null ||
        !client.hasOwnProperty("name")
      ) {
        clientField = i18n.t("dashboard.klanten.naam");
        notify("warning", clientField + message, this.notifyInvoiceRef);
        return true;
      } else if (
        client.clientNumber === "" ||
        client.clientNumber === null ||
        !client.hasOwnProperty("clientNumber")
      ) {
        clientField = i18n.t("dashboard.klanten.nummer");
        notify("warning", clientField + message, this.notifyInvoiceRef);
        return true;
      } else if (
        client.city === "" ||
        client.city === null ||
        !client.hasOwnProperty("city")
      ) {
        clientField = i18n.t("dashboard.facturen.klant_stad");
        notify("warning", clientField + message, this.notifyInvoiceRef);
        return true;
      } else if (
        client.contact === "" ||
        client.contact === null ||
        !client.hasOwnProperty("contact")
      ) {
        clientField = i18n.t("dashboard.klanten.contact_persoon");
        notify("warning", clientField + message, this.notifyInvoiceRef);
        return true;
      } else if (
        client.email === "" ||
        client.email === null ||
        !client.hasOwnProperty("email")
      ) {
        clientField = i18n.t("dashboard.facturen.klant_email");
        notify("warning", clientField + message, this.notifyInvoiceRef);
        return true;
      } else if (
        client.street === "" ||
        client.street === null ||
        !client.hasOwnProperty("street")
      ) {
        clientField = i18n.t("dashboard.facturen.klant_straat");
        notify("warning", clientField + message, this.notifyInvoiceRef);
        return true;
      } else if (
        client.streetNumber === "" ||
        client.streetNumber === null ||
        !client.hasOwnProperty("streetNumber")
      ) {
        clientField = i18n.t("dashboard.facturen.klant_huisnr");
        notify("warning", clientField + message, this.notifyInvoiceRef);
        return true;
      } else if (
        client.zipcode === "" ||
        client.zipcode === null ||
        !client.hasOwnProperty("zipcode")
      ) {
        clientField = i18n.t("dashboard.facturen.klant_postcode");
        notify("warning", clientField + message, this.notifyInvoiceRef);
        return true;
      }
    });
  }

  setInvoice() {
    let company = {
      name: this.state.company.name,
      address:
        this.state.company.streetName + " " + this.state.company.houseNumber,
      location: this.state.company.zipcode + ", " + this.state.company.city,
      kvk: this.state.company.kvkNumber,
      btw: this.state.company.btwNumber,
      iban: this.state.company.iban,
      bic: this.state.company.bic,
      tel: this.state.company.phone,
      email: this.state.company.email,
      type: this.state.company.type,
      logo: this.state.company.picture || null,
    };

    let client = {
      name: this.state.client.name,
      street: this.state.client.street,
      streetNumber: this.state.client.streetNumber,
      zipcode: this.state.client.zipcode,
      city: this.state.client.city,
      email: this.state.client.email,
      contact: this.state.client.contact,
    };

    let invoiceNumber = this.state.invoiceNumber;
    let invoiceName =
      i18n.t("dashboard.facturen.factuur") + "-" + invoiceNumber + ".pdf";
    let hourlyRate = this.state.project.hourlyRate;
    let hours = this.state.hours;
    let costs = this.state.costsEnabled ? this.state.costs : 0;

    let invoice = {
      invoiceName: invoiceName,
      company: company,
      client: client,
      invoiceNumber: invoiceNumber,
      hourlyRate: hourlyRate,
      hours: hours,
      costs: costs,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      costsEnabled: this.state.costsEnabled,
      invoicePayment: hours * hourlyRate + costs,
      invoicePaymentWithTax: (hours * hourlyRate + costs) * 1.21,
      invoiceTax: (hours * hourlyRate + costs) * 0.21,
    };
    this.setState({ invoice: invoice });
  }

  saveInvoice() {
    let state = this.state.invoice;
    let invoice = {
      invoiceName: state.invoiceName,
      invoiceNumber: state.invoiceNumber,
      invoicePayment: state.invoicePayment,
      invoicePaymentWithTax: state.invoicePaymentWithTax,
      invoiceTax: state.invoiceTax,
      hours: state.hours,
      costs: state.costs,
      costsEnabled: this.state.costsEnabled,
      hourlyRate: state.hourlyRate,
      company: state.company,
      client: state.client,
      startDate: moment(state.startDate).format("DD-MM-YYYY"),
      endDate: moment(state.endDate).format("DD-MM-YYYY"),
      template: "default",
      projectId: this.state.projectId,
      companyId: this.state.companyId,
    };

    firebase
      .database()
      .ref("invoices")
      .push(invoice, (error) => {
        if (error) {
          console.error(error);
        } else {
          notify(
            "success",
            i18n.t("meldingen.factuur_opgeslagen"),
            this.notifyInvoiceRef
          );
          this.getInitialInvoiceData(this.state.companyId);
          this.setState({
            invoiceModal: false,
            projectId: null,
            project: null,
            clientId: null,
            client: null,
          });
          calculateInvoiceNumber(this.state.companyId).then((invoiceNumber) =>
            this.setState({ invoiceNumber: invoiceNumber })
          );
        }
      });
  }

  /**
   * Set state of clientToDelete to the clicked project and set deleteModal to true,
   * which opens the modal.
   */
  onPressDeleteInvoice(invoice) {
    this.setState({ invoiceToDelete: invoice, deleteModal: true });
  }

  /**
   * Set state of clicked projects and open editForm modal.
   */
  onPressEditInvoice(invoiceId) {
    this.setState({
      clientName: invoiceId[1].name,
      formModal: true,
      editForm: true,
      clientId: invoiceId[0],
    });
  }

  onDeleteInvoice() {
    let invoiceId = this.state.invoiceToDelete[0];
    let invoiceRef = firebase.database().ref("invoices/" + invoiceId);
    invoiceRef
      .remove()
      .then(() => {
        this.getInitialInvoiceData(this.state.companyId);
      })
      .then(() => {
        calculateInvoiceNumber(this.state.companyId).then((invoiceNumber) =>
          this.setState({ invoiceNumber: invoiceNumber })
        );
      })
      .then(() => {
        notify(
          "success",
          i18n.t("meldingen.factuur_verwijderd"),
          this.notifyInvoiceRef
        );
        this.setState({
          invoiceToDelete: null,
          deleteModal: false,
        });
      });
  }

  toggle = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handleReactDatetimeChange = (who, date) => {
    if (
      this.state.startDate &&
      who === "endDate" &&
      new Date(this.state.startDate._d + "") > new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else if (
      this.state.endDate &&
      who === "startDate" &&
      new Date(this.state.endDate._d + "") < new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else {
      this.setState({
        [who]: date,
      });
    }
  };

  getClassNameReactDatetimeDays = (date) => {
    if (this.state.startDate && this.state.endDate) {
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  render() {
    const { steps } = this.state;
    return (
      <>
        <Tour
          steps={steps}
          continuous={true}
          run={this.state.startTour}
          onFinished={() => this.setState({ startTour: false })}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref={this.notifyInvoiceRef} />
        </div>
        <SimpleHeader
          name={i18n.t("dashboard.facturen.facturen")}
          parentName={i18n.t("dashboard.bedrijf")}
          isAdminPage={isAdminPage(this.props.location.pathname)}
          startTour={() => this.setState({ startTour: true })}
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col md="4">
              <Modal
                className="modal-dialog-centered"
                contentClassName="w-fit-content"
                size="lg"
                isOpen={this.state.invoiceModal}
                toggle={() => this.toggle("invoiceModal")}
              >
                <Card className="bg-secondary border-0 mb-0">
                  {!this.state.invoiceHoursLoaded &&
                  !this.state.invoiceCostsLoaded ? (
                    <Skeleton count={18} height={50} />
                  ) : (
                    <div>
                      <div style={{ width: "661px", height: "936px" }}>
                        <Invoice invoice={this.state.invoice} />
                      </div>
                      <Row className="align-items-center justify-content-center text-center mb-3">
                        <Button
                          className="ml-2"
                          color="success"
                          onClick={() => this.saveInvoice()}
                        >
                          <i className="far fa-save" />{" "}
                          {i18n.t("dashboard.facturen.opslaan")}
                        </Button>
                      </Row>
                    </div>
                  )}
                </Card>
              </Modal>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="card-wrapper">
                <Card id="facturen-body">
                  <CardHeader>
                    <h3 className="mb-0">
                      {i18n.t("dashboard.facturen.genereren")}
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div className="pl-lg-2">
                        <Row>
                          <Col lg="12">
                            <FormGroup id="facturen-klant">
                              <label className=" form-control-label">
                                {i18n.t("dashboard.facturen.tabel.klant.label")}
                              </label>
                              <Select2
                                className="form-control"
                                defaultValue="0"
                                options={{
                                  placeholder: i18n.t(
                                    "dashboard.facturen.tabel.klant.placeholder"
                                  ),
                                }}
                                data={this.state.clientsForSelect}
                                value={this.state.clientId}
                                onSelect={(e) =>
                                  this.setState({ clientId: e.target.value })
                                }
                              />
                            </FormGroup>
                            <FormGroup>
                              <label className=" form-control-label">
                                {i18n.t("dashboard.uren.project.label")}
                              </label>
                              <Select2
                                className="form-control"
                                defaultValue="0"
                                options={{
                                  placeholder: i18n.t(
                                    "dashboard.uren.project.placeholder"
                                  ),
                                }}
                                data={this.state.projectsForSelect}
                                value={this.state.projectId}
                                onSelect={(e) =>
                                  this.setState({ projectId: e.target.value })
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12" id="facturen-datum">
                            <label className=" form-control-label">
                              {i18n.t("dashboard.facturen.tabel.van.label")}
                            </label>
                            <FormGroup>
                              <ReactDatetime
                                inputProps={{
                                  placeholder: i18n.t(
                                    "dashboard.facturen.tabel.van.placeholder"
                                  ),
                                }}
                                value={this.state.startDate}
                                timeFormat={false}
                                onChange={(e) =>
                                  this.handleReactDatetimeChange("startDate", e)
                                }
                                renderDay={(
                                  props,
                                  currentDate,
                                  selectedDate
                                ) => {
                                  let classes = props.className;
                                  classes +=
                                    this.getClassNameReactDatetimeDays(
                                      currentDate
                                    );
                                  return (
                                    <td {...props} className={classes}>
                                      {currentDate.date()}
                                    </td>
                                  );
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label className=" form-control-label">
                                {i18n.t("dashboard.facturen.tabel.tot.label")}
                              </label>
                              <ReactDatetime
                                inputProps={{
                                  placeholder: i18n.t(
                                    "dashboard.facturen.tabel.tot.placeholder"
                                  ),
                                }}
                                value={this.state.endDate}
                                timeFormat={false}
                                onChange={(e) =>
                                  this.handleReactDatetimeChange("endDate", e)
                                }
                                renderDay={(
                                  props,
                                  currentDate,
                                  selectedDate
                                ) => {
                                  let classes = props.className;
                                  classes +=
                                    this.getClassNameReactDatetimeDays(
                                      currentDate
                                    );
                                  return (
                                    <td {...props} className={classes}>
                                      {currentDate.date()}
                                    </td>
                                  );
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <Toggle
                              label={i18n.t(
                                "dashboard.facturen.tabel.kosten.label"
                              )}
                              break={<br />}
                              className={"custom-toggle mr-1"}
                              checked={this.state.costsEnabled}
                              onToggleChange={() => this.toggle("costsEnabled")}
                            />
                          </Col>
                        </Row>
                      </div>
                      <hr className="my-4" />
                      <Button
                        id="facturen-gegevens"
                        className="my-4"
                        color="info"
                        onClick={() => {
                          this.generateInvoicePreviewData();
                        }}
                      >
                        <i
                          className="fa fa-search-plus mr-2"
                          aria-hidden="true"
                        />
                        {i18n.t("dashboard.facturen.voorbeeld")}
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col lg="6">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0">
                      {i18n.t("dashboard.facturen.mijn_facturen")}
                    </h3>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <tbody>
                      {Object.entries(this.state.invoices).map(
                        (invoice, index) => {
                          return (
                            <tr>
                              <th tabIndex={index} scope="row">
                                {invoice[1].invoiceName}
                              </th>
                              <td>
                                <i className="far fa-calendar mr-2" />
                                {invoice[1].startDate} / {invoice[1].endDate}
                              </td>
                              <td>
                                <i className="far fa-building mr-2" />
                                {invoice[1].client.name}
                              </td>
                              <td />
                              <td>
                                <PDFDownloadLink
                                  document={<Invoice invoice={invoice[1]} />}
                                  fileName={invoice[1].invoiceName}
                                >
                                  {({ blob, url, loading, error }) =>
                                    loading ? (
                                      <>
                                        <Skeleton
                                          count={1}
                                          height={15}
                                          width={"80px"}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <i className="far fa-save text-success mr-2" />
                                        {i18n.t(
                                          "dashboard.facturen.download_btn"
                                        )}
                                      </>
                                    )
                                  }
                                </PDFDownloadLink>
                              </td>
                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-info"
                                    color=""
                                    role="button"
                                    size="sm"
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={() =>
                                        this.onPressDeleteInvoice(invoice)
                                      }
                                    >
                                      {i18n.t("dashboard.verwijderen")}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                  {Object.entries(this.state.invoices).length === 0 && (
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center">
                        <span className="card-header-title text-center">
                          {i18n.t("dashboard.facturen.facturen_leeg")}
                        </span>
                      </div>
                    </CardBody>
                  )}
                </Card>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={this.state.deleteModal}
                toggle={() =>
                  this.setState({
                    deleteModal: !this.state.deleteModal,
                    invoiceToDelete: null,
                  })
                }
              >
                {this.state.invoiceToDelete !== null && (
                  <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                      <CardHeader className="bg-transparent pb-3">
                        <div className="text-muted text-center mt-2 mb-3">
                          <span className="card-header-title">
                            {i18n.t("dashboard.facturen.verwijderen")}
                          </span>
                        </div>
                      </CardHeader>
                      <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center">
                          <span className="card-header-subtitle text-center">
                            {this.state.invoiceToDelete[1].invoiceNumber}
                          </span>
                        </div>
                        <Form role="form">
                          <div className="text-center">
                            <Button
                              className="my-4"
                              color="primary"
                              type="button"
                              onClick={() =>
                                this.setState({
                                  deleteModal: false,
                                  memberToDelete: null,
                                })
                              }
                            >
                              {i18n.t("dashboard.sluiten")}
                            </Button>
                            <Button
                              className="my-4"
                              color="success"
                              type="button"
                              onClick={() => this.onDeleteInvoice()}
                            >
                              {i18n.t("dashboard.verwijderen")}
                            </Button>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </div>
                )}
              </Modal>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    profile: state.firebaseReducer.profile,
  }
}

export default connect(mapStateToProps)(Facturen);
