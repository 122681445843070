import React from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CardHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import AuthHeader from "components/Headers/AuthHeader.js";
import firebase from "firebase";
import logo from "../../../assets/img/brand/logo-purple.png";
import encode from "nodejs-base64-encode";
import urls from "../../../urls";
import i18n from "variables/translations/i18n";
import { WEBSITE_NAME } from "constants/CompanyConstants";
import {
  META_HOME_TITLE,
  META_GENERIC_DESCRIPTION,
} from "constants/MetaTagsConstants";
import { FIREBASE_INACTIVE_USER_PROJECTS_TABLE } from "constants/FirebaseConstants";
import { Helmet } from "react-helmet";

class AcceptInvite extends React.Component {
  state = {
    encodedLink: this.props.match.params.encodedLink,
    userId: null,
    decodedEmail: "",
    invitedEmail: "",
    formEmail: "",
    formName: "",
    formPassword: "",
    role: "",
    offdays: null,
    companyId: "",
    companyName: "",
    registrationComplete: false,
    errorMessage: "",
    redirectLogin: false,
    validInvite: true,
    deactivatedProjects: null,
  };

  componentDidMount() {
    this.decodeLink();
  }

  getUserInfo() {
    try {
      let user = firebase.database().ref("invitedUsers/" + this.state.userId);
      user.once("value", (snapshot) => {
        let data = snapshot.val();
        if (!!data) {
          if (data.email !== this.state.decodedEmail) {
            this.setState({ validInvite: false });
          } else {
            this.setState(
              {
                companyId: data.companyId,
                role: data.role,
                invitedEmail: data.email,
                offdays: !!data.offdays ? data.offdays : null,
                deactivatedProjects: !!data.deactivatedProjects
                  ? data.deactivatedProjects
                  : null,
              },
              this.getCompanyInfo
            );
          }
        } else {
          this.setState({ validInvite: false });
        }
      });
    } catch (error) {
      this.setState({ validInvite: false });
    }
  }

  getCompanyInfo() {
    let company = firebase.database().ref("companies/" + this.state.companyId);
    company.on("value", (snapshot) => {
      let data = snapshot.val();
      this.setState({ companyName: data.name });
    });
  }

  validateForm() {
    if (this.state.formEmail !== this.state.invitedEmail) {
      this.setState({
        errorMessage: i18n.t("meldingen.uitnodiging_verkeerd_email"),
      });
    } else if (this.state.formName.length < 3) {
      this.setState({
        errorMessage: i18n.t("meldingen.naam_3_letters"),
      });
      return false;
    } else if (this.state.formPassword.length < 6) {
      this.setState({
        errorMessage: i18n.t("meldingen.wachtwoord_6_karakters"),
      });
      return false;
    } else {
      return true;
    }
  }

  onPressRegister() {
    /** Create user and add user to our database */
    if (this.validateForm()) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(
          this.state.formEmail,
          this.state.formPassword
        )
        .then((user) => {
          firebase
            .database()
            .ref("users/" + user.user.uid)
            .set(
              {
                id: user.user.uid,
                name: this.state.formName,
                email: this.state.formEmail,
                companyId: this.state.companyId,
                role: this.state.role,
                offdays: this.state.offdays,
                created_at: firebase.database.ServerValue.TIMESTAMP,
              },
              (error) => {
                if (error) {
                  console.error(error);
                  this.setState({ errorMessage: error });
                } else {
                  if (!!this.state.deactivatedProjects) {
                    firebase
                      .database()
                      .ref(
                        FIREBASE_INACTIVE_USER_PROJECTS_TABLE +
                          "/" +
                          user.user.uid
                      )
                      .set({
                        projects: this.state.deactivatedProjects,
                        companyId: this.state.companyId,
                      });
                  }
                  firebase
                    .database()
                    .ref("invitedUsers/" + this.state.userId)
                    .remove()
                    .catch((error) => {
                      this.setState({ errorMessage: error });
                    });
                }
              }
            );
          let loggedInUser = firebase.auth().currentUser;
          let self = this;
          let actionCodeSettings = {
            url: urls.base + urls.login,
          };

          loggedInUser
            .sendEmailVerification(actionCodeSettings)
            .then(function () {
              self.setState({ registrationComplete: true });
            })
            .catch(function (error) {
              console.error(error);
            });
        })
        .catch((error) => {
          let errorMessage = error.message;
          console.log(error);
          if (error.code === "auth/email-already-in-use")
            errorMessage = i18n.t("meldingen.email_reeds_gekozen");
          this.setState({ errorMessage: errorMessage });
        });
    }
  }

  decodeLink() {
    let decoded = encode.decode(this.state.encodedLink, "base64");
    let parts = decoded.split("/");
    let key = parts[0];
    let email = parts[1];
    this.setState({ userId: key, decodedEmail: email }, this.getUserInfo);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>
            {WEBSITE_NAME} · {META_HOME_TITLE}
          </title>
          <meta name="description" content={META_GENERIC_DESCRIPTION} />
        </Helmet>
        <AuthHeader
          title={i18n.t("auth.uitnodiging.titel")}
          lead={i18n.t("auth.uitnodiging.subtitel")}
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0 pt-4">
                <CardHeader className="bg-transparent pb-4">
                  <div className="text-muted text-center mt-2 mb-2">
                    <img className="mw-100" src={logo} />
                    {this.state.validInvite ? (
                      <span className="card-header-title">
                        {i18n.t("auth.uitnodiging.registreren_bij")}
                        {this.state.companyName}
                      </span>
                    ) : (
                      <span className="card-header-title">
                        {i18n.t("auth.uitnodiging.ongeldig")}
                      </span>
                    )}
                  </div>
                </CardHeader>
                {this.state.validInvite && !this.state.registrationComplete && (
                  <CardBody className="px-lg-5 py-lg-5">
                    <Form role="form">
                      <FormGroup
                        className={classnames({
                          focused: this.state.focusedEmail,
                        })}
                      >
                        <label className="form-control-label">
                          {i18n.t("auth.email")}
                        </label>
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder={i18n.t("auth.email")}
                            type="email"
                            onChange={(e) =>
                              this.setState({ formEmail: e.target.value })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.focusedName,
                        })}
                      >
                        <label className="form-control-label">
                          {i18n.t("auth.registratie.naam")}
                        </label>
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-circle-08" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder={i18n.t("auth.registratie.naam")}
                            type="text"
                            onFocus={() => this.setState({ focusedName: true })}
                            onBlur={() => this.setState({ focusedName: false })}
                            onChange={(e) =>
                              this.setState({ formName: e.target.value })
                            }
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                this.onPressRegister();
                              }
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.focusedPassword,
                        })}
                      >
                        <label className="form-control-label">
                          {i18n.t("auth.wachtwoord")}
                        </label>
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder={i18n.t("auth.wachtwoord")}
                            type="password"
                            onFocus={() =>
                              this.setState({ focusedPassword: true })
                            }
                            onBlur={() =>
                              this.setState({ focusedPassword: false })
                            }
                            onChange={(e) =>
                              this.setState({
                                formPassword: e.target.value,
                              })
                            }
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                this.onPressRegister();
                              }
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                      <div className="text-center">
                        <small className="text-warning">
                          {this.state.errorMessage}
                        </small>
                      </div>
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="info"
                          type="button"
                          onClick={() => this.onPressRegister()}
                        >
                          {i18n.t("auth.uitnodiging.submit_btn")}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                )}
                {this.state.registrationComplete && (
                  <CardBody className="px-lg-5 py-lg-5 text-center text-muted">
                    <Row>
                      <span>
                        {i18n.t("auth.uitnodiging.alert.text1")}{" "}
                        {this.state.formEmail}{" "}
                        {i18n.t("auth.uitnodiging.alert.text2")}
                      </span>
                    </Row>
                    <Row className="mt-3 justify-content-center">
                      <Link to={urls.login}>
                        {i18n.t("auth.uitnodiging.alert.text_btn")}
                      </Link>
                    </Row>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AcceptInvite;
