import moment from "moment";
import {getRandomColor} from "./dataService";
import _ from "lodash";

/**
 * Fetch entries from the Firebase Response and set ID of the Object as key/value
 * @param snapshot Firebase response
 * @returns {Promise<unknown>} Array of Objects with an extra field ID
 */
function getCosts(snapshot) {
  return new Promise(function (resolve, reject) {
    if (snapshot.val() !== null) {
      let data = Object.values(snapshot.val());
      let newData = Object.entries(snapshot.val());
      // Add id of hour to object
      newData.forEach(([key], index) => {
        data[index].id = key;
      });
      resolve(data);
    } else {
      reject("No costs found");
    }
  });
}

/**
 * Calculate costs based upon all the costs, all the project and current year
 * @param allCosts Firebase costs Object as Array
 * @param allProjects Firebase projects Object as Array
 * @param year Current year
 * @returns {Promise<unknown>} Object containing the costs calculation for the charts
 */
function calculateCosts(allCosts, allProjects, year) {
  return new Promise(function (resolve, reject) {
    let costs = [];
    let costsForMonthProjects = [];
    let formattedMonths = [];

    let dataThisYear = allCosts
      .filter(function (value) {
        return moment.unix(value.date).year() === year;
      })
      .sort((a, b) => {
        return b.created_at - a.created_at;
      });

    if (dataThisYear.length === 0) reject("no-costs");

    let uniMonths = dataThisYear
      .map((item) => moment.unix(item.date).month())
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort(function (a, b) {
        return a - b;
      });

    /** Clone projects, each time we recalculate we want to start with empty projects */
    let projects = _.cloneDeep(allProjects);
    for (let i = 0; i < uniMonths.length; i++) {
      formattedMonths.push(moment(uniMonths[i] + 1, "M").format("MMMM"));
      costs.push(0);
    }

    for (let item in dataThisYear) {
      if (dataThisYear.hasOwnProperty(item)) {
        let obj = dataThisYear[item];
        let dateYear = moment.unix(obj.date).year();
        if (dateYear === year) {
          let monthsIndex = uniMonths.findIndex(
            (x) => x === moment.unix(obj.date).month()
          );
          costs[monthsIndex] += parseFloat(obj.costs);

          let index = projects.findIndex((x) => x.id === obj.projectId);

          if (!!projects[index]) {
            obj.projectName = projects[index].name;

            if (!projects[index].monthlyCosts[monthsIndex]) {
              projects[index].monthlyCosts[monthsIndex] = parseFloat(obj.costs);
            } else {
              projects[index].monthlyCosts[monthsIndex] += parseFloat(
                obj.costs
              );
            }
          }
        }
      }
    }

    for (let i = 0; i < projects.length; i++) {
      let project = projects[i];
      let color = getRandomColor(project.name);
      let costs = {
        label: project.name,
        data: project.monthlyCosts,
        backgroundColor: color.color,
        borderColor: color.border,
        maxBarThickness: 10,
      };
      costsForMonthProjects.push(costs);
    }

    let totalCosts = costs.reduce((a, b) => a + b);
    let returnObject = {
      totalCosts: totalCosts,
      costsThisYear: dataThisYear,
      months: formattedMonths,
      costsForMonthTotal: costs,
      costsForMonthProjects: costsForMonthProjects,
    };
    resolve(returnObject);
  });
}

/**
 * Fetch active projects
 * @param snapshot Firebase projects response
 * @returns {Promise<unknown>} An Array containing only active projects
 */
function getActiveProjects(snapshot) {
  return new Promise(function (resolve, reject) {
    let data = snapshot.val();
    if (data !== null) {
      let projectsArray = [];
      for (let key in data) {
        if (!data.hasOwnProperty(key)) continue;
        let projectId = key;
        let projectName;
        let active = true;
        let completed = false;
        let obj = data[key];
        for (let prop in obj) {
          if (!obj.hasOwnProperty(prop)) continue;
          if (!obj.active) active = false;
          if (prop === "name") projectName = obj[prop];
          if (prop === "status" && obj[prop] === "completed") completed = true;
        }
        if (active && !completed) {
          let object = {
            id: projectId,
            name: projectName,
            monthlyCosts: [],
          };
          projectsArray.push(object);
        }
      }
      resolve(projectsArray);
    }
  });
}

export {
  getCosts, calculateCosts, getActiveProjects
};