import React, { useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Media,
  Modal,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import classnames from "classnames";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import firebase from "firebase";
import NotificationAlert from "react-notification-alert";
import notify from "../../../functions/notify";
import beeImage from "assets/img/theme/dash.png";
import { Link } from "react-router-dom";
import urls from "../../../../urls";
import { isAdminPage } from "views/functions/generic";
import Tour from "components/Support/Tour";
import { tourSteps } from "variables/tourSteps";
import { deleteImage, uploadImage } from "../../../functions/images";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Checkbox } from "antd";

const Projects = (props) => {
  const { t } = useTranslation();
  const companyId = localStorage.getItem("compid");
  const [projectId, setProjectId] = useState("");
  const [projects, setProjects] = useState(null);
  const [formModal, setFormModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedProjectsOverviewModal, setDeletedProjectsOverviewModal] =
    useState(false);
  const [editForm, setEditForm] = useState(false);
  const [projectNameFocused, setProjectNameFocused] = useState("");
  const [projectHourlyRateFocused, setProjectHourlyRateFocused] = useState("");
  const [projectBudgetFocused, setProjectBudgetFocused] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectHourlyRate, setProjectHourlyRate] = useState(null);
  const [projectBudget, setProjectBudget] = useState(null);
  const [projectLogo, setProjectLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [editProjectLogo, setEditProjectLogo] = useState(null);
  const [projectStatus, setProjectStatus] = useState("");
  const [deletedProjects, setDeletedProjects] = useState(null);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [onlyActiveProjectsToggle, setOnlyActiveProjectsToggle] =
    useState(true);
  const [filteredProjects, setFilteredProjects] = useState(null);
  const [startTour, setStartTour] = useState(false);
  const steps = tourSteps.projecten;
  const notifyProjectsRef = useRef(null);

  useEffect(() => {
    getProjects();
  }, []);

  /**
   * Fetch projects from Firebase based on companyId.
   * Update the projects state with the projects. If there are no projects,
   * open the modal to create a first project.
   */
  const getProjects = () => {
    let projects = firebase
      .database()
      .ref("projects")
      .orderByChild("companyId")
      .equalTo(companyId);
    projects.on("value", (snapshot) => {
      let data = snapshot.val();
      const entries = Object.entries(data);
      const sorted = _.sortBy(entries, (item) => {
        item[1].id = item[0];
        return item[1].name.toLowerCase();
      });
      if (sorted !== null) {
        setProjects(sorted);
        setFilteredProjects(sorted);
        handleSetFilteredProjects(sorted);
      } else {
        setProjects(null);
        setFilteredProjects(null);
        setFormModal(true);
      }
    });
  };

  const formValidation = () => {
    if (projectName === "") {
      notify("warning", t("meldingen.project_leeg"), notifyProjectsRef);
      return false;
    } else return true;
  };

  /**
   * First, validate the inputs of the form. Then, convert the logo to a base64 string.
   * Push a new entry to the projects endpoint in the database. When done, set all
   * states to the initial values.
   */
  const onSaveProject = () => {
    if (formValidation()) {
      uploadImage(projectLogo)
        .then((projectLogo) => {
          firebase
            .database()
            .ref("projects")
            .push(
              {
                companyId: companyId,
                name: projectName,
                hourlyRate: !!projectHourlyRate
                  ? parseFloat(projectHourlyRate).toFixed(2)
                  : projectHourlyRate,
                logo: projectLogo,
                budget: projectBudget,
                status: "inprogress",
                active: true,
                created_at: firebase.database.ServerValue.TIMESTAMP,
              },
              (error) => {
                if (error) {
                  notify(
                    "success",
                    t("meldingen.foutmelding"),
                    notifyProjectsRef
                  );
                } else {
                  notify(
                    "success",
                    t("meldingen.project_opgeslagen"),
                    notifyProjectsRef
                  );
                  setProjectName("");
                  setProjectHourlyRate(null);
                  setProjectLogo(null);
                  setProjectBudget(null);
                  setFormModal(false);
                }
              }
            );
        })
        .catch(() => {
          notify(
            "warning",
            t("meldingen.afbeelding_upload_gefaald"),
            notifyProjectsRef
          );
        });
    }
  };

  /**
   * First, validate the inputs of the form. Then, convert the logo to a base64 string.
   * Update the corresponding project in the database. When done, set all
   * states to the initial values.
   */
  const onEditProject = () => {
    if (formValidation()) {
      if (!!editProjectLogo && !!logoUrl) deleteImage(logoUrl);
      uploadImage(editProjectLogo)
        .then((projectLogo) => {
          let updateObject = {
            name: projectName,
            hourlyRate:
              projectHourlyRate !== ""
                ? parseFloat(projectHourlyRate).toFixed(2)
                : projectHourlyRate,
            status: projectStatus,
            budget: projectBudget,
          };
          if (projectLogo !== null && !!editProjectLogo)
            updateObject.logo = projectLogo;
          firebase
            .database()
            .ref("projects/" + projectId)
            .update(updateObject, (error) => {
              if (error) {
                notify(
                  "success",
                  t("meldingen.foutmelding"),
                  notifyProjectsRef
                );
              } else {
                notify(
                  "success",
                  t("meldingen.project_opgeslagen"),
                  notifyProjectsRef
                );
                setProjectName("");
                setProjectHourlyRate(null);
                setProjectLogo(null);
                setEditProjectLogo(null);
                setProjectId("");
                setProjectBudget(null);
                setFormModal(false);
                setEditForm(false);
              }
            });
        })
        .catch(() => {
          notify(
            "warning",
            t("meldingen.afbeelding_upload_gefaald"),
            notifyProjectsRef
          );
        });
    }
  };

  /**
   * Get all non-active projects out of projects state and set this to deletedProjects.
   * Open modal that shows all non-active projects.
   */
  const openNonActiveProjectsModal = () => {
    let deletedProjects = projects.filter(function (obj) {
      return obj[1].active === false;
    });
    setDeletedProjects(deletedProjects);
    setDeletedProjectsOverviewModal(true);
  };

  /**
   * Set state of projectToDelete to the clicked project and set deleteModal to true,
   * which opens the modal.
   */
  const onPressDeleteProject = (project) => {
    setProjectToDelete(project);
    setDeleteModal(true);
  };

  /**
   * Set a project's active value to false.
   */
  const onDeleteProject = () => {
    let projectId = projectToDelete[0];
    firebase
      .database()
      .ref("projects/" + projectId)
      .update({ active: false }, (error) => {
        if (error) {
          console.error(error);
        } else {
          notify(
            "success",
            t("meldingen.project_verwijderd"),
            notifyProjectsRef
          );
          setProjectToDelete(null);
          setDeleteModal(false);
        }
      });
  };

  /**
   * Set state of clicked projects and open editForm modal.
   */
  const openEditModal = (project) => {
    setProjectName(project[1].name);
    setProjectHourlyRate(
      !!project[1].hourlyRate ? project[1].hourlyRate.toString() : ""
    );
    setProjectLogo(!!project[1].logo ? project[1].logo : "");
    setLogoUrl(!!project[1].logo ? project[1].logo : "");
    setProjectStatus(!!project[1].status ? project[1].status : "");
    setProjectBudget(!!project[1].budget ? project[1].budget : "");
    setFormModal(true);
    setEditForm(true);
    setProjectId(project[0]);
  };

  /**
   * Set a non-active project's active value to true
   */
  const onRestoreProject = (projectId) => {
    firebase
      .database()
      .ref("projects/" + projectId)
      .update({ active: true }, (error) => {
        if (error) {
          console.error(error);
        } else {
          notify(
            "success",
            t("meldingen.project_teruggezet"),
            notifyProjectsRef
          );
          setDeletedProjectsOverviewModal(false);
        }
      });
  };

  const onClickOnlyActiveProjectsToggle = () => {
    if (onlyActiveProjectsToggle) setFilteredProjects(projects);
    else {
      handleSetFilteredProjects(projects);
    }
    setOnlyActiveProjectsToggle(!onlyActiveProjectsToggle);
  };

  const handleSetFilteredProjects = (projects) => {
    const filtered = _.filter(
      projects,
      (item) => item[1].status !== "completed"
    );
    setFilteredProjects(filtered);
  };

  return (
    <>
      <Tour
        steps={steps}
        continuous={true}
        run={startTour}
        onFinished={() => setStartTour(false)}
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notifyProjectsRef} />
      </div>
      <SimpleHeader
        name={t("dashboard.projecten.projecten")}
        parentName={t("dashboard.bedrijf")}
        isAdminPage={isAdminPage(props.location.pathname)}
        startTour={() => setStartTour(true)}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="4">
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={formModal}
              toggle={() => setFormModal(!formModal)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary border-0 mb-0">
                  <CardHeader className="bg-transparent pb-3">
                    <div className="text-muted text-center mt-2 mb-3">
                      <span className="card-header-title">
                        {editForm
                          ? t("dashboard.projecten.bewerken")
                          : t("dashboard.projecten.aanmaken")}
                      </span>
                      <span className="card-header-subtitle">
                        {projects !== null
                          ? t("dashboard.vul_formulier_in")
                          : t("dashboard.projecten.eerste_aanmaken")}
                      </span>
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-5 py-lg-5">
                    <Form role="form">
                      <FormGroup>
                        <InputGroup
                          className={classnames("input-group-merge", {
                            focused: projectNameFocused,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-user" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder={t("dashboard.projecten.naam")}
                            type="text"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            onFocus={(e) => setProjectNameFocused(true)}
                            onBlur={(e) => setProjectNameFocused(false)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup
                          className={classnames("input-group-merge", {
                            focused: projectHourlyRateFocused,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-euro-sign" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder={t(
                              "dashboard.projecten.tabel.uurtarief.placeholder"
                            )}
                            type="number"
                            step="0.5"
                            value={projectHourlyRate}
                            onChange={(e) =>
                              setProjectHourlyRate(e.target.value)
                            }
                            onFocus={(e) => setProjectHourlyRateFocused(true)}
                            onBlur={(e) => setProjectHourlyRateFocused(false)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup
                          className={classnames("input-group-merge", {
                            focused: projectBudgetFocused,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-clock" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder={t(
                              "dashboard.projecten.tabel.uurbudget.placeholder"
                            )}
                            type="number"
                            value={projectBudget}
                            onChange={(e) => setProjectBudget(e.target.value)}
                            onFocus={(e) => setProjectBudgetFocused(true)}
                            onBlur={(e) => setProjectBudgetFocused(false)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <div className="custom-file">
                          <input
                            className="custom-file-input opacity-0"
                            id="customFileLang"
                            lang="nl"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={(e) =>
                              editForm
                                ? setEditProjectLogo(e.target.files[0])
                                : setProjectLogo(e.target.files[0])
                            }
                          />
                          <label
                            className={
                              !projectLogo && !editProjectLogo
                                ? "custom-file-label"
                                : "custom-file-label text-success"
                            }
                            htmlFor="customFileLang"
                          >
                            {!projectLogo && !editProjectLogo
                              ? t("dashboard.projecten.tabel.logo.placeholder")
                              : t("dashboard.projecten.tabel.logo.gekozen")}
                          </label>
                        </div>
                      </FormGroup>
                      {editForm && (
                        <FormGroup>
                          <Input
                            id="project-status-select"
                            type="select"
                            value={projectStatus}
                            onChange={(e) => setProjectStatus(e.target.value)}
                          >
                            <option value="" disabled>
                              {t("dashboard.projecten.tabel.status.label")}
                            </option>
                            <option value="inprogress">
                              {t("dashboard.projecten.tabel.status.bezig")}
                            </option>
                            <option value="completed">
                              {t("dashboard.projecten.tabel.status.afgesloten")}
                            </option>
                          </Input>
                        </FormGroup>
                      )}
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={() => {
                            setProjectName("");
                            setProjectHourlyRate(null);
                            setProjectLogo(null);
                            setProjectBudget(null);
                            setFormModal(false);
                            setEditForm(false);
                          }}
                        >
                          {t("dashboard.sluiten")}
                        </Button>
                        <Button
                          className="my-4"
                          color="success"
                          type="button"
                          onClick={() =>
                            editForm ? onEditProject() : onSaveProject()
                          }
                        >
                          {t("dashboard.opslaan")}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>
          </Col>
        </Row>
        <Row>
          <div className="col">
            <Card id="projecten-body">
              <CardHeader className="border-0">
                <Row>
                  <Col xs="4">
                    <h3 className="mb-0">
                      {t("dashboard.projecten.projecten")}
                    </h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Button
                      className="btn-round btn-icon"
                      color="info"
                      id="new-project-btn"
                      onClick={(e) => setFormModal(true)}
                      size="sm"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-user-edit" />
                      </span>
                      <span className="btn-inner--text">
                        {t("dashboard.projecten.nieuw")}
                      </span>
                    </Button>
                  </Col>
                  <Col className="text-right" xs="2">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn-icon-only text-muted"
                        id="projecten-verwijderd"
                        color=""
                        role="button"
                        size="sm"
                      >
                        <i className="fas fa-ellipsis-v" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem onClick={onClickOnlyActiveProjectsToggle}>
                          <Checkbox checked={onlyActiveProjectsToggle}>
                            {t("dashboard.projecten.alleenActief")}
                          </Checkbox>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => openNonActiveProjectsModal()}
                        >
                          {t("dashboard.projecten.verwijderd")}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="sort" scope="col">
                      {t("dashboard.projecten.tabel.project")}
                    </th>
                    <th className="sort" scope="col">
                      {t("dashboard.projecten.tabel.uurtarief.label")}
                    </th>
                    <th className="sort" scope="col">
                      {t("dashboard.projecten.tabel.uurbudget.label")}
                    </th>
                    <th className="sort" scope="col">
                      {t("dashboard.projecten.tabel.status.label")}
                    </th>
                    {/* <th className="sort" data-sort="completion" scope="col">
                   Voortgang
                   </th> */}
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody className="list">
                  {!!filteredProjects &&
                    filteredProjects.map((project, i) => {
                      if (project[1].active !== false)
                        return (
                          <tr key={i}>
                            <td scope="row" data-field={"name"}>
                              <Media className="align-items-center">
                                <img
                                  src={
                                    !!project[1].logo
                                      ? project[1].logo
                                      : beeImage
                                  }
                                  className="avatar mr-3 bg-white"
                                  alt="logo"
                                />
                                <Media>
                                  <Link
                                    to={urls.overzichtPerProject + project[0]}
                                  >
                                    <span className="name mb-0 text-sm font-weight-bold">
                                      {project[1].name}
                                    </span>
                                  </Link>
                                </Media>
                              </Media>
                            </td>
                            <td className="budget">
                              {!!project[1].hourlyRate
                                ? "€ " + project[1].hourlyRate
                                : t("dashboard.geen")}
                            </td>
                            <td className="budget">
                              {!!project[1].budget
                                ? project[1].budget
                                : t("dashboard.geen")}
                            </td>
                            <td>
                              <Badge color="" className="badge-dot mr-4">
                                <i
                                  className={
                                    project[1].status === "inprogress"
                                      ? "bg-warning"
                                      : "bg-success"
                                  }
                                />
                                <span className="status">
                                  {project[1].status === "inprogress"
                                    ? t(
                                        "dashboard.projecten.tabel.status.bezig"
                                      )
                                    : t(
                                        "dashboard.projecten.tabel.status.afgesloten"
                                      )}
                                </span>
                              </Badge>
                            </td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  id="projecten-edit"
                                  className="btn-icon-only text-info"
                                  color=""
                                  role="button"
                                  size="sm"
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <DropdownItem
                                    onClick={() => openEditModal(project)}
                                  >
                                    {t("dashboard.bewerken")}
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() =>
                                      onPressDeleteProject(project)
                                    }
                                  >
                                    {t("dashboard.verwijderen")}
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      else return null;
                    })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        <Row>
          <Col md="4">
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={deleteModal}
              toggle={() => {
                setDeleteModal(!deleteModal);
                setProjectToDelete(null);
              }}
            >
              {projectToDelete !== null && (
                <div className="modal-body p-0">
                  <Card className="bg-secondary border-0 mb-0">
                    <CardHeader className="bg-transparent pb-3">
                      <div className="text-muted text-center mt-2 mb-3">
                        <span className="card-header-title">
                          {t("dashboard.projecten.verwijderen")}
                        </span>
                        <span className="text-muted d-block">
                          {t("dashboard.projecten.verwijderen_desc")}
                        </span>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="logo project"
                          className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                          src={
                            !!projectToDelete[1].logo
                              ? projectToDelete[1].logo
                              : beeImage
                          }
                          style={{ width: "140px" }}
                        />
                      </a>
                      <div className="pt-4 text-center">
                        <h5 className="h3 title">
                          <span className="d-block mb-1">
                            {projectToDelete[1].name}
                          </span>
                        </h5>
                      </div>
                      <Form role="form">
                        <div className="text-center">
                          <Button
                            className="my-4"
                            color="primary"
                            type="button"
                            onClick={() => {
                              setDeleteModal(false);
                              setProjectToDelete(null);
                            }}
                          >
                            {t("dashboard.sluiten")}
                          </Button>
                          <Button
                            className="my-4"
                            color="success"
                            type="button"
                            onClick={() => onDeleteProject()}
                          >
                            {t("dashboard.verwijderen")}
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              )}
            </Modal>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Modal
              className="modal-dialog-centered"
              size="md"
              isOpen={deletedProjectsOverviewModal}
              toggle={() =>
                setDeletedProjectsOverviewModal(!deletedProjectsOverviewModal)
              }
            >
              {!!deletedProjects && (
                <div className="modal-body p-0">
                  <Card className="bg-secondary border-0 mb-0">
                    <CardHeader className="bg-transparent pb-3">
                      <div className="text-muted text-center mt-2 mb-3">
                        <span className="card-header-title">
                          {t("dashboard.projecten.verwijderd")}
                        </span>
                        <span className="text-muted d-block">
                          {t("dashboard.projecten.verwijderd_desc")}
                        </span>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-3 py-lg-3">
                      {deletedProjects.length > 0 ? (
                        deletedProjects.map((project, i) => {
                          return (
                            <Row
                              key={i}
                              className={
                                i % 2 === 0
                                  ? "pb-4 pt-4"
                                  : "bg-lighter pb-4 pt-4"
                              }
                            >
                              <Col xs="6" className="align-self-center">
                                <h3 className="mb-0 text-center">
                                  {project[1].name}
                                </h3>
                              </Col>
                              <Col xs="6" className="text-center">
                                <Button
                                  color="info"
                                  type="button"
                                  onClick={() => onRestoreProject(project[0])}
                                >
                                  {t("dashboard.projecten.terugzetten")}
                                </Button>
                              </Col>
                            </Row>
                          );
                        })
                      ) : (
                        <h3 className="mt-3 mb-3 text-center">
                          {t("dashboard.projecten.geen_verw_projecten")}
                        </h3>
                      )}
                      <Row className="justify-content-center mt-3">
                        <Button
                          color="primary"
                          type="button"
                          onClick={() => setDeletedProjectsOverviewModal(false)}
                        >
                          {t("dashboard.sluiten")}
                        </Button>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              )}
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Projects;
