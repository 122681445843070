import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import firebase from "firebase";
import urls from "urls.js";
import routes from "routes.js";
import Cookies from "js-cookie";
import { CookieNotice } from "react-cookienotice";
import "react-cookienotice/dist/style.css";
import i18n from "../variables/translations/i18n";

class Auth extends React.Component {
  state = {
    isAuthenticated: null,
  };

  componentDidMount() {
    let self = this;
    /** Check if user is already authenticated. If so, go to dashboard */
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        let userData = firebase.database().ref("users/" + user.uid);
        userData.on(
          "value",
          (snapshot) => {
            /** Set Name and UID to localstorage so we won't have to call firebase for that all the time */
            let obj = snapshot.val();
            if (!!obj) {
              if (obj.active !== false) {
                localStorage.setItem("uid", obj.id);
                localStorage.setItem("userName", obj.name);
                localStorage.setItem("compid", obj.companyId);
                self.setState({ isAuthenticated: true });
              }
            }
          },
          (error) => {
            console.error(error);
          }
        );
      } else {
        self.setState({ isAuthenticated: false });
      }
    });
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    if (this.state.isAuthenticated) return <Redirect to={urls.dashboard} />;

    const cookieValue = Cookies.get("allow-cookies");
    return (
      <>
        <div className="main-content" ref="mainContent">
          <AuthNavbar />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/auth/login" />
          </Switch>
        </div>
        <div className={typeof cookieValue !== "undefined" && "d-none"}>
          <CookieNotice
            titleLabel={i18n.t("cookiebar_text")}
            descriptionLabel=""
            acceptAllButtonLabel="OK"
            readMoreLabel={i18n.t("cookiebar_meer_lezen")}
            readMoreLink="https://dashbee.nl/privacy"
            readMoreInNewTab
            hideDeclineButton
            declineAllButtonLabel={""}
          />
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Auth;
