import {Badge, Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import { Link } from "react-router-dom";
import React from "react";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import i18n from "variables/translations/i18n";

const PricingCard = (props) => {
  return (
    <Card
      className={`card-pricing test border-0 text-center mx-4 ${
        props.isDisabled && "opacity-5"
      }`}
    >
      <CardHeader className="bg-transparent" id={"price-header-" + props.id}>
        {props.active && !props.cancelled &&
        <Row className="justify-content-between">
          <Col className="col-auto">
            <Badge className="badge-lg" color={props.activeColor}>
              {i18n.t("prijzen_pagina.actief")}
            </Badge>
          </Col>
          <Col className="col-auto">
             <span className={`text-xs ${props.activeColorText}`}>
              {props.subscriptionPeriod}
             </span>
          </Col>
        </Row>
        }
        {props.active && props.cancelled &&
        <Row className="justify-content-between">
          <Col className="col-auto">
            <Badge className="badge-lg" color={props.activeColor}>
              {i18n.t("prijzen_pagina.geannuleerd")}
            </Badge>
          </Col>
        </Row>
        }
        <h4
          className={`text-uppercase ls-1 py-3 mb-0 align-center ${props.cardTextColor}`}
        >
          {props.cardTitle}
        </h4>
        {props.isDisabled && (
          <UncontrolledTooltip delay={0} target={"price-header-"+ props.id}>
            {i18n.t("dashboard.abonnement.prijs_tooltip_gebruikers")}
          </UncontrolledTooltip>
        )}
      </CardHeader>
      <CardBody className="px-lg-7">
        {!!props.oldPrice && (
          <div
            className="display-4 text-muted"
            style={{ textDecoration: "line-through" }}
          >
            {props.oldPrice}
          </div>
        )}
        <div className={`display-2 ${props.cardTextSecondColor}`}>
          {props.subscriptionPrice}
        </div>
        <span className={`${props.monthColor}`}>{props.monthText}</span>
        <ul className="list-unstyled my-4">
          <li>
            <div className="d-flex align-items-center">
              <div>
                <div className={props.iconClass}>
                  <i className={props.icon} />
                </div>
              </div>
              <div>
                <span className={props.firstBulletClass}>
                  {props.firstBullet}
                </span>
              </div>
            </div>
          </li>
          <li>
            <div className="d-flex align-items-center">
              <div>
                <div className={props.secondBulletIconClass}>
                  <i className={props.secondBulletIcon} />
                </div>
              </div>
              <div>
                <span className={props.secondBulleClass}>
                  {props.secondBulletText}
                </span>
              </div>
            </div>
          </li>
          <li>
            <div className="d-flex align-items-center">
              <div>
                <div className={props.thirdBulletIconClass}>
                  <i className={props.thirdBulletIcon} />
                </div>
              </div>
              <div>
                <span className={props.thirdBulletClass}>
                  {props.thirdBulletText}
                </span>
              </div>
            </div>
          </li>
        </ul>
        <Button
          id={"card-" + props.id}
          className="mb-3"
          color={props.buttonClass}
          type="button"
          to={
            {
              pathname: props.buttonUrl, 
              authorized: props.authorized,
              priceId: props.priceId,
              subscriptionType: props.subscriptionType,
              duration: props.duration
            }
          }
          tag={Link}
          disabled={props.isDisabled}
        >
          {props.buttonText}
        </Button>
        {props.active && !props.cancelled &&
        <p className="text-xs">
          <span className={`text-${props.activeColor} mr-2`}>
              {props.renewalText}   
              <br/>   
              {props.renewalDate}
          </span>
        </p>
        }
        {props.active && props.cancelled &&
        <p className="text-xs">
          <span className={`text-${props.activeColor} mr-2`}>
              {props.renewalText} {props.renewalDate}
          </span>
        </p>
        }
        
      </CardBody>
    </Card>
  );
};

export default PricingCard;
