import React from "react";
import testimonialData from "../../data/testimonial/testimonial.json";
import TestimonialSingle from "../../components/Testimonials/TestimonialSingle.js";
import i18n from "../../variables/translations/i18n";

const Testimonials = () => {
  return (
    <div className="container testimonial-area">
      <h2 className="display-4 text-center mb-4">
        {i18n.t("testimonials_title")}
      </h2>
      <div className="text-center">
        <div className="row">
          <div className="col-lg-10 col-md-10 ml-auto mr-auto">
            {testimonialData &&
              testimonialData.map((single, key) => {
                return <TestimonialSingle data={single} key={key} />;
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;