export const EN = {
  cookiebar_text: "DashBee uses cookies to ensure an awesome user experience",
  cookiebar_meer_lezen: "Read More",
  contact_bedrijf: "Gideloff BV",
  contact_kvk: "KVK - 81756356",
  contact_straat: "Gooimeer 3B",
  contact_postcode_land: "1411 DC Naarden - The Netherlands",
  contact_telefoon: "(035) 20 300 44",
  contact_email: "info@gideloff.nl",
  header_titel: "DashBee offers startups structure",
  header_subtitel:
    "By digitizing business processes and providing insightful data analyses",
  nav_dashboard: "Dashboard",
  nav_prijzen: "Pricing",
  nav_contact: "Contact",
  nav_login: "Login",
  nav_privacy: "Privacy Policy",
  nav_voorwaarden: "Terms & Conditions",
  nav_home: "Home",
  bedrijf_aanmelden_btn: "Try For Free",
  contact_btn: "Contact us",
  show_pricing: "See pricing",
  urenregistratie: "Time registration",
  urenregistratie_desc:
    "Register all worked hours of your company per project in one place!",
  gedeelde_agenda: "Shared calendar",
  gedeelde_agenda_desc:
    "One calendar in which all company activities and holidays are visible for everyone.",
  verlof_administratie: "Leave administration",
  verlof_administratie_desc:
    "Easily request and process off days for everyone in the company.",
  admin_panel: "Data analysis",
  admin_panel_desc:
    "View all collected data, draw conclusions and recognize patterns from the analysis.",
  homepage_titel:
    "A dashboard for administration, registration and data analysis",
  homepage_desc_1:
    "Did you know that a hive can consist of 60,000 bees? Each 🐝 with its own task! Quite a bit of administration you'd think, not with DashBee!",
  homepage_desc_2:
    "DashBee digitizes daily tasks and brings structure to your company. All business processes are registered and managed in the dashboard. Our focus on usability and simplicity allows your company to complete administrative tasks faster, giving you more time for company-specific tasks. A nice side effect is the fact that the registration and administration within DashBee will give you insights about your own company in the form of data analysis.",
  homepage_desc_3:
    "Register your company and become the Queen Bee yourself, add Worker Bees, handle time registration and leave requests and ",
  homepage_desc_4: "Become a productive hive!",
  homepage_card1_titel: "ACCESSIBLE USER INTERFACE",
  homepage_card2_titel: "COMPANY-WIDE REGISTRATION",
  homepage_card3_titel: "SUBLIME SUPPORT",
  homepage_card1_desc:
    "DashBee is web-based and therefore always accessible to everyone. The dashboard is built to be user-friendly for all users and can be set up per user. Just add a team member, select a roll and invite! Easy as that.",
  homepage_card2_desc:
    "As a Queen Bee you can view the entire administration. Invoice hours and process leave requests with just the push of a button. Only activate the modules that are relevant to your company to keep the user-friendliness optimal.",
  homepage_card3_desc:
    "DashBee was developed by Gideloff, a software development company that maintains close contact with its clients. If you have any questions, comments or need for customization within DashBee, please contact us!",
  homepage_question_1: "Why would I want to store my own company data?",
  homepage_quote_1:
    "Digitization is everywhere these days, companies are increasingly focusing on digitalization because it can save a lot of time and money. In addition, data has recently become increasingly important, mainly for analyzing data. Analyzing data allows you to learn from the past, draw conclusions and recognize patterns. This also applies to your own company. DashBee makes it possible to easily store and analyze company data for great insights.",
  homepage_quote_2:
    "DashBee digitizes daily tasks and brings structure to your company.",
  homepage_feature1_titel: "DashBee modules",
  homepage_feature2_titel: "Time registration",
  homepage_feature3_titel: "Leave administration",
  homepage_feature4_titel: "Shared company agenda",
  homepage_feature5_titel: "Generate invoicing",
  homepage_feature6_titel: "Company specific modules",
  homepage_feature1_desc:
    "The dashboard offers several modules that simplify the management of a company. DashBee is a platform where all employees and employers can easily arrange matters such as requesting and handling leave days and registering hours per person and per project.",
  homepage_feature2_desc:
    "Everyone can register their hours within DashBee. Queen Bees can view the hours of the company in total and per project. Queen Bees can generate invoices based on the hours or export the hours to Excel.",
  homepage_feature3_desc:
    "With DashBee, leave requests can be handled from one place. Worker Bees can easily make requests within the dashboard. The Queen Bee can then confirm or reject the request.",
  homepage_feature4_desc:
    "With the shared calendar you can create a work environment where it is clear to everyone what is planned for the week, who is absent for the day and everyone is informed of the latest updates. As a Queen Bee, you can create events to share with the entire team. One company, one agenda!",
  homepage_feature5_desc:
    "Why use tools like Excel for registering your hours and creating invoices when you can generate professional invoices with only one click? DashBee makes it possible to generate invoices based on date, project hours and costs. Personalize your company invoice with your own logo and company details and save all your invoices in one place.",
  homepage_feature6_desc:
    "DashBee is a universal platform and therefore applicable to different types of companies and industries. Of course it is also possible to expand DashBee with extra modules. For this you can contact us!",
  homepage_feature1_bullets: [
    "Time registration",
    "Leave administration",
    "Shared company calendar",
    "Administration costs",
    "Generate invoicing",
  ],
  homepage_feature2_bullets: [
    "Register hours per Worker Bee",
    "Register hours per Project",
    "Generate invoice based on hours",
    "Export to Excel",
  ],
  homepage_feature3_bullets: [
    "Make an application easily",
    "Treatment by Queen Bee",
    "Leave balance",
    "Shared calendar",
  ],
  homepage_feature4_bullets: [
    "One agenda for all Bees",
    "Create & Share events",
    "Oversee all leave requests",
    "Project Planning in calendar",
  ],
  homepage_feature5_bullets: [
    "Customer registration",
    "Generate invoice based on hours",
    "Generate invoice based on project",
    "Personalized invoice",
  ],
  homepage_feature6_bullets: [],
  footer_sub_titel: "Offers entrepreneurs structure",
  testimonials_title: "What are our DashBee users saying?",
  auth: {
    login_titel: "Welcome",
    login_subtitel: "Let's get to work!",
    email: "E-mail",
    telephone: "Phone number",
    wachtwoord: "Password",
    wachtwoord_vergeten: "Forgot password?",
    onthouden: "Remember me",
    login_btn: "Login",
    verificatie_opnieuw: "Re-send verification link",
    registratie: {
      titel1: "Create account",
      subtitel1: "Before we register your company, let's make a user account",
      titel2: "Your account has been created!",
      subtitel2: "Let's register your company now",
      instructie1: "Enter your account information",
      instructie2: "Enter your company information",
      naam: "First and last name",
      bedrijfsnaam: "Company name",
      rechtsvorm: "Legal form / Type",
      eenmanszaak: "Proprietorship / Personal / One-man team",
      bv: "VOF / BV / NV / CV / Foundation / Company",
      akkoord: "I agree to the ",
      submit_btn1: "Create Account",
      submit_btn2: "Create Company",
      inloggen: "Login",
      privacy: "Privacy Policy",
    },
    reset: {
      titel: "Recover Password",
      subtitel: "Use the form to request a new password",
      instructie: "Enter your email address",
      submit_btn: "Reset password",
      inloggen: "Login",
      alert: {
        titel: "Success",
        text_btn: "To login",
        text: "Check your mailbox for the reset link",
      },
    },
    uitnodiging: {
      titel: "Welcome!",
      subtitel: "Use the form to register",
      registreren_bij: "Register with ",
      ongeldig: "Invalid link",
      instructie: "Enter your email address",
      submit_btn: "Register",
      alert: {
        text1: "A verification email has been sent to ",
        text2: ". Click on the link to verify your email address.",
        text_btn: "To login",
        text: "Check your mailbox for the reset link",
      },
    },
  },
  prijzen_pagina: {
    pagina_titel: "Choose the right plan for your business",
    tijdelijk_gratis: "(Free for now)",
    freebee_titel: "FreeBee - 30 day trial",
    pagina_desc:
      "DashBee can be used free of charge for one month. To maintain our hive, we charge a jar of honey after this period based on the amount of users. ",
    pagina_slogan: "Keep the hive alive!",
    registreer_btn: "Try DashBee FOR FREE",
    demo_btn: "Demo Dashboard",
    meest_gekozen: "Most chosen",
    automatisch_verlengd: "Will be automatically renewed on",
    automatisch_aflopen: "Active till",
    maand_abonnement: "Monthly",
    actief: "Active",
    geannuleerd: "Cancelled",
    jaar_abonnement: "Yearly",
    gratis_dagen: "30 days",
    excluding_tax:
      "* All our prices are exclusive of VAT and include personal support. No payment is required for the 30 day FreeBee period.",
    larva_pack: {
      titel: "Larva Pack",
      naam_small: "L",
      per_gebruiker: "per month",
      onder_per_gebruiker: "",
      feature1: "30 days FreeBee",
      feature2: "For freelancers",
      feature3: "Direct support",
      feature4: "1 Bee",
    },
    hive_pack: {
      titel: "Hive Pack",
      naam_small: "H",
      per_gebruiker: "per month",
      onder_per_gebruiker: "",
      feature1: "30 days FreeBee",
      feature2: "For SMEs",
      feature3: "Direct support",
      feature4: "2 - 20 Bees",
    },
    honey_factory_pack: {
      titel: "Honey Factory",
      naam_small: "F",
      per_gebruiker: "per month",
      onder_per_gebruiker: "",
      feature1: "30 days FreeBee",
      feature2: "For big companies",
      feature3: "Direct support",
      feature4: "21 - 60 Bees",
    },
    cards: {
      card1_titel: "Monthly terminable",
      card1_desc:
        "When getting a DashBee subscription, you can choose to pay monthly or annually. A monthly subscription can be stopped every month. With an annual subscription, payment is made in advance for the entire year. If canceled prematurely, the company will keep access to DashBee until the end date of the contract. After that, the contract will be terminated.",
      card1_badge1: "monthly",
      card1_badge2: "automatically",
      card1_badge3: "annually",
      card2_titel: "Secure data",
      card2_desc:
        "The data that is entered and / or loaded into DashBee is stored securely. DashBee takes the privacy of the users and sensitivity of company data into account. All sensitive information is stored encrypted. All data is stored in Northwestern Europe. It is also possible to export all your data to CSV or Excel for external applications.",
      card2_badge1: "encryption",
      card2_badge2: "Europe",
      card2_badge3: "privacy",
      card3_titel: "Superb support",
      card3_desc:
        "We do our best to make DashBee as user-friendly as possible, yet it may happen that someone does not understand a certain function or cannot find certain information. We are happy to help and explain the possibilities of DashBee. Found a bug? Please, let us know! We are available 24/7 for support!",
      card3_badge1: "24/7",
      card3_badge2: "face-to-face",
      card3_badge3: "by phone",
    },
    tabel: {
      functies: "Functionality",
      larva: "Larva Pack",
      hive: "Hive Pack",
      factory: "Honey Factory",
      features: [
        { titel: "Time registration", larva: true, hive: true, factory: true },
        { titel: "Company calendar", larva: true, hive: true, factory: true },
        { titel: "Projects", larva: true, hive: true, factory: true },
        { titel: "Clients", larva: true, hive: true, factory: true },
        { titel: "Costs", larva: true, hive: true, factory: true },
        { titel: "Generate invoices", larva: true, hive: true, factory: true },
        {
          titel: "Monthly new features",
          larva: true,
          hive: true,
          factory: true,
        },
        {
          titel: "Leave administration",
          larva: false,
          hive: true,
          factory: true,
        },
        { titel: "Team", larva: false, hive: true, factory: true },
      ],
    },
  },
  contact_pagina: {
    contact_pagina: "contact page",
    pagina_titel: "We would love to get in touch with you!",
    contact_form_titel: "Contact without obligation",
    contact_desc_1:
      "Customers are like teeth. If you don't take care of them they will go away one by one until there are none left...",
    contact_desc_2:
      "Wherever you go, whatever you do, DashBee will 🐝 there, supporting you.",
    contact_form_desc:
      "Do you have any questions regarding DashBee or would you like to receive more information? Fill out the form below and you'll hear from us as soon as possible!",
    copy: "Click & Copy",
    contact_form_label_1: "How can we reach you?",
    contact_form_label_2: "What would you like to know?",
    contact_form_label_3: "Tell us more about your questions/comments",
    contact_form_placeholder_3:
      "How can I add team members to my project? I can be reached on WhatsApp all day long. Regards, Tim",
    contact_form_onderwerpen_1: "Demo / Introduction",
    contact_form_onderwerpen_2: "Customization",
    contact_form_onderwerpen_3: "Pricing",
    contact_form_onderwerpen_4: "Support",
    contact_form_onderwerpen_5: "Other questions",
    contact_form_foutmelding_1: "Enter your email address",
    contact_form_foutmelding_2: "Enter your message",
    verstuur_btn: "Please contact me",
    bericht_ontvangen:
      "Your message has been sent to the DashBee-hive. One of our Bees will contact you as soon as possible! ;-).",
    support_desc: "",
    support: {
      support_titel: "How can we help you?",
      support_desc:
        "Drop all your questions here, we will contact you within 24 hours. For urgent questions you can contact us directly by phone. We are happy to help you!",
      supporter_name: "Leendert Eloff",
      supporter_location: "Amsterdam, The Netherlands",
      supporter_quote: "Your favorite Helper Bee",
      supporter_phone: {
        number: "0644541305",
        href: "tel:0644541305",
        whatsapp: "https://wa.me/+31644541305",
      },
      support_message:
        "Hi, I'm Leendert and happy to help you with your questions :) Need a quick answer to your questions? Send me a WhatsApp message or call me on",
      support_topic_titel: "What are we working on?",
      support_topic_1: "Provide insight into hours per week / user",
      support_topic_2: "Access control for each module per user",
      support_faq_link: "Check our Frequently Asked Questions section on the ",
    },
  },
  privacy_beleid: {
    tldr: {
      titel: "TLDR",
      text: "DashBee processes minimal personal data. When creating an account, data is stored with regard to your profile. This includes your name, email address and an optional photo. All data from DashBee is stored encrypted and sent over a secure connection. We also use Google Analytics to measure website traffic. Your IP address is stored in order to find out where website visitors are located. This is for analytical purposes only. DashBee uses cookies. On the one hand to ensure that your data can be displayed quickly in the dashboard and on the other hand to be able to measure website traffic.",
    },
    privacy_verklaring: {
      titel: "Privacy statement",
      text: "DashBee processes your personal data because you use our services and / or because you provide this information to us yourself. Below you will find an overview of the personal data that we process:",
      bullets: [
        "First and last name, email address, profile picture (only if you have an account)",
        "Information about your activities on our website",
        "Payment info (if you purchase a subscription)",
      ],
    },
    verzamelde_gegevens: {
      titel: "Special and / or sensitive personal data that we process",
      text: "Our website and / or service does not intend to collect data about website visitors under the age of 16. Unless they have permission from their parents or guardian. However, we cannot check whether a visitor is older than 16. We therefore advise parents to be involved in the online activities of their children, in order to prevent data about children from being collected without parental consent. If you are convinced that we have collected personal information about a minor without this permission, please contact us via info@gideloff.nl and we will delete this information.",
    },
    doel: {
      titel: "For what purpose and on what basis we process personal data",
      text: "DashBee processes your personal data for the following purposes: To track website traffic and to make the dashboard work properly.",
    },
    besluitvorming: {
      titel: "Automated decision-making",
      text: "DashBee does not make decisions based on automated processing on matters that can have (significant) consequences for people. These are decisions taken by computer programs or systems, without involving a person (for example, an employee of DashBee).",
    },
    tijd: {
      titel: "How long do we store personal data",
      text: "DashBee does not store your personal data longer than is strictly necessary to achieve the purposes for which your data is collected. We use the following retention periods for the following (categories) of personal data:",
      bullets: [
        "Data from your DashBee account after deleting your account: 12 months",
        "Information about your activities on our website: 26 months",
        "Invoice and payment data - 7 years",
      ],
    },
    delen: {
      titel: "Sharing of personal data with third parties",
      text: "DashBee only provides to third parties and only if this is necessary for the execution of our agreement with you or to comply with a legal obligation.",
    },
    cookies: {
      titel: "Cookies, or similar techniques, that we use",
      text: "DashBee uses functional, analytical and tracking cookies. A cookie is a small text file that is stored in the browser of your computer, tablet or smartphone when you first visit this website. DashBee uses cookies with a purely technical functionality. These ensure that the website works properly and that, for example, your preferred settings are remembered. These cookies are also used to make the website work properly and to optimize it. In addition, we place cookies that keep track of your surfing behavior on this website. This is strict for analytical purposes. On your first visit to our website, we have already informed you about these cookies and we have asked for your permission to place them. You can opt out of cookies by setting your internet browser so that it no longer stores cookies. In addition, you can also delete all information previously saved via your browser settings.",
    },
    inzien: {
      titel: "View, modify or delete data",
      text: "You have the right to view, correct or delete your personal data. This means that you can submit a request to us to send the personal data that we have about you in a computer file to you or another organization mentioned by you. If you want to make use of your right to object and / or right to data portability or if you have other questions / comments about data processing, please send a specified request to info@gideloff.nl. Gideloff will respond to your request as soon as possible, but in any case within four weeks.",
    },
    beveiligen: {
      titel: "How we protect personal data",
      text: "DashBee takes the protection of your data seriously and takes appropriate measures to prevent misuse, loss, unauthorized access, unwanted disclosure and unauthorized changes. If you feel that your data is not properly secured or there are indications of abuse, please contact our customer service or via info@gideloff.nl. DashBee has taken the following measures to protect your personal data: TLS (formerly SSL) We send your data via a secure internet connection. You can see this at the address bar 'https' and the padlock in the address bar. We store all data relating to your DashBee account and the data associated with it encrypted in our database.",
    },
  },
  dashboard: {
    ja: "Yes",
    nee: "No",
    bewerken: "Edit",
    verwijderen: "Delete",
    opslaan: "Save",
    sluiten: "Close",
    toevoegen: "Add",
    per_project: "Per Project",
    totaal: "Total",
    bedrijf: "Company",
    vul_formulier_in: "Fill in the form",
    excel_export: "Export to Excel",
    geen: "None",
    taal_kiezen: "Choose language",
    all_projects: "All projects",
    all_users: "All users",
    navbar: {
      meldingen1: "You have ",
      meldingen2: " notifications",
      welkom: "Welcome!",
      profiel: "My profile",
      bedrijf: "My company",
      abonnement: "Subscription",
      instellingen: "Settings",
      uitloggen: "Logout",
      urenregistratie: "Time registration",
      verlof: "Leave",
      kalender: "Calendar",
      admin: {
        aanvragen: "Leave requests",
        uren: "Hours",
        kosten: "Costs",
        projecten: "Projects",
        team: "Team",
        facturen: "Invoices",
        klanten: "Clients",
      },
    },
    profiel: {
      groet: "Hi ",
      desc: "This is your profile page. This is where you can view and adjust your data.",
      bewerken: "Edit profile",
      gebruikers_info: "User Info",
      rol_info: "Your role in ",
      naam: "Name",
      email: "Email address",
      profielfoto: {
        label: "Profile picture",
        gekozen: "Profile picture chosen",
      },
      functie: "Function",
      dashbee_rol: "DashBee Role",
    },
    uren: {
      uren: "Hours",
      titel: "Time registration",
      totaal: "Hours",
      recent: "Recent hours ",
      omzet_uren: "Revenue from hours",
      gewerkt: "Worked hours ",
      toevoegen: "Add hours",
      aanpassen: "Edit hours",
      per_maand: "Per month",
      deze_week: "This week",
      rijen1: "Show ",
      rijen2: " rows",
      alleenFactureerbaar: "Only invoiceable",
      datum: {
        label: "Date",
        placeholder: "Choose a date",
      },
      aantal: {
        label: "Number of hours",
        placeholder: "Choose number of hours",
      },
      factureerbaar: {
        label: "Are the hours invoiceable?",
      },
      project: {
        label: "Project",
        placeholder: "Choose project",
      },
      opmerkingen: {
        label: "Notes",
        placeholder: "Optional",
      },
      kosten: {
        label: "Costs (€)",
        placeholder: "Enter costs",
      },
      kosten_type: {
        label: "Costs type",
        placeholder: "Choose costs type",
      },
      gebruiker: {
        label: "User",
        placeholder: "Who did the work?",
        tooltip: "Only Queen Bees can pick a user",
      },
      tabel: {
        subtitel: "Your most recent hours this year",
        subtitel_bedrijf: "Recently worked hours",
        zoeken: "Search:",
        zoeken_placeholder: "Enter search term",
        datum: "Date",
        uren: "Hours",
        project: "Project",
        opmerkingen: "Notes",
        acties: "Actions",
        wie: "Who",
        omzet: "Revenue",
        factureerbaar: "Invoiceable",
      },
      uurtarief: "Hourly Rate: €",
      uurbudget: " hours left",
      geen_uurtarief: "No hourly rate set",
      geen_uurbudget: "No budget set",
      gewerkt_budget: "Worked/Budget",
      omzet_budget: "Revenue/Budget",
    },
    verlof: {
      titel: "Off days",
      saldo: "Leave balance",
      verbruikt: "Off days used",
      recent: "Recent requests",
      nieuw_btn: "New Requests",
      submit_btn: "Request",
      dag_eenvoud: "Day",
      dag_meervoud: "Days",
      geaccepteerd: "Accepted",
      afgekeurd: "Rejected",
      aangevraagd: "Requested",
      aanpassen: "Edit request",
      verwijderen: "Delete request",
      verwijderen_uitleg:
        "The leave will also be removed from the calendar. Are you sure?",
      aanvragen: "Request leave",
      bewerken: "Edit request",
      hele_dag: "Entire day",
      van_tot: "From - Until",
      periode: "Period",
      welke_dag: "What day",
      van: "From",
      tot: "Until",
      tot_met: "Up to and including",
      type: "Off day type",
      prive: "Personal",
      zakelijk: "Business",
      zakelijk_uitleg: "Business leave is not deducted from the balance",
      opmerking: "Notes",
      onbehandeld: "Unhandled requests",
      behandeld: "Handled requests",
      accepteren: "Accept",
      weigeren: "Reject",
      aanvraag_van: "Request from",
      tabel: {
        datum: "Date",
        van: "From",
        tot: "Until",
        type: "Type",
        opmerkingen: "Notes",
        status: "Status",
        behandelaar: "Handler",
        wie: "Who",
      },
    },
    kalender: {
      titel: "Calendar",
      maand: "Month",
      week: "Week",
      dag: "Day",
      vrij: " free",
      vrij_dag: " free (whole day)",
      evenement: {
        form_titel: "New calendar item",
        form_subtitel: "Fill in the form",
        titel: { label: "Title", placeholder: "Company outing" },
        beschrijving: {
          label: "Description",
          placeholder: "We're going to do something fun",
        },
        hele_dag_vrij: " is out of the office for the entire day",
        dag_periode_vrij: " is out from ",
        tot_en_met: " to ",
      },
    },
    kosten: {
      kosten: "Costs",
      totaal: "Total costs ",
      gemaakt: "Costs incurred ",
      toevoegen: "Add costs",
      aanpassen: "Edit costs",
      recent: "Recent costs ",
      recent2: "Most recent costs this year",
      administratie: "Administration",
      inkoop: "Inventory",
      marketing: "Marketing",
      service: "Service",
      transport: "Transport",
      overig: "Other",
      tabel: {
        datum: "Date",
        kosten: "Costs (€)",
        project: "Project",
        type: "Costs type",
        opmerkingen: "Notes",
        acties: "Actions",
        wie: "Who",
      },
    },
    projecten: {
      projecten: "Projects",
      nieuw: "New Project",
      verwijderd: "Deleted projects",
      verwijderd_desc:
        "When you restore a project, users can see it again and add hours to it.",
      aanmaken: "Add project",
      bewerken: "Edit project",
      terugzetten: "Restore",
      verwijderen: "Delete project",
      verwijderen_desc:
        "Hours of this project remain, but are no longer linked to a project.",
      geen_verw_projecten: "No deleted projects",
      eerste_aanmaken: "Create your first project here",
      naam: "Project name",
      alleenActief: "Only active projects",
      tabel: {
        uurtarief: {
          label: "Hourly rate",
          placeholder: "Hourly rate (optional)",
        },
        uurbudget: {
          label: "Budget in hours",
          placeholder: "Budget in hours (optional)",
        },
        logo: {
          label: "Logo",
          placeholder: "Logo (optional)",
          gekozen: "Logo chosen",
        },
        status: { label: "Status", bezig: "Ongoing", afgesloten: "Finished" },
        project: "Project",
        acties: "Actions",
      },
    },
    klanten: {
      klanten: "Clients",
      nieuw: "New Client",
      aanmaken: "Create client",
      bewerken: "Edit client",
      verwijderen: "Delete client",
      eerste_aanmaken: "Create your first client",
      naam: "Client name",
      nummer: "Client number",
      straat: "Street",
      huisnummer: "House number",
      postcode: "Zipcode",
      stad: "City",
      email: "Email",
      contact_persoon: "Contact name",
      tabel: {
        logo: {
          label: "Logo",
          placeholder: "Logo (optional)",
          gekozen: "Logo chosen",
        },
        klant: "Client",
        contact: "Contact",
        acties: "Actions",
      },
    },
    facturen: {
      factuur: "Invoice",
      facturen: "Invoices",
      document_laden: "Loading document...",
      downloaden: "Download invoice",
      opslaan: "Save invoice",
      genereren: "Generate invoice",
      voorbeeld: "Invoice preview",
      mijn_facturen: "My invoices",
      facturen_leeg: "You have made no invoices yet",
      verwijderen: "Delete invoice",
      download_btn: "Download",
      bedrijfsnaam: "Company name",
      bic: "BIC",
      btw_nr: "VAT number",
      stad: "City",
      email: "E-mail on invoice",
      huisnr: "House number",
      iban: "Account number",
      kvk_nr: "CoC number",
      telefoonnr: "Phone number",
      straat: "Street",
      bedrijfstype: "Company type",
      postcode: "Zipcode",
      klant_stad: "City of client",
      klant_email: "E-mail of client",
      klant_straat: "Street of client",
      klant_huisnr: "House number of client",
      klant_postcode: "Zipcode of client",
      factuur_tav: "attn. ",
      factuur_nummer: "Invoice nr: ",
      factuur_datum: "Invoice date: ",
      factuur_adres: "Address: ",
      factuur_kvk: "CoC nr: ",
      factuur_btw: "VAT nr: ",
      factuur_iban: "IBAN: ",
      factuur_bic: "BIC: ",
      factuur_tel: "Phone: ",
      factuur_email: "Email: ",
      factuur_uren: "Hours",
      factuur_kosten: "Costs",
      factuur_periode: "Period",
      factuur_prijs: "Price",
      factuur_totaal_btw: "Total VAT 21%",
      factuur_betalen: "Amount to be paid",
      tabel: {
        klant: {
          label: "Client",
          placeholder: "Choose client",
        },
        van: {
          label: "Period from",
          placeholder: "Start date",
        },
        tot: {
          label: "Period until",
          placeholder: "End date",
        },
        kosten: {
          label: "Include costs",
          ja: "Yes",
          nee: "No",
        },
      },
    },
    mijn_bedrijf: {
      pagina_titel:
        "This is the company page. This is where you can view and adjust your company data.",
      adres: "Address",
      straat: "Street",
      huisnummer: "House number",
      postcode: "Zipcode",
      stad: "City",
      contact: "Contact",
      telefoonnr: "Phone number",
      email: "E-mail",
      kvk: "Chamber of Commerce",
      kvk_nr: "CoC number",
      btw_nr: "VAT number",
      bank: "Bank",
      iban: "IBAN",
      bic: "BIC",
      logo: "Company logo",
      logo_gekozen: "Logo chosen",
      logo_kies: "Choose company logo",
    },
    instellingen: {
      instellingen_titel: "Settings",
      instellingen_subtitel:
        "This is the settings page. This is where you can view and adjust your personal settings.",
      instellingen_subtitel_admin:
        "This is the settings page. This is where you can view and adjust your personal and company settings.",
      instellingen_beheren: "Manage personal settings",
      modules_beheren: "Manage company modules",
      notificaties: {
        algemeen: "notifications",
        verlofaanvragen: "Receive emails regarding off days",
        promotie_mails: "Receive promotion mails",
      },
      toegang: {
        algemeen: "access",
        beheren_verlofaanvragen: "Leave requests",
        genereren_factuur: "Invoicing",
        aanmaken_klant: "Clients",
        overzicht_uren: "Time registration",
        overzicht_kosten: "Costs",
        overzicht_projecten: "Projects",
        overzicht_team: "Team",
        overzicht_kalender: "Company calendar",
      },
      meldingen: {
        aan: "are now turned on",
        uit: "are now turned off",
        verlofaanvragen: "Leave requests mail notifications",
        promotie_mails: "Promotion mails notifications",
        module: "module",
      },
    },
    abonnement: {
      abonnement_titel: "Subscription",
      abonnement_afkorting: "S",
      abonnement_subtitel:
        "On this page, you can purchase or manage your business subscription.",
      abonnement_kiezen_btn: "Select",
      abonnement_opzeggen_btn: "Cancel",
      abonnement_upgraden_btn: "Upgrade",
      abonnement_downgraden_btn: "Downgrade",
      abonnement_hernieuwen_btn: "Renew",
      abonnement_info: "Every subscription can be canceled monthly",
      maandelijks: "Monthly",
      jaarlijks: "Yearly",
      korting_voor_altijd: "{{korting}}% discount. Valid forever.",
      korting_voor_maand: "{{korting}}% discount. Valid for {{maand}} month.",
      korting_voor_maanden:
        "{{korting}}% discount. Valid for {{maand}} months.",
      prijs_tooltip_gebruikers:
        "This subscription does not match the number of users of your organization",
      betaling_geslaagd_titel: "Payment Succeeded",
      betaling_geslaagd_desc:
        "We received your payment. Your subscription has been updated!",
      betaling_mislukt_titel: "Payment Failed",
      betaling_mislukt_desc:
        "The payment process could not be completed. Please contact us to resolve this issue.",
      opzeggen_geslaagd_titel: "Subscription Canceled",
      opzeggen_geslaagd_desc:
        "Your subscription has been canceled. Feel free to come back anytime!",
      opzeggen_mislukt_titel: "Cancelling Failed",
      opzeggen_mislukt_desc:
        "The cancelling process could not be completed. Please contact us to resolve this issue.",
      hernieuwen_geslaagd_titel: "Subscription is renewed",
      hernieuwen_geslaagd_desc:
        "We resumed your subscription. It will be renewed automatically.",
      hernieuwen_mislukt_titel: "Renewing failed",
      hernieuwen_mislukt_desc:
        "The renewing process could not be completed. Please contact us to resolve this issue.",
      opzeggen_bevestigings_vraag_titel: "Cancel subscription?",
      opzeggen_bevestigings_vraag_descr:
        "You will lose access to DashBee's functionalities when your subscription ends. Are you sure?",
      hernieuwen_bevestigings_vraag_titel: "Renew Subscription?",
      hernieuwen_bevestigings_vraag_descr:
        "Your current subscription will be charged automatically again.",
      upgraden_bevestigings_vraag_titel: "Upgrade Subscription?",
      upgraden_bevestigings_vraag_descr: "Your subscription will be upgraded.",
      downgraden_bevestigings_vraag_titel: "Downgrade Subscription?",
      downgraden_bevestigings_vraag_descr:
        "Your subscription will be downgraded.",
      lock_scherm_titel: "Trial Period Expired",
      lock_scherm_desc:
        "Unfortunately... Your FreeBee period has ended. Click on the button to purchase a subscription.",
      lock_scherm_desc_user:
        "Unfortunately... The FreeBee period has ended. Ask the Queen Bee to purchase a subscription.",
      lock_scherm_button: "Purchase Subscription",
      ideal_betaling_header_titel: "Payment",
      ideal_betaling_titel: "iDEAL Payment",
      ideal_betaling_subtitel: "Choose your bank information",
      ideal_betaling_button: "Submit Payment",
      ideal_betaling_term_and_conditions:
        "*By providing your payment information and confirming this payment, you authorise Gideloff and Stripe, our payment service provider, to send instructions to your bank to debit your account and your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.",
      ideal_betaling_informatie:
        "To verify your account, we ask you to pay € 0.01 through iDEAL. Once the account has been verified, the € 0.01 will be refunded and the chosen subscription will be automatically charged.",
      kortingscode_invullen_titel: "Enter discount code",
      kortingscode_invullen_inputField: "Discount code",
      kortingscode_invullen_button: "Cash in",
      kortingscode_invalide: "Invalid discount code",
      kortingscode_niet_toegestaan:
        "Discount code not allowed with an annual subscription.",
      freebee_dagen_resterend: "Days left",
      freebee_verlopen: "Subscription expired",
      freebee_tot: "Expires on",
      freebee_naam: "FreeBee",
      freebee_naam_small: "F",
      onbeperkt: "Unlimited",
      bank_empty: "Choose a bank",
      larva_subscription_month: "Larva Pack Monthly Subscription",
      larva_subscription_year: "Larva Pack Yearly Subscription",
      hive_subscription_month: "Hive Pack Monthly Subscription",
      hive_subscription_year: "Hive Pack Yearly Subscription",
      factory_subscription_month: "Factory Pack Monthly Subscription",
      factory_subscription_year: "Factory Pack Yearly Subscription",
      dagen_resterend_uitleg:
        "Do you have an active subscription? Then it will be extended after the number of days shown. If you have canceled your subscription or are in the trial period, your access to DashBee will expire after the number of days shown.",
    },
    team: {
      team: "Team",
      team_dagen: "Team - Off days",
      team_dagen_uitleg:
        "The default number of days off for team members. This can be adjusted per user",
      team_saldo: "Team - Leave balance",
      team_saldo_uitleg: "The total leave balance of the team",
      team_gebruikt: "Team - Off days used",
      team_gebruikt_uitleg: "Total number of used leave days of the team",
      nieuw: "Add user",
      nieuw_info: "The user will receive an invite via email",
      bewerken: "Edit user",
      verwijderen: "Delete user",
      rol_aanpassen: "Edit user role",
      uitnodiging_verstuurd: "has received an invitation by e-mail",
      link_kopieren: "Copy invite link",
      link_copied1: "Link for ",
      link_copied2: " has been copied",
      email: { label: "Email", placeholder: "Email address" },
      rol: { label: "Role", placeholder: "User role" },
      verlof_dagen: {
        label: "Off days",
        placeholder: "Amount",
        uitleg:
          "Does the user have different days of leave compared to the team? Indicate that here.",
      },
      gebruikers: "User",
      uitgenodigd: "Invited",
      actief: "Active",
      verlofdagen: "Off days",
      verlof_aanpassen: "Edit team off day amount",
      verlof_per_jaar: "Off days per year",
      tabel: {
        gebruiker: "User",
        email: "Email",
        rol: "Role",
        functie: "Function",
        status: "Status",
        verlofdagen: "Leave days",
        verlofsaldo: "Leave balance",
        verlof_gebruikt: "Off days used",
      },
    },
    notificaties: {
      aanvraag: "Off day request",
      nieuw_aanvraag_titel: "New off day request",
      nieuw_aanvraag_desc: "Press to check all requests",
      aanvraag_geaccepteerd: "Your request has been accepted",
      aanvraag_geweigerd: "Your request has been rejected",
    },
    tour: {
      stappen: {
        volgende: "Next step",
        terug: "Go back",
        skip: "Skip",
        afronden: "Done",
      },
      urenregistratie: {
        body: "Welcome to the Time Registration page! Here you can view all registered hours. Have fun registering!",
        formulier:
          "Enter your hours per project and by date here. Adding a comment is optional.",
        tabel:
          "View all registered hours here. To edit or delete entered hours, press the icon under ACTIONS.",
        grafiek_toggle: "View the hours worked per month and per project here.",
      },
      verlof: {
        body: "Welcome to the Leave page! View your leave balance here and request leave for all your days off, private or business-related.",
        header_card_1:
          "Here you can always see your current leave balance. Requested leave days that have not yet been accepted are not yet visible here.",
        header_card_2: "This shows the number of days of leave used this year.",
        button:
          "Click here to submit a leave request. The Queen Bee will receive a notification to process your request.",
        tabel:
          "View all your requests here, including the request status and who handled your requests.",
      },
      kalender: {
        body: "Welcome to the Shared Calendar! The Calendar contains all colleague holidays and leave days, business activities and appointments. Queen Bees can create, edit and delete events here.",
      },
      verlofaanvraag: {
        body: "Welcome to the Leave requests page! As a Queen Bee you can view and process all leave requests here.",
        onbehandeld: "All unhandled requests will be listed here.",
        behandeld: "All handled requests are listed here.",
        export: "Export everything to Excel.",
      },
      uren: {
        body: "Welcome to the Hours page! View the registered hours of all team members here. Also view the turnover based on the hours worked.",
        header_card_1: "All registered hours of current year.",
        header_card_2: "View the total turnover based on hourly rate.",
        grafiek: "View worked hours per project.",
        tabel: "View registered hours by all Bees.",
        export: "Export everything to Excel.",
      },
      kosten: {
        body: "Welcome to the Costs page. Enter all operating costs here to get an overall picture of the turnover and profit.",
        formulier:
          "Enter the costs incurred per project and include the type of costs for better insights.",
      },
      facturen: {
        body: "Welcome to the invoices page! Create invoices here and generate them as PDF. All invoices are stored here and can also be downloaded.",
        klant:
          "To generate an invoice you must first create a customer and project.",
        datum:
          "Choose a start date and an end date. The hours are automatically retrieved for the selected project.",
        bedrijfsgegevens:
          "You can only generate invoices when your own company details have been entered. This can be done under 'My company' in the settings.",
      },
      klanten: {
        body: "Welcome to the Clients page! Create all your clients here for administration and invoicing.",
        button: "Click here to create a new client.",
        tabel: "The overview of existing clients.",
      },
      projecten: {
        body: "Welcome to the Projects page. Create all your projects here, the projects will be visible for registration and invoicing.",
        button: "Click here to create new projects.",
        edit: "Click here to edit an existing project.",
        verwijderd: "Click here to view and reactivate all deleted projects.",
      },
      team: {
        body: "Welcome to the Teams page! All team members can be added here. Also being shown here per user: Total leave days, used leave days and available days.",
        header_card_1: "The default number of days off for each team member.",
        header_icon: "Click here to adjust the default number of holidays.",
        header_card_2: "This is the total leave balance of the entire team.",
        header_card_3: "These are the used holidays of the entire team.",
        button:
          "Click here to add new team members! Adding a team member generates a link, which can be shared with the new team member.",
        edit: "Click here to edit or delete existing team members.",
        tabel:
          "All team members are listed here. View the status of each team member and his or her leave balance.",
      },
      header: {
        settings: "Click here for profile and company settings.",
        taal: "Click here to change the language.",
        notificatie:
          "This is where all notifications and general reports come in.",
      },
    },
  },
  faq: {
    general: {
      title: "Frequently Asked Question",
      sub_title: "General questions about DashBee",
      more_info: "More information",
      questions: [
        {
          title: "Who is DashBee for?",
          description:
            "DashBee is intended for companies / self-employed who are looking for more structure, insights and control. Questions such as: How many hours have worked on a specific project, is a project profitable, how many people are available today and are my budgets correct, can be easily answered with DashBee.",
        },
        {
          title: "What are the benefits of using DashBee?",
          description:
            "Digitization is everywhere these days, companies are increasingly focusing on digitalization because it can save a lot of time and money. In addition, data has recently become increasingly important, mainly for analyzing data. Analyzing data allows you to learn from the past, draw conclusions and recognize patterns. This also applies to your own company. DashBee makes it possible to easily store and analyze company data for great insights.",
        },
        {
          title: "Do I have to pay right away?",
          description:
            "Nope, you can create an account for free and test all the functionalities in DashBee. The first month is completely free and if you're still in doubt, we can extend the free period for another month!🥰",
        },
        {
          title: "Why would I want a subscription to DashBee?",
          description:
            "DashBee helps small business owners and freelancers with their administration. In addition, DashBee is the ideal tool to analyze business processes and optimize them. Subscribers can also contact the developers directly to discuss additional features. Subscribers ensure that DashBee can continue to exist. We are very grateful for that!🥰",
        },
        {
          title:
            "Is it possible to get a demo of the dashboard before registering my company?",
          description: "Of course! Request a Demo via the contact form.",
        },
        {
          title: "Can I import my current company data?",
          description:
            "All company data can be imported, we are happy to help you with this!",
        },
        {
          title:
            "What happens to my company data when I cancel my subscription?",
          description:
            "The collected data is yours and remains yours. All data can be exported at any time. If you want to do so, please contact us using our contact form.. We will delete your account from the database after cancellation.",
        },
        {
          title: "Is there an option for customization?",
          description:
            "Customization is our passion! Tell us about your ideas and let's see what we can accomplish together. This does not necessarily have to result in extra costs. If it's a functionality that applies to multiple DashBee users, we can implement the functionality in DashBee without any extra costs.",
        },
        {
          title: "Is DashBee being used and by who?",
          description:
            "DashBee went live in early March 2021 and has since systematically expanded in functionality. We currently have a few customers who thoroughly test this for us. Of course we also use DashBee ourselves for our own administration and registration.😎",
        },
        {
          title: "I am still not sure yet, can I try it for free for a while?",
          description:
            "Not sure yet? Don't worry, we can easily extend the test period for another month.👌",
        },
      ],
    },
    pricing: {
      title: "Frequently Asked Question",
      sub_title: "Frequently Asked Question about pricing",
      more_info: "More infomation",
      questions: [
        {
          title: "Can DashBee be canceled monthly?",
          description:
            "DashBee can be cancelled at any time in the dashboard. You will continue to have access until the term has expired. The subscription will not be renewed after cancelling.",
        },
        {
          title: "Where is the data stored?",
          description:
            "All our data is stored securely. We use encryption for sensitive data. All data is physically stored in North West Europe.",
        },
        {
          title: "How can I upgrade my subscription?",
          description:
            "On the subscription page you can see which subscription is active and whether it is possible for you to upgrade or downgrade. This is automatically processed in the monthly or annual payment. Are you looking for certain functions that are not (yet) in DashBee? Please contact Gideloff BV.",
        },
        {
          title: "Where can I enter my discount code?",
          description:
            "When purchasing a subscription, you can enter a discount code on the checkout page. This is immediately checked for validity and automatically processed with the next payment.",
        },
        {
          title: "Can I export my data?",
          description:
            "You can export all data from your company account or your personal account to CSV or Excel.",
        },
        {
          title:
            "How long is my subscription valid after I have canceled my subscription?",
          description:
            "On the subscription page you can see how many days of access you have left. If you cancel the subscription before the end date, it will stop on the end date, otherwise the subscription will be renewed automatically.",
        },
        {
          title: "Can I contact the developers?",
          description:
            "DashBee is maintained and developed by Gideloff BV. You can contact Gideloff, by phone or by email.",
          link: "https://gideloff.com/nl/contact",
        },
      ],
    },
  },
  meldingen: {
    gelukt: "Success",
    waarschuwing: "Pay attention",
    je: "Your ",
    is_opgeslagen: " has been saved",
    email_leeg: "Enter a valid email address",
    account_verwijderd: "This account has been removed from the organisation",
    verifieer_account: "Verify your account with the link in your mailbox",
    wachtwoord_incorrect: "The password you entered is incorrect",
    geen_account: "There is no account with this email address",
    mail_verstuurd: "Mail sent, check your inbox!",
    naam_3_letters: "Enter a name with at least 3 characters",
    wachtwoord_6_karakters:
      "Your password need to contain at least 6 characters",
    bedrijfsnaam_leeg: "Enter a company name",
    naam_leeg: "Enter a name",
    rechtsvorm_leeg: "Choose a legal form",
    email_reeds_gekozen: "An account with this email address already exists",
    privacy_beleid: "You have to accept the privacy policy",
    uitnodiging_verkeerd_email:
      "The invitation is in the name of another email address",
    uren_opgeslagen: "Your hours have been saved",
    uren_verwijderd: "Hours deleted",
    datum_ongeldig: "Enter a valid date",
    datum_leeg: "Enter a date",
    project_opmerking_leeg: "Choose a project or add a note",
    uren_leeg: "You must've worked for at least half an hour",
    nohours_year1: "In  ",
    nohours_company_year: "In ",
    nohours_user_year: " has worked no hours in ",
    nohours_year2: " you have not worked any hours",
    nocosts: " you did not incur any costs ",
    day_24hours: "A day only consists of 24 hours as far as we know",
    verlofsaldo_onvoldoende: "You do not have enough leave balance",
    verlof_overlap:
      "Your application overlaps with another application, adjust the date / time",
    aanvraag_ingediend: "Your request has been submitted",
    aanvraag_opgeslagen: "Request saved",
    aanvraag_verwijderd: "Request deleted",
    aanvraag_reeds_behandeld: "This request has already been handled",
    aanvraag_behandeld_verwijderen:
      "Handled requests can only be deleted by admins",
    kosten_opgeslagen: "Costs saved",
    kosten_verwijderd: "Costs deleted",
    kosten_leeg: "You must enter at least one cent of costs",
    kosten_negatief: "Costs cannot be negative",
    kosten_type_leeg: "Choose a cost type",
    project_opgeslagen: "Project saved",
    project_leeg: "Enter a project name",
    project_verwijderd: "Project deleted",
    project_teruggezet: "Project restored",
    foutmelding: "Something went wrong, try again later",
    max_60_char: "Maximum amount of characters is 60",
    gebruiker_bestaat_al: " already has a DashBee account",
    gebruiker_reeds_uitgenodigd: " is already invited",
    gebruiker_aangepast: "User edited",
    gebruiker_verwijderd: "User deleted",
    verlofdagen_aangepast: "Leave balance of team has been saved",
    jezelf_bewerken: "You cannot edit yourself",
    jezelf_verwijderen: "You cannot delete yourself",
    naam_opgeslagen: "Your name has been saved",
    functie_opgeslagen: "Your function has been saved",
    foto_opgeslagen: "Your profile picture has been saved",
    bedrijfslogo_opgeslagen: "Company logo saved",
    evenement_opgeslagen: "Calendar item saved",
    evenement_verwijderd: "Calendar item deleted",
    evenement_leeg: "Enter a title",
    factuur_periode_leeg: "Select a period",
    factuur_project_leeg: "Select a project",
    factuur_klant_leeg: "Select a client",
    factuur_geen_uren_gevonden: "No hours found",
    factuur_geen_kosten_gevonden: "No costs found",
    factuur_geen_project_tarief_gevonden: "No hourly rate found for project",
    factuur_opgeslagen: "Invoice saved",
    factuur_verwijderd: "Invoice deleted",
    bedrijfsveld_leeg:
      " has not yet been entered in your company data. You can do this in My company.",
    klantgegevens_leeg: " has not yet been entered in client data.",
    klant_opgeslagen: "Client saved",
    klant_verwijderd: "Client deleted",
    klantnaam_leeg: "Enter a client name",
    afbeelding_upload_gefaald:
      "Image upload failed, please try again a bit later",
  },
  form: {
    enter_email: "Enter an email address",
    enter_role: "Choose a role",
    enter_project_access: "Give the user access to at least 1 project",
    enter_project: "Choose a project",
    no_projects: "No active projects found",
    enter_hours: "Enter the amount of hours",
    enter_date: "Choose a date",
  },
  day: "Day",
  week: "Week",
  month: "Month",
  quarter: "Quarter",
  year: "Year",
  per: "Per",
  total: "Total",
  hours: "Hours",
};
