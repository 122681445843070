import React from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CardHeader,
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import { Link, Redirect } from "react-router-dom";
import firebase from "firebase";
import urls from "../../../urls";
import ReactBSAlert from "react-bootstrap-sweetalert";
import i18n from "variables/translations/i18n";
import { Helmet } from "react-helmet";
import { META_RESET_PASSWORD_TITLE, META_RESET_PASSWORD_DESCRIPTION } from "constants/MetaTagsConstants";
import { WEBSITE_NAME } from "constants/CompanyConstants";
import { EMAIL_VALIDATION_REGEX } from "../../../constants/InputConstants";

class ForgotPassword extends React.Component {
  state = {
    email: "",
    errorMessage: "",
    formSubmitted: false,
    alert: null,
    redirectLogin: false,
  };

  onPressSubmit() {
    let self = this;
    let auth = firebase.auth();
    let emailAddress = this.state.email;
    let actionCodeSettings = {
      url: urls.base + urls.login,
    };
    if (this.state.email.match(EMAIL_VALIDATION_REGEX)) {
      auth
        .sendPasswordResetEmail(emailAddress, actionCodeSettings)
        .then(function () {
          self.successAlert();
        })
        .catch(function (error) {
          console.log(error);
          let errorCode = error.code;
          if (errorCode === "auth/user-not-found")
            self.setState({
              errorMessage: i18n.t("meldingen.geen_account"),
            });
        });
    } else {
      this.setState({ errorMessage: i18n.t("meldingen.email_leeg") });
    }
  }

  successAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block" }}
          title={i18n.t("auth.reset.alert.titel")}
          onConfirm={() => this.setState({ redirectLogin: true })}
          confirmBtnBsStyle="success"
          confirmBtnText={i18n.t("auth.reset.alert.text_btn")}
          btnSize=""
        >
          {i18n.t("auth.reset.alert.text")}
        </ReactBSAlert>
      ),
    });
  };

  render() {
    if (this.state.redirectLogin) return <Redirect to={urls.login} />;
    return (
      <>
        {this.state.alert}
        <Helmet>
          <title>{META_RESET_PASSWORD_TITLE} · {WEBSITE_NAME}</title>
          <meta name="description" content={META_RESET_PASSWORD_DESCRIPTION}/>
        </Helmet>
        <AuthHeader
          title={i18n.t("auth.reset.titel")}
          lead={i18n.t("auth.reset.subtitel")}
        />
        <Container className="mt--8 mb-8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0 pt-4">
                <CardHeader className="bg-transparent pb-4">
                  <div className="text-muted text-center mt-2 mb-3">
                    <span className="card-header-title">
                      {i18n.t("auth.reset.instructie")}
                    </span>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form">
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: this.state.focusedEmail,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={i18n.t("auth.email")}
                          type="email"
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          onKeyPress={(event) => {
                            console.log(event.keyCode);
                            if (event.key === "Enter") {
                              event.preventDefault();
                              this.onPressSubmit();
                            }
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="info"
                        type="button"
                        onClick={() => this.onPressSubmit()}
                      >
                        {i18n.t("auth.reset.submit_btn")}
                      </Button>
                    </div>
                    <div className="text-center">
                      <small className="text-warning">
                        {this.state.errorMessage}
                      </small>
                    </div>
                  </Form>
                  <Row className="mt-2">
                    <Col xs="6">
                      <Link to={urls.login} className="text-muted">
                        <small>{i18n.t("auth.reset.inloggen")}</small>
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ForgotPassword;
