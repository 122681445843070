import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import HomepageNavbar from "components/Navbars/HomepageNavbar";
import AuthFooter from "components/Footers/AuthFooter";
import beeImage from "assets/img/theme/dash.png";
import urls from "../../../urls";
import { Link } from "react-router-dom";
import i18n from "../../../variables/translations/i18n";
import Fade from "react-reveal/Fade";
import { PricingTable } from "components/Tables/PricingTable";
import { PricingFaq } from "../../../components/Faq/PricingFaq";
import { PricingCardsExplanation } from "../../../components/Subscription/PricingCardsExplanation";
import { WEBSITE_NAME } from "constants/CompanyConstants";
import {
  META_PRICING_DESCRIPTION,
  META_PRICING_TITLE,
} from "constants/MetaTagsConstants";
import { Helmet } from "react-helmet";
import { BreadcrumbStructuredData } from "../../../components/StructuredData/BreadcrumbStructuredData";
import {
  BREADCRUMB_HOME,
  BREADCRUMB_PRICING,
} from "../../../constants/BreadcrumbConstants";

class Pricing extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            {META_PRICING_TITLE} · {WEBSITE_NAME}
          </title>
          <meta name="description" content={META_PRICING_DESCRIPTION} />
          {BreadcrumbStructuredData([BREADCRUMB_HOME, BREADCRUMB_PRICING])}
        </Helmet>
        <HomepageNavbar />
        <AuthHeader title={i18n.t("prijzen_pagina.pagina_titel")} />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="12" className="col-lg-14">
              <div className="pricing card-group flex-column flex-md-row mb-3 justify-content-center">
                <Fade delay={200}>
                  <Card className="card-pricing border-0 text-center mb-4">
                    <CardHeader className="bg-transparent">
                      <h4 className="text-uppercase ls-1 text-info py-3 mb-0">
                        {i18n.t("prijzen_pagina.larva_pack.titel")}
                      </h4>
                    </CardHeader>
                    <CardBody className="px-lg-7">
                      <div className="display-2">
                        {"€" + i18n.t("prijzen_pagina.larva_pack.prijs")}
                      </div>
                      <span className="text-muted">
                        {i18n.t("prijzen_pagina.larva_pack.per_gebruiker")}
                      </span>
                      <p className="text-muted text-sm">
                        {i18n.t(
                          "prijzen_pagina.larva_pack.onder_per_gebruiker"
                        )}
                      </p>
                      <ul className="list-unstyled my-4">
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                <i className="fas fa-euro-sign" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2">
                                {i18n.t("prijzen_pagina.larva_pack.feature1")}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                <i className="fas fa-user" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2">
                                {i18n.t("prijzen_pagina.larva_pack.feature2")}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                <i className="fa fa-phone" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2">
                                {i18n.t("prijzen_pagina.larva_pack.feature3")}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                <i className="fa fa-users" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2">
                                {i18n.t("prijzen_pagina.larva_pack.feature4")}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <Button
                        className="btn-cta mb-3"
                        type="button"
                        to={urls.registerCompany}
                        tag={Link}
                      >
                        {i18n.t("prijzen_pagina.registreer_btn")}
                      </Button>
                    </CardBody>
                  </Card>
                </Fade>
                <Fade delay={300}>
                  <Card className="card-pricing bg-cards zoom-in shadow-lg rounded border-0 text-center mb-4">
                    <CardHeader className="bg-transparent">
                      <div className="py-2">
                        <h4 className="text-uppercase ls-1 text-white mb-1">
                          {i18n.t("prijzen_pagina.hive_pack.titel")}
                        </h4>
                        <h6 className="text-uppercase ls-1 text-white mb-0">
                          {i18n.t("prijzen_pagina.meest_gekozen")}
                        </h6>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-7">
                      <div className="display-1 text-white">
                        {"€" + i18n.t("prijzen_pagina.hive_pack.prijs")}
                      </div>
                      <span className="text-white">
                        {i18n.t("prijzen_pagina.hive_pack.per_gebruiker")}
                      </span>
                      <ul className="list-unstyled my-4">
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                <i className="fas fa-euro-sign" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2 text-white">
                                {i18n.t("prijzen_pagina.hive_pack.feature1")}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                <i className="fas fa-home" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2 text-white">
                                {i18n.t("prijzen_pagina.hive_pack.feature2")}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                <i className="fas fa-phone" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2 text-white">
                                {i18n.t("prijzen_pagina.hive_pack.feature3")}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                <i className="fas fa-users" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2 text-white">
                                {i18n.t("prijzen_pagina.hive_pack.feature4")}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <Button
                        className="btn-cta mb-3"
                        type="button"
                        to={urls.registerCompany}
                        tag={Link}
                      >
                        {i18n.t("prijzen_pagina.registreer_btn")}
                      </Button>
                    </CardBody>
                  </Card>
                </Fade>
                <Fade delay={200}>
                  <Card className="card-pricing border-0 text-center mb-4 col-14-mt bg-info">
                    <CardHeader className="bg-transparent">
                      <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                        {i18n.t("prijzen_pagina.honey_factory_pack.titel")}
                      </h4>
                    </CardHeader>
                    <CardBody className="px-lg-7">
                      <div className="display-2 text-white">
                        {"€" +
                          i18n.t("prijzen_pagina.honey_factory_pack.prijs")}
                      </div>
                      <span className="text-white">
                        {i18n.t(
                          "prijzen_pagina.honey_factory_pack.per_gebruiker"
                        )}
                      </span>
                      <p className="text-white text-sm">
                        {i18n.t(
                          "prijzen_pagina.honey_factory_pack.onder_per_gebruiker"
                        )}
                      </p>
                      <ul className="list-unstyled my-4">
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                <i className="fas fa-euro-sign" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2 text-white">
                                {i18n.t(
                                  "prijzen_pagina.honey_factory_pack.feature1"
                                )}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                <i className="fas fa-building" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2 text-white">
                                {i18n.t(
                                  "prijzen_pagina.honey_factory_pack.feature2"
                                )}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                <i className="fa fa-phone" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2 text-white">
                                {i18n.t(
                                  "prijzen_pagina.honey_factory_pack.feature3"
                                )}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                <i className="fa fa-users" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2 text-white">
                                {i18n.t(
                                  "prijzen_pagina.honey_factory_pack.feature4"
                                )}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <Button
                        className="btn-cta mb-3"
                        type="button"
                        to={urls.registerCompany}
                        tag={Link}
                      >
                        {i18n.t("prijzen_pagina.registreer_btn")}
                      </Button>
                    </CardBody>
                  </Card>
                </Fade>
              </div>
            </Col>
          </Row>
          <Fade delay={200}>
            <div className="d-flex justify-content-lg-center px-3">
              <Col lg="8">
                <p className="text-muted text-xs">
                  {i18n.t("prijzen_pagina.excluding_tax")}
                </p>
              </Col>
            </div>
          </Fade>
          <Fade delay={300}>
            <div className="d-flex justify-content-lg-center px-3 mt-5">
              <div>
                <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
                  <img src={beeImage} className="mw-100" />
                </div>
              </div>
              <Col lg="8">
                <h4 className="text-uppercase ls-1 text-info py-3 mb-0">
                  {i18n.t("prijzen_pagina.freebee_titel")}
                </h4>
                <p className="text-muted">
                  {i18n.t("prijzen_pagina.pagina_desc")}
                  <strong>{i18n.t("prijzen_pagina.pagina_slogan")}</strong>
                </p>
              </Col>
            </div>
          </Fade>
          <Row className="row-grid justify-content-center">
            <Col lg="10">
              <PricingTable />
            </Col>
          </Row>
          <section className="section section-lg pt-lg-0 mt-5 mb-5">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row>
                    <PricingCardsExplanation
                      themeColor={"warning"}
                      titel={i18n.t("prijzen_pagina.cards.card1_titel")}
                      description={i18n.t("prijzen_pagina.cards.card1_desc")}
                      icon={"ni ni-check-bold"}
                      badge1={i18n.t("prijzen_pagina.cards.card1_badge1")}
                      badge2={i18n.t("prijzen_pagina.cards.card1_badge2")}
                      badge3={i18n.t("prijzen_pagina.cards.card1_badge3")}
                    />
                    <PricingCardsExplanation
                      themeColor={"success"}
                      titel={i18n.t("prijzen_pagina.cards.card2_titel")}
                      description={i18n.t("prijzen_pagina.cards.card2_desc")}
                      icon={"fas fa-shield-alt"}
                      badge1={i18n.t("prijzen_pagina.cards.card2_badge1")}
                      badge2={i18n.t("prijzen_pagina.cards.card2_badge2")}
                      badge3={i18n.t("prijzen_pagina.cards.card2_badge3")}
                    />
                    <PricingCardsExplanation
                      themeColor={"info"}
                      titel={i18n.t("prijzen_pagina.cards.card3_titel")}
                      description={i18n.t("prijzen_pagina.cards.card3_desc")}
                      icon={"fas fa-headset"}
                      badge1={i18n.t("prijzen_pagina.cards.card3_badge1")}
                      badge2={i18n.t("prijzen_pagina.cards.card3_badge2")}
                      badge3={i18n.t("prijzen_pagina.cards.card3_badge3")}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>

          <PricingFaq />
        </Container>
        <AuthFooter />
      </>
    );
  }
}

export default Pricing;
