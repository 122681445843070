import firebase from "firebase";
import urls from "../../../urls";

/**
 * Fetch price information from the PaymentAPI with the priceId
 * @param priceId
 * @returns {Promise<unknown>}
 */
function fetchPriceInfo(priceId) {
  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
    };
    fetch(process.env.REACT_APP_STRIPE_PAYMENT_API + urls.stripePayment + priceId, requestOptions)
      .then(price => price.json())
      .then((price) => {
        resolve(price)})
      .catch(() => reject("Price not found"))
  })
}

/**
 * Save Subscription details in Firebase
 *  * @param subscription
 * @returns {Promise<unknown>}
 */
function saveStripeSubscriptionDetails(subscription) {
  firebase
    .database()
    .ref("subscriptions/" + subscription.companyId)
    .update(subscription).then(() => {});
}

/**
 * Create customer in Stripe
 * @param props
 * @returns {Promise<unknown>}
 */
function createStripeCustomer(props) {
  return new Promise(function (resolve, reject) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      {
        priceId: props.priceId,
        customer: {
          name: props.companyName,
          email: !!props.companyEmail ? props.companyEmail : props.userEmail,
          description: "DashBee Customer"
        }
      }
    )
  };
  fetch(process.env.REACT_APP_STRIPE_PAYMENT_API + urls.stripeSubscriptionSetup, requestOptions)
    .then((response) => resolve(response.json()))
    .catch(() => reject("Customer creation failed"))
  })
}

/**
 * Create subscription for customer in Stripe
 * @param customerId
 * @param price
 * @param couponId
 * @returns {Promise<unknown>}
 */
function createPaymentSubscription(customerId, price, couponId) {
  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
          paymentAmount : price.unitAmountDecimal,
          priceId: price.priceId,
          customerId: customerId,
          couponId: couponId
        })
    };
    fetch(process.env.REACT_APP_STRIPE_PAYMENT_API + urls.stripeSubscription, requestOptions)
      .then((response) => resolve(response.json()))
      .catch(() => reject("Payment subscription failed"))
  })
}

export {
  fetchPriceInfo, 
  saveStripeSubscriptionDetails, 
  createPaymentSubscription, 
  createStripeCustomer
}