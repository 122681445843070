import React from "react";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interaction from "@fullcalendar/interaction";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Modal,
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Label,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import firebase from "firebase";
import Moment from "moment";
import { extendMoment } from "moment-range";
import i18n from "variables/translations/i18n";
import i18next from "i18next";
import notify from "views/functions/notify";
import { isAuthenticatedUserAdmin } from "views/functions/generic";
import { calculateStartAndEnd } from "views/functions/offDays";
import Tour from "components/Support/Tour";
import { tourSteps } from "variables/tourSteps";
import TourIcon from "components/Support/TourIcon";

let calendar;
const moment = extendMoment(Moment);
const today = moment().format("YYYY-MM-DD");
// 1 day
const defaultEventDay = moment().add(1, "day").format("YYYY-MM-DD");
// From/Until time
const defaultFromTime = "09:00";
const defaultUntilTime = "13:00";
// Period
const defaultFromDay = moment().add(1, "day").format("YYYY-MM-DD");
const defaultUntilDay = moment().add(2, "day").format("YYYY-MM-DD");

class CalendarView extends React.Component {
  constructor(props) {
    super(props);
    this.calendarRef = React.createRef();
    this.notifyCalendarRef = React.createRef();
  }
  state = {
    companyId: localStorage.getItem("compid"),
    userId: localStorage.getItem("uid"),
    isAdmin: null,
    events: null,
    clickedEvent: null,
    eventTitle: null,
    eventDescription: null,
    eventFromTime: defaultFromTime,
    eventUntilTime: defaultUntilTime,
    eventDay: defaultEventDay,
    eventDayFrom: defaultFromDay,
    eventDayUntil: null,
    minUntilDay: defaultUntilDay,
    minUntilTime: defaultUntilTime,
    eventType: "dayPeriod",
    descriptionModal: false,
    startTour: false,
    steps: tourSteps.kalender,
  };
  componentDidMount() {
    // Admins have different functionality here, so first get the user role
    isAuthenticatedUserAdmin().then((boolean) => {
      this.setState({ isAdmin: boolean }, this.getOffDays);
    });
  }

  /**
   * Get all offdays out of the database and add these to the state
   */
  getOffDays() {
    let events = [];
    // Get off days for company
    let offdays = firebase
      .database()
      .ref("offdays")
      .orderByChild("companyId")
      .equalTo(this.state.companyId);
    offdays.on("value", (snapshot) => {
      let data = snapshot.val();
      if (!!data) {
        let acceptedData = Object.entries(data).filter(function (obj) {
          return obj[1].status == "accepted";
        });
        if (acceptedData.length !== 0) {
          for (let i = 0; i < acceptedData.length; i++) {
            let current = acceptedData[i];
            let id = current[0];
            let data = current[1];
            let userId = data.userId;

            // Retrieve user data and get full event object
            let user = firebase.database().ref("users/" + userId);
            user.on("value", (snapshot) => {
              let obj = this.getOffdayObject(snapshot, id, data);
              if (!!obj) events.push(obj);
              if (Object.keys(acceptedData).length === events.length) {
                this.setState({ events: events }, this.getCalendarEvents);
              }
            });
          }
        } else this.getCalendarEvents();
      } else this.getCalendarEvents();
    });
  }

  /**
   * Get all calendar events out of the database and add these to the state on top of the offdays
   */
  getCalendarEvents() {
    let array = [];
    let self = this;
    // Get calendar events for company
    let events = firebase
      .database()
      .ref("calendarEvents")
      .orderByChild("companyId")
      .equalTo(this.state.companyId);
    events.once("value", (snapshot) => {
      let counter = 0;
      let data = snapshot.val();
      if (!!data && counter === 0) {
        Object.entries(data).forEach(function (obj) {
          let id = obj[0];
          let data = obj[1];
          let startEndObj = calculateStartAndEnd(data);
          let object = {
            id: id,
            className: "bg-info",
            allDay: data.type !== "dayPeriod",
            start: startEndObj.start,
            end: startEndObj.end,
            title: data.title,
            description: data.description,
            type: "calendarEvent",
          };
          array.push(object);
        });
        counter++;
        !!this.state.events
          ? this.setState(
              { events: [...this.state.events, ...array] },
              this.createCalendar
            )
          : this.setState({ events: [...array] }, this.createCalendar);
      } else this.createCalendar();
    });
  }

  /**
   * Get an object out of the event data that can be loaded into the calendar
   * @param snapshot the firebase snapshot corresponding to the user.
   * @param id the offday object id.
   * @param data the offday object data.
   */
  getOffdayObject(snapshot, id, data) {
    let obj = {};
    let user = snapshot.val();
    if (user.active !== false) {
      let title;
      switch (data.type) {
        case "day":
          title = user.name + i18n.t("dashboard.kalender.vrij_dag");
          break;
        case "period":
          title = user.name + i18n.t("dashboard.kalender.vrij");
          break;
        case "dayPeriod":
          title = user.name + i18n.t("dashboard.kalender.vrij");
          break;
        default:
          title = user.name + i18n.t("dashboard.kalender.vrij");
      }
      let entireDay = data.type !== "dayPeriod";
      let startEndObj = calculateStartAndEnd(data);
      let description = this.formatOffdayDescription(data, user.name);
      obj = {
        id: id,
        name: user.name,
        title: title,
        allDay: entireDay,
        start: startEndObj.start,
        end: startEndObj.end,
        className: "bg-warning",
        description: description,
        type: "offday",
      };
      return obj;
    } else return obj;
  }

  /**
   * Set the state of the off day object according to the user input.
   * @param data the event object.
   * @param name the name of the user that is out of office.
   */
  formatOffdayDescription(data, name) {
    if (data.type === "day")
      return name + i18n.t("dashboard.kalender.evenement.hele_dag_vrij");
    else if (data.type === "dayPeriod")
      return (
        name +
        i18n.t("dashboard.kalender.evenement.dag_periode_vrij") +
        data.fromTime +
        " - " +
        data.untilTime
      );
    else
      return (
        name +
        i18n.t("dashboard.kalender.evenement.dag_periode_vrij") +
        moment(data.fromDay).format("D MMMM") +
        i18n.t("dashboard.kalender.evenement.tot_en_met") +
        moment(data.untilDay).format("D MMMM")
      );
  }

  /**
   * Set the state of the event object according to the user input.
   * @param e the user selected day.
   */
  handleEventFromDayChange(e) {
    let minUntilDay = moment(e.target.value).add(1, "day").format("YYYY-MM-DD");
    if (
      !!this.state.eventDayUntil &&
      (moment(e.target.value).isSame(this.state.eventDayUntil) ||
        moment(e.target.value).isAfter(this.state.eventDayUntil))
    ) {
      this.setState({
        eventDayFrom: e.target.value,
        eventDayUntil: minUntilDay,
        minUntilDay: minUntilDay,
      });
    } else {
      this.setState({
        eventDayFrom: e.target.value,
      });
    }
  }

  /**
   * Set the state of the event time object according to the user input.
   * @param e the user selected time.
   * @param type the type of the event time Object, e.g. from or until
   */
  handleEventTimeChange(e, type) {
    let minUntilTime = moment(e.target.value, "HH:mm")
      .add(1, "hour")
      .format("HH:mm");
    let today = moment().format("YYYY-MM-DD");
    // In order to compare 2 times, the day must be specified
    let target = moment(today + " " + e.target.value).format(
      "YYYY-MM-DD HH:mm"
    );
    let fromTime = moment(today + " " + this.state.eventFromTime).format(
      "YYYY-MM-DD HH:mm"
    );
    let untilTime = moment(today + " " + this.state.eventUntilTime).format(
      "YYYY-MM-DD HH:mm"
    );
    if (type === "from") {
      if (e.target.value === "23:00") minUntilTime = "23:59";
      if (
        !!this.state.eventUntilTime &&
        (moment(target).isSame(untilTime) || moment(target).isAfter(untilTime))
      ) {
        this.setState({
          eventFromTime: e.target.value,
          eventUntilTime: minUntilTime,
          minUntilTime: minUntilTime,
        });
      } else {
        this.setState({
          eventFromTime: e.target.value,
          minUntilTime: minUntilTime,
        });
      }
    }
    if (type === "until") {
      if (
        moment(target).isSame(fromTime) ||
        moment(target).isBefore(fromTime)
      ) {
        let newFromTime = moment(e.target.value, "HH:mm")
          .subtract(1, "hour")
          .format("HH:mm");
        this.setState({
          eventFromTime: newFromTime,
          eventUntilTime: e.target.value,
        });
      } else {
        this.setState({
          eventUntilTime: e.target.value,
        });
      }
    }
  }

  /**
   * Create the calendar using the events from state. Also add
   * functions that enable the user to click on events
   */
  createCalendar = () => {
    calendar = new Calendar(this.calendarRef, {
      plugins: [interaction, dayGridPlugin],
      defaultView: "dayGridMonth",
      selectable: true,
      selectHelper: true,
      editable: true,
      events: this.state.events,
      locale: i18next.language,
      displayEventTime: true,
      displayEventEnd: true,
      firstDay: 1,
      // Add new event
      select: (info) => {
        this.state.isAdmin &&
          this.setState({
            modalAdd: true,
            eventDay: info.startStr,
            eventDayFrom: info.startStr,
            eventDayUntil: info.endStr,
          });
      },
      // Edit calendar event action
      eventClick: ({ event }) => {
        if (
          this.state.isAdmin &&
          event.extendedProps.type === "calendarEvent"
        ) {
          this.setState({
            editModal: true,
            eventId: event.id,
            eventTitle: event.title,
            eventDescription: event.extendedProps.description,
            clickedEvent: event,
          });
        } else if (
          (!this.state.isAdmin &&
            event.extendedProps.type === "calendarEvent") ||
          event.extendedProps.type === "offday"
        ) {
          // Show event details
          this.setState({
            descriptionModal: true,
            eventTitle: event.title,
            eventDescription: event.extendedProps.description,
            clickedEvent: event,
          });
        }
      },
    });
    try {
      calendar.render();
    } catch (error) {
      //TODO: Check this error
    }

    if (!!calendar.view) {
      this.setState({
        currentDate: calendar.view.title,
      });
    }
  };

  /**
   * Change the view of the calendar
   * @param newView the type of view that has to be shown, consisting of month, day or week
   */
  changeView = (newView) => {
    calendar.changeView(newView);
    this.setState({
      currentDate: calendar.view.title,
    });
  };

  /**
   * Delete the clicked item after pressing the delete button (admin only)
   */
  onDeleteEvent() {
    let clicked = this.state.clickedEvent;
    firebase
      .database()
      .ref("calendarEvents/" + clicked.id)
      .remove()
      .then(() => {
        this.setState({
          editModal: false,
          clickedEvent: null,
        });
        clicked.remove();
        notify(
          "success",
          i18n.t("meldingen.evenement_verwijderd"),
          this.notifyCalendarRef
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /**
   * Edit the clicked item (admin only)
   */
  onEditEvent() {
    let clicked = this.state.clickedEvent;
    let { eventTitle, eventDescription } = this.state;
    if (
      eventTitle === clicked.title &&
      eventDescription === clicked.extendedProps.description
    ) {
      // Nothing changed, close modal and show that event is saved
      this.setState({
        editModal: false,
        clickedEvent: null,
      });
      notify(
        "success",
        i18n.t("meldingen.evenement_opgeslagen"),
        this.notifyCalendarRef
      );
    } else {
      // Save the event to firebase and add to calendar
      firebase
        .database()
        .ref("calendarEvents/" + clicked.id)
        .update({
          title: eventTitle,
          description: eventDescription || null,
        })
        .then(() => {
          this.state.clickedEvent.setProp("title", eventTitle);
          this.state.clickedEvent.setExtendedProp(
            "description",
            eventDescription
          );
          this.setState({
            editModal: false,
            clickedEvent: null,
          });
          notify(
            "success",
            i18n.t("meldingen.evenement_opgeslagen"),
            this.notifyCalendarRef
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  /**
   * Save a new event to the database. Also add it directly to the calendar
   */
  onSaveEvent() {
    const {
      eventDay,
      eventDayFrom,
      eventDayUntil,
      eventTitle,
      eventDescription,
      eventType,
      eventFromTime,
      eventUntilTime,
    } = this.state;

    if (!!eventTitle) {
      let object = {
        companyId: this.state.companyId,
        userId: this.state.userId,
        type: eventType,
        title: eventTitle,
        description: eventDescription || null,
        created_at: firebase.database.ServerValue.TIMESTAMP,
      };
      switch (eventType) {
        case "dayPeriod":
          object["day"] = eventDay;
          object["fromTime"] = eventFromTime;
          object["untilTime"] = eventUntilTime
            ? eventUntilTime
            : defaultUntilTime;
          break;
        case "day":
          object["day"] = eventDay;
          break;
        case "period":
          object["fromDay"] = eventDayFrom;
          object["untilDay"] = eventDayUntil ? eventDayUntil : defaultUntilTime;
          break;
        default:
          console.error("Something wrong with event type");
      }

      firebase
        .database()
        .ref("calendarEvents")
        .push(object, (error) => {
          if (error) {
            console.error(error);
          }
        })
        .then((snap) => {
          let key = snap.key;
          let startEndObj = calculateStartAndEnd(object);
          calendar.addEvent({
            id: key,
            title: eventTitle,
            className: "bg-darkblue",
            description: eventDescription,
            start: startEndObj.start,
            end: startEndObj.end,
          });
          this.setState({
            eventTitle: null,
            eventDescription: null,
            eventFromTime: defaultFromTime,
            eventUntilTime: null,
            eventDay: defaultEventDay,
            eventDayFrom: defaultFromDay,
            eventDayUntil: null,
            eventType: "dayPeriod",
            modalAdd: false,
          });
          notify(
            "success",
            i18n.t("meldingen.evenement_opgeslagen"),
            this.notifyCalendarRef
          );
        });
    } else {
      notify(
        "warning",
        i18n.t("meldingen.evenement_leeg"),
        this.notifyCalendarRef
      );
    }
  }
  render() {
    const { steps } = this.state;
    return (
      <>
        <Tour
          steps={steps}
          continuous={true}
          run={this.state.startTour}
          onFinished={() => this.setState({ startTour: false })}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref={this.notifyCalendarRef} />
        </div>
        <div className="header header-dark bg-gradient-info pb-6 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6">
                  <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0 mr-1">
                    {this.state.currentDate}
                  </h6>
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-lg-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        <i className="fas fa-home" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      {i18n.t("dashboard.kalender.titel")}
                    </BreadcrumbItem>
                  </Breadcrumb>
                  <div className="ml-3 d-inline">
                    <TourIcon
                      startTour={() => this.setState({ startTour: true })}
                      color={"white"}
                    />
                  </div>
                </Col>
                <Col className="mt-3 mt-md-0 text-md-right" lg="6">
                  <Button
                    className="fullcalendar-btn-prev btn-neutral"
                    color="default"
                    onClick={() => {
                      calendar.prev();
                      this.setState({
                        currentDate: calendar.view.title,
                      });
                    }}
                    size="sm"
                  >
                    <i className="fas fa-angle-left" />
                  </Button>
                  <Button
                    className="fullcalendar-btn-next btn-neutral"
                    color="default"
                    onClick={() => {
                      calendar.next();
                      this.setState({
                        currentDate: calendar.view.title,
                      });
                    }}
                    size="sm"
                  >
                    <i className="fas fa-angle-right" />
                  </Button>
                  <Button
                    className="btn-neutral"
                    color="default"
                    data-calendar-view="month"
                    onClick={() => this.changeView("dayGridMonth")}
                    size="sm"
                  >
                    {i18n.t("dashboard.kalender.maand")}
                  </Button>
                  <Button
                    className="btn-neutral"
                    color="default"
                    data-calendar-view="basicWeek"
                    onClick={() => this.changeView("dayGridWeek")}
                    size="sm"
                  >
                    {i18n.t("dashboard.kalender.week")}
                  </Button>
                  <Button
                    className="btn-neutral"
                    color="default"
                    data-calendar-view="basicDay"
                    onClick={() => this.changeView("dayGridDay")}
                    size="sm"
                  >
                    {i18n.t("dashboard.kalender.dag")}
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card className="card-calendar" id="kalender-body">
                <CardHeader>
                  <h5 className="h3 mb-0">
                    {i18n.t("dashboard.kalender.titel")}
                  </h5>
                </CardHeader>
                <CardBody className="p-0">
                  <div
                    className="calendar"
                    data-toggle="calendar"
                    id="calendar"
                    ref={(reference) => (this.calendarRef = reference)}
                  />
                </CardBody>
              </Card>
              <Modal
                isOpen={this.state.editModal}
                toggle={() => this.setState({ editModal: false })}
                className="modal-dialog-centered modal-secondary"
              >
                <div className="modal-body">
                  <Form className="edit-event--form">
                    <FormGroup>
                      <label className="form-control-label">
                        {i18n.t("dashboard.kalender.evenement.titel.label")}
                      </label>
                      <Input
                        className="form-control-alternative edit-event--title"
                        placeholder={i18n.t(
                          "dashboard.kalender.evenement.titel.placeholder"
                        )}
                        type="text"
                        defaultValue={this.state.eventTitle}
                        onChange={(e) =>
                          this.setState({ eventTitle: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className="form-control-label">
                        {i18n.t(
                          "dashboard.kalender.evenement.beschrijving.label"
                        )}
                      </label>
                      <Input
                        className="form-control-alternative edit-event--description textarea-autosize"
                        placeholder={i18n.t(
                          "dashboard.kalender.evenement.beschrijving.placeholder"
                        )}
                        type="textarea"
                        defaultValue={this.state.eventDescription}
                        onChange={(e) =>
                          this.setState({ eventDescription: e.target.value })
                        }
                      />
                      <i className="form-group--bar" />
                    </FormGroup>
                    <input className="edit-event--id" type="hidden" />
                  </Form>
                </div>
                <div className="modal-footer">
                  <Button
                    color="link"
                    onClick={() => this.setState({ editModal: false })}
                  >
                    {i18n.t("dashboard.sluiten")}
                  </Button>
                  <Button color="danger" onClick={() => this.onDeleteEvent()}>
                    {i18n.t("dashboard.verwijderen")}
                  </Button>
                  <Button
                    className="ml-auto"
                    color="info"
                    onClick={() => this.onEditEvent()}
                  >
                    {i18n.t("dashboard.opslaan")}
                  </Button>
                </div>
              </Modal>
            </div>
          </Row>
          <Row>
            <Modal
              className="modal-dialog-centered"
              size="md"
              isOpen={this.state.modalAdd}
              toggle={() =>
                this.setState({
                  modalAdd: false,
                  eventTitle: null,
                  eventDescription: null,
                })
              }
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary border-0 mb-0">
                  <CardHeader className="bg-transparent pb-3">
                    <div className="text-muted text-center mt-2 mb-3">
                      <span className="card-header-title">
                        {i18n.t("dashboard.kalender.evenement.form_titel")}
                      </span>
                      <span className="card-header-subtitle">
                        {i18n.t("dashboard.kalender.evenement.form_subtitel")}
                      </span>
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-4 py-lg-4">
                    <Row className="justify-content-md-center mb-4">
                      <ButtonGroup aria-label="Basic example" role="group">
                        <Button
                          className={
                            this.state.eventType === "day"
                              ? "active"
                              : undefined
                          }
                          color="secondary"
                          type="button"
                          onClick={() => this.setState({ eventType: "day" })}
                        >
                          {i18n.t("dashboard.verlof.hele_dag")}
                        </Button>
                        <Button
                          className={
                            this.state.eventType === "dayPeriod"
                              ? "active"
                              : undefined
                          }
                          color="secondary"
                          type="button"
                          onClick={() =>
                            this.setState({ eventType: "dayPeriod" })
                          }
                        >
                          {i18n.t("dashboard.verlof.van_tot")}
                        </Button>
                        <Button
                          className={
                            this.state.eventType === "period"
                              ? "active"
                              : undefined
                          }
                          color="secondary"
                          type="button"
                          onClick={() => this.setState({ eventType: "period" })}
                        >
                          {i18n.t("dashboard.verlof.periode")}
                        </Button>
                      </ButtonGroup>
                    </Row>
                    <Form role="form">
                      {this.state.eventType === "dayPeriod" && (
                        <div>
                          <FormGroup className="row">
                            <Label className="form-control-label" md="4">
                              {i18n.t("dashboard.verlof.welke_dag")}
                            </Label>
                            <Col md="8">
                              <Input
                                defaultValue={this.state.eventDay}
                                type="date"
                                onChange={(e) =>
                                  this.setState({
                                    eventDay: e.target.value,
                                  })
                                }
                                min={today}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label className="form-control-label" md="4">
                              {i18n.t("dashboard.verlof.van")}
                            </Label>
                            <Col md="8">
                              <Input
                                type="time"
                                value={this.state.eventFromTime}
                                onChange={(e) =>
                                  this.handleEventTimeChange(e, "from")
                                }
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label className="form-control-label" md="4">
                              {i18n.t("dashboard.verlof.tot")}
                            </Label>
                            <Col md="8">
                              <Input
                                type="time"
                                value={
                                  !!this.state.eventUntilTime
                                    ? this.state.eventUntilTime
                                    : defaultUntilTime
                                }
                                onChange={(e) =>
                                  this.handleEventTimeChange(e, "until")
                                }
                                min={this.state.minUntilTime}
                              />
                            </Col>
                          </FormGroup>
                        </div>
                      )}

                      {this.state.eventType === "day" && (
                        <div>
                          <FormGroup className="row">
                            <Label className="form-control-label" md="4">
                              {i18n.t("dashboard.verlof.welke_dag")}
                            </Label>
                            <Col md="8">
                              <Input
                                defaultValue={this.state.eventDay}
                                type="date"
                                onChange={(e) =>
                                  this.setState({
                                    eventDay: e.target.value,
                                  })
                                }
                                min={today}
                              />
                            </Col>
                          </FormGroup>
                        </div>
                      )}

                      {this.state.eventType === "period" && (
                        <div>
                          <FormGroup className="row">
                            <Label className="form-control-label" md="4">
                              {i18n.t("dashboard.verlof.van")}
                            </Label>
                            <Col md="8">
                              <Input
                                value={this.state.eventDayFrom}
                                type="date"
                                onChange={(e) => {
                                  this.handleEventFromDayChange(e);
                                }}
                                min={today}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label className="form-control-label" md="4">
                              {i18n.t("dashboard.verlof.tot_met")}
                            </Label>
                            <Col md="8">
                              <Input
                                value={
                                  !!this.state.eventDayUntil
                                    ? this.state.eventDayUntil
                                    : defaultUntilDay
                                }
                                type="date"
                                onChange={(e) =>
                                  this.setState({
                                    eventDayUntil: e.target.value,
                                  })
                                }
                                min={this.state.minUntilDay}
                              />
                            </Col>
                          </FormGroup>
                        </div>
                      )}
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="textarea"
                        >
                          {i18n.t("dashboard.kalender.evenement.titel.label")}
                        </label>
                        <Input
                          id="textarea"
                          resize="none"
                          rows="1"
                          type="textarea"
                          placeholder={i18n.t(
                            "dashboard.kalender.evenement.titel.placeholder"
                          )}
                          value={this.state.eventNotes}
                          onChange={(e) =>
                            this.setState({ eventTitle: e.target.value })
                          }
                          invalid={this.state.notesError}
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="textarea"
                        >
                          {i18n.t(
                            "dashboard.kalender.evenement.beschrijving.label"
                          )}
                        </label>
                        <Input
                          id="textarea"
                          resize="none"
                          rows="3"
                          type="textarea"
                          placeholder={i18n.t(
                            "dashboard.kalender.evenement.beschrijving.placeholder"
                          )}
                          value={this.state.eventNotes}
                          onChange={(e) =>
                            this.setState({ eventDescription: e.target.value })
                          }
                          invalid={this.state.notesError}
                        />
                        {this.state.notesError && (
                          <div className="invalid-feedback">
                            {i18n.t("meldingen.max_60_char")}
                          </div>
                        )}
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={() =>
                            this.setState({
                              modalAdd: false,
                              eventTitle: null,
                              eventDescription: null,
                            })
                          }
                        >
                          {i18n.t("dashboard.sluiten")}
                        </Button>
                        <Button
                          className="my-4"
                          color="success"
                          type="button"
                          onClick={() => this.onSaveEvent()}
                        >
                          {i18n.t("dashboard.opslaan")}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>
          </Row>
          <Row>
            <Modal
              className="modal-dialog-centered"
              size="sm"
              isOpen={this.state.descriptionModal}
              toggle={() => this.setState({ descriptionModal: false })}
            >
              <div className="modal-body p-0">
                <Card className="m-0">
                  <CardBody>
                    <CardTitle className="mb-3" tag="h3">
                      {this.state.eventTitle}
                    </CardTitle>
                    <CardText className="mb-4">
                      {!!this.state.clickedEvent &&
                        this.state.clickedEvent.extendedProps.description}
                    </CardText>
                    <Button
                      color="info"
                      onClick={() => this.setState({ descriptionModal: false })}
                    >
                      {i18n.t("dashboard.sluiten")}
                    </Button>
                  </CardBody>
                </Card>
              </div>
            </Modal>
          </Row>
        </Container>
      </>
    );
  }
}

export default CalendarView;
