import React from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip
} from "reactstrap";
import firebase from "firebase";
import NotificationAlert from "react-notification-alert";
import notify from "../../../functions/notify";
import Moment from "moment";
import beeImage from "assets/img/theme/dash.png";
import CardsHeader from "../../../../components/Headers/CardsHeader";
import { calculateOffDayBalance, hasRemainingOffDaysInBalance } from "../../../functions/offDays";
import { isPlural } from "../../../functions/generic";
import { getUserByUserId } from "../../../functions/firebase/users";
import { extendMoment } from "moment-range";
import i18n from "variables/translations/i18n";
import { sendOffdayRequestEmail } from "views/functions/mail";
import Tour from "components/Support/Tour";
import { tourSteps } from "variables/tourSteps";
import { formatOffdayDayTime, formatOffdayPeriod, formatOffdaySingleDay } from "../../../functions/formatting";

const moment = extendMoment(Moment);
const today = moment().format("YYYY-MM-DD");
// 1 day
const defaultOffDay = moment().add(1, "day").format("YYYY-MM-DD");
// From/Until time
const defaultFromTime = "09:00";
const defaultUntilTime = "13:00";
// Period
const defaultFromDay = moment().add(1, "day").format("YYYY-MM-DD");
const defaultUntilDay = moment().add(2, "day").format("YYYY-MM-DD");

class Verlof extends React.Component {
  state = {
    companyId: localStorage.getItem("compid"),
    userId: localStorage.getItem("uid"),
    requests: {},
    formModal: false,
    editForm: false,
    verlofFromTime: defaultFromTime,
    verlofUntilTime: null,
    verlofDay: defaultOffDay,
    verlofDayFrom: defaultFromDay,
    verlofDayUntil: null,
    minUntilDay: defaultUntilDay,
    minUntilTime: defaultUntilTime,
    verlofStatus: "requested",
    verlofType: "dayPeriod",
    requestId: null,
    errorMessage: "",
    initialOffDays: 25,
    remainingDays: 25,
    usedOffDays: 0,
    offDaysUser: null,
    priveButtonActive: true,
    isBusinessOffday: false,
    notes: "",
    notesError: false,
    requestToDelete: null,
    deleteModal: false,
    startTour: false,
    steps: tourSteps.verlof,
    userName: localStorage.getItem("userName")
  };

  constructor(props) {
    super(props);
    this.notifyVerlofRef = React.createRef();
  }

  componentDidMount() {
    this.getInitialOffDaysCompany();
  }

  componentDidUpdate() {
    this.checkNotification();
  }

  /**
   * Fetch initial off days of the company from Firebase.
   */
  getInitialOffDaysCompany() {
    // Get user offdays. If set, use that to calculate balance. Else, use company offdays.
    getUserByUserId(this.state.userId).then((user) => {
      if (!!user.offdays) {
        this.setState({initialOffDays: user.offdays});
        this.getRequests(user.offdays);
      } else {
        let company = firebase
          .database()
          .ref("companies/" + this.state.companyId);
        company.on("value", (snapshot) => {
          let data = snapshot.val();
          if (!!data && !!data.offDaysTeam) {
            this.setState({initialOffDays: data.offDaysTeam});
            this.getRequests(data.offDaysTeam);
          } else {
            this.getRequests(this.state.initialOffDays);
          }
        });
      }
    });
  }

  /**
   * Fetch the off days of the user.
   * @param offDaysAmount Initial off days, either of the company or (if present) of the user.
   */
  getRequests(offDaysAmount) {
    let requests = firebase
      .database()
      .ref("offdays")
      .orderByChild("userId")
      .equalTo(this.state.userId);
    requests.on("value", (snapshot) => {
      let data = snapshot.val();
      if (!!data) {
        this.getOffDaysOfUser(Object.values(data), offDaysAmount);
        let sortedData = {};
        Object.entries(data)
          // Sort by created_at; new items first
          .sort((a, b) => {
            return b[1].created_at - a[1].created_at;
          })
          .map(function (item, i) {
            sortedData[item[0]] = item[1];
          });

        // If request is handled, retrieve name and picture of the handler
        for (let i = 0; i < Object.keys(sortedData).length; i++) {
          let current = Object.entries(sortedData)[i];
          let id = current[0];
          let handler = current[1].handledBy;
          if (!!handler) {
            firebase
              .database()
              .ref("users/" + handler)
              .on("value", (snapshot) => {
                let user = snapshot.val();
                sortedData[id].handler = {
                  id: user.id,
                  picture: user.picture,
                  name: user.name
                };
                if (i === Object.keys(sortedData).length - 1)
                  this.setState({requests: sortedData});
              });
          }
          if (i === Object.keys(sortedData).length - 1)
            this.setState({requests: sortedData});
        }
      } else {
        this.setState({
          requests: null,
          remainingDays: this.state.initialOffDays
        });
      }
    });
  }

  /**
   * Check and remove notification from Firebase 'notifications' Object, if clicked upon.
   */
  checkNotification() {
    let notification = this.props.location.notification;
    if (!!notification) {
      let id = notification[0];
      /** Notification was clicked, so now delete it */
      firebase
        .database()
        .ref("notifications/" + id)
        .remove((error) => {
          if (error) {
            console.error(error);
          }
        });
    }
  }

  /**
   * Save the user requested off day object on Firebase.
   * If the user doesn't have enough balance for the off day, he/she will see a notification banner.
   */
  onSaveRequest() {
    let object = {
      companyId: this.state.companyId,
      userId: this.state.userId,
      status: this.state.verlofStatus,
      type: this.state.verlofType,
      handledBy: null,
      created_at: firebase.database.ServerValue.TIMESTAMP,
      notes: this.state.notes,
      isBusinessOffday: this.state.isBusinessOffday
    };
    let timeObject = {};
    let datePeriod = ""
    switch (this.state.verlofType) {
      case "dayPeriod":
        object["day"] = this.state.verlofDay;
        object["fromTime"] = this.state.verlofFromTime;
        object["untilTime"] = !!this.state.verlofUntilTime
          ? this.state.verlofUntilTime
          : this.state.minUntilTime;
        timeObject = {
          type: this.state.verlofType,
          day: this.state.verlofDay,
          fromTime: this.state.verlofFromTime,
          untilTime: !!this.state.verlofUntilTime
            ? this.state.verlofUntilTime
            : this.state.minUntilTime
        };
        datePeriod = formatOffdayDayTime(object["day"], object["fromTime"], object["untilTime"])
        break;
      case "day":
        object["day"] = this.state.verlofDay;
        timeObject = {
          type: this.state.verlofType,
          day: this.state.verlofDay
        };
        datePeriod = formatOffdaySingleDay(object["day"])
        break;
      case "period":
        object["fromDay"] = this.state.verlofDayFrom;
        object["untilDay"] = !!this.state.verlofDayUntil
          ? this.state.verlofDayUntil
          : this.state.minUntilDay;
        timeObject = {
          type: this.state.verlofType,
          fromDay: this.state.verlofDayFrom,
          untilDay: !!this.state.verlofDayUntil
            ? this.state.verlofDayUntil
            : this.state.minUntilDay
        };
        datePeriod = formatOffdayPeriod(object["fromDay"], object["untilDay"])
        break;
      default:
        console.error("No request status found");
    }
    if (!this.hasOffDayBalance(timeObject)) {
      notify(
        "warning",
        i18n.t("meldingen.verlofsaldo_onvoldoende"),
        this.notifyVerlofRef
      );
    } else if (this.hasOverlapWithOtherRequest(object)) {
      notify(
        "warning",
        i18n.t("meldingen.verlof_overlap"),
        this.notifyVerlofRef
      );
    } else {
      firebase
        .database()
        .ref("offdays")
        .push(object, (error) => {
          if (error) {
            console.error(error);
          } else {
            this.setState({
              verlofFromTime: defaultFromTime,
              verlofUntilTime: null,
              verlofDay: defaultOffDay,
              verlofDayFrom: defaultFromDay,
              verlofDayUntil: null,
              minUntilDay: defaultUntilDay,
              minUntilTime: defaultUntilTime,
              formModal: false,
              priveButtonActive: true,
              notes: ""
            });
            notify(
              "success",
              i18n.t("meldingen.aanvraag_ingediend"),
              this.notifyVerlofRef
            );
          }
        })
        .then((snap) => {
          let key = snap.key;
          firebase.database().ref("notifications").push({
            created_at: firebase.database.ServerValue.TIMESTAMP,
            companyId: this.state.companyId,
            type: "request-offday",
            offdayId: key
          });

          let offdayType = this.state.isBusinessOffday ? "zakelijk" : "prive"
          sendOffdayRequestEmail(this.state.userId, this.state.userName, offdayType, datePeriod);
        });
    }
  }

  /**
   * Edit off day request on Firebase.
   */
  onEditRequest() {
    let object = {
      type: this.state.verlofType,
      notes: this.state.notes,
      isBusinessOffday: this.state.isBusinessOffday
    };
    let datePeriod = ""
    switch (this.state.verlofType) {
      case "dayPeriod":
        object["day"] = this.state.verlofDay;
        object["fromTime"] = this.state.verlofFromTime;
        object["untilTime"] = !!this.state.verlofUntilTime
          ? this.state.verlofUntilTime
          : this.state.minUntilTime;
        datePeriod = formatOffdayDayTime(object["day"], object["fromTime"], object["untilTime"])
        break;
      case "day":
        object["day"] = this.state.verlofDay;
        datePeriod = formatOffdaySingleDay(object["day"])
        break;
      case "period":
        object["fromDay"] = this.state.verlofDayFrom;
        object["untilDay"] = !!this.state.verlofDayUntil
          ? this.state.verlofDayUntil
          : this.state.minUntilDay;
        datePeriod = formatOffdayPeriod(object["fromDay"], object["untilDay"])
        break;
      default:
        console.error("No request status found");
    }
    firebase
      .database()
      .ref("offdays/" + this.state.requestId)
      .update(object, (error) => {
        if (error) {
          console.error(error);
        } else {
          notify(
            "success",
            i18n.t("meldingen.aanvraag_opgeslagen"),
            this.notifyVerlofRef
          );
          this.setState({
            verlofFromTime: defaultFromTime,
            verlofUntilTime: null,
            verlofDay: defaultOffDay,
            verlofDayFrom: defaultFromDay,
            verlofDayUntil: null,
            minUntilDay: defaultUntilDay,
            minUntilTime: defaultUntilTime,
            formModal: false,
            editForm: false,
            requestId: null,
            notes: "",
            priveButtonActive: true
          });

          let offdayType = this.state.isBusinessOffday ? "zakelijk" : "prive"
          sendOffdayRequestEmail(this.state.userId, this.state.userName, offdayType, datePeriod);
        }
      });
  }

  /**
   * Toggle for displaying/hiding the off day modal.
   * @param state the state of the object clicked upon, e.g. formModal.
   */
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
      notesError: !this.state[state],
      notes: "",
      editForm: false
    });
  };

  /**
   * Delete off days from Firebase.
   * @param requestId
   */
  onPressDeleteRequest(requestId) {
    // TODO: Make are you sure modal OR make undo button for few seconds
    firebase
      .database()
      .ref("offdays/" + requestId)
      .remove((error) => {
        if (error) {
          console.error(error);
          notify(
            "warning",
            i18n.t("meldingen.foutmelding"),
            this.notifyVerlofRef
          );
        } else {
          this.setState({requestToDelete: null, deleteModal: false});
          notify(
            "success",
            i18n.t("meldingen.aanvraag_verwijderd"),
            this.notifyVerlofRef
          );
        }
      });
  }

  /**
   * Set edit state of the off day object.
   * @param request The Firebase off day object.
   */
  openEditModal(request) {
    let req = request[1];
    let type = req.type;
    if (req.status === "requested") {
      let object = {
        editForm: true,
        formModal: true,
        requestId: request[0],
        notes: req.notes,
        priveButtonActive: !req.isBusinessOffday
      };
      switch (type) {
        case "dayPeriod":
          object["verlofDay"] = req.day;
          object["verlofFromTime"] = req.fromTime;
          object["verlofUntilTime"] = req.untilTime;
          object["verlofType"] = type;
          break;
        case "day":
          object["verlofDay"] = req.day;
          object["verlofType"] = type;
          break;
        case "period":
          object["verlofDayFrom"] = req.fromDay;
          object["verlofDayUntil"] = req.untilDay;
          object["verlofType"] = type;
          break;
        default:
          console.error("No request status found");
      }
      this.setState(object);
    } else
      notify(
        "warning",
        i18n.t("meldingen.aanvraag_reeds_behandeld"),
        this.notifyVerlofRef
      );
  }

  /**
   * Set the state of the off day object according to the user input.
   * @param e the user selected day.
   */
  handleVerlofFromDayChange(e) {
    let minUntilDay = moment(e.target.value).add(1, "day").format("YYYY-MM-DD");
    if (
      !!this.state.verlofDayUntil &&
      (moment(e.target.value).isSame(this.state.verlofDayUntil) ||
        moment(e.target.value).isAfter(this.state.verlofDayUntil))
    ) {
      this.setState({
        verlofDayFrom: e.target.value,
        verlofDayUntil: null,
        minUntilDay: minUntilDay
      });
    } else {
      this.setState({
        verlofDayFrom: e.target.value,
        minUntilDay: minUntilDay
      });
    }
  }

  /**
   * Set the state of the off day time object according to the user input.
   * @param e the user selected time.
   * @param type the type of the off day time Object, e.g. from or until
   */
  handleVerlofTimeChange(e, type) {
    let minUntilTime = moment(e.target.value, "HH:mm")
      .add(1, "hour")
      .format("HH:mm");
    let today = moment().format("YYYY-MM-DD");
    // In order to compare 2 times, the day must be specified
    let target = moment(today + " " + e.target.value).format(
      "YYYY-MM-DD HH:mm"
    );
    let fromTime = moment(today + " " + this.state.verlofFromTime).format(
      "YYYY-MM-DD HH:mm"
    );
    let untilTime = moment(today + " " + this.state.verlofUntilTime).format(
      "YYYY-MM-DD HH:mm"
    );
    if (type === "from") {
      if (e.target.value === "23:00") minUntilTime = "23:59";
      if (
        !!this.state.verlofUntilTime &&
        (moment(target).isSame(untilTime) || moment(target).isAfter(untilTime))
      ) {
        this.setState({
          verlofFromTime: e.target.value,
          verlofUntilTime: null,
          minUntilTime: minUntilTime
        });
      } else {
        this.setState({
          verlofFromTime: e.target.value,
          minUntilTime: minUntilTime
        });
      }
    }
    if (type === "until") {
      if (
        moment(target).isSame(fromTime) ||
        moment(target).isBefore(fromTime)
      ) {
        let newFromTime = moment(e.target.value, "HH:mm")
          .subtract(1, "hour")
          .format("HH:mm");
        this.setState({
          verlofFromTime: newFromTime,
          verlofUntilTime: e.target.value
        });
      } else {
        this.setState({
          verlofUntilTime: e.target.value
        });
      }
    }
  }

  /**
   * Toggle for showing a notification if the 'to delete' request is already handled, otherwise it will open the delete modal.
   * @param member The user to delete
   */
  onPressDelete(request) {
    if (request[1].status !== "requested")
      notify(
        "warning",
        i18n.t("meldingen.aanvraag_behandeld_verwijderen"),
        this.notifyVerlofRef
      );
    else this.setState({requestToDelete: request, deleteModal: true});
  }

  /**
   * Fetch the moment time values depending on the Firebase off day object
   * @param request The Firebase off day object
   * @param type The off day type, e.g. fromTime/untilTime
   * @returns {string|*}
   */
  getTableTimeField(request, type) {
    if (request.type === "dayPeriod") {
      if (type === "from") return request.fromTime;
      if (type === "until") return request.untilTime;
    } else if (request.type === "period") {
      if (type === "from") {
        if (moment().year() !== moment(request.fromDay).year())
          return moment(request.fromDay).format("dddd DD MMM YYYY");
        else return moment(request.fromDay).format("dddd DD MMM");
      }
      if (type === "until") {
        if (moment().year() !== moment(request.untilDay).year())
          return moment(request.untilDay).format("dddd DD MMM YYYY");
        else return moment(request.untilDay).format("dddd DD MMM");
      }
    } else return "-";
  }

  /**
   * Convert off day to html for in the delete modal
   * @param request Off day request
   * @returns html corresponding to request information
   */
  getDeleteModalData(request) {
    if (request.type === "day" || request.type === "dayPeriod") {
      return (
        <div>
          <p className="d-block mb-1 font-weight-bold">
            {moment(request.day).format("dddd DD MMM")}
          </p>
          <p className="font-weight-light">{request.notes}</p>
        </div>
      );
    } else {
      return (
        <div>
          <p className="d-block m-0 font-weight-bold">
            {moment(request.fromDay).format("dddd DD MMM")}
          </p>
          <p className="m-0">-</p>
          <p className="d-block font-weight-bold">
            {moment(request.untilDay).format("dddd DD MMM")}
          </p>
          <p className="font-weight-light">{request.notes}</p>
        </div>
      );
    }
  }

  /**
   * Convert off day timestamps to readable formats
   * @param request Off day request
   * @returns {string} Readable format by moment.js
   */
  getTableDate(request) {
    if (request.type === "day" || request.type === "dayPeriod") {
      if (moment().year() !== moment(request.day).year())
        return moment(request.day).format("dddd DD MMM YYYY");
      else return moment(request.day).format("dddd DD MMM");
    } else return "-";
  }

  /**
   * Fetch the color of the badge depending on the status of the off day.
   * @param status The status of the off day
   * @returns {string} as css color
   */
  getBadgeColor(status) {
    if (status === "requested") return "bg-teal";
    if (status === "accepted") return "bg-success";
    if (status === "denied") return "bg-danger";
  }

  /**
   * Fetch the status of the off day depending on the input.
   * @param result The value from Firebase
   * @returns {string} The value as a readable String in Dutch
   */
  getStatus(result) {
    if (result === "accepted") return i18n.t("dashboard.verlof.geaccepteerd");
    if (result === "denied") return i18n.t("dashboard.verlof.afgekeurd");
    if (result === "requested") return i18n.t("dashboard.verlof.aangevraagd");
  }

  /**
   * Calculate the off day usage and balance of the user
   * @param offDaysFirebaseObjectArray All the off days the user has taken
   * @param offDaysTeam The initial off day balance of the user decided by the company, e.g. 25
   */
  getOffDaysOfUser(offDaysFirebaseObjectArray, offDaysTeam) {
    let returnObject = calculateOffDayBalance(
      offDaysFirebaseObjectArray,
      offDaysTeam
    );
    this.setState({
      usedOffDays: returnObject.usedDays,
      remainingDays: returnObject.remainingDays
    });
  }

  /**
   * Set the state of the private/business off day button
   * @param value The type the user clicked on, e.g. prive or zakelijk
   */
  onVerlofTypeButtonCLick(value) {
    if (value === "prive")
      this.setState({priveButtonActive: true, isBusinessOffday: false});
    else this.setState({priveButtonActive: false, isBusinessOffday: true});
  }

  /**
   * Set the state of the note depending on the user input.
   * @param input The user input of the notes
   */
  onNotesChange(input) {
    if (input.target.value.length > 60) this.setState({notesError: true});
    else this.setState({notesError: false, notes: input.target.value});
  }

  /**
   * Check if the user has the balance for the requested off day
   * @param timeObject the off day request
   * @returns {boolean} for having a balance for the request.
   */
  hasOffDayBalance(timeObject) {
    if (this.state.isBusinessOffday) return true;
    else return hasRemainingOffDaysInBalance(timeObject, this.state.requests, this.state.initialOffDays);
  }

  /**
   * Check if the request that is being made has overlap with another request of the user
   * @param request the off day request
   * @returns {boolean} for having overlap or not.
   */
  hasOverlapWithOtherRequest(request) {
    // On first request, there is certainly no overlap
    if (this.state.requests === null) return false;
    let requests = Object.values(this.state.requests);
    if (request.type === "dayPeriod" || request.type === "day") {
      for (let entry of requests) {
        if (entry.day === request.day) {
          if (entry.type === "dayPeriod") {
            // Same day, check if the times overlap
            let range1 = moment.range(
              moment(request.fromTime, "HH:mm"),
              moment(request.untilTime, "HH:mm")
            );
            let range2 = moment.range(
              moment(entry.fromTime, "HH:mm"),
              moment(entry.untilTime, "HH:mm")
            );
            if (range1.overlaps(range2)) return true;
          } else {
            // Request type is day and day is the same
            return true;
          }
        }
        if (entry.type === "day" && entry.day === request.day) return true;
        if (
          entry.type === "period" &&
          (request.day === entry.fromDay || request.day === entry.untilDay)
        )
          return true;
      }
      return false;
    } else {
      for (let entry of requests) {
        if (entry.type === "period") {
          let range1 = moment.range(
            moment(request.fromDay, "YYYY-MM-DD"),
            moment(request.untilDay, "YYYY-MM-DD")
          );
          let range2 = moment.range(
            moment(entry.fromDay, "YYYY-MM-DD"),
            moment(entry.untilDay, "YYYY-MM-DD")
          );
          if (range1.overlaps(range2)) return true;
        } else {
          // Current type is day or dayPeriod, check if day is in range
          if (request.fromDay === entry.day || request.untilDay === entry.day)
            return true;
        }
      }
      return false;
    }
  }

  render() {
    const {steps} = this.state;
    return (
      <>
        <Tour
          steps={steps}
          continuous={true}
          run={this.state.startTour}
          onFinished={() => this.setState({startTour: false})}
        />
        <div className="rna-wrapper">
          <NotificationAlert ref={this.notifyVerlofRef}/>
        </div>
        <CardsHeader
          parentName={"Verlof"}
          startTour={() => this.setState({startTour: true})}
          data={[
            {
              name: i18n.t("dashboard.verlof.saldo"),
              value:
                this.state.remainingDays +
                (isPlural(this.state.remainingDays)
                  ? " " + i18n.t("dashboard.verlof.dag_meervoud").toLowerCase()
                  : " " + i18n.t("dashboard.verlof.dag_eenvoud").toLowerCase())
            },
            {
              name: i18n.t("dashboard.verlof.verbruikt"),
              value:
                this.state.usedOffDays +
                (isPlural(this.state.usedOffDays)
                  ? " " + i18n.t("dashboard.verlof.dag_meervoud").toLowerCase()
                  : " " + i18n.t("dashboard.verlof.dag_eenvoud").toLowerCase())
            }
          ]}
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col md="4">
              <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={this.state.formModal}
                toggle={() => this.toggleModal("formModal")}
              >
                <div className="modal-body p-0">
                  <Card className="bg-secondary border-0 mb-0">
                    <CardHeader className="bg-transparent pb-3">
                      <div className="text-muted text-center mt-2 mb-3">
                        <span className="card-header-title">
                          {this.state.editForm
                            ? i18n.t("dashboard.verlof.bewerken")
                            : i18n.t("dashboard.verlof.aanvragen")}
                        </span>
                        <span className="card-header-subtitle">
                          {i18n.t("dashboard.vul_formulier_in")}
                        </span>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-4 py-lg-4">
                      <Row className="justify-content-md-center mb-4">
                        {this.state.editForm === false && (
                          <ButtonGroup aria-label="Basic example" role="group">
                            <Button
                              className={
                                this.state.verlofType === "day"
                                  ? "active"
                                  : undefined
                              }
                              color="secondary"
                              type="button"
                              onClick={() =>
                                this.setState({verlofType: "day", verlofDay: defaultOffDay})
                              }
                            >
                              {i18n.t("dashboard.verlof.hele_dag")}
                            </Button>
                            <Button
                              className={
                                this.state.verlofType === "dayPeriod"
                                  ? "active"
                                  : undefined
                              }
                              color="secondary"
                              type="button"
                              onClick={() =>
                                this.setState({verlofType: "dayPeriod", verlofDay: defaultOffDay})
                              }
                            >
                              {i18n.t("dashboard.verlof.van_tot")}
                            </Button>
                            <Button
                              className={
                                this.state.verlofType === "period"
                                  ? "active"
                                  : undefined
                              }
                              color="secondary"
                              type="button"
                              onClick={() =>
                                this.setState({verlofType: "period"})
                              }
                            >
                              {i18n.t("dashboard.verlof.periode")}
                            </Button>
                          </ButtonGroup>
                        )}
                      </Row>
                      <Form role="form">
                        {this.state.verlofType === "dayPeriod" && (
                          <div>
                            <FormGroup className="row">
                              <Label className="form-control-label" md="4">
                                {i18n.t("dashboard.verlof.welke_dag")}
                              </Label>
                              <Col md="8">
                                <Input
                                  defaultValue={
                                    this.state.editForm
                                      ? this.state.verlofDay
                                      : defaultOffDay
                                  }
                                  type="date"
                                  onChange={(e) =>
                                    this.setState({
                                      verlofDay: e.target.value
                                    })
                                  }
                                  min={today}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="row">
                              <Label className="form-control-label" md="4">
                                {i18n.t("dashboard.verlof.van")}
                              </Label>
                              <Col md="8">
                                <Input
                                  defaultValue={
                                    this.state.editForm
                                      ? this.state.verlofFromTime
                                      : defaultFromTime
                                  }
                                  type="time"
                                  value={this.state.verlofFromTime}
                                  onChange={(e) =>
                                    this.handleVerlofTimeChange(e, "from")
                                  }
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="row">
                              <Label className="form-control-label" md="4">
                                {i18n.t("dashboard.verlof.tot")}
                              </Label>
                              <Col md="8">
                                <Input
                                  defaultValue={
                                    this.state.editForm
                                      ? this.state.verlofUntilTime
                                      : defaultUntilTime
                                  }
                                  type="time"
                                  value={
                                    !!this.state.verlofUntilTime
                                      ? this.state.verlofUntilTime
                                      : this.state.minUntilTime
                                  }
                                  onChange={(e) =>
                                    this.handleVerlofTimeChange(e, "until")
                                  }
                                  min={this.state.minUntilTime}
                                />
                              </Col>
                            </FormGroup>
                          </div>
                        )}

                        {this.state.verlofType === "day" && (
                          <div>
                            <FormGroup className="row">
                              <Label className="form-control-label" md="4">
                                {i18n.t("dashboard.verlof.welke_dag")}
                              </Label>
                              <Col md="8">
                                <Input
                                  defaultValue={
                                    this.state.editForm
                                      ? this.state.verlofDay
                                      : defaultOffDay
                                  }
                                  type="date"
                                  onChange={(e) =>
                                    this.setState({
                                      verlofDay: e.target.value
                                    })
                                  }
                                  min={today}
                                />
                              </Col>
                            </FormGroup>
                          </div>
                        )}

                        {this.state.verlofType === "period" && (
                          <div>
                            <FormGroup className="row">
                              <Label className="form-control-label" md="4">
                                {i18n.t("dashboard.verlof.van")}
                              </Label>
                              <Col md="8">
                                <Input
                                  defaultValue={this.state.verlofDayFrom}
                                  type="date"
                                  onChange={(e) => {
                                    this.handleVerlofFromDayChange(e);
                                  }}
                                  min={today}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="row">
                              <Label className="form-control-label" md="4">
                                {i18n.t("dashboard.verlof.tot_met")}
                              </Label>
                              <Col md="8">
                                <Input
                                  defaultValue={this.state.minUntilDay}
                                  value={
                                    !!this.state.verlofDayUntil
                                      ? this.state.verlofDayUntil
                                      : this.state.minUntilDay
                                  }
                                  type="date"
                                  onChange={(e) =>
                                    this.setState({
                                      verlofDayUntil: e.target.value
                                    })
                                  }
                                  min={this.state.minUntilDay}
                                />
                              </Col>
                            </FormGroup>
                          </div>
                        )}
                        <FormGroup className="row">
                          <Label className="form-control-label" md="4">
                            {i18n.t("dashboard.verlof.type")}
                          </Label>
                          <UncontrolledTooltip
                            delay={0}
                            target={"zakelijkVerlof"}
                          >
                            {i18n.t("dashboard.verlof.zakelijk_uitleg")}
                          </UncontrolledTooltip>
                          <Col md="8">
                            <Button
                              color="info"
                              outline
                              type="button"
                              disabled={this.state.editForm && !this.state.priveButtonActive}
                              active={this.state.priveButtonActive}
                              onClick={() =>
                                this.onVerlofTypeButtonCLick("prive")
                              }
                            >
                              {i18n.t("dashboard.verlof.prive")}
                            </Button>
                            <Button
                              color="info"
                              outline
                              type="button"
                              id="zakelijkVerlof"
                              disabled={this.state.editForm && this.state.priveButtonActive}
                              active={!this.state.priveButtonActive}
                              onClick={() =>
                                this.onVerlofTypeButtonCLick("zakelijk")
                              }
                            >
                              {i18n.t("dashboard.verlof.zakelijk")}
                            </Button>
                          </Col>
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="textarea"
                          >
                            {i18n.t("dashboard.verlof.opmerking")}
                          </label>
                          <Input
                            id="textarea"
                            resize="none"
                            rows="3"
                            type="textarea"
                            value={this.state.notes}
                            onChange={(input) => this.onNotesChange(input)}
                            invalid={this.state.notesError}
                          />
                          {this.state.notesError && (
                            <div className="invalid-feedback">
                              {i18n.t("meldingen.max_60_char")}
                            </div>
                          )}
                        </FormGroup>

                        <div className="text-center">
                          <Button
                            className="my-4"
                            color="primary"
                            type="button"
                            onClick={() =>
                              this.state.editForm
                                ? this.setState({
                                  formModal: false,
                                  editForm: false
                                })
                                : this.toggleModal("formModal")
                            }
                          >
                            {i18n.t("dashboard.sluiten")}
                          </Button>
                          <Button
                            className="my-4"
                            color="success"
                            type="button"
                            onClick={() =>
                              this.state.editForm
                                ? this.onEditRequest()
                                : this.onSaveRequest()
                            }
                          >
                            {i18n.t("dashboard.verlof.submit_btn")}
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </Modal>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card id="aanvragen-tabel">
                <CardHeader className="border-0">
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">
                        {i18n.t("dashboard.verlof.recent")}
                      </h3>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Button
                        className="btn-round btn-icon"
                        color="info"
                        id="verlofaanvraag-button"
                        onClick={(e) => this.setState({formModal: true})}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit"/>
                        </span>
                        <span className="btn-inner--text">
                          {i18n.t("dashboard.verlof.nieuw_btn")}
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table table-responsive w-100 d-block d-md-table">
                  <thead className="thead-light">
                  <tr>
                    <th scope="col">
                      {i18n.t("dashboard.verlof.tabel.datum")}
                    </th>
                    <th scope="col">
                      {i18n.t("dashboard.verlof.tabel.van")}
                    </th>
                    <th scope="col">
                      {i18n.t("dashboard.verlof.tabel.tot")}
                    </th>
                    <th scope="col">
                      {i18n.t("dashboard.verlof.tabel.type")}
                    </th>
                    <th scope="col">
                      {i18n.t("dashboard.verlof.tabel.opmerkingen")}
                    </th>
                    <th scope="col">
                      {i18n.t("dashboard.verlof.tabel.status")}
                    </th>
                    <th scope="col">
                      {i18n.t("dashboard.verlof.tabel.behandelaar")}
                    </th>
                    <th scope="col"/>
                  </tr>
                  </thead>
                  <tbody className="list">
                  {this.state.requests !== null &&
                  Object.entries(this.state.requests).map((request, i) => {
                    if (request[1].active !== false)
                      return (
                        <tr
                          key={i}
                          className={
                            !!this.props.location.notification &&
                            this.props.location.notification[1].offdayId ===
                            request[0]
                              ? "bg-light"
                              : undefined
                          }
                        >
                          <td className="name">
                            {this.getTableDate(request[1])}
                          </td>
                          <td>
                            {this.getTableTimeField(request[1], "from")}
                          </td>
                          <td>
                            {this.getTableTimeField(request[1], "until")}
                          </td>
                          <td>
                            {request[1].isBusinessOffday
                              ? i18n.t("dashboard.verlof.zakelijk")
                              : i18n.t("dashboard.verlof.prive")}
                          </td>
                          <td>{request[1].notes}</td>
                          <td>
                            <Badge color="" className="badge-dot mr-4">
                              <i
                                className={this.getBadgeColor(
                                  request[1].status
                                )}
                              />
                              <span className="status">
                                    {this.getStatus(request[1].status)}
                                  </span>
                            </Badge>
                          </td>
                          <td>
                            {!!request[1].handler ? (
                              <div className="avatar-group">
                                <a
                                  className="avatar avatar-sm rounded-circle"
                                  id={"handlerImage-" + i}
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <img
                                    alt={"Behandelaar"}
                                    src={
                                      !!request[1].handler.picture
                                        ? request[1].handler.picture
                                        : beeImage
                                    }
                                  />
                                </a>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={"handlerImage-" + i}
                                >
                                  {request[1].handler.name}
                                </UncontrolledTooltip>
                              </div>
                            ) : (
                              <div>-</div>
                            )}
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-info"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v"/>
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={() =>
                                    this.openEditModal(request)
                                  }
                                >
                                  {i18n.t("dashboard.bewerken")}
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    this.onPressDelete(request)
                                  }
                                >
                                  {i18n.t("dashboard.verwijderen")}
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    else return null;
                  })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
          {/* Delete Modal */}
          <Row>
            <Col md="4">
              <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={this.state.deleteModal}
                toggle={() =>
                  this.setState({
                    deleteModal: !this.state.deleteModal,
                    requestToDelete: null
                  })
                }
              >
                {this.state.requestToDelete !== null && (
                  <div className="modal-body p-0">
                    <Card className="bg-secondary border-0 mb-0">
                      <CardHeader className="bg-transparent pb-3">
                        <div className="text-muted text-center mt-2 mb-3">
                          <span className="card-header-title">
                            {i18n.t("dashboard.verlof.verwijderen")}
                          </span>
                        </div>
                      </CardHeader>
                      <CardBody className="px-lg-5 py-lg-5">
                        <div className="pt-4 text-center">
                          <h5 className="h3 title">
                            {this.getDeleteModalData(
                              this.state.requestToDelete[1]
                            )}
                          </h5>
                        </div>
                        <Form role="form">
                          <div className="text-center">
                            <Button
                              className="my-4"
                              color="primary"
                              type="button"
                              onClick={() =>
                                this.setState({
                                  deleteModal: false,
                                  requestToDelete: null
                                })
                              }
                            >
                              {i18n.t("dashboard.sluiten")}
                            </Button>
                            <Button
                              className="my-4"
                              color="success"
                              type="button"
                              onClick={() =>
                                this.onPressDeleteRequest(
                                  this.state.requestToDelete[0]
                                )
                              }
                            >
                              {i18n.t("dashboard.verwijderen")}
                            </Button>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </div>
                )}
              </Modal>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Verlof;
