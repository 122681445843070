export const NL = {
  cookiebar_text: "DashBee maakt gebruik van cookies voor een goede ervaring",
  cookiebar_meer_lezen: "Meer Lezen",
  contact_bedrijf: "Gideloff BV",
  contact_kvk: "KVK - 81756356",
  contact_straat: "Gooimeer 3B",
  contact_postcode_land: "1411 DC Naarden - Nederland",
  contact_telefoon: "(035) 20 300 44",
  contact_email: "info@gideloff.nl",
  header_titel: "DashBee biedt startups structuur",
  header_subtitel:
    "Door bedrijfsprocessen te digitaliseren en inzichtelijke data-analyses te geven",
  nav_dashboard: "Dashboard",
  nav_prijzen: "Prijzen",
  nav_contact: "Contact",
  nav_login: "Inloggen",
  nav_privacy: "Privacy Beleid",
  nav_voorwaarden: "Algemene Voorwaarden",
  nav_home: "Home",
  bedrijf_aanmelden_btn: "Probeer Gratis",
  contact_btn: "Neem contact op",
  show_pricing: "Bekijk de prijzen",
  urenregistratie: "Urenregistratie",
  urenregistratie_desc:
    "Maak projecten aan en registeer alle uren van jouw bedrijf per project op één plek!",
  gedeelde_agenda: "Gedeelde agenda",
  gedeelde_agenda_desc:
    "Één agenda waarin alle bedrijfsactiviteiten,verlofdagen en grote events zichtbaar zijn voor iedereen.",
  verlof_administratie: "Verlofadministratie",
  verlof_administratie_desc:
    "Voor iedereen in het bedrijf eenvoudig verlof aanvragen en verwerken.",
  admin_panel: "Data analyse",
  admin_panel_desc:
    "Bekijk alle verzamelde data, trek conclusies en herken patronen uit de analyses.",
  homepage_titel:
    "Een dashboard voor administratie, registratie en data-analyse",
  homepage_desc_1:
    "Wist je dat een bijenkorf uit 60.000 bijen kan bestaan? Waarbij iedere 🐝 ook nog eens een eigen taak heeft! Aardig wat administratie zou je denken, niet met DashBee!",
  homepage_desc_2:
    "DashBee digitaliseert dagelijkse taken en brengt structuur in je bedrijf. Alle bedrijfsprocessen worden geregistreerd en beheerd in het dashboard. Door onze focus op gebruiksvriendelijkheid en eenvoudigheid kan jouw bedrijf administratieve taken sneller afhandelen zodat er meer tijd overblijft voor bedrijfsspecifieke taken. Een leuke bijkomstigheid is het feit dat de registratie en administratie binnen DashBee jou inzichten zal geven over je eigen bedrijf in de vorm van data-analyses.",
  homepage_desc_3:
    "Registreer je bedrijf en word zelf de Queen Bee, voeg Worker Bees toe, handel urenregistratie en verlofaanvragen af en ",
  homepage_desc_4: "Become a productive hive!",
  homepage_card1_titel: "TOEGANKELIJKE GEBRUIKERSINTERFACE",
  homepage_card2_titel: "BEDRIJFSWIJDE REGISTRATIE",
  homepage_card3_titel: "SUBLIEME ONDERSTEUNING",
  homepage_card1_desc:
    "DashBee is web-based en daardoor voor iedereen altijd toegankelijk. Het dashboard is gebouwd om voor iedereen gemakkelijk te werken en is per gebruiker in te richten. Wel zo makkelijk.",
  homepage_card2_desc:
    "Als Queen Bee kun jij de totale administratie inzien. Factureer uren en handel verlofaanvragen af met maar één druk op de knop. Activeer alleen de modules die relevant zijn voor jouw bedrijf om het gebruikersgemak optimaal te houden.",
  homepage_card3_desc:
    "DashBee is ontwikkeld door Gideloff, een software ontwikkelingsbedrijf die nauw contact houdt met haar opdrachtgevers. Heb je vragen, opmerkingen of behoefte aan maatwerk binnen DashBee, neem gerust contact op met ons. We horen graag van je!",
  homepage_question_1:
    "Waarom zou ik gegevens willen opslaan van mijn eigen bedrijf?",
  homepage_quote_1:
    "Digitalisering is tegenwoordig overal te zien, bedrijven zetten hier steeds meer op in omdat het veel tijd en geld kan besparen. Daarbij is data de laatste tijd ook steeds belangrijker geworden, voornameliijk data analyseren. Met het analyseren van data kan je namelijk leren van het verleden, conclusies trekken en patronen herkennen. Dit geldt ook voor je eigen bedrijf. DashBee maakt het gemakkelijk om bedrijfsgegevens op te slaan en te analyseren voor geweldige inzichten.",
  homepage_quote_2:
    "DashBee digitaliseert dagelijkse taken en brengt structuur in je bedrijf",
  homepage_feature1_titel: "DashBee modules",
  homepage_feature2_titel: "Uren registreren",
  homepage_feature3_titel: "Verlof aanvragen",
  homepage_feature4_titel: "Gedeelde bedrijfsagenda",
  homepage_feature5_titel: "Facturen genereren",
  homepage_feature6_titel: "Bedrijfsspecifieke modules",
  homepage_feature1_desc:
    "Het dashboard biedt meerdere modules aan die het managen van een bedrijf vereenvoudigd. DashBee is een platform waar alle werknemers en werkgevers eenvoudig zaken kunnen regelen zoals verlofdagen aanvragen en behandelen, uren registreren per persoon en per project.",
  homepage_feature2_desc:
    "Iedereen kan binnen DashBee zijn uren registreren. Vul de datum, aantal uren, het project en eventuele opmerkingen in en klaar is kees. Queen Bees kunnen de uren inzien van het bedrijf in totaal. Handig voor het factureren.",
  homepage_feature3_desc:
    "Met DashBee kunnen verlofaanvragen vanuit één plek behandeld worden. Worker Bees kunnen binnen het dashboard eenvoudig aanvragen doen. De Queen Bee kan vervolgens iedere aanvraag bevestigen of afkeuren.",
  homepage_feature4_desc:
    "Met de gedeelde agenda creëer je een werkomgeving waarin het voor iedereen duidelijk is wat er gepland staat voor de week, wie er die dag afwezig is en iedereen wordt op de hoogte gehouden van de laatste updates. Als Queen Bee kan je evenementen aanmaken om met het hele team te delen. Eén bedrijf, één agenda!",
  homepage_feature5_desc:
    "Waarom zou je tools als Excel gebruiken om je uren te registreren en facturen aan te maken als je met één klik professionele facturen kunt genereren? DashBee maakt het mogelijk om facturen te genereren op basis van datum, project-uren en kosten. Personaliseer je bedrijfsfactuur met je eigen logo en bedrijfsgegevens en bewaar al je facturen op één plek.",
  homepage_feature6_desc:
    "DashBee is een universeel platform en daarom toepasbaar op verschillende type bedrijven en branches. Uiteraard is het ook mogelijk om DashBee uit te breiden met extra modules. Hiervoor kun je contact met ons opnemen.",
  homepage_feature1_bullets: [
    "Urenregistratie",
    "Verlofadministratie",
    "Gedeelde bedrijfsagenda",
    "Kosten administratie",
    "Facturen genereren",
  ],
  homepage_feature2_bullets: [
    "Per Worker Bee",
    "Per Project",
    "Exporteer naar Excel",
    "Exporteer factuur",
  ],
  homepage_feature3_bullets: [
    "Gemakkelijk aanvraag doen",
    "Behandeling door Queen Bee",
    "Verlofsaldo",
    "Gedeelde agenda",
  ],
  homepage_feature4_bullets: [
    "Eén agenda voor alle Bees",
    "Bedrijfsevenementen aanmaken & delen",
    "Alle verlofaanvragen zichtbaar",
    "Project planning",
  ],
  homepage_feature5_bullets: [
    "Klantregistratie",
    "Factuur genereren op basis van uren",
    "Factuur genereren per project",
    "Gepersonaliseerde factuur",
  ],
  homepage_feature6_bullets: [],
  footer_sub_titel: "Biedt ondernemers structuur",
  testimonials_title: "Wat zeggen onze DashBee gebruikers?",
  auth: {
    login_titel: "Welkom",
    login_subtitel: "Let's get to work!",
    email: "E-mail",
    telephone: "Telefoonnummer",
    wachtwoord: "Wachtwoord",
    wachtwoord_vergeten: "Wachtwoord vergeten?",
    onthouden: "Onthouden",
    login_btn: "Inloggen",
    verificatie_opnieuw: "Verificatielink opnieuw sturen",
    registratie: {
      titel1: "Account aanmaken",
      subtitel1:
        "Voordat we je bedrijf registreren, maken we een gebruikersaccount aan",
      titel2: "Je account is aangemaakt!",
      subtitel2: "Laten we nu je bedrijf registreren",
      instructie1: "Vul je account gegevens in",
      instructie2: "Vul je bedrijfsgegevens in",
      naam: "Voor- en achternaam",
      bedrijfsnaam: "Bedrijfsnaam",
      rechtsvorm: "Rechtsvorm / Type",
      eenmanszaak: "Eenmanszaak / Persoonlijk",
      bv: "VOF / BV / NV / CV / Stichting",
      akkoord: "Ik ga akkoord met het ",
      submit_btn1: "Account Aanmaken",
      submit_btn2: "Bedrijf Aanmaken",
      inloggen: "Inloggen",
      privacy: "Privacy Beleid",
    },
    reset: {
      titel: "Wachtwoord herstellen",
      subtitel: "Gebruik het formulier om een nieuw wachtwoord aan te vragen!",
      instructie: "Vul je e-mailadres in",
      submit_btn: "Wachtwoord resetten",
      inloggen: "Inloggen",
      alert: {
        titel: "Gelukt",
        text_btn: "Naar login",
        text: "Check je mailbox voor de reset link",
      },
    },
    uitnodiging: {
      titel: "Welkom!",
      subtitel: "Gebruik het formulier om je te registreren!",
      registreren_bij: "Registreren bij ",
      ongeldig: "Ongeldige link",
      instructie: "Vul je e-mailadres in",
      submit_btn: "Registreren",
      alert: {
        text1: "Er is een verificatiemail gestuurd naar ",
        text2: ". Druk op de link om je e-mailadres te verifiëren.",
        text_btn: "Naar login",
        text: "Check je mailbox voor de reset link",
      },
    },
  },
  prijzen_pagina: {
    pagina_titel: "Kies het juiste plan voor jouw bedrijf",
    tijdelijk_gratis: "(Tijdelijk GRATIS)",
    freebee_titel: "FreeBee - 30 dagen gratis try-out",
    pagina_desc:
      "DashBee kan een maand lang gratis gebruikt worden. Om onze bijenkorf te onderhouden rekenen wij na deze periode een pot honing aan de hand van het aantal gebruikers. ",
    pagina_slogan: "Keep the hive alive!",
    registreer_btn: "Probeer DashBee GRATIS",
    demo_btn: "Demo Dashboard",
    meest_gekozen: "Meest gekozen",
    gratis_dagen: "30 dagen",
    automatisch_verlengd: "Wordt automatisch verlengd op",
    automatisch_aflopen: "Actief tot",
    maand_abonnement: "Maandelijks",
    jaar_abonnement: "Jaarlijks",
    actief: "Actief",
    geannuleerd: "Geannuleerd",
    excluding_tax:
      "* Al onze prijzen zijn exclusief BTW en inclusief persoonlijk support. Voor de 30 dagen FreeBee periode is geen betaling vereist.",
    larva_pack: {
      titel: "Larva Pack",
      naam_small: "L",
      prijs: "5",
      prijs_yearly: "55",
      per_gebruiker: "per maand",
      onder_per_gebruiker: "",
      feature1: "30 dagen FreeBee",
      feature2: "Voor ZZP'ers",
      feature3: "Directe support",
      feature4: "1 Bij",
    },
    hive_pack: {
      titel: "Hive Pack",
      naam_small: "H",
      prijs: "17.50",
      prijs_yearly: "192.50",
      per_gebruiker: "per maand",
      onder_per_gebruiker: "",
      feature1: "30 dagen gratis (FreeBee)",
      feature2: "Voor MKB",
      feature3: "Directe support",
      feature4: "Tot 20 Bijen",
    },
    honey_factory_pack: {
      titel: "Honey Factory",
      naam_small: "F",
      prijs: "50",
      prijs_yearly: "550",
      per_gebruiker: "per maand",
      onder_per_gebruiker: "",
      feature1: "30 dagen gratis (FreeBee)",
      feature2: "Voor grote bedrijven",
      feature3: "Directe support",
      feature4: "21 - 60 Bijen",
    },
    cards: {
      card1_titel: "Maandelijks opzegbaar",
      card1_desc:
        "Bij het afsluiten van een DashBee abonnement kan er gekozen worden voor maandelijks of jaarlijkse betaling. Een maandelijks abonnement kan iedere maand gestopt worden. Bij een jaarlijks abonnement wordt er vooruit betaald voor het jaar. Als er voortijdig wordt geannuleerd houdt het bedrijf toegang tot de einddatum van het contract. Daarna wordt de toegang tot DashBee verbroken.",
      card1_badge1: "maandelijks",
      card1_badge2: "automatisch",
      card1_badge3: "jaarlijks",
      card2_titel: "Beveiligde data",
      card2_desc:
        "De data die wordt ingevoerd en/of ingeladen in DashBee wordt op een veilig manier opgeslagen. DashBee houdt rekening met de privacy van de gebruikers en de gevoeligheid van bedrijfsgegevens. Gevoelige informatie wordt versleuteld opgeslagen. Alle data wordt in Noord-West Europa opgeslagen. Met DashBee is het ook mogelijk om al je data te exporteren naar CSV of Excel voor externe applicaties.",
      card2_badge1: "encryptie",
      card2_badge2: "Europa",
      card2_badge3: "privacy",
      card3_titel: "Sublieme support",
      card3_desc:
        "Wij doen ons best om DashBee zo gebruiksvriendelijk mogelijk te maken. Toch kan het gebeuren dat iemand een bepaalde functie niet begrijpt of bepaalde informatie niet kan vinden. Hierbij helpen we graag mee en geven met plezier uitleg over de mogelijkheden van DashBee. Ook als iets niet naar behoren werkt in DashBee horen we het graag! Wij zijn een team van developers en zijn 24/7 beschikbaar voor support!",
      card3_badge1: "24/7",
      card3_badge2: "face-to-face",
      card3_badge3: "telefonisch",
    },
    tabel: {
      functies: "Functies",
      larva: "Larva Pack",
      hive: "Hive Pack",
      factory: "Honey Factory",
      features: [
        { titel: "Urenregistratie", larva: true, hive: true, factory: true },
        { titel: "Bedrijfskalender", larva: true, hive: true, factory: true },
        { titel: "Projecten", larva: true, hive: true, factory: true },
        { titel: "Klanten", larva: true, hive: true, factory: true },
        { titel: "Bedrijfskosten", larva: true, hive: true, factory: true },
        { titel: "Facturen genereren", larva: true, hive: true, factory: true },
        {
          titel: "Maandelijks nieuwe features",
          larva: true,
          hive: true,
          factory: true,
        },
        { titel: "Verlofregistratie", larva: false, hive: true, factory: true },
        { titel: "Team", larva: false, hive: true, factory: true },
      ],
    },
  },
  contact_pagina: {
    contact_pagina: "contactpagina",
    pagina_titel: "We nemen graag contact met je op!",
    contact_desc_1:
      "Customers are like teeth. If you don't take care of them they will go away one by one until there are none left...",
    contact_desc_2:
      "Wherever you go, whatever you do, DashBee will 🐝 there, supporting you.",
    contact_form_titel: "Vrijblijvend contact opnemen",
    contact_form_desc:
      "Heb je vragen over DashBee of wil je meer informatie ontvangen? Vul het onderstaande formulier in en je hoort zo snel mogelijk van ons!",
    copy: "Kopieër",
    contact_form_label_1: "Hoe kunnen we je bereiken?",
    contact_form_label_2: "Waar wil je meer over weten?",
    contact_form_label_3: "Vertel ons meer over je vragen / opmerkingen",
    contact_form_placeholder_3:
      "Hoe kan ik teamleden toevoegen aan mij project? Ik ben de hele dag bereikbaar op WhatsApp. Groetjes, Tim",
    contact_form_onderwerpen_1: "Demo / Introductie",
    contact_form_onderwerpen_2: "Maatwerk",
    contact_form_onderwerpen_3: "Prijzen",
    contact_form_onderwerpen_4: "Support",
    contact_form_onderwerpen_5: "Overige vragen",
    contact_form_foutmelding_1: "Vul je e-mailadres in",
    contact_form_foutmelding_2: "Vul een vraag of opmerking in",
    verstuur_btn: "Neem contact met mij op",
    bericht_ontvangen:
      "Je bericht is naar de DashBee Bijenkorf verstuurd. Één van onze Bijtjes neemt snel contact met je op! We komen zo Bij je ;-).",
    support: {
      support_titel: "Hoe kunnen wij je helpen?",
      support_desc:
        "Leg hier al je vragen neer, wij nemen binnen 24 uur contact met je op. Voor dringende vragen kan je direct telefonisch contact opnemen met ons. We helpen je graag!",
      support_message:
        "Hi, ik ben Leendert en help je graag verder! Snel antwoord nodig op je vragen? Stuur me even een WhatsApp berichtje of bel me op",
      supporter_name: "Leendert Eloff",
      supporter_location: "Amsterdam, Nederland",
      supporter_quote: "Your favorite Helper Bee",
      supporter_phone: {
        number: "0644541305",
        href: "tel:0644541305",
        whatsapp: "https://wa.me/+31644541305",
      },
      support_topic_titel: "Waar zijn we mee bezig?",
      support_topic_1: "Inzicht geven in uren per week / gebruiker",
      support_topic_2: "Toegangsbeheer per gebruiker voor modules",
      support_faq_link: "Bekijk ook onze Veel Gestelde Vragen sectie op de ",
    },
  },
  privacy_beleid: {
    tldr: {
      titel: "TLDR",
      text: "DashBee verwerkt minimale persoongegevens. Bij het aanmaken van een account worden er gegevens opgeslagen met betrekking tot je profiel. Hieronder valt je naam, e-mailadres en een optionele foto. Alle data van DashBee wordt versleuteld opgeslagen en over een beveiligde verbinding verstuurd. Daarnaast maken we gebruik van Google Analytics om websiteverkeer te meten. Hierbij wordt jouw ip-adres opgeslagen om te kunnen achterhalen waar websitebezoekers zich bevinden. Dit is enkel en alleen voor analytische doeleinden. DashBee maakt gebruik van cookies. Enerzijds om ervoor te zorgen dat jouw gegevens snel weergegeven kunnen worden in het dashboard en anderzijds om websiteverkeer te kunnen meten.",
    },
    privacy_verklaring: {
      titel: "Privacyverklaring",
      text: "DashBee verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt. Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:",
      bullets: [
        "Voor- en achternaam, e-mailadres, profielfoto (alleen als je een account hebt)",
        "Gegevens over jouw activiteiten op onze website",
        "Betaalgegevens (als je een abonnement aanschaft)",
      ],
    },
    verzamelde_gegevens: {
      titel: "Bijzondere en/of gevoelige persoonsgegevens die wij verwerken",
      text: "Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als je er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via info@gideloff.nl, dan verwijderen wij deze informatie.",
    },
    doel: {
      titel:
        "Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken",
      text: "DashBee verwerkt jouw persoonsgegevens voor de volgende doelen: Om websiteverkeer bij te houden en om het dashboard naar behoren te laten werken.",
    },
    besluitvorming: {
      titel: "Geautomatiseerde besluitvorming",
      text: "DashBee neemt geen besluiten op basis van geautomatiseerde verwerkingen over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen door computerprogramma's of -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van DashBee) tussen zit.",
    },
    tijd: {
      titel: "Hoe lang we persoonsgegevens bewaren",
      text: "DashBee bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van persoonsgegevens:",
      bullets: [
        "Gegevens van je DashBee account na het verwijderen van je account: 12 maanden",
        "Gegevens over jouw activiteiten op onze website: 26 maanden",
        "Factuur- en betaalgegevens - 7 jaar",
      ],
    },
    delen: {
      titel: "Delen van persoonsgegevens met derden",
      text: "DashBee verstrekt uitsluitend aan derden en alleen als dit nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen aan een wettelijke verplichting.",
    },
    cookies: {
      titel: "Cookies, of vergelijkbare technieken, die wij gebruiken",
      text: "DashBee gebruikt functionele, analytische en tracking cookies. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen in de browser van je computer, tablet of smartphone. DashBee gebruikt cookies met een puur technische functionaliteit. Deze zorgen ervoor dat de website naar behoren werkt en dat bijvoorbeeld jouw voorkeursinstellingen onthouden worden. Deze cookies worden ook gebruikt om de website goed te laten werken en deze te kunnen optimaliseren. Daarnaast plaatsen we cookies die jouw surfgedrag op deze website bijhouden. Dit is strict voor analytische doeleinden. Bij jouw eerste bezoek aan onze website hebben wij je al geïnformeerd over deze cookies en hebben we je toestemming gevraagd voor het plaatsen ervan. Je kunt je afmelden voor cookies door je internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kun je ook alle informatie die eerder is opgeslagen via de instellingen van je browser verwijderen.",
    },
    inzien: {
      titel: "Gegevens inzien, aanpassen of verwijderen",
      text: "Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een ander, door jou genoemde organisatie, te sturen. Wil je gebruik maken van je recht op bezwaar en/of recht op gegevensoverdraagbaarheid of heb je andere vragen/opmerkingen over de gegevensverwerking, stuur dan een gespecificeerd verzoek naar info@gideloff.nl. Gideloff zal zo snel mogelijk, maar in ieder geval binnen vier weken, op jouw verzoek reageren.",
    },
    beveiligen: {
      titel: "Hoe wij persoonsgegevens beveiligen",
      text: "DashBee neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er zijn aanwijzingen van misbruik, neem dan contact op met onze klantenservice of via info@gideloff.nl. DashBee heeft de volgende maatregelen genomen om jouw persoonsgegevens te beveiligen: TLS (voorheen SSL) Wij versturen jouw gegevens via een beveiligde internetverbinding. Dit kun je zien aan de adresbalk 'https' en het hangslotje in de adresbalk. Wij slaan alle gegevens met betrekking tot jouw DashBee account en de gegevens die hierbij horen versleuteld op in onze database.",
    },
  },
  dashboard: {
    ja: "Ja",
    nee: "Nee",
    bewerken: "Bewerken",
    verwijderen: "Verwijderen",
    opslaan: "Opslaan",
    sluiten: "Sluiten",
    toevoegen: "Toevoegen",
    per_project: "Per Project",
    totaal: "Totaal",
    bedrijf: "Bedrijf",
    vul_formulier_in: "Vul het formulier in",
    excel_export: "Exporteren naar Excel",
    geen: "Geen",
    taal_kiezen: "Taal kiezen",
    all_projects: "Alle projecten",
    all_users: "Alle gebruikers",
    navbar: {
      meldingen1: "Je hebt ",
      meldingen2: " meldingen",
      welkom: "Welkom!",
      profiel: "Mijn profiel",
      bedrijf: "Mijn bedrijf",
      abonnement: "Abonnement",
      instellingen: "Instellingen",
      uitloggen: "Uitloggen",
      urenregistratie: "Urenregistratie",
      verlof: "Verlof",
      kalender: "Kalender",
      admin: {
        aanvragen: "Verlofaanvragen",
        uren: "Uren",
        uren_overzicht: "Overzicht",
        uren_registratie: "Registratie",
        kosten: "Kosten",
        projecten: "Projecten",
        team: "Team",
        facturen: "Facturen",
        klanten: "Klanten",
      },
    },
    profiel: {
      groet: "Hey ",
      desc: "Dit is je profielpagina. Hier kun je je gegevens bekijken en aanpassen.",
      bewerken: "Profiel bewerken",
      gebruikers_info: "Gebruikers Info",
      rol_info: "Je rol binnen ",
      naam: "Naam",
      email: "E-mailadres",
      profielfoto: { label: "Profielfoto", gekozen: "Profielfoto gekozen" },
      functie: "Functie",
      dashbee_rol: "DashBee Rol",
    },
    uren: {
      uren: "Uren",
      titel: "Urenregistratie",
      totaal: "Uren",
      recent: "Recente uren ",
      omzet_uren: "Omzet uit uren",
      gewerkt: "Gewerkte uren ",
      toevoegen: "Uren toevoegen",
      aanpassen: "Uren aanpassen",
      per_maand: "Per maand",
      rijen1: "Laat ",
      rijen2: " rijen zien",
      deze_week: "Deze week",
      alleenFactureerbaar: "Alleen factureerbaar",
      datum: {
        label: "Datum",
        placeholder: "Kies een datum",
      },
      aantal: {
        label: "Aantal uren",
        placeholder: "Kies aantal uren",
      },
      factureerbaar: {
        label: "Zijn de uren factureerbaar?",
      },
      project: {
        label: "Project",
        placeholder: "Kies project",
      },
      opmerkingen: {
        label: "Opmerkingen",
        placeholder: "Optioneel",
      },
      kosten: {
        label: "Kosten (€)",
        placeholder: "Vul kosten in",
      },
      kosten_type: {
        label: "Kosten type",
        placeholder: "Kies kosten type",
      },
      gebruiker: {
        label: "Gebruiker",
        placeholder: "Wie heeft de uren gemaakt?",
        tooltip: "Alleen Queen Bees kunnen de gebruiker kiezen",
      },
      tabel: {
        subtitel: "Je meest recente uren dit jaar",
        subtitel_bedrijf: "Recent gemaakte uren",
        zoeken: "Zoeken:",
        zoeken_placeholder: "Vul zoekterm in",
        datum: "Datum",
        uren: "Uren",
        project: "Project",
        opmerkingen: "Opmerkingen",
        acties: "Acties",
        wie: "Wie",
        omzet: "Omzet",
        factureerbaar: "Factureerbaar",
      },
      uurtarief: "Uurtarief: €",
      uurbudget: " uur over",
      geen_uurtarief: "Geen uurtarief ingesteld",
      geen_uurbudget: "Geen urenbudget ingesteld",
      gewerkt_budget: "Gewerkt/Budget",
      omzet_budget: "Omzet/Budget",
    },
    verlof: {
      titel: "Verlof",
      saldo: "Verlofsaldo",
      verbruikt: "Verlof verbruikt",
      recent: "Recente aanvragen",
      nieuw_btn: "Nieuwe Aanvraag",
      submit_btn: "Aanvragen",
      dag_eenvoud: "Dag",
      dag_meervoud: "Dagen",
      geaccepteerd: "Geaccepteerd",
      afgekeurd: "Afgekeurd",
      aangevraagd: "Aangevraagd",
      aanpassen: "Aanvraag bewerken",
      verwijderen: "Aanvraag verwijderen",
      verwijderen_uitleg:
        "Het verlof wordt ook uit de kalender gehaald. Weet je het zeker?",
      aanvragen: "Verlof aanvragen",
      bewerken: "Aanvraag bewerken",
      hele_dag: "Hele dag",
      van_tot: "Van - Tot",
      periode: "Periode",
      welke_dag: "Welke dag",
      van: "Van",
      tot: "Tot",
      tot_met: "Tot en met",
      type: "Type verlof",
      prive: "Privé",
      zakelijk: "Zakelijk",
      zakelijk_uitleg: "Zakelijk verlof gaat niet van het saldo af",
      opmerking: "Opmerking",
      onbehandeld: "Onbehandelde aanvragen",
      behandeld: "Behandelde aanvragen",
      accepteren: "Accepteren",
      weigeren: "Weigeren",
      aanvraag_van: "Aanvraag van",
      tabel: {
        datum: "Datum",
        van: "Van",
        tot: "Tot",
        type: "Verlof Type",
        opmerkingen: "Opmerkingen",
        status: "Status",
        behandelaar: "Behandelaar",
        wie: "Wie",
      },
    },
    kalender: {
      titel: "Kalender",
      maand: "Maand",
      week: "Week",
      dag: "Dag",
      vrij: " vrij",
      vrij_dag: " vrij (hele dag)",
      evenement: {
        form_titel: "Nieuw kalender item",
        form_subtitel: "Vul het formulier in",
        titel: { label: "Titel", placeholder: "Bedrijfsuitje" },
        beschrijving: {
          label: "Beschrijving",
          placeholder: "We gaan wat leuks doen",
        },
        hele_dag_vrij: " is de hele dag vrij",
        dag_periode_vrij: " is vrij van ",
        tot_en_met: " tot en met ",
      },
    },
    kosten: {
      kosten: "Kosten",
      totaal: "Totaal kosten ",
      gemaakt: "Gemaakte kosten ",
      toevoegen: "Kosten toevoegen",
      aanpassen: "Kosten aanpassen",
      recent: "Recente kosten ",
      recent2: "Meeste recente kosten dit jaar",
      administratie: "Administratie",
      inkoop: "Inkoop",
      marketing: "Marketing",
      service: "Service",
      transport: "Transport",
      overig: "Overig",
      tabel: {
        datum: "Datum",
        kosten: "Kosten (€)",
        project: "Project",
        type: "Kosten type",
        opmerkingen: "Opmerkingen",
        acties: "Acties",
        wie: "Wie",
      },
    },
    projecten: {
      projecten: "Projecten",
      nieuw: "Nieuw Project",
      verwijderd: "Verwijderde projecten",
      verwijderd_desc:
        "Als je een project terugzet, kunnen gebruikers deze weer zien en hier uren aan toevoegen.",
      aanmaken: "Project aanmaken",
      bewerken: "Project bewerken",
      terugzetten: "Terugzetten",
      verwijderen: "Project verwijderen",
      verwijderen_desc:
        "Uren van dit project blijven bestaan, maar hangen niet meer aan een project vast.",
      geen_verw_projecten: "Geen verwijderde projecten",
      eerste_aanmaken: "Maak hier je eerste project aan",
      naam: "Naam project",
      alleenActief: "Alleen lopende projecten",
      tabel: {
        uurtarief: { label: "Uurtarief", placeholder: "Uurtarief (optioneel)" },
        uurbudget: {
          label: "Uren Budget",
          placeholder: "Budget in uren (optioneel)",
        },
        logo: {
          label: "Logo",
          placeholder: "Logo (optioneel)",
          gekozen: "Logo gekozen",
        },
        status: { label: "Status", bezig: "Bezig", afgesloten: "Afgesloten" },
        project: "Project",
        acties: "Acties",
      },
    },
    klanten: {
      klanten: "Klanten",
      nieuw: "Nieuwe Klant",
      aanmaken: "Klant aanmaken",
      bewerken: "Klant bewerken",
      verwijderen: "Klant verwijderen",
      eerste_aanmaken: "Maak hier je eerste klant aan",
      naam: "Naam klant",
      nummer: "Klantnummer",
      straat: "Straat",
      huisnummer: "Huisnummer",
      postcode: "Postcode",
      stad: "Stad",
      email: "E-mailadres",
      contact_persoon: "Naam contactpersoon",
      tabel: {
        logo: {
          label: "Logo",
          placeholder: "Logo (optioneel)",
          gekozen: "Logo gekozen",
        },
        klant: "Klant",
        contact: "Contactpersoon",
        acties: "Acties",
      },
    },
    facturen: {
      factuur: "Factuur",
      facturen: "Facturen",
      document_laden: "Document laden...",
      downloaden: "Factuur downloaden",
      opslaan: "Factuur opslaan",
      genereren: "Factuur genereren",
      voorbeeld: "Factuur voorbeeld",
      mijn_facturen: "Mijn facturen",
      facturen_leeg: "Je hebt nog geen facturen gemaakt",
      verwijderen: "Factuur verwijderen",
      download_btn: "Download",
      bedrijfsnaam: "Bedrijfsnaam",
      bic: "BIC",
      btw_nr: "BTW-nummer",
      stad: "Stad",
      email: "E-mailadres voor op factuur",
      huisnr: "Huisnummer",
      iban: "Rekeningnummer",
      kvk_nr: "KvK nummer",
      telefoonnr: "Telefoonnummer",
      straat: "Straat",
      bedrijfstype: "Bedrijfstype",
      postcode: "Postcode",
      klant_stad: "Stad klant",
      klant_email: "E-mailadres klant",
      klant_straat: "Straat klant",
      klant_huisnr: "Huisnummer klant",
      klant_postcode: "Postcode klant",
      factuur_tav: "t.a.v ",
      factuur_nummer: "Factuurnummer: ",
      factuur_datum: "Factuurdatum: ",
      factuur_adres: "Adres: ",
      factuur_kvk: "KvK-nr: ",
      factuur_btw: "BTW-nr: ",
      factuur_iban: "IBAN: ",
      factuur_bic: "BIC: ",
      factuur_tel: "Tel: ",
      factuur_email: "E-mail: ",
      factuur_uren: "Uren",
      factuur_kosten: "Kosten",
      factuur_periode: "Periode",
      factuur_prijs: "Prijs",
      factuur_totaal_btw: "Totaal BTW 21%",
      factuur_betalen: "Te betalen",
      tabel: {
        klant: {
          label: "Klant",
          placeholder: "Kies klant",
        },
        van: {
          label: "Periode van",
          placeholder: "Begindatum",
        },
        tot: {
          label: "Periode tot",
          placeholder: "Einddatum",
        },
        kosten: {
          label: "Kosten meenemen",
          ja: "Ja",
          nee: "Nee",
        },
      },
    },
    mijn_bedrijf: {
      pagina_titel:
        "Dit is je bedrijfspagina. Hier kun je je gegevens bekijken en aanpassen.",
      adres: "Adres",
      straat: "Straat",
      huisnummer: "Huisnummer",
      postcode: "Postcode",
      stad: "Stad",
      contact: "Contact",
      telefoonnr: "Telefoonnummer",
      email: "E-mailadres",
      kvk: "KvK",
      kvk_nr: "KvK nummer",
      btw_nr: "BTW-nummer",
      bank: "Bank",
      iban: "IBAN",
      bic: "BIC",
      logo: "Bedrijfslogo",
      logo_gekozen: "Bedrijfslogo gekozen",
      logo_kies: "Kies bedrijfslogo",
    },
    instellingen: {
      instellingen_titel: "Instellingen",
      instellingen_subtitel:
        "Dit is de instellingen pagina. Hier kun je je persoonlijke instellingen bekijken en aanpassen.",
      instellingen_subtitel_admin:
        "Dit is de instellingen pagina. Hier kun je je persoonlijke en bedrijfs instellingen bekijken en aanpassen.",
      instellingen_beheren: "Persoonlijke instellingen beheren",
      modules_beheren: "Bedrijfsmodules beheren",
      notificaties: {
        algemeen: "notificaties",
        verlofaanvragen: "Mails ontvangen m.b.t verlof",
        promotie_mails: "Promotie mails ontvangen",
      },
      toegang: {
        algemeen: "toegang",
        beheren_verlofaanvragen: "Verlofaanvragen",
        genereren_factuur: "Facturatie",
        aanmaken_klant: "Klanten",
        overzicht_uren: "Urenregistratie",
        overzicht_kosten: "Kosten",
        overzicht_projecten: "Projecten",
        overzicht_team: "Team",
        overzicht_kalender: "Bedrijfskalender",
      },
      meldingen: {
        aan: "zijn nu aangezet",
        uit: "zijn nu uitgezet",
        verlofaanvragen: "Verlofaanvragen mail notificaties",
        promotie_mails: "Promotie mails notificaties",
        module: "module",
      },
    },
    abonnement: {
      abonnement_titel: "Abonnement",
      abonnement_afkorting: "A",
      abonnement_subtitel:
        "Op deze pagina kun je jouw bedrijfs-abonnement aanschaffen en beheren.",
      abonnement_kiezen_btn: "Selecteren",
      abonnement_opzeggen_btn: "Opzeggen",
      abonnement_upgraden_btn: "Upgraden",
      abonnement_downgraden_btn: "Downgraden",
      abonnement_hernieuwen_btn: "Hernieuwen",
      abonnement_info: "Ieder abonnement is maandelijks opzegbaar",
      maandelijks: "Maandelijks",
      jaarlijks: "Jaarlijks",
      korting_voor_altijd: "{{korting}}% korting, geldig voor altijd.",
      korting_voor_maand: "{{korting}}% korting, geldig voor {{maand}} maand.",
      korting_voor_maanden:
        "{{korting}}% korting, geldig voor {{maand}} maanden.",
      prijs_tooltip_gebruikers:
        "Dit abonnement past niet bij het aantal gebruikers van je bedrijf",
      betaling_geslaagd_titel: "Betaling Geslaagd",
      betaling_geslaagd_desc:
        "We hebben je betaling ontvangen. Je abonnement is geüpdatet!",
      betaling_mislukt_titel: "Betaling Mislukt",
      betaling_mislukt_desc:
        "Je betaling is niet correct verwerkt. Neem alsjeblieft contact met ons op.",
      opzeggen_geslaagd_titel: "Abonnement Stopgezet",
      opzeggen_geslaagd_desc:
        "Je abonnement is stopgezet. Voel je vrij om op ieder moment terug te komen!",
      opzeggen_mislukt_titel: "Opzeggen Mislukt",
      opzeggen_mislukt_desc:
        "Het opzeggen van je abonnement is mislukt. Neem alsjeblieft contact met ons op.",
      hernieuwen_geslaagd_titel: "Abonnement is weer actief",
      hernieuwen_geslaagd_desc:
        "We hebben je abonnement weer gestart. Deze wordt automatisch verlengd.",
      hernieuwen_mislukt_titel: "Hernieuwen Mislukt",
      hernieuwen_mislukt_desc:
        "Het hernieuwen van je abonnement is niet gelukt. Neem alsjeblieft contact met ons op.",
      opzeggen_bevestigings_vraag_titel: "Abonnement Stopzetten?",
      opzeggen_bevestigings_vraag_descr:
        "Je zal toegang verliezen tot de functionaliteiten van DashBee wanneer je abonnement afloopt. Weet je het zeker?",
      hernieuwen_bevestigings_vraag_titel: "Abonnement Hernieuwen?",
      hernieuwen_bevestigings_vraag_descr:
        "Je huidige abonnement zal weer automatisch verlengd worden.",
      upgraden_bevestigings_vraag_titel: "Abonnement Upgraden?",
      upgraden_bevestigings_vraag_descr: "Je abonnement zal geüpgraded worden.",
      downgraden_bevestigings_vraag_titel: "Abonnement Downgraden?",
      downgraden_bevestigings_vraag_descr:
        "Je abonnement zal gedowngraded worden.",
      lock_scherm_titel: "Gratis Periode Afgelopen",
      lock_scherm_desc:
        "Helaas, je FreeBee periode is afgelopen. Klik op de knop om een abonnement aan te schaffen.",
      lock_scherm_desc_user:
        "Helaas, de FreeBee periode is afgelopen. Vraag aan de Queen Bee om een abonnement aan te schaffen.",
      lock_scherm_button: "Abonnement aanschaffen",
      ideal_betaling_header_titel: "Betaling",
      ideal_betaling_titel: "iDEAL Betaling",
      ideal_betaling_subtitel: "Kies je bank gegevens",
      ideal_betaling_term_and_conditions:
        "*Door je betalingsgegevens te verstrekken en deze betaling te bevestigen, machtig je Gideloff en Stripe, onze betalingsdienstaanbieder, om instructies naar je bank te sturen om je rekening te debiteren in overeenstemming met die instructies. Als onderdeel van je rechten heb je recht op terugbetaling door je bank volgens de voorwaarden van je overeenkomst met je bank. Een terugbetaling moet worden aangevraagd binnen 8 weken vanaf de datum waarop je rekening is gedebiteerd. Je rechten worden toegelicht in een verklaring die je bij je bank kunt opvragen. Je stemt ermee in om meldingen te ontvangen voor toekomstige afschrijvingen tot 2 dagen voordat ze plaatsvinden.",
      ideal_betaling_informatie:
        "Om de rekening te verifiëren vragen wij om via iDEAL €0,01 te betalen. Zodra de rekening geverifieerd is, zal de €0,01 terug worden gestort en worden de kosten van het gekozen abonnement automatisch in rekening gebracht.",
      ideal_betaling_button: "Betaling Bevestigen",
      kortingscode_invullen_titel: "Kortingscode invullen",
      kortingscode_invullen_inputField: "Kortingscode",
      kortingscode_invullen_button: "Verzilveren",
      kortingscode_invalide: "Kortingscode bestaat niet",
      kortingscode_niet_toegestaan:
        "Kortingscode niet toegestaan bij een jaarlijks abonnement.",
      freebee_dagen_resterend: "Dagen resterend",
      freebee_verlopen: "Abonnement verlopen",
      freebee_tot: "Verloopt op",
      freebee_naam: "FreeBee",
      freebee_naam_small: "F",
      onbeperkt: "Onbeperkt",
      bank_empty: "Kies een bank",
      dagen_resterend_uitleg:
        "Heb je een actief abonnement? Dan wordt deze verlengd over het getoonde aantal dagen. Als je je abonnement hebt opgezegd of in de proefperiode zit, loopt je toegang tot DashBee af over het getoonde aantal dagen.",
      larva_subscription_month: "Larva Pack Maandelijks Abonnement",
      larva_subscription_year: "Larva Pack Jaarlijks Abonnement",
      hive_subscription_month: "Hive Pack Maandelijks Abonnement",
      hive_subscription_year: "Hive Pack Jaarlijks Abonnement",
      factory_subscription_month: "Factory Pack Maandelijks Abonnement",
      factory_subscription_year: "Factory Pack Jaarlijks Abonnement",
    },
    team: {
      team: "Team",
      team_dagen: "Team - Verlofdagen",
      team_dagen_uitleg:
        "Het standaard aantal verlofdagen voor teamleden. Dit kan per gebruiker aangepast worden",
      team_saldo: "Team - Verlofsaldo",
      team_saldo_uitleg: "Het totale verlofsaldo van het team",
      team_gebruikt: "Team - Verlof Gebruikt",
      team_gebruikt_uitleg: "Totaal aantal gebruikte verlofdagen van het team",
      nieuw: "Gebruiker toevoegen",
      nieuw_info: "De gebruiker krijgt een uitnodiging per mail",
      bewerken: "Gebruiker bewerken",
      verwijderen: "Gebruiker verwijderen",
      rol_aanpassen: "Gebruikersrol aanpassen",
      uitnodiging_verstuurd: "heeft een uitnodiging per mail ontvangen",
      link_kopieren: "Uitnodigingslink kopiëren",
      link_copied1: "Link voor ",
      link_copied2: " gekopieerd",
      email: { label: "E-mail", placeholder: "E-mailadres" },
      rol: { label: "Rol", placeholder: "Gebruikersrol" },
      project_toegang: { label: "Toegang Projecten", placeholder: "Projecten" },
      verlof_dagen: {
        label: "Verlofdagen",
        placeholder: "Aantal",
        uitleg:
          "Heeft de gebruiker afwijkende verlofdagen ten opzichte van het team? Geef dat hier aan.",
      },
      gebruikers: "Gebruikers",
      uitgenodigd: "Uitgenodigd",
      actief: "Actief",
      verlofdagen: "Verlofdagen",
      verlof_aanpassen: "Team verlofdagen aanpassen",
      verlof_per_jaar: "Verlofdagen per jaar",
      tabel: {
        gebruiker: "Gebruiker",
        email: "E-mail",
        rol: "Rol",
        functie: "Functie",
        status: "Status",
        verlofdagen: "Verlofdagen",
        verlofsaldo: "Verlofsaldo",
        verlof_gebruikt: "Verlof gebruikt",
      },
    },
    notificaties: {
      aanvraag: "Verlofaanvraag",
      nieuw_aanvraag_titel: "Nieuwe verlofaanvraag",
      nieuw_aanvraag_desc: "Klik hier om de aanvragen in te zien",
      aanvraag_geaccepteerd: "Je verlofaanvraag is geaccepteerd",
      aanvraag_geweigerd: "Je verlofaanvraag is geweigerd",
    },
    tour: {
      stappen: {
        volgende: "Volgende",
        terug: "Terug",
        skip: "Overslaan",
        afronden: "Einde",
      },
      urenregistratie: {
        body: "Welkom op de Urenregistratie-pagina! Hier kan je alle gemaakte uren registreren en inzien. Enjoy!",
        formulier:
          "Vul hier je uren per datum en per project in. Opmerking toevoegen is optioneel.",
        tabel:
          "Bekijk hier alle recent gemaakte uren. Je kan hier ook je ingevulde uren aanpassen of verwijderen, hiervoor klik je op het icoon onder ACTIES.",
        grafiek_toggle:
          "Bekijk hier de gemaakte uren per maand en per project.",
      },
      verlof: {
        body: "Welkom op de Verlof-pagina! Bekijk hier je verlofsaldo en vraag verlof aan voor al je vrije dagen, privé en zakelijk.",
        header_card_1:
          "Hier kan je altijd je huidige verlofsaldo zien. Aangevraagde verlofdagen die nog niet geaccepteerd zijn, zijn hier nog niet zichtbaar.",
        header_card_2: "Hier staat het verbruikte verlof van dit jaar.",
        button:
          "Klik hier om een verlofaanvraag in te dienen. De Queen Bee ontvangt een melding om je verzoek in behandeling te nemen.",
        tabel:
          "Bekijk hier al je aanvragen, inclusief de aanvraagstatus en wie je aanvraag heeft behandeld.",
      },
      kalender: {
        body: "Welkom in de gedeelde bedrijfsagenda! Hier staan alle verlofdagen, bedrijfsactiviteiten en afspraken in. Queen Bees kunnen hier evenementen aanmaken, aanpassen en verwijderen. ",
      },
      verlofaanvraag: {
        body: "Welkom op de Verlofaanvragen-pagina! Als Queen Bee kan je hier alle verlofaanvragen inzien en behandelen.",
        onbehandeld: "Alle onbehandelde aanvragen worden hier vermeld.",
        behandeld: "Alle behandelde aanvragen worden hier vermeld.",
        export: "Exporteer alles naar Excel.",
      },
      uren: {
        body: "Welkom op de Uren-pagina! Bekijk hier alle gemaakte uren van jouw bedrijf. Bekijk ook de omzet gebaseerd op de gemaakte uren.",
        header_card_1: "Alle geregistreerde uren van het lopende jaar.",
        header_card_2: "Zie hier de totale omzet van de gewerkte uren.",
        grafiek: "Bekijk de gemaakte uren per project",
        tabel: "Bekijk hier de gemaakte uren van alle medewerkers",
        export: "Exporteer alles naar Excel.",
      },
      kosten: {
        body: "Welkom op de Kosten-pagina. Vul hier alle bedrijfskosten in om zo een totaal beeld te krijgen van de omzet en winst.",
        formulier:
          "Vul hier de gemaakte kosten in per project en geef ook de type kosten mee voor betere inzichten.",
      },
      facturen: {
        body: "Welkom op de facturen-pagina! Maak hier facturen aan en genereer ze als PDF. Alle facturen worden hier opgeslagen en kunnen ook gedownload worden.",
        klant:
          "Om een factuur te kunnen genereren moet je eerst een klant en project aanmaken.",
        datum:
          "Kies een begin- en een einddatum. De uren van het geselecteerde project worden automatisch opgehaald.",
        bedrijfsgegevens:
          "Facturen genereren kan pas wanneer de eigen bedrijfsgegevens zijn ingevuld. Dit kan onder het kopje 'Mijn bedrijf' in de instellingen.",
      },
      klanten: {
        body: "Welkom op de Klanten-pagina! Maak hier al je klanten aan voor de administratie en facturatie.",
        button: "Klik hier om een nieuwe klant aan te maken.",
        tabel:
          "Hier kun je bestaande klanten inzien, aanpassen en verwijderen.",
      },
      projecten: {
        body: "Welkom op de Projecten-pagina. Maak hier projecten aan. De projecten worden zichtbaar bij registratie en facturatie.",
        button: "Klik hier om een nieuw project aan te maken.",
        edit: "Klik hier om een bestaand project aan te passen.",
        verwijderd:
          "Klik hier om alle verwijderde projecten terug te zien en weer te activeren als je een foutje hebt gemaakt",
      },
      team: {
        body: "Welkom op de Teams-pagina! Teamleden kunnen hier worden toegevoegd. Ook wordt hier getoond per gebruiker: Totale verlofdagen, huidige saldo en gebruikte verlofdagen.",
        header_card_1:
          "Dit is het standaard aantal verlofdagen voor teamleden.",
        header_icon:
          "Klik hier om het standaard aantal verlofdagen aan te passen.",
        header_card_2: "Dit is het totale verlofsaldo van het gehele team.",
        header_card_3: "Dit zijn de gebruikte verlofdagen van het gehele team.",
        button:
          "Klik hier om nieuwe teamleden toe te voegen! Toevoegen van een teamlid genereert een link, de link kan vervolgens gedeeld worden met het nieuwe teamlid.",
        edit: "Klik hier om bestaande teamleden aan te passen of verwijderen.",
        tabel:
          "Hier staan alle teamleden. Bekijk de status van elk teamlid en zijn of haar verlofsaldo.",
      },
      header: {
        settings: "Klik hier voor profiel- en bedrijfsinstellingen.",
        taal: "Klik hier om de taal aan te passen.",
        notificatie:
          "Hier komen alle notifi9caties en algemene meldingen binnen.",
      },
    },
  },
  faq: {
    general: {
      title: "Accordion",
      sub_title: "Algemene vragen over DashBee",
      more_info: "Meer informatie",
      questions: [
        {
          title: "Voor wie is DashBee bedoeld?",
          description:
            "DashBee is bedoelt voor bedrijven / zelfstandigen die opzoek zijn naar meer structuur, inzichten en controle. DashBee maakt het eenvoudig voor iedereen in het bedrijf om alles te registreren en administreren, daarbij maakt DashBee het mogelijk om bedrijfsdata te analyseren. Vragen als: hoeveel uur is er aan een specifiek project gewerkt, is dit project winstgevend, hoeveel mensen zijn er vandaag beschikbaar en kloppen mijn begrotingen wel, kunnen met DashBee eenvoudig beantwoord worden.",
        },
        {
          title: "Wat zijn de voordelen van DashBee?",
          description:
            "Digitalisering is tegenwoordig overal te zien, bedrijven zetten hier steeds meer op in omdat het veel tijd en geld kan besparen. Daarbij is data de laatste tijd ook steeds belangrijker geworden, voornamelijk het analyseren van data. Met het analyseren van data kan je namelijk leren van het verleden, conclusies trekken en patronen herkennen. Dit geldt ook voor je eigen bedrijf. DashBee maakt het gemakkelijk om bedrijfsgegevens op te slaan en te analyseren voor geweldige inzichten. ",
        },
        {
          title: "Moet ik gelijk betalen?",
          description:
            "Nee, dat hoeft niet! Je kan gratis een account aanmaken en alle functionaliteiten in DashBee testen. De eerst maand is helemaal gratis en als je het dan nog steeds niet zeker weet kunnen we de gratis periode verlengen met nog een maand!🥰",
        },
        {
          title: "Waarom zou ik een abonnement op DashBee willen?",
          description:
            "DashBee is ontwikkeld om MKB'ers en zelfstandigen te helpen met administratieve taken. Daarnaast is DashBee de ideale tool om bedrijfsprocessen te analyseren om vervolgens te optimaliseren. Betalende klanten kunnen ook direct contact opnemen met de ontwikkelaars om extra functionaliteiten te bespreken. Betalende klanten zorgen ervoor dat DashBee kan blijven bestaan. Daar zijn wij heel erg dankbaar voor!🥰",
        },
        {
          title:
            "Is het mogelijk om een demo van het dashboard te krijgen voordat ik mijn bedrijf registreer?",
          description: "Jazeker! Vraag een Demo aan via het contactformulier.",
        },
        {
          title: "Kan ik mijn huidige bedrijfsgegevens importeren?",
          description:
            "Alle bedrijfsgegevens kunnen geïmporteerd worden, hier helpen we je graag bij!",
        },
        {
          title:
            "Wat gebeurt er met mijn bedrijfsgegevens als ik mijn abonnement opzeg?",
          description:
            "De verzamelde data is van jou en blijft van jou. Alle data kan te allen tijde geëxporteerd worden. Als je dit wilt doen kan je het aanvragen via het contactformulier. Wij zullen je account na het annuleren verwijderen uit de database.",
        },
        {
          title: "Is er een optie voor maatwerk?",
          description:
            "Maatwerk is onze passie! Vertel ons over je ideeën en laten we kijken wat we samen kunnen bereiken. Dit hoeft niet per se tot extra kosten te leiden. Indien het een functionaliteit is die voor meerdere DashBee gebruikers geldt, kunnen wij de functionaliteit zonder extra kosten in DashBee implementeren.",
        },
        {
          title: "Wie gebruikt al DashBee?",
          description:
            "DashBee is begin maart 2021 live gegaan en sindsdien systematisch uitgebreidt in functionaliteit. Wij hebben op dit moment een aantal klanten die dit voor ons grondig testen. Uiteraard gebruiken we DashBee ook zelf voor ons eigen administratie en registratie.😎",
        },
        {
          title:
            "Ik weet het nog niet zeker, kan ik het nog een tijdje gratis proberen?",
          description:
            "Weet je het nog niet zeker? Maak je geen zorgen, we kunnen de testperiode eenvoudig verlengen met nog een maand.👌",
        },
      ],
    },
    pricing: {
      title: "Accordion",
      sub_title: "Veel Gestelde Vragen",
      more_info: "Meer informatie",
      questions: [
        {
          title: "Is DashBee maandelijks opzegbaar?",
          description:
            "DashBee kan op ieder moment opgezegd worden in het dashboard. Je kunt gewoon gebruik blijven maken van DashBee totdat je abonnement afloopt. Wanneer deze afloopt, wordt het contract niet verlengd.",
        },
        {
          title: "Waar wordt mijn data opgeslagen?",
          description:
            "Alle data binnen DashBee wordt beveiligd opgeslagen. Voor gevoelige data gebruiken wij encryptie. Alle data wordt fysiek in Noordwest Europa opgeslagen.",
        },
        {
          title: "Hoe kan ik mijn abonnement upgraden?",
          description:
            "Op de abonnementen-pagina kan je inzien welk abonnement actief is en of het mogelijk is voor jou om te upgraden of downgraden. Dit wordt automatisch verwerkt in de maandelijks of jaarlijkse betaling. Ben je op zoek naar bepaalde functies die (nog) niet in DashBee zitten? Neem dan contact op met Gideloff BV.",
        },
        {
          title: "Waar kan ik mijn kortingscode invullen?",
          description:
            "Bij het aanschaffen van een abonnement kan je op de checkout-pagina een kortingscode invoeren. Deze wordt gecontroleerd op geldigheid en automatisch verwerkt met de eerstvolgende betaling.",
        },
        {
          title: "Kan ik mijn data exporteren?",
          description:
            "Je kan alle data met betrekking tot jouw bedrijfsaccount en/of persoonlijke account exporteren naar CSV of Excel.",
        },
        {
          title:
            "Hoe lang is mijn abonnement nog geldig nadat ik mijn abonnement heb opgezegd?",
          description:
            "Op de abonnementen-pagina zie je hoeveel dagen er nog resterend zijn. Als je voor de einddatum het abonnement opzegt, zal deze stoppen op de einddatum, anders wordt het abonnement automatisch verlengd.",
        },
        {
          title: "Kan ik contact opnemen met de ontwikkelaars?",
          description:
            "DashBee is ontwikkeld en wordt onderhouden door Gideloff BV. Je kan contact opnemen met Gideloff, telefonisch of via e-mail.",
          link: "https://gideloff.com/nl/contact",
        },
      ],
    },
  },

  meldingen: {
    gelukt: "Gelukt",
    waarschuwing: "Let op",
    je: "Je ",
    is_opgeslagen: " is opgeslagen",
    email_leeg: "Vul een geldig e-mailadres in",
    account_verwijderd: "Dit account is verwijderd uit de organisatie",
    verifieer_account: "Verifieer je account met de link in je mail",
    wachtwoord_incorrect: "Het ingevoerde wachtwoord klopt niet",
    geen_account: "Er bestaat geen account met dit e-mailadres",
    mail_verstuurd: "Mail verstuurd, check je inbox!",
    naam_3_letters: "Vul een naam in met minstens 3 letters",
    wachtwoord_6_karakters:
      "Je wachtwoord moet uit minstens 6 karakters bestaan",
    bedrijfsnaam_leeg: "Vul een bedrijfsnaam in",
    naam_leeg: "Vul een naam in",
    rechtsvorm_leeg: "Kies een rechtsvorm",
    email_reeds_gekozen: "Er bestaat al een account met dit e-mailadres",
    privacy_beleid: "Je moet het Privacy Beleid accepteren",
    uitnodiging_verkeerd_email:
      "De uitnodiging staat op naam van een ander e-mailadres",
    uren_opgeslagen: "Je uren zijn opgeslagen",
    uren_verwijderd: "Uren verwijderd",
    datum_ongeldig: "Vul een geldige datum in",
    datum_leeg: "Vul een datum in",
    project_opmerking_leeg: "Kies een project of voeg een opmerking toe",
    uren_leeg: "Je moet minstens een halfuur hebben gewerkt",
    nohours_year1: "Je hebt in ",
    nohours_company_year: "Er zijn in ",
    nohours_user_year: " heeft geen uren gemaakt in ",
    nohours_year2: " geen uren gemaakt",
    nocosts: " geen kosten gemaakt",
    day_24hours: "Voor zover wij weten zit er maar 24 uur in een dag",
    verlofsaldo_onvoldoende: "Je hebt niet genoeg verlofsaldo",
    verlof_overlap:
      "Je aanvraag overlapt met een andere aanvraag, pas de datum/tijd aan",
    aanvraag_ingediend: "Je aanvraag is ingediend",
    aanvraag_opgeslagen: "Aanvraag opgeslagen",
    aanvraag_verwijderd: "Aanvraag verwijderd",
    aanvraag_reeds_behandeld: "Deze aanvraag is al behandeld",
    aanvraag_behandeld_verwijderen:
      "Behandelde aanvragen kunnen alleen door een admin worden verwijderd",
    kosten_opgeslagen: "Kosten opgeslagen",
    kosten_verwijderd: "Kosten verwijderd",
    kosten_leeg: "Je moet minstens een cent aan kosten hebben",
    kosten_negatief: "Kosten kunnen niet negatief zijn",
    kosten_type_leeg: "Kies een kosten type",
    project_opgeslagen: "Project opgeslagen",
    project_leeg: "Vul een project naam in",
    project_verwijderd: "Project verwijderd",
    project_teruggezet: "Project teruggezet",
    foutmelding: "Er ging iets mis, probeer het straks nog eens",
    max_60_char: "Maximaal aantal toegestane karakters is 60",
    gebruiker_bestaat_al: " heeft al een DashBee account",
    gebruiker_reeds_uitgenodigd: " is al uitgenodigd",
    gebruiker_aangepast: "Gebruiker aangepast",
    gebruiker_verwijderd: "Gebruiker verwijderd",
    verlofdagen_aangepast: "Verlofdagen van het team zijn aangepast",
    jezelf_bewerken: "Je kan jezelf niet bewerken",
    jezelf_verwijderen: "Je kan jezelf niet verwijderen",
    naam_opgeslagen: "Je naam is opgeslagen",
    functie_opgeslagen: "Je functie is opgeslagen",
    foto_opgeslagen: "Je profielfoto is opgeslagen",
    bedrijfslogo_opgeslagen: "Bedrijfslogo opgeslagen",
    evenement_opgeslagen: "Kalender item is opgeslagen",
    evenement_verwijderd: "Kalender item is verwijderd",
    evenement_leeg: "Vul een titel in",
    factuur_periode_leeg: "Selecteer een periode",
    factuur_project_leeg: "Selecteer een project",
    factuur_klant_leeg: "Selecteer een klant",
    factuur_geen_uren_gevonden: "Geen uren gevonden",
    factuur_geen_kosten_gevonden: "Geen kosten gevonden",
    factuur_geen_project_tarief_gevonden:
      "Geen uurtarief gevonden voor project",
    factuur_opgeslagen: "Factuur opgeslagen",
    factuur_verwijderd: "Factuur verwijderd",
    bedrijfsveld_leeg:
      " is nog niet ingevuld in je bedrijfsgegevens. Doe dit in Mijn Bedrijf.",
    klantgegevens_leeg: " is nog niet ingevuld in klant gegevens.",
    klant_opgeslagen: "Klant opgeslagen",
    klant_verwijderd: "Klant verwijderd",
    klantnaam_leeg: "Vul een klantnaam in",
    afbeelding_upload_gefaald:
      "Het uploaden van de afbeelding is mislukt, probeer het straks opnieuw",
  },
  form: {
    enter_email: "Vul een e-mailadres in",
    enter_role: "Kies een rol",
    enter_project_access: "Wijs minimaal 1 project aan de gebruiker toe",
    enter_project: "Kies een project",
    no_projects: "Er zijn geen actieve projecten",
    enter_hours: "Vul het aantal uren in",
    enter_date: "Kies een datum",
  },
  day: "Dag",
  week: "Week",
  month: "Maand",
  quarter: "Kwartaal",
  year: "Jaar",
  per: "Per",
  total: "Totaal",
  hours: "Uur",
};
