import firebase from "firebase";

export function getClientsForCompany(companyId) {
  return new Promise((resolve, reject) => {
    let clients = firebase
      .database()
      .ref("clients")
      .orderByChild("companyId")
      .equalTo(companyId);
    clients.on("value", (snapshot) => {
      if (!!snapshot.val()) {
        let clientArray = Object.entries(snapshot.val())
          let clientsForSelect = [];
          for (let i = 0; i < clientArray.length; i++) {
            let object = {
              id: clientArray[i][0],
              text: clientArray[i][1].name,
            };
            clientsForSelect.push(object);
          }
          resolve(clientsForSelect)
      }
    });
  })
}

export function getClientInfo(clientId) {
  return new Promise((resolve, reject) => {
    let clients = firebase.database().ref("clients/" + clientId);
    clients.on("value", (snapshot) => {
      let client = snapshot.val();
      if (!!client) {
        resolve(client)
      } else {
        reject("No client data found")
      }
    });
  })
}