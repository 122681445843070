import firebase from "firebase";
import { getActiveProjects } from "./costs";
import _ from "lodash";

export function getProjectsForCompany(companyId) {
  return new Promise((resolve, reject) => {
    let projects = firebase
      .database()
      .ref("projects")
      .orderByChild("companyId")
      .equalTo(companyId);
    projects.on("value", (snapshot) => {
      if (!!snapshot.val()) {
        getActiveProjects(snapshot).then((projectsArray) => {
          let projectsForSelect = [];
          for (let i = 0; i < projectsArray.length; i++) {
            let object = {
              id: projectsArray[i].id,
              text: projectsArray[i].name,
            };
            projectsForSelect.push(object);
          }
          resolve(projectsForSelect);
        });
      }
    });
  });
}

export const getProjectHourlyRateByProjectId = (projects, projectId) => {
  if (!!projects && !!projectId) {
    const foundProject = _.find(
      projects,
      (project) => project.key === projectId
    );
    if (!!foundProject && !!foundProject.value.hourlyRate) {
      return parseFloat(foundProject.value.hourlyRate);
    } else return null;
  } else return null;
};
